import { GET_ACTIVE_PAGE, UPDATE_ACTIVE_PAGE, ADD_ACTIVE_PAGE, CLEAR_ALL } from "constants/actions";


const initialState = {
    lead: 0,
    task: 0,
    inventory: 0,
    valid_inventory: 0,
    customer: 0,
    landingpage: 0,
    creditapplication: 0,
    comment: 0,
    trade: 0,
    testdrive: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ACTIVE_PAGE:
            return {  ...state};
        case UPDATE_ACTIVE_PAGE:
            return {  ...state,
                [action.payload.name]: action.payload.page};
        case CLEAR_ALL:
            return {
                ...state,
                leads: 0,
                tasks: 0,
                inventory: 0,
            };
        default:
            return state;
    }
}
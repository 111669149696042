import { GET_INVENTORY_FINANCE, GET_INVENTORY_FINANCE_PENDING, FETCH_INVENTORY_FINANCE, EDIT_INVENTORY_FINANCE,
    ADD_INVENTORY_FINANCE, CLEAR_ALL, DELETE_INVENTORY_FINANCE, } from "constants/actions";

const initialState = {
    inventoryfinance: [],
    loadinginventoryfinance: false,
    pending: false
};

export default function (state = initialState, action) { 
    switch (action.type) {
        case GET_INVENTORY_FINANCE_PENDING:
            return {
                ...state,
                pending: true,
                loadinginventoryfinance: true,
            }
        case GET_INVENTORY_FINANCE:
            return {
                ...state,
                inventoryfinance: action.payload, 
                loadinginventoryfinance: false,
                pending: false
            };
        case FETCH_INVENTORY_FINANCE:
            return { ...state, [action.payload.inventory_id]: action.payload, loadinginventoryfinance: false };

        case EDIT_INVENTORY_FINANCE:
            return { ...state, [action.payload.inventory_id]: action.payload, loadinginventoryfinance: false };

        case DELETE_INVENTORY_FINANCE:
            return {
                ...state,
                inventoryfinance: state.inventoryfinance.filter(Inventory => Inventory.id !== action.payload)
            };
        case ADD_INVENTORY_FINANCE:
            return {
                ...state,
                inventoryfinance: [...state.inventoryfinance, action.payload], loadinginventoryfinance: false
            };
        case CLEAR_ALL:
            return {
                ...state,
                inventoryfinance: []
            };
        default:
            return state;
    }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_INVENTORIES:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, inventories: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }

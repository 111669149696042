import React, { useCallback, useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { SG_ADD_INVENTORYIMAGE, LOAD_INVENTORYIMAGES, SG_DELETE_INVENTORYIMAGE } from "constants/actions";
import ImageCard from "./ImageCard"
// reactstrap components
import { Alert, Button, Container, Row, Col } from "reactstrap";

// Import the dropzone component
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import update from "immutability-helper";
import cuid from "cuid";

import { isTouchDevice } from "./utils";

import "./dropimage.css";
import Dropzone from "./DropZone";
import ImagePreview from "./ImagePreview";

import ImageGallery from "./ImageGallery"

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const DropImage = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  // Create a state called images using useState hooks and pass the initial value as empty array
  const [images, setImages] = useState([]);
  const [auth, setAuth] = React.useState([]);
  const [fileErrors, setFileErrors] = React.useState([]);
  const [imageSizeError, setImageSizeError]= React.useState(false)

  const [carImages, setCarImages] = React.useState(props.images);
  const notify2 = () => toast.success(`Uploaded File successfully`);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log("Load API")
    dispatch({ type: LOAD_INVENTORYIMAGES, payload: id });
  }, [dispatch]);

  const { get_inventory_images, get_errors, get_auth } = useSelector(
    (state) => ({
      get_inventory_images: state.inventoryimages.inventoryimages,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_inventory_images) {
      var files = _.orderBy(get_inventory_images, "sort_order", "asc");
      setCarImages(files);
    }
    if (get_errors) {
      setFileErrors(get_errors);
    }
    if (get_auth) {
      setAuth(get_auth);
    }
  }, [get_inventory_images, get_errors, get_auth]);

  // onDrop function
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      // console.log(file)
      const reader = new FileReader();
      reader.onload = function (e) {
          console.log(file)
        setImages((prevState) => [
          ...prevState,
          { id: cuid(), src: e.target.result, file: file },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = images[dragIndex];
    setImages(
      update(images, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedImage],
        ],
      })
    );
  };

  const onSubmit = () => {
    // console.log(data)
    images.map((p, i) => {
      // console.log(p.id)
      // console.log(p.file)
      //
      dispatch({
        type: SG_ADD_INVENTORYIMAGE,
        payload: {
          inventory: id,
          profile_id: 1,
          sort_order: i,
          company: auth.dealer_id,
          image: p.file,
        },
      }); //
    });
    setImages([]);
    // e.target.reset(); // reset after form submit
    notify2();
  };

  const deleteImage = (e) => {
    const newCar = images.filter((fl) => fl.id !== e);
    setImages(newCar);
  };
  const deleteAllImages = () => {
    setImages([]);
  };

  const handleRemove = () => {
    //
    carImages.map(p => (
      // console.log(p)
      dispatch({
        type: SG_DELETE_INVENTORYIMAGE,
        payload: { id: p.id, inventory_id: p.inventory },
      })
    ))
    
  };
  function imageError(e) {
    if (imageSizeError) {
      setImageSizeError(e)
    } else {
      setImageSizeError(e)
    }

  }
  // We pass onDrop function and accept prop to the component. It will be used as initial params for useDropzone hook
  return (
    <>
      <Container>
       
      <div className="d-flex justify-content-between">
        <Button
          color="danger"
          disabled={carImages.length > 0 ? false : true}
          onClick={handleRemove}
        >
          Remove All
        </Button>
        </div>
        {imageSizeError? <Alert color="danger">
       One or more Errors in Image Size {imageSizeError}, please correct before uploading
      </Alert> : '' }
        {/* <Row>
          {carImages
            ? carImages.map((p, k) => (
                <Col md="4" sm="12" key={k}>
                  <ImageCard
                    imageUrl={p.regimage}
                    id={p.id}
                    name={p.image_name}
                    imgHeight={p.height}
                    imgWidth={p.width}
                    editable={true}
                  />
                </Col>
              ))
            : "Loading ..."}
        </Row> */} 
        <h3 className="text-center">Drag and Sort Images</h3>
        <ImageGallery />
      </Container>
      <h3 className="text-center">Drag and Drop Images (Max 1 MB )</h3>
      <Dropzone onDrop={onDrop} accept={"image/*"} />
      {images && images.length > 0 && (
        <h3 className="text-center">Drag the Images to change positions</h3>
      )}
      <DndProvider backend={backendForDND}>
        <ImagePreview
          images={images}
          deleteImage={deleteImage}
          moveImage={moveImage}
          imageError={imageError}
        />
      </DndProvider>

      <div className="d-flex justify-content-between">
        <Button
          color="danger"
          disabled={images.length > 0 ? false : true}
          onClick={deleteAllImages}
        >
          Remove All
        </Button>
        
        <Button
          color="success"
          disabled={images.length > 0 ? imageSizeError? true :false : true}
          onClick={onSubmit}
        >
          Upload
        </Button>
      </div>
      {imageSizeError? <Alert color="danger">
        One or more Errors in Image Size {imageSizeError}, please correct before uploading
      </Alert> : '' }
    </>
  );
};

export default DropImage;

import React from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_WEB_LEAD_LIST,
} from "constants/actions";

import {
    Button,

  } from "reactstrap";
const Download = ({pageParams, fetch, setFetch}) => {
    const dispatch = useDispatch();
  const [webLeadList, setWebLeadList] = React.useState([]);


  function GetList() {
    dispatch({
      type: LOAD_WEB_LEAD_LIST,
      payload: pageParams,
    });
    
  }

  const { get_weblead_list, get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_weblead_list: state.webleads.webleadslist,
    }),
    shallowEqual
  );
  
console.log(get_weblead_list)

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_weblead_list.length > 0) {
      setWebLeadList(get_weblead_list);
      setFetch(true)
    }
  }, [get_weblead_list]);

  return (
    <>
    { get_auth?
        get_auth?.role_id < 6? 
        fetch? 
       <CSVLink
        data={webLeadList}
        filename={"web-leads-list.csv"}
        className="btn btn-success"
        target="_blank"
      >
        Download Leads CSV
      </CSVLink>  : 
      <Button onClick={GetList} className="btn btn-primary">
      Start Lead Download</Button>
    : ''
    : ''}
    </>
  );
};

export default Download;

import {  GET_WEB_COMMENTS, DELETE_WEB_COMMENT,
    ADD_WEB_COMMENT,  
    FETCH_WEB_COMMENT,  
    EDIT_WEB_COMMENT, 
    CLEAR_WEB_COMMENT,
    CLEAR_ALL } from "constants/actions";


const initialState = {
    web_comments: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_WEB_COMMENTS:
            return {
                ...state,
                web_comments: action.payload
            };
        case FETCH_WEB_COMMENT:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_WEB_COMMENT:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_WEB_COMMENT:
            return {
                ...state,
                web_comments: state.web_comments.filter(w => w.id !== action.payload)
            };
        case ADD_WEB_COMMENT:
            return {
                ...state,
                web_comments: [...state.web_comments, action.payload]
            };
        case CLEAR_WEB_COMMENT:
            return {
                ...state,
                web_comments: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                web_comments: []
            };
        default:
            return state;
    }
}


import { RESET_YEARS, RESET_MODELS, RESET_MAKES, CLEAR_ALL } from "constants/actions";

const initialState = {
    years: false,
    makes: false,
    models: false


};

export default function (state = initialState, action) {
    switch (action.type) {

        case RESET_YEARS:
            return {
                ...state,
                years: action.payload
            };

        case RESET_MAKES:
            return {
                ...state,
                makes: action.payload
            };

        case RESET_MODELS:
            return {
                ...state,
                models: action.payload
            };
            
        case CLEAR_ALL:
            return {
                ...state,
                paginate: []
            };
        default:
            return state;
    }
}
import { put, all, takeLatest } from 'redux-saga/effects'
import { CLEAR_ALL, SG_CLEAR_ALL } from "constants/actions";

function* loadClearAll(action) {
    yield put({ type: CLEAR_ALL });
    
}

export function* clearAllLoad() {
   yield takeLatest(SG_CLEAR_ALL, loadClearAll);
}

export default function* index() {
    yield all([clearAllLoad()]);
  }
import { put, call, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    LOAD_LEAD_STAGE,
    GET_LEAD_STAGES,
    GET_DEFAULT_LEAD_STAGES,
    LOAD_DEFAULT_LEAD_STAGE,
    DELETE_LEAD_STAGE,
    SG_DELETE_LEAD_STAGE,
    ADD_LEAD_STAGE,
    SG_ADD_LEAD_STAGE,
    FETCH_LEAD_STAGE,
    SG_FETCH_LEAD_STAGE,
    UPDATE_LEAD_STAGE,
    EDIT_LEAD_STAGE,
  ALL_ERRORS,
} from "constants/actions";


function* loadWebLead(action) {

  try {
    const json = yield call(api.GET_LEAD_STAGES, action.payload);

    yield put({ type: GET_LEAD_STAGES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadLoad() {
  yield takeLatest(LOAD_LEAD_STAGE, loadWebLead);
}

function* loadDefaultWebLead() {

  try {
    const json = yield call(api.GET_LEAD_STAGES, 'company=1');

    yield put({ type: GET_DEFAULT_LEAD_STAGES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadLoadDefault() {
  yield takeLatest(LOAD_DEFAULT_LEAD_STAGE, loadDefaultWebLead);
}

function* fetchWeblead(action) {

  try {
    const json = yield call(api.FETCH_LEAD_STAGE, action.payload);

    yield put({ type: FETCH_LEAD_STAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadFetchOne() {
  yield takeLatest(SG_FETCH_LEAD_STAGE, fetchWeblead);
}

function* editWebLead(action) {
  try {
    const json = yield call(api.EDIT_LEAD_STAGE, action.payload);

    yield put({ type: EDIT_LEAD_STAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadEdit() {
  yield takeLatest(UPDATE_LEAD_STAGE, editWebLead);
}

function* addWebLead(action) {
  try {
    const json = yield call(api.ADD_LEAD_STAGE, action.payload);

    yield put({ type: ADD_LEAD_STAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadAdd() {
  yield takeEvery(SG_ADD_LEAD_STAGE, addWebLead);
}

export default function* index() {
  yield all([
    webleadLoad(),
    webleadLoadDefault(),
    webleadFetchOne(),
    webleadEdit(),
    webleadAdd(),
  ]);
}

import React from "react";
import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import { NEW_LANDINGPAGE } from "constants/actions";

// import task_data from "Data/task_data.json"
import List from "./List";

import { Button as MUIButton } from "@mui/material";
import AddNewLandingPage from "./AddNewLandingPage";

export default () => {
  const dispatch = useDispatch();
  const NewLandingPage = () => {
    dispatch({ type: NEW_LANDINGPAGE });
  };

  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };
  
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <CardHeader>
                      <CardTitle tag="h4">Landing Pages</CardTitle>
                      <h5 className="card-category">
                        List of All Landing Pages
                      </h5>
                    </CardHeader>
                  </Col>
                  <Col className="ml-auto" md="2">
                    {/* <Button
                      color="default"
                      size="sm"
                      onClick={NewLandingPage}
                      to="/admin/landing-page-create"
                      tag={Link}
                    >
                      Create New
                    </Button> */}
                    <MUIButton
                      color="preAuto"
                      onClick={onOpenAddTask}
                      variant="contained"
                    >
                      Create New
                    </MUIButton>
                  </Col>
                </Row>

                <List />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <AddNewLandingPage
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

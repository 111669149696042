import { GET_DEALERS, DELETE_DEALER, ADD_DEALER, 
    CLEAR_DEALER, CLEAR_ALL, FETCH_DEALER, 
    JSON_LOGGED_DEALER, FETCH_RELATED_DEALER, 
    EDIT_DEALER } from "constants/actions";


const initialState = {
    dealers: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEALERS:
            return {
                ...state,
                dealers: action.payload
            };
        case JSON_LOGGED_DEALER:
            return { ...state, logged: action.payload };
        
        case FETCH_RELATED_DEALER:
                return { ...state, related: action.payload };
                
        case FETCH_DEALER:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_DEALER:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_DEALER:
            return {
                ...state,
                dealers: state.dealers.filter(dealer => dealer.id !== action.payload)
            };
        case ADD_DEALER:
            return {
                ...state,
                dealers: [...state.dealers, action.payload]
            };
        case CLEAR_DEALER:
            return {
                ...state,
                dealers: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                dealers: []
            };
        default:
            return state;
    }
}

import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from 'constants/api';

import {LOAD_LANDINGPAGES, GET_LANDINGPAGES, GET_LANDINGPAGES_PENDING,
    FETCH_LANDINGPAGE, 
    SG_FETCH_LANDINGPAGE,EDIT_LANDINGPAGE, UPDATE_LANDINGPAGE, 
     ADD_LANDINGPAGE, CREATE_LANDINGPAGE, 
UPLOAD_LANDINGPAGE_IMAGE, ADD_LANDINGPAGE_IMAGE, 
DELETE_LANDINGPAGE,
REMOVE_LANDINGPAGE_IMAGE, 
NEW_A_LANDINGPAGE,
NEW_LANDINGPAGE,
DELETE_LANDINGPAGE_IMAGE, 
ALL_ERRORS,CLEAR_LANDINGPAGE, 
 } from 'constants/actions';

function* loadLandingpages(action) {
    yield put({ type: GET_LANDINGPAGES_PENDING });
    // console.log('action.payload', action.payload)
    try {
      const json = yield call(api.GET_LANDING_PAGES, action.payload)
  
      yield put({ type: GET_LANDINGPAGES, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* landingPagesLoad() {
    yield takeLatest(LOAD_LANDINGPAGES, loadLandingpages);
  }
  

  function* fetchLandingpages(action) {
    try {
      const json = yield call(api.FETCH_LANDING_PAGE, action.payload)
  
      yield put({ type: FETCH_LANDINGPAGE, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* landingPagesFetch() {
    yield takeLatest(SG_FETCH_LANDINGPAGE, fetchLandingpages);
  }

  function* updateLandingpages(action) {
    try {
      const json = yield call(api.EDIT_LANDING_PAGE, action.payload)
  
      yield put({ type: EDIT_LANDINGPAGE, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* landingPagesUpdate() {
    yield takeLatest(UPDATE_LANDINGPAGE, updateLandingpages);
  }

  function* addLandingpages(action) {
    try {
      const json = yield call(api.ADD_LANDING_PAGE, action.payload)
  
      yield put({ type: ADD_LANDINGPAGE, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* landingPagesAdd() {
    yield takeLatest(CREATE_LANDINGPAGE, addLandingpages);
  }


  function* addLandingpageImage(action) {

    try {
      const json = yield call(api.ADD_LANDING_PAGE_IMAGE, action.payload)
  
      yield put({ type: ADD_LANDINGPAGE_IMAGE, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* landingPagesImageAdd() {
    yield takeLatest(UPLOAD_LANDINGPAGE_IMAGE, addLandingpageImage);
  }

  function* deleteLandingpages(action) {
    try {
      const json = yield call(api.DELETE_LANDING_PAGE, action.payload)
  
      yield put({ type: DELETE_LANDINGPAGE, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* landingPagesDelete() {
    yield takeLatest(REMOVE_LANDINGPAGE_IMAGE, deleteLandingpages);
  }

  function* loadLandingpageNew() {
    yield put({ type: NEW_A_LANDINGPAGE });
  }
  
  export function* landingPagesNew() {
    yield takeLatest(NEW_LANDINGPAGE, loadLandingpageNew);
  }

export default function* index() {
yield all([
    landingPagesLoad(),
  landingPagesFetch(),
  landingPagesUpdate(),
  landingPagesAdd(),
  landingPagesImageAdd(),
  landingPagesDelete(),
  landingPagesNew(),
]);
}
import React, { useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import VerticalCollapse from "../VerticalCollapse";
import VerticalItem from "../VerticalItem";
import VerticalNavGroupItem from "./VerticalNavGroupItem";

const VerticalNavGroup = ({ item, level }) => {

  return (
    <>
      <VerticalNavGroupItem
        level={level}
        sidebarTextColor={'rgba(0, 0, 0, 0.7)'}
        component="div"
        className={clsx("nav-item nav-item-header")}
      >
        {item.name}
      </VerticalNavGroupItem>

      {item.children && (
        <>
          {item.children.map((item) => (
            <React.Fragment key={item.id}>
              {item.type === "group" && (
                <NavVerticalGroup item={item} level={level} />
              )}

              {item.type === "collapse" && (
                <VerticalCollapse item={item} level={level} />
              )}

              {item.type === "item" && (
                <VerticalItem item={item} level={level} />
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

VerticalNavGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    permittedRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    messageId: PropTypes.string,
    children: PropTypes.array,
  }),
  level: PropTypes.number,
};

VerticalNavGroup.defaultProps = {};

const NavVerticalGroup = VerticalNavGroup;

export default NavVerticalGroup;

import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_LEADTYPES,
  SG_ADD_LEADTYPE,
  LOAD_DEFAULT_LEADTYPES,
} from "constants/actions";

import ReactPaginate from "react-paginate";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile, deviceType
// } from "react-device-detect";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  // Table,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const Leadsource = (props) => {
  const dispatch = useDispatch();
  const [params, setParams] = React.useState(props.params);
  const { id, slug } = useParams();
  const [auth, setAuth] = React.useState([]);
  const [leadTypes, setLeadTypes] = React.useState([]);
  const [generateRows, setGenerateRows] = React.useState(false);

  const search = window.location.search;
  const queryString = new URLSearchParams(search);

  let CompanyId = queryString.get("company-id");

  React.useEffect(() => {
    dispatch({ type: LOAD_LEADTYPES, payload: params });
  }, [dispatch, params]);

  const { lead_types, default_lead_types, get_auth } = useSelector(
    (state) => ({
      lead_types: state.leadtypes.leadtypes,
      get_auth: state.auth,
      default_lead_types: state.leadtypes.default,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (lead_types) {
      setLeadTypes(lead_types);
    }
  }, [lead_types]);

  const GenerateDefault = () => {
    dispatch({ type: LOAD_DEFAULT_LEADTYPES });
    setGenerateRows(true);
  };

  if (auth?.dealer_id) {
    CompanyId = Number(get_auth.dealer_id);
  }

  React.useEffect(() => {
    setAuth(get_auth);
  }, [get_auth]);

  React.useEffect(() => {
    if (default_lead_types) {
      if (generateRows) {
        default_lead_types.map((p) => {
          //  console.log({...p,  company: parseInt(props.id)})
          dispatch({
            type: SG_ADD_LEADTYPE,
            payload: { ...p, company: parseInt(props.id) },
          });
        });
        setGenerateRows(false);
      }
    }
  }, [default_lead_types, generateRows]);

  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Lead Type</CardTitle>
                <h5 className="card-category">Evaluate all Lead Types</h5>
              </Col>
              <Col className="ml-auto" md="2">
                {/* {CompanyId&&
                <Button
                      color="default"
                      size="sm"
                      to={`/admin/lead-type-create?company-id=${CompanyId}`}                      tag={Link}
                    >
                      Create New
                    </Button>
                    } */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                {leadTypes.length > 0 ? (
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="center">
                              Order
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Active
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Internal URL
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Started On
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leadTypes.map((p, k) => (
                            <StyledTableRow key={k}>
                              <StyledTableCell>{p.name}</StyledTableCell>
                              <StyledTableCell align="center">
                                {p.sort_order}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {p.active ? "Yes" : "No"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {p.internal_url}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {moment(p.start_date).format("YYYY-MM-DD")}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Link to={`/admin/lead-type-detail/${p.id}`}>
                                  <RemoveRedEyeOutlinedIcon
                                    size={40}
                                    color={"preAuto"}
                                  />
                                </Link>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
       rowsPerPageOptions={[12]}
       component="div"
       count={totalResults}
       rowsPerPage={12}
       page={page}
       onPageChange={handleChangePage}
       ActionsComponent={TablePaginationActions}
       sx={{
         ".MuiTablePagination-selectLabel": {
           marginTop: "auto",
         },
         ".MuiTablePagination-displayedRows": {
           marginTop: "auto",
         },
       }}
     /> */}
                  </Paper>
                ) : props.showDefault ? (
                  <Button color="warning" size="sm" onClick={GenerateDefault}>
                    Generate Lead Stage
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            {/* <div className="stats">
            <i className="fa fa-refresh spin" />
            Updated 3 minutes ago
          </div> */}
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default Leadsource;

import {
  GET_TRADE_INS_PENDING,
  GET_TRADE_INS,
  GET_ADMIN_TRADE_INS,
  FETCH_TRADE_INS,
  DELETE_TRADE_IN,
  NEW_TRADE_IN,
  ADD_TRADE_IN,
  EDIT_TRADE_INS,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  tradeins: [],
  admintradeins: [],
  created: null,
  loadingtradeins: false,
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRADE_INS_PENDING:
      return {
        ...state,
        pending: true,
        loadingtradeins: true,
        // 
      };
    case GET_ADMIN_TRADE_INS:
      return {
        ...state,
        admintradeins: action.payload,
        loadingtradeins: false,
        pending: false,
      };
    case GET_TRADE_INS:
      return {
        ...state,
        tradeins: action.payload,
        loadingtradeins: false,
        pending: false,
      };

    case FETCH_TRADE_INS:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loadingtradeins: false,
        // created: false,
      };

    case EDIT_TRADE_INS:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loadingtradeins: false,
        created: null,
      };

    case DELETE_TRADE_IN:
      return {
        ...state,
        tradeins: state.tradeins.filter((Trade) => Trade.id !== action.payload),
      };
    case NEW_TRADE_IN:
      return {
        ...state,
        created: null,
        // 
      };
    case ADD_TRADE_IN:
      return {
        ...state,
        created: action.payload,
        loadingtradeins: false,
        pending: false,
        tradeins: [...state.tradeins, action.payload],
      };

    case CLEAR_ALL:
      return {
        ...state,
        tradeins: [],
      };

    default:
      return state;
  }
}

import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_INVENTORY_CUSTOMER,
  GET_CUSTOMER_INVENTORY,
} from "constants/actions";

// reactstrap components
import { Spinner, Fade, Row, Col } from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
  Box,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const CustomerInterested = ({ inventory_id, customer_id }) => {
  const dispatch = useDispatch();
  const [webLeads, setWebLeads] = React.useState([]);

  React.useEffect(() => {
    if (inventory_id) {
      dispatch({
        type: SG_GET_INVENTORY_CUSTOMER,
        payload: { id: inventory_id },
      });
    }
  }, [dispatch, inventory_id]);

  React.useEffect(() => {
    if (customer_id) {
      dispatch({
        type: GET_CUSTOMER_INVENTORY,
        payload: { id: customer_id },
      });
    }
  }, [dispatch, customer_id]);

  const { get_web_leads } = useSelector(
    (state) => ({
      get_web_leads: state.webleads.inventory_customer.results,
      get_pending: state.webleads.pending,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setWebLeads(get_web_leads);
  }, [get_web_leads]);

  return (
    <>
      <div>
        <Row>
          <Col md="12">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Customer</StyledTableCell>
                      <StyledTableCell align="center">Source</StyledTableCell>
                      <StyledTableCell align="center">Stage</StyledTableCell>
                      <StyledTableCell align="center">
                        Lead Type
                      </StyledTableCell>
                      <StyledTableCell align="center">Created</StyledTableCell>
                      <StyledTableCell align="center">Assigned</StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {webLeads && webLeads.length
                      ? webLeads.map((p, k) => (
                          <StyledTableRow key={k}>
                            <StyledTableCell align="left">
                              {p.first_name} {p.last_name} <br />
                              <small>
                                {p.customer ? (
                                  <Link
                                    to={`/admin/customer-detail/${p.customer}`}
                                  >
                                    View
                                  </Link>
                                ) : null}
                              </small>
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              {p.web_lead_source
                                ? p.web_lead_source_name
                                : null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {p.web_lead_stage ? p.web_lead_stage_name : null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {p.web_lead_type ? p.web_lead_type_name : null}
                            </StyledTableCell>

                            <StyledTableCell StyledTableCell align="center">
                              {p.created_at
                                ? moment(p.created_at).format(
                                    "YYYY-MM-DD hh:mm a"
                                  )
                                : "No date given"}
                            </StyledTableCell>
                            <StyledTableCell StyledTableCell align="center">
                              {p.employee_first_name
                                ? `${p.employee_first_name} ${p.employee_last_name}`
                                : null}
                            </StyledTableCell>
                            <StyledTableCell StyledTableCell align="center">
                              <Link to={`/admin/lead-detail/${p.id}`}>
                                <RemoveRedEyeOutlinedIcon color={"preAuto"} />
                              </Link>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
            </Paper>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomerInterested;

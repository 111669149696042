import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  LOAD_CUSTOMER,
  SG_UPDATE_ACTIVE_PAGE,
  SEARCH_CUSTOMER_VALUE,
  CLEAR_SEARCH_CUSTOMER_VALUE,
} from "constants/actions";

import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";
import Select from "react-select";

// reactstrap components
import {
  Button,
  Collapse,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  // Table,
  Row,
  Col,
} from "reactstrap";

import "./customer.css";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TablePaginationActions from "mui/tables/TablePaginationActions";

const searchOptions = [
  { value: "phone", label: "Phone" },
  { value: "email", label: "email" },
  { value: "first_name", label: "First Name" },
  { value: "last_name", label: "Last Name" },
];

const List = () => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let qPhone = params.get("phone");
  let qEmail = params.get("email");
  let qFirstname = params.get("firstname");
  let qLastname = params.get("lastname");

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
    formState,
  } = useForm();

  const [customers, setCustomers] = React.useState([]);

  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState();
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);

  const [searchValue, setSearchValue] = React.useState("");
  const [searchError, setSearchError] = React.useState("");
  const [fadeIn, setFadeIn] = React.useState(false);

  React.useEffect(() => {
    // console.log(user)
    // /`&query=${searchValue}`
    if (searchValue) {
      dispatch({
        type: LOAD_CUSTOMER,
        payload: { page: pageactivePage, params: `&query=${searchValue}` },
      });
    } else {
      dispatch({
        type: LOAD_CUSTOMER,
        payload: { page: pageactivePage, params: pageParams },
      });
    }
  }, [dispatch, activePage, pageactivePage, pageParams, searchValue]);

  const {
    customer_list,
    get_auth,
    get_errors,
    get_activePage,
    customer_search_value,
  } = useSelector(
    (state) => ({
      customer_list: state.customer.customers,
      get_auth: state.auth,
      get_errors: state.errors,
      get_activePage: state.paginate.customer,
      customer_search_value: state.customer.search_value,
    }),
    shallowEqual
  );

  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (customer_list) {
      setCustomers(customer_list.results);
      setTotalResults(customer_list.count);
      setPerPage(customer_list.page_size);
      setTotalPages(Math.ceil(customer_list.count / customer_list.page_size));
    }
  }, [customer_list]);

  // console.log(customer_search_value)

  React.useEffect(() => {
    if (customer_search_value?.length > 0) {
      setSearchValue(customer_search_value);
    }
  }, [customer_search_value]);

  const [page, setPage] = React.useState(0);

  const handleClick = (event, newPage) => {
    // console.log('page',page)
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "customer", page: newPage + 1 },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };

  React.useEffect(() => {
    setPageActivePage(get_activePage);
    setActivePage(get_activePage);
  }, [get_activePage]);

  const onSubmit = (data) => {
    if (!searchValue) {
      setSearchError("Please enter a search value");
      return;
    }
    setPageParams(`&query=${searchValue}`);
    // }

    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "customer", page: 0 },
    });
    setActivePage(0);

    // console.log(pageParams)
    dispatch({
      type: LOAD_CUSTOMER,
      payload: { page: 1, params: `&query=${searchValue}` },
    });
    // console.log(data);
  };

  function SearchValue(e) {
    setSearchValue(e.target.value);
    dispatch({
      type: SEARCH_CUSTOMER_VALUE,
      payload: e.target.value,
    });
  }

  function ClearSearchValue() {
    setSearchValue("");
    dispatch({ type: CLEAR_SEARCH_CUSTOMER_VALUE });
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        {/* <Button color="primary" onClick={() => setFadeIn(!fadeIn)}>
          {fadeIn ? "Hide Filter" : "Show Filter"}
        </Button> */}
        {/* <div className={fadeIn? "visible": "invisible"}>
        <Button  color="secondary" size="sm" onClick={resetParams}>
          Reset 
        </Button></div> */}
      </div>

      {/* <Collapse isOpen={fadeIn} className="mt-3 mb-3"> */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="9">
            <label>Search For</label>
            {searchValue.length > 0 && (
              <span onClick={ClearSearchValue} className="linkbutton">
                Clear Search
              </span>
            )}

            {/* <Controller
                as={<Select />}
                name="search"
                options={searchOptions}
                control={control}
                className="text-dark"
                rules={{ required: "Please enter search" }}
              />{" "}
              <p className="text-danger small">
                {errors.search && <span>{errors.search.message}</span>}
              </p>
            </Col>
            <Col md="7" className="mb-3"> 
              <label>Value</label>*/}
            <Input
              type="text"
              name="value"
              value={searchValue}
              onChange={(e) => SearchValue(e)}
              placeholder="search for name, phone, email, mobile"
            />
            <p className="text-danger small">
              {searchError && <span>{searchError}</span>}
            </p>
          </Col>

          <Col md="2" className="mt-2">
            <Button color="primary" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      {/* </Collapse> */}
      <Row>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
        <Col md="12">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell align="center">Last Name</StyledTableCell>
                    <StyledTableCell align="center">Mobile</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Since</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers
                    ? customers.map((p, k) => (
                        <StyledTableRow key={k}>
                          <StyledTableCell>{p.first_name}</StyledTableCell>
                          <StyledTableCell align="center">
                            {p.last_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {p.phone}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {p.email}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {moment(p.created_at).format("YYYY-MM-DD")}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Link to={`/admin/customer-detail/${p.id}`}>
                              <RemoveRedEyeOutlinedIcon
                                size={40}
                                color={"preAuto"}
                              />
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    : "No customers found"}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[12]}
                component="div"
                count={totalResults || 0}
                rowsPerPage={
                  totalPages > 12 ? customers.length || 0 : customers || 0
                }
                page={page}
                onPageChange={handleClick}
                ActionsComponent={TablePaginationActions}
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    marginTop: "auto",
                  },
                  ".MuiTablePagination-displayedRows": {
                    marginTop: "auto",
                  },
                }}
              />
            </TableContainer>
          </Paper>
        </Col>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
      </Row>
    </>
  );
};

export default List;

import React from "react";
import { alpha, Box, Button, TextField, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AppGridContainer from "../../../../crema/AppGridContainer";
import Grid from "@mui/material/Grid";
import IntlMessages from "../../../../crema/helpers/src/IntlMessages";
import { useDropzone } from "react-dropzone";
import { Form } from "formik";
import PropTypes, { object } from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { Fonts } from "../../../../crema/constants/src/AppEnums";
import AppTextField from "../../../../crema/AppFormComponents/AppTextField";
import { useEffect } from "react";

const AvatarViewWrapper = styled("div")(({ theme }) => {
  return {
    position: "relative",
    cursor: "pointer",
    "& .edit-icon": {
      position: "absolute",
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: "50%",
      width: 26,
      height: 26,
      display: "none",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.4s ease",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        fontSize: 16,
      },
    },
    "&.dropzone": {
      outline: 0,
      "&:hover .edit-icon, &:focus .edit-icon": {
        display: "flex",
      },
    },
  };
});

const PersonalInfoForm = ({ user }) => {
  if (!user) return null;

  return (
    <>
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 4 },
        }}
      >
        <IntlMessages id="Personal Info" />
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: { xs: 5, lg: 6 },
        }}
      >
        <Avatar
          sx={{
            width: { xs: 50, lg: 64 },
            height: { xs: 50, lg: 64 },
            // cursor: "pointer",

            backgroundColor: "#1d4aa7",
          }}
        >
          {user?.first_name?.charAt(0) + user?.last_name?.charAt(0)}
        </Avatar>
        {/* <Box className="edit-icon">
          <EditIcon />
        </Box> */}

        <Box
          sx={{
            ml: 4,
          }}
        >
          <Typography
            sx={{
              fontWeight: Fonts.MEDIUM,
            }}
          >
            {user.first_name + " " + user.last_name}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {user.email}
          </Typography>
        </Box>
      </Box>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            name="company_name"
            fullWidth
            label={"Company ( Disabled )"}
            disabled
            value={user.company_name || "Dealer"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="email"
            fullWidth
            label={"Email"}
            defaultValue={user.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="first_name"
            label={"First Name"}
            defaultValue={user.first_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="last_name"
            label={"Last Name"}
            defaultValue={user.last_name}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            multiline
            name="notes"
            minRows={4}
            maxRows={8}
            fullWidth
            label={"About Me"}
            defaultValue={user.notes}
          />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                position: "relative",
                minWidth: 100,
              }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <IntlMessages id="common.saveChanges" />
            </Button>
            <Button
              sx={{
                position: "relative",
                minWidth: 100,
                ml: 2.5,
              }}
              color="primary"
              variant="outlined"
              type="cancel"
            >
              <IntlMessages id="common.cancel" />
            </Button>
          </Box>
        </Grid> */}
      </AppGridContainer>
    </>
  );
};

export default PersonalInfoForm;
PersonalInfoForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

import { put, call, all, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  GET_LEADSOURCES_PENDING,
  GET_LEADSOURCES, LOAD_LEADSOURCES,
  GET_DEFAULT_LEADSOURCES,
  LOAD_DEFAULT_LEADSOURCES,
  FETCH_LEADSOURCES,
  FETCH_LEADSOURCES_PENDING, 
  SG_FETCH_LEADSOURCES,
  DELETE_LEADSOURCE, 
  SG_DELETE_LEADSOURCE,
  ADD_LEADSOURCE,
  EDIT_LEADSOURCES, UPDATE_LEADSOURCES,
  SG_ADD_LEADSOURCE,
  ALL_ERRORS, 
  VALIDATION_ERRORS
} from "constants/actions";

import * as api from '../constants/api';



function* loadLeadSource(action) {
  yield put({ type: GET_LEADSOURCES_PENDING });
  try {
    const json = yield call(api.GET_LEAD_SOURCES, action.payload)

    yield put({ type: GET_LEADSOURCES, payload: json.data });

  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadsourceLoad() {
  yield takeLatest(LOAD_LEADSOURCES, loadLeadSource);
}


function* loadDefaultLeadSource() {

  try {
    const json = yield call(api.GET_LEAD_SOURCES, 'company=1')

    yield put({ type: GET_DEFAULT_LEADSOURCES, payload: json.data });

  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadsourceLoadDefault() {
  yield takeLatest(LOAD_DEFAULT_LEADSOURCES, loadDefaultLeadSource);
}

function* fetchLeadsource(action) {
  yield put({ type: FETCH_LEADSOURCES_PENDING });
  try {
    const json = yield call(api.FETCH_LEAD_SOURCE, action.payload)

    yield put({ type: FETCH_LEADSOURCES, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadsourceFetchOne() {
  yield takeLatest(SG_FETCH_LEADSOURCES, fetchLeadsource);
}

function* editLeadsource(action) {

  yield put({ type: FETCH_LEADSOURCES_PENDING });
  try {
    const json = yield call(api.EDIT_LEAD_SOURCE, action.payload.id, action.payload)
    yield put({ type: EDIT_LEADSOURCES, payload: json.data });
  } catch (e) {

    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadsourceEdit() {
  yield takeLatest(UPDATE_LEADSOURCES, editLeadsource);
}
// Add New
function* addLeadsource(action) {
  try {
    const json = yield call(api.ADD_LEAD_SOURCE, action.payload)
    yield put({ type: ADD_LEADSOURCE, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadsourceAdd() {
  yield takeEvery(SG_ADD_LEADSOURCE, addLeadsource);
}

// Delete ==
function* deleteLeadsource(action) {
  try {
    const json = yield call(api.DELETE_LEAD_SOURCE, action.payload)
    yield put({ type: DELETE_LEADSOURCE, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadsourceDelete() {
  yield takeEvery(SG_DELETE_LEADSOURCE, deleteLeadsource);
}

export default function* index() {
  yield all([leadsourceLoad(),
  leadsourceLoadDefault(),
  leadsourceFetchOne(),
  leadsourceEdit(),
  leadsourceAdd(),
  leadsourceDelete(),
]);
}

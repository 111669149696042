import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "../constants/api";

import {
  LOAD_LOGIN_TRAIL,
  GET_LOGIN_TRAIL,
  ONE_LOGIN_TRAIL,
  FETCH_LOGIN_TRAIL,
  ALL_ERRORS,
} from "constants/actions";

function* loadLoginTrail(action) {
  try {
    const json = yield call(api.GET_LOGIN_TRAIL, action.payload);
    yield put({ type: GET_LOGIN_TRAIL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* loginTrailLoad() {
  yield takeLatest(LOAD_LOGIN_TRAIL, loadLoginTrail);
}

// function* fetchProduct(action) {
//   try {
//     const json = yield call(api.FETCH_PRODUCT, action.payload);

//     yield put({ type: FETCH_PRODUCT, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, error: e.message });
//   }
// }

// export function* productFetch() {
//   yield takeLatest(ONE_PRODUCT, fetchProduct);
// }



export default function* index() {
  yield all([
    loginTrailLoad(),
    // productFetch(),
  ]);
}

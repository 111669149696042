import axios from "axios";

// import store from './store';

import urlconfig from "../_config";
const VERSION = "v1";
const d_token = urlconfig.apikey;

// user related

// Inventory
export const GET_INVENTORIES = (props) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory/?page=${props.pageactivePage ? props.pageactivePage : 1
    }${props.pageparams ? props.pageparams : ""}`,
    tokenConfig()
  );
};

//
export const GET_VALID_INVENTORIES = (props) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory/?valid-list=True&page=${props.pageactivePage ? props.pageactivePage : 1
    }${props.pageparams ? props.pageparams : ""}`,
    tokenConfig()
  );
};

//inventory-listing
export const GET_VALID_INVENTORY_LIST = (params) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory-listing/${params ? `?${params}` : ""
    }`,
    tokenConfig()
  );
};

// http://127.0.0.1:8000/api/dealer/v1/existing-inventory
//inventory-listing
export const GET_COMPARED_INVENTORY = (props) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/existing-inventory/${props ? `?${props}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_GSHEET_INVENTORY = (props) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/spreadsheet-inventory/${props ? `?${props}` : ""
    }`,
    tokenConfig()
  );
};

export const getPagedInventories = ({ pageparams, pageactivePage }) => {
  // console.log(pageactivePage, 'page')
  // console.log('params', pageparams)
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory/?page=${pageactivePage ? pageactivePage : 1
    }${pageparams ? pageparams : ""}`,
    tokenConfig()
  );
};

export const FETCH_INVENTORY = (inventory_id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory/${inventory_id}/`,
    tokenConfig()
  );
// export const ADD_INVENTORY_PIC = (inventory_id, pic) => axios.post(`${urlconfig.serverapi}/${VERSION}/inventory/${inventory_id}/upload-image/`, pic, tokenConfig());
export const ADD_INVENTORY = (inventory) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/inventory/`,
    inventory,
    tokenConfig()
  );

export const EDIT_INVENTORY = (id, inventory) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/inventory/${id}/`,
    inventory,
    tokenConfig()
  );
// update-mileage
export const EDIT_MILEAGE = (id, inventory) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/inventory/${id}/update-mileage/`,
    inventory,
    tokenConfig()
  );

export const DELETE_INVENTORY = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/inventory/${id}/`,
    tokenConfig()
  );

// inventory-select-listing
export const GET_SELECT_INVENTORIES = () => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory-select-listing/`,
    tokenConfig()
  );
};

export const GET_INVENTORY_IMAGES = (inventory_id) => {
  // console.log(inventory_id)
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventoryimage/?inventory_id=${inventory_id}`,
    tokenConfig()
  );
};

export const EDIT_INVENTORY_IMAGE = (id, inventory, param) => {
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/inventoryimage/${id}/?inventory_id=${param}`,
    inventory,
    tokenConfig()
  );
};

// http://127.0.0.1:8000/api/dealer/v1/inventoryimage/
export const ADD_INVENTORY_IMAGE = (upload) => {
  // console.log(upload)
  const formData = new FormData();
  formData.append("inventory", upload.inventory);
  formData.append("company", upload.company);
  formData.append("sort_order", upload.sort_order);
  // formData.append("imageprofile", upload.profile_id);
  formData.append("image", upload.image);
  return axios.post(
    `${urlconfig.serverapi}/${VERSION}/inventoryimage/`,
    formData,
    tokenFileConfig()
  );
};

export const DELETE_INVENTORY_IMAGE = (payload) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/inventoryimage/${payload.id}/?inventory_id=${payload.inventory_id}`,
    tokenConfig()
  );

export const GET_WEBSITE_IMAGES = (inventory_id) => {
  // console.log(inventory_id)
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/websiteimage/`,
    tokenConfig()
  );
};
// http://127.0.0.1:8000/api/dealer/v1/inventoryimage/
export const ADD_WEBSITE_IMAGE = (upload) => {
  // console.log(upload)
  const formData = new FormData();
  formData.append("company", upload.company);
  formData.append("webheader", upload.webheader_id);
  formData.append("image", upload.image);
  return axios.post(
    `${urlconfig.serverapi}/${VERSION}/websiteimage/`,
    formData,
    tokenFileConfig()
  );
};

export const DELETE_WEBSITE_IMAGE = (payload) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/websiteimage/${payload.id}/`,
    tokenConfig()
  );

// push-to-kijiji
export const PUSH_TO_KIJIJI = (inventory_id) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory/push-to-kijiji/?inventory-id=${inventory_id}`,
    tokenConfig()
  );
};

export const GET_INVENTORY_KIJIJI = (inventory_id) => {
  // console.log(inventory_id)
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory-kijiji/?inventory-id=${inventory_id}`,
    tokenConfig()
  );
};

// Inventory specs categories
export const GET_SPECS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/spec/`, tokenConfig());
export const GET_CATEGORIES = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/category/`, tokenConfig());

// Inventory Engine
export const GET_ENGINES = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventoryengine/`,
    tokenConfig()
  );
export const FETCH_ENGINE = (engine_id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventoryengine/${engine_id}`,
    tokenConfig()
  );
export const ADD_ENGINE = (engine) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/inventoryengine/`,
    engine,
    tokenConfig()
  );
export const EDIT_ENGINE = (id, engine) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/inventoryengine/${id}/`,
    engine,
    tokenConfig()
  );
export const DELETE_ENGINE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/inventoryengine/${id}/`,
    tokenConfig()
  );
//
export const ADD_DESCRIPTION = (id, engine) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/inventorynotes/${id}/`,
    engine,
    tokenConfig()
  );

export const GET_DESCRIPTION = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventorynotes/?inventory-id=${id}`,
    tokenConfig()
  );

export const GET_DESCRIPTION_LIST = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventorynotes/selectlist`,
    tokenConfig()
  );

export const EDIT_DESCRIPTION = (id, engine) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/inventorynotes/${id}/`,
    engine,
    tokenConfig()
  );
export const DELETE_DESCRIPTION = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/inventorynotes/${id}/`,
    tokenConfig()
  );
// LOAN
export const ADD_LOAN = (id, engine) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/loan/${id}/`,
    engine,
    tokenConfig()
  );
export const GET_LOAN = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/loan/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_LOAN = (id) =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/loan/${id}/`, tokenConfig());

export const EDIT_LOAN = (id, engine) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/loan/${id}/`,
    engine,
    tokenConfig()
  );
export const DELETE_LOAN = (id) =>
  axios.delete(`${urlconfig.serverapi}/${VERSION}/loan/${id}/`, tokenConfig());
// Image Profile
export const GET_IMAGE_PROFILES = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/imageprofile/`, tokenConfig());
export const FETCH_IMAGE_PROFILE = (imageprofile_id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/imageprofile/${imageprofile_id}`,
    tokenConfig()
  );
// export const ADD_IMAGE_PROFILE = imageprofile => axios.post(`${urlconfig.serverapi}/${VERSION}/imageprofile/`, imageprofile, tokenConfig())
// export const EDIT_IMAGE_PROFILE = (id, imageprofile) => axios.patch(`${urlconfig.serverapi}/${VERSION}/imageprofile/${id}/`, imageprofile, tokenConfig())
// export const DELETE_IMAGE_PROFILE = (id) => axios.delete(`${urlconfig.serverapi}/${VERSION}/imageprofile/${id}/`, tokenConfig())

// Employee
export const GET_EMPLOYEES = (params = null) =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/employee/?${params ? params : ""}`,
    tokenConfig()
  );

export const GET_CASHFORCARS = (params = null) =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/cash-for-cars/?${params ? params : ""}`,
    tokenConfig()
  );

export const GET_STRIPE_CUSTOMER = () =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.payment}/${VERSION}/fetch-customers/`,
    tokenConfig()
  );

export const GET_STRIPE_PRODUCTS = () =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.payment}/${VERSION}/fetch-products/`,
    tokenConfig()
  );

export const LOAD_CMS_PRIVACY = (params = null) =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/privacy-cms/?${params ? params : ""}`,
    tokenConfig()
  );

export const LOAD_HOMEPAGE = (params = null) =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/homepage-cms/?${params ? params : ""}`,
    tokenConfig()
  );

export const LOAD_CMS_TERMS = (params = null) =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/terms-cms/?${params ? params : ""}`,
    tokenConfig()
  );

export const LOAD_CMS_ABOUT = (params = null) =>
  // /?${ params ? params : ""`
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/aboutpage-cms/?${params ? params : ""}`,
    tokenConfig()
  );

export const LOAD_CMS_CONTACT_PAGE = (params = null) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/contact-cms/?${params ? params : ""}`,
    tokenConfig()
  );
export const FETCH_EMPLOYEE = (employee_id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/employee/${employee_id}/`,
    tokenConfig()
  );
export const FETCH_CASH_FOR_CARS_CMS = (params = null) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/cash-for-cars/${params ? params + "/" : ""
    }`,
    tokenConfig()
  );
// export const ADD_EMPLOYEE_PIC = (employee_id, pic) => {
//     // const formData = new FormData();
//     // formData.append("image", payload.image);
//     return axios.post(`${urlconfig.serverapi}/${VERSION}/employee/${employee_id}/upload-image/`, pic, tokenConfig());
// }
export const ADD_EMPLOYEE_PIC = (payload) => {
  // console.log(payload)
  const formData = new FormData();
  formData.append("picture", payload.picture);
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/employee/${payload.id}/`,
    formData,
    tokenConfig()
  );
};
export const ADD_EMPLOYEE = (employee) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/employee/`,
    employee,
    tokenConfig()
  );
export const CASH_FOR_CARS = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/cash-for-cars/`,
    payload,
    tokenConfig()
  );

export const CMS_CREATE_CONTACT_PAGE = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/contact-cms/`,
    payload,
    tokenConfig()
  );

export const CMS_PRIVACY_POLICY = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/privacy-cms/`,
    payload,
    tokenConfig()
  );

export const CMS_HOMEPAGE = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/homepage-cms/`,
    payload,
    tokenConfig()
  );

export const STRIPE_CREATE_INVOICE = (payload) => {
  axios.post(
    `${urlconfig.payment}/${VERSION}/create-invoice/`,
    payload,
    tokenConfig()
  );
}

export const STRIPE_CREATE_CUSTOMER = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/create-customer/`,
    payload,
    tokenConfig()
  );


export const STRIPE_FETCH_INVOICE = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/fetch-invoices/`,
    payload,
    tokenConfig()
  );

export const CMS_TERMS = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/terms-cms/`,
    payload,
    tokenConfig()
  );

export const CMS_ABOUT = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/aboutpage-cms/`,
    payload,
    tokenConfig()
  );

export const EDIT_CASH_FOR_CARS = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/cash-for-cars/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const UPDATE_CMS_CONTACT_PAGE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/contact-cms/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const UPDATE_CMS_PRIVACY_PAGE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/privacy-cms/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const UPDATE_CMS_HOME_PAGE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/homepage-cms/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const UPDATE_CMS_TERMS_PAGE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/terms-cms/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const UPDATE_CMS_ABOUT_PAGE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/aboutpage-cms/${payload.id}/`,
    payload,
    tokenConfig()
  );
export const EDIT_EMPLOYEE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/employee/${payload.id}/`,
    payload,
    tokenConfig()
  );
export const DELETE_EMPLOYEE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/employee/${id}/`,
    tokenConfig()
  );

// Company
export const GET_COMPANIES = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/company/${payload.params ? `?${payload.params}` : ""
    }`,
    tokenConfig()
  );
export const FETCH_COMPANY = (id) =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/company/${id}/`, tokenConfig());

export const ADD_COMPANY = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/company/`,
    payload,
    tokenConfig()
  );
export const ADD_COMPANY_LOGO = (payload) => {
  const formData = new FormData();
  formData.append("logo", payload.logo);
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company/${payload.id}/`,
    formData,
    tokenConfig()
  );
};

export const EDIT_COMPANY = (id, payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company/${id}/`,
    payload,
    tokenConfig()
  );
export const DELETE_COMPANY = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/company/${id}/`,
    tokenConfig()
  );

// company-information
export const GET_COMPANY_INFORMATION = (params = null) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/company-information/?${params ? params : ""
    }`,
    tokenConfig()
  );
export const FETCH_COMPANY_INFORMATION = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/company-information/${id}`,
    tokenConfig()
  );
export const ADD_COMPANY_INFORMATION = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/company-information/`,
    payload,
    tokenConfig()
  );
export const EDIT_COMPANY_INFORMATION = (id, payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company-information/${id}/`,
    payload,
    tokenConfig()
  );

export const ADD_EDIT_COMPANY_INFORMATION = (id, payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company-information/${id}/update-desc/`,
    payload,
    tokenConfig()
  );
export const REMOVE_COMPANY_INFORMATION = (id, payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company-information/${id}/delete-desc/`,
    payload,
    tokenConfig()
  );
export const DELETE_COMPANY_INFORMATION = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/company-information/${id}/`,
    tokenConfig()
  );

// Dealer
export const GET_RELATED_DEALERS = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/company/get_related/`,
    tokenConfig()
  );
export const FETCH_DEALER = (dealer_id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/company/${dealer_id}/`,
    tokenConfig()
  );
// export const ADD_DEALER_PIC = (dealer_id, pic) => axios.post(`${urlconfig.serverapi}/${VERSION}/company/${dealer_id}/upload-image/`, pic, tokenConfig());
export const ADD_DEALER = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/company/`,
    payload,
    tokenConfig()
  );
export const ADD_DEALER_LOGO = (payload) => {
  // console.log(payload)
  const formData = new FormData();
  formData.append("logo", payload.logo);
  formData.append("id", payload.id);
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company/${payload.id}/`,
    formData,
    tokenConfig()
  );
};
export const EDIT_DEALER = (id, employee) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/company/${id}/`,
    employee,
    tokenConfig()
  );
export const DELETE_DEALER = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/company/${id}/`,
    tokenConfig()
  );

// Vindecoder file upload API's
// for decoding single VIN pulls
export const ADD_VIN_UPLOAD = (vinupload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/vin-upload/`,
    vinupload,
    tokenConfig()
  );
export const FETCH_VIN_UPLOAD = (id) => {
  // console.log(id)
  const jsondata = axios.get(
    `${urlconfig.serverapi}/${VERSION}/vin-upload/${id}/`,
    tokenConfig()
  );
  // console.log(jsondata)
  return jsondata;
};
export const EDIT_VIN_UPLOAD = (id, vinupload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/vin-upload/${id}/`,
    vinupload,
    tokenConfig()
  );
export const GET_ALL_VINDECODER_ROWS = ({ page, params }) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/vin-upload/?page=${page ? page : 1}${params ? `&${params}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_VALID_VINDECODER_ROWS = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/vin-upload/valid-list/`,
    tokenConfig()
  );

// file uploads which can result in vin decoder
export const GET_ALL_FILE_UPLOADS = ({ page, params }) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/vin-decoder-upload/?page=${page ? page : 1
    }${params ? params : ""}`,
    tokenConfig()
  );

// fix if there are multiple options like multiple trims or colors from
// the server as response
export const UPDATE_MULTI_VIN_RESPONSE = (vindecoder) =>
  axios.put(
    `${urlconfig.serverapi}/${VERSION}/vin-decoder-select-style/`,
    vindecoder,
    tokenConfig()
  );

export const UPLOAD_FILE = (upload) => {
  const formData = new FormData();
  formData.append("file_type", upload.file_type); // 1=> NHVTSA for testing and 2 => CarsXE for productions
  formData.append("name", upload.name);
  formData.append("company", upload.company);
  formData.append("location_id", upload.location_id);
  formData.append("data_file", upload.data_file);
  return axios.post(
    `${urlconfig.serverapi}/${VERSION}/vin-decoder-upload/`,
    formData,
    tokenFileConfig()
  );
};

export const UPLOAD_FILE_LINK = `${urlconfig.serverapi}/${VERSION}/vin-decoder-upload/`;

export const FETCH_UPLOADED_FILE = (file_id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/vin-decoder-upload/${file_id}/`,
    tokenConfig()
  );

export const DELETE_UPLOADED_FILE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/vin-decoder-upload/${id}/`,
    tokenConfig()
  );

export const GET_BLACKBOOK_VALUES = ({ params }) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/black-book-api/${params ? `?search=${params}` : ""
    }`,
    tokenConfig()
  );

// customers

export const GET_CUSTOMERS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );

export const GET_CUSTOMER_LIST = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/customer-list/`, tokenConfig());

// export const FETCH_CUSTOMER = (params=null)  =>  axios.get(`${urlconfig.serverapi}/${VERSION}/customer?${params}`, tokenConfig());
export const FETCH_CUSTOMER = (id) =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/customer/${id}/`, tokenConfig());

export const ADD_CUSTOMER = (customer) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/customer/`,
    customer,
    tokenConfig()
  );
export const EDIT_CUSTOMER = (id, customer) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/customer/${id}/`,
    customer,
    tokenConfig()
  );
export const DELETE_CUSTOMER = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/customer/${id}/`,
    tokenConfig()
  );

// WebLeads
export const GET_WEB_LEADS = ({ page = 1, params = null }) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-leads/?page=${page}${params ? `&${params}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_WEBLEAD_LIST = (params) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-list/${params ? `?${params}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_WEB_LEAD = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-leads/${id}/`,
    tokenConfig()
  );
export const ADD_WEB_LEAD = (web_lead) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/web-leads/`,
    web_lead,
    tokenConfig()
  );
export const EDIT_WEB_LEAD = (id, web_lead) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/web-leads/${id}/`,
    web_lead,
    tokenConfig()
  );
export const DELETE_WEB_LEAD = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/web-leads/${id}/`,
    tokenConfig()
  );

// WebLeadSourceViewSet
export const GET_LEAD_SOURCES = (params = null) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-source/?${params ? params : ""
    }`,
    tokenConfig()
  );
export const FETCH_LEAD_SOURCE = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-source/${id}`,
    tokenConfig()
  );
export const ADD_LEAD_SOURCE = (web_lead_source) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/web-lead-source/`,
    web_lead_source,
    tokenConfig()
  );
export const EDIT_LEAD_SOURCE = (id, web_lead_source) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/web-lead-source/${id}/`,
    web_lead_source,
    tokenConfig()
  );
export const DELETE_LEAD_SOURCE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/web-lead-source/${id}/`,
    tokenConfig()
  );

// web lead types
export const GET_LEAD_TYPES = (params = null) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-types/?${params ? params : ""}`,
    tokenConfig()
  );
export const FETCH_LEAD_TYPE = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-types/${id}`,
    tokenConfig()
  );
export const ADD_LEAD_TYPE = (web_lead_type) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/web-lead-types/`,
    web_lead_type,
    tokenConfig()
  );
export const EDIT_LEAD_TYPE = (id, web_lead_type) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/web-lead-types/${id}/`,
    web_lead_type,
    tokenConfig()
  );
export const DELETE_LEAD_TYPE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/web-lead-types/${id}/`,
    tokenConfig()
  );

// web lead stages
export const GET_LEAD_STAGES = (params = null) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-stages/?${params ? params : ""
    }`,
    tokenConfig()
  );
export const FETCH_LEAD_STAGE = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-lead-stages/${id}`,
    tokenConfig()
  );
export const ADD_LEAD_STAGE = (web_lead_stage) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/web-lead-stages/`,
    web_lead_stage,
    tokenConfig()
  );
export const EDIT_LEAD_STAGE = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/web-lead-stages/${payload.id}/`,
    payload,
    tokenConfig()
  );
export const DELETE_LEAD_STAGE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/web-lead-stages/${id}/`,
    tokenConfig()
  );

// web comments stages
export const GET_WEB_COMMENTS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-comments/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );
export const FETCH_WEB_COMMENT = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/web-comments/${id}/`,
    tokenConfig()
  );
export const ADD_WEB_COMMENT = (web_comment) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/web-comments/`,
    web_comment,
    tokenConfig()
  );
export const EDIT_WEB_COMMENT = (id, web_comment) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/web-comments/${id}/`,
    web_comment,
    tokenConfig()
  );
export const DELETE_WEB_COMMENT = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/web-comments/${id}/`,
    tokenConfig()
  );

// customer credit application
export const GET_CREDIT_APPLICATIONS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/credit-application/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );
export const FETCH_CREDIT_APPLICATION = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/credit-application/${id}/`,
    tokenConfig()
  );
export const ADD_CREDIT_APPLICATION = (credit_application) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/credit-application/`,
    credit_application,
    tokenConfig()
  );
export const EDIT_CREDIT_APPLICATION = (id, credit_application) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/credit-application/${id}/`,
    credit_application,
    tokenConfig()
  );
export const DELETE_CREDIT_APPLICATION = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/credit-application/${id}/`,
    tokenConfig()
  );

// Employee Tasks
export const GET_EMPLOYEE_TASKS = (payload) => {
  return axios.get(
    `${urlconfig.serverapi}/${VERSION}/employee-task/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );
};

export const FETCH_EMPLOYEE_TASK = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/employee-task/${id}/`,
    tokenConfig()
  );
export const ADD_EMPLOYEE_TASK = (employee_task) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/employee-task/`,
    employee_task,
    tokenConfig()
  );
export const EDIT_EMPLOYEE_TASK = (id, employee_task) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/employee-task/${id}/`,
    employee_task,
    tokenConfig()
  );
export const DELETE_EMPLOYEE_TASK = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/employee-task/${id}/`,
    tokenConfig()
  );

// Customer Trade Ins
export const GET_CUSTOMER_TRADE_INS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer-trade-in/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );

export const GET_ADMIN_TRADE_INS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer-trade-in/admin-list/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );

export const FETCH_CUSTOMER_TRADE_IN = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer-trade-in/${payload.id}/${payload.params ? `${payload.params}` : ""
    }`,
    tokenConfig()
  );
export const ADD_CUSTOMER_TRADE_IN = (customer_trade_in) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/customer-trade-in/`,
    customer_trade_in,
    tokenConfig()
  );
export const EDIT_CUSTOMER_TRADE_IN = (payload) =>
  axios.patch(
    `${urlconfig.serverapi}/${VERSION}/customer-trade-in/${payload.id}/`,
    payload,
    tokenConfig()
  );
export const DELETE_CUSTOMER_TRADE_IN = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/customer-trade-in/${id}/`,
    tokenConfig()
  );

// Customer Appointments
export const GET_CUSTOMER_APPOINTMENTS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer-appointment/?page=${payload.page ? payload.page : 1
    }${payload.params ? `&${payload.params}` : ""}`,
    tokenConfig()
  );
export const FETCH_CUSTOMER_APPOINTMENT = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer-appointment/${id}/`,
    tokenConfig()
  );
// export const ADD_CUSTOMER_APPOINTMENT = customer_appointment => axios.post(`${urlconfig.serverapi}/${VERSION}/customer-appointment/`, web_lead, tokenConfig())
// export const EDIT_CUSTOMER_APPOINTMENT = (id, customer_appointment) => axios.patch(`${urlconfig.serverapi}/${VERSION}/customer-appointment/${id}/`, customer_appointment, tokenConfig())
export const DELETE_CUSTOMER_APPOINTMENT = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/customer-appointment/${id}/`,
    tokenConfig()
  );

// Credit Applications
export const GET_CUSTOMER_INVENTORY = (param) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/customer-inventory/${param.id}/`,
    tokenConfig()
  );

export const GET_INVENTORY_CUSTOMER = (param) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/inventory-customer/${param.id}/`,
    tokenConfig()
  );

// Landing Page
export const GET_LANDING_PAGES = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/landingpage/`, tokenConfig());

export const FETCH_LANDING_PAGE = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/landingpage/${id}/`,
    tokenConfig()
  );

export const ADD_LANDING_PAGE = (landingpage) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/landingpage/`,
    landingpage,
    tokenConfig()
  );
export const EDIT_LANDING_PAGE = (payload) => {
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/landingpage/${payload.id}/`,
    payload,
    tokenConfig()
  );
};
export const DELETE_LANDING_PAGE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/landingpage/${id}/`,
    tokenConfig()
  );
export const DELETE_LANDING_PAGE_IMAGE = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/landingpage/${id}/`,
    tokenConfig()
  );

export const ADD_LANDING_PAGE_IMAGE = (payload) => {
  // console.log(payload.id)
  // console.log(payload)
  const formData = new FormData();
  formData.append("image", payload.image);
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/landingpage/${payload.id}/`,
    formData,
    tokenConfig()
  );
};

// Lead Reports
export const GET_LEAD_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/weblead-report/${payload.params ? `?${payload.params}` : ""
    }`,
    tokenConfig()
  );

//
// Product
export const GET_PRODUCTS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/products/`, tokenConfig());

// GET_ACTIVE_PRODUCTS
export const GET_ACTIVE_PRODUCTS = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/products/active-list`,
    tokenConfig()
  );

export const FETCH_PRODUCT = (id) =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/products/${id}/`, tokenConfig());

export const ADD_PRODUCT = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/products/`,
    payload,
    tokenConfig()
  );
export const EDIT_PRODUCT = (payload) => {
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/products/${payload.id}/`,
    payload,
    tokenConfig()
  );
};
export const DELETE_PRODUCT = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/products/${id}/`,
    tokenConfig()
  );

// Product GROUP
export const GET_PRODUCT_GROUPS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/product-group/`, tokenConfig());

export const FETCH_PRODUCT_GROUP = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/product-group/${id}/`,
    tokenConfig()
  );

export const ADD_PRODUCT_GROUP = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/product-group/`,
    payload,
    tokenConfig()
  );
export const EDIT_PRODUCT_GROUP = (payload) => {
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/product-group/${payload.id}/`,
    payload,
    tokenConfig()
  );
};
export const DELETE_PRODUCT_GROUP = (id) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/product-group/${id}/`,
    tokenConfig()
  );

// Product Contract
export const GET_PRODUCT_CONTRACTS = (payload) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/product-contract/?${payload.params}`,
    tokenConfig()
  );

export const GET_ACTIVE_PRODUCT_CONTRACTS = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/product-contract/active-list`,
    tokenConfig()
  );

export const FETCH_PRODUCT_CONTRACT = (id) =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/product-contract/${id}`,
    tokenConfig()
  );

export const ADD_PRODUCT_CONTRACT = (payload) =>
  axios.post(
    `${urlconfig.serverapi}/${VERSION}/product-contract/`,
    payload,
    tokenConfig()
  );
export const EDIT_PRODUCT_CONTRACT = (payload) => {
  return axios.patch(
    `${urlconfig.serverapi}/${VERSION}/product-contract/${payload.id}/?company=${payload.company}`,
    payload,
    tokenConfig()
  );
};
export const DELETE_PRODUCT_CONTRACT = (payload) =>
  axios.delete(
    `${urlconfig.serverapi}/${VERSION}/product-contract/${payload.id}/?company=${payload.companyId}`,
    tokenConfig()
  );

// Login Trail
export const GET_LOGIN_TRAIL = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/login-trail/`, tokenConfig());

// this is valid with a dealer id which it auto populates
export const GET_CARYEARS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/year?dealer-code=${d_token}`);
export const GET_CARMAKES = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/make?dealer-code=${d_token}`);
export const GET_CARMODELS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/model?dealer-code=${d_token}`);
export const GET_CARTRIMS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/trim?dealer-code=${d_token}`);
export const GET_CARCOLORS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/color?dealer-code=${d_token}`);
export const GET_CARBODYTYPES = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/body-type?dealer-code=${d_token}`
  );
export const GET_KMS_PRICE = () =>
  axios.get(
    `${urlconfig.serverapi}/${VERSION}/page-filter?dealer-code=${d_token}`
  );

// this is for the select list of year make model trim of car
export const GET_CARYEARS_LIST = (params) =>
  axios.get(
    `${urlconfig.website}/${VERSION}/year?dealer-code=${d_token}&${params}`
  );
export const GET_CARMAKES_LIST = (params) =>
  axios.get(
    `${urlconfig.website}/${VERSION}/make?dealer-code=${d_token}&${params}`
  );
export const GET_CARMODELS_LIST = (params) =>
  axios.get(
    `${urlconfig.website}/${VERSION}/model?dealer-code=${d_token}&${params}`
  );
export const GET_CARTRIMS_LIST = (params) =>
  axios.get(
    `${urlconfig.website}/${VERSION}/trim?dealer-code=${d_token}&${params}`
  );

export const GET_YEAR_MAKE_MODEL_TRIM = (params) =>
  axios.get(
    `${urlconfig.website}/${VERSION}/year-make-model-trim?dealer-code=${d_token}&q=${params}`
  );

export const GET_YEAR_MAKE = (params) => {
  // console.log("API Param check", params)
  return axios.get(
    `${urlconfig.website}/${VERSION}/year-make?dealer-code=${d_token}${params ? `&q=${params}` : ""
    }`
  );
};

export const GET_BLACKBOOK_API = (payload) => {
  // console.log(payload)
  return axios.get(
    `${urlconfig.website}/${VERSION}/black-book-api?dealer-code=${d_token}&${payload}`
  );
};

// Other urls
export const GET_DEALERS = (token) =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/dealer?dealer-code=${token}`);

export const GET_GARAGE = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/garage`, tokenConfig());
export const GET_SERVICE_REQUESTS = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/service-request`, tokenConfig());

export const getCarYears = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/year`, tokenConfig());
export const getCarMakes = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/make`, tokenConfig());
export const getCarModels = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/model`, tokenConfig());
export const getCarTrims = () =>
  axios.get(`${urlconfig.serverapi}/${VERSION}/car-trim`, tokenConfig());

export const ADD_LEAD = "ADD_LEAD";
export const UPDATE_LEADS = "UPDATE_LEADS";
export const CLEAR_LEADS = "CLEAR_LEADS";

// multiple
// axios.all([
//     axios.get('https://api.github.com/users/iliakan'),
//     axios.get('https://api.github.com/users/taylorotwell')
//   ])
//   .then(axios.spread((obj1, obj2) => {
//     // Both requests are now complete
//     console.log(obj1.data.login + ' has ' + obj1.data.public_repos + ' public repos on GitHub');
//     console.log(obj2.data.login + ' has ' + obj2.data.public_repos + ' public repos on GitHub');
//   }));

// Login Urls
// CHECK TOKEN & LOAD USER

export const FETCH_LOGIN_EMPLOYEE = ({ employee_id, token }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  axios.get(
    `${urlconfig.serverapi}/${VERSION}/employee/${employee_id}/`,
    config
  );
};

export const GET_LOGIN_RELATED_DEALERS = ({ token }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };
  axios.get(`${urlconfig.serverapi}/${VERSION}/company/get_related/`, config);
};
export const FETCH_LOGIN_DEALER = ({ dealer_id, token }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };
  axios.get(`${urlconfig.serverapi}/${VERSION}/company/${dealer_id}/`, config);
};

export const LOAD_USER = () => (getState) => {
  return axios.get(
    `${urlconfig.loginapi}/${VERSION}/profile/`,
    tokenConfig(getState)
  );
};
export const LOGIN_USER = ({ email, password, dealer_token }) => {
  const body = JSON.stringify({ email, password, dealer_token });
  const response = axios.post(
    `${urlconfig.loginapi}/${VERSION}/dealer-token/`,
    body,
    config
  );
  return response;
};
export async function REGISTER_USER({
  password,
  password2,
  dealer_code,
  email,
}) {
  // Request Body
  const body = JSON.stringify({
    email,
    password,
    password2,
    dealer_code,
  });
  let res = await axios.post(
    `${urlconfig.loginapi}/${VERSION}/create/dealer/`,
    body,
    config
  );
  // console.log(res)
  let data = { status: res.status, statusText: res.statusText };
  // console.log(data)
  return data;
}
export async function REGISTER_DEALER_USER({
  city,
  company_name,
  confirm_password,
  country_code,
  email,
  first_name,
  last_name,
  password,
  phone_number,
  plan_id
}) {
  // Request Body
  const body = JSON.stringify({
    city,
    company_name,
    confirm_password,
    email,
    first_name,
    last_name,
    password,
    phone_number: country_code + '-' + phone_number,
    plan_id
  });
  let res = await axios.post(
    `${urlconfig.loginapi}/${VERSION}/company-register/`,
    body,
    config
  );
  // console.log(res)
  let data = { status: res?.status, statusText: res?.statusText };

  window.location.href =res?.data?.payment_link;
  return data;
}
export async function CHECK_PAYMENT_STATUS({
  session_id
}){
  const body = JSON.stringify({session_id
});
let res = await axios.post(
    `${urlconfig.loginapi}/${VERSION}/check-payment-status/`,
    body,
    config
  );
  let data = { status: res?.status, statusText: res?.statusText };
  return data;
}
export const LOGOUT_USER = () => (getState) => {
  // return `${urlconfig.authapi}/${VERSION}/logout/`, null, tokenConfig(getState);
};

export const FORGOT_PASSWORD = async ({
  email,
  dealer_token,
  token_type,
  user_ip,
}) => {
  // Request Body
  const body = JSON.stringify({ email, dealer_token, token_type, user_ip });
  const response = await axios.post(
    `${urlconfig.loginapi}/${VERSION}/password_reset/`,
    body,
    config
  );
  // console.log(response)
  let { data } = response;
  // console.log(data)
  return data;
};

export const RESET_PASSWORD = async ({ password, token }) => {
  // Request Body
  const body = JSON.stringify({ password, token });
  let res = await axios.post(
    `${urlconfig.loginapi}/${VERSION}/password_reset/confirm/?token=${token}`,
    body,
    config
  );
  return res;
};

//user-password-change/
export const CHANGE_PASSWORD = async ({
  old_password,
  password,
  confirmed_password,
  token,
}) => {
  // Request Body
  const body = JSON.stringify({
    old_password,
    password,
    confirmed_password,
    token,
  });
  let res = await axios.put(
    `${urlconfig.loginapi}/${VERSION}/user-password-change/?token=${token}`,
    body,
    config
  );
  return res;
};

// Headers
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const tokenConfig = () => {
  // const state = store.getState();
  // // Get token from state
  // const token = state.auth.token;
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

const tokenFileConfig = () => {
  // const state = store.getState();
  // // Get token from state
  // const token = state.auth.token;
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

// const asyncTokenConfig = async ()  => {
//     const state = store.getState();
//     // Get token from state

// //    async function async waitForstate2() {
// //        return  setTimeout(() => {
// //     return console.log("waiting 5 secs");
// //   }, 5000);
// //     }
// await function  waitForstate () {
//      setTimeout(() => {
//     return console.log("waiting 5 secs");
//   }, 5000)

// }
//     const token = await state.auth.token;
//     // Headers
//     console.log(token)
//     setTimeout(() => {
//         return console.log("waiting 5 secs");
//       }, 5000)
//     if (token) {
//         const config = {
//             headers: {
//                 "Content-Type": "application/json",
//                 'Authorization': `Token ${token}`
//             }
//         };
//         console.log(config)
//         return config;
//     } else {
//         const config = {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         };
//         console.log(config)
//         return config
//       }

//       //   the chain continues with more .then() handlers
//   }

//   const makeRequest = async () => {
//     const value1 = await setTimeout(() => {
//         return console.log("waiting 5 secs");
//       }, 500)
//       const state = await  store.getState()
//     const statetime = await setTimeout(() => {
//         return console.log("waiting 5 more secs", state.auth);
//       }, 500)

//     return (value1, state.auth)
//   }

//   const makeRequest = () =>
//   setTimeout(() => {
//     return console.log("waiting 5 secs");
//   }, 500).then(() => {
//         const state = store.getState();
//         console.log('state', state.auth.token)
//         return state.auth.token
//     }).then((data) => {
//         console.log(data)
//         return "ype its done"
//     })
// function aFunction(){
//    console.log(localStorage.getItem("token"))
//     var newState =store.getState().auth;
//     console.log('state changed', newState);
//  }

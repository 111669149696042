export const VehicleOptions = [
    { value: "Car", label: "Car" },
    { value: "SUV", label: "SUV" },
    { value: "Truck", label: "Truck" },
    { value: "Van", label: "Van" },
    { value: "Other", label: "Other" },
  ];
  // "CONVERTIBLE", "COUPE", "CROSSOVER", "HATCHBACK", "MINIVAN", "SMALL_CAR", "TRUCK", "SUV", "SEDAN", "VAN", "WAGON", "OTHER", "NONE".
  export  const FuelOptions = [
    { value: "Gasoline", label: "Gasoline" },
    { value: "Diesel", label: "Diesel" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "Electric", label: "Electric" },
    { value: "Other", label: "Other" },
  ];

  export  const WheelBaseTypeOptions = [
    { value: "XLWB", label: "Extra Long (XLWB)" },
    { value: "LWB", label: "Long (LWB)" },
    { value: "MWB", label: "Medium (MWB)" },
    { value: "SWB", label: "Short (SWB)" },
  ];

  export  const CabTypeOptions = [
    { value: "CREW", label: "CREW" },
    { value: "EXTENDED", label: "EXTENDED" },
    { value: "EXTENDED_CREW", label: "EXTENDED_CREW" },
    { value: "REGULAR", label: "REGULAR" },
  ];

  export  const PickupBedtypeOptions = [
    { value: "Step Side", label: "Step Side" },
    { value: "Straight Side", label: "Straight Side" },
  ];

  export  const PickupBedlengthOptions = [
    { value: "Short", label: "Short" },
    { value: "Regular", label: "Regular" },
    { value: "Long", label: "Long" },
  ];
  
  
  export const TransmissionOptions = [
    { value: 1, label: "Automatic" },
    { value: 2, label: "Manual" },
    { value: 3, label: "CVT" },
    { value: 4, label: "Other" },
  ];
  export const ConditionOptions = [
    { value: 1, label: "New" },
    { value: 2, label: "Used" },
    { value: 3, label: "Trade In" },
  ];
  
  export const StatusOptions = [
    { value: 1, label: "Coming", warn: false },
    { value: 2, label: "New", warn: false },
    { value: 3, label: "Under Process", warn: false },
    { value: 4, label: "Live", warn: false },
    { value: 5, label: "On Hold", warn: false },
    { value: 6, label: "Sold Listed (Visible on site)", warn: false },
    { value: 11, label: "Delist (Remove From Website)", warn: true },
  ];
  

  // COMING = 1
  // NEW = 2
  // UNDERPROCESS = 3
  // LIVE = 4
  // ON_HOLD = 5
  // SOLD_LISTED = 6
  // SOLD_DELISTED = 11
  // DELISTED = 21
  // INVENTORY_STATUS = (
  //     (COMING, 'Coming'),
  //     (NEW, 'New'),
  //     (UNDERPROCESS, 'Under Process'),
  //     (LIVE, 'Live'),
  //     (ON_HOLD, 'On Hold'),
  //     (SOLD_LISTED, 'Sold Listed'),
  //     (SOLD_DELISTED, 'Sold & Paid (Delisted)'),
  //     (DELISTED, 'De Listed')
  // )
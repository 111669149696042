import React from "react";
import _ from "lodash";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import {
  UPDATE_LANDINGPAGE,
  CREATE_LANDINGPAGE,
  SG_FETCH_LANDINGPAGE,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Fade,
  CardTitle,
  CardFooter,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

import EditorConvertToHTML from "Draftjs/EditorConvertToHTML";
import Image from "./Image";

import { Button as MUIButton } from "@mui/material";

const CreateLandingPage = ({ props, setAddTaskOpen, setLabel }) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [landingPages, setLandingPages] = React.useState([]);
  const [editOverall, setEditOverall] = React.useState();

  const [fadeIn, setFadeIn] = React.useState(true);
  const [genUrl, setGenUrl] = React.useState("");
  const [metaDesc, setMetaDesc] = React.useState("");
  const [landingPageCreated, setLandingPageCreated] = React.useState(null);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  const [visible, setVisible] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);

  const [currentDateTime, setCurrentDateTime] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_LANDINGPAGE, payload: id });
    }
  }, [dispatch]);

  const { get_landing_pages, get_auth, get_errors, get_landing_page_created } =
    useSelector(
      (state) => ({
        get_landing_pages: state.landingpages,
        get_landing_page_created: state.landingpages.created,
        get_auth: state.auth,
        get_errors: state.errors,
      }),
      shallowEqual
    );

  React.useEffect(() => {
    // console.log(_.isEmpty(get_errors.errors))
    if (_.isEmpty(get_errors.errors)) {
      // console.log(get_errors)

      setVisible(true);
      setPageErrors(get_errors?.errors);
    }
  }, [get_errors]);

  React.useEffect(() => {
    if (get_landing_pages[parseInt(id)]) {
      setLandingPages(get_landing_pages[parseInt(id)]);
    }
  }, [get_landing_pages, id]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  //
  React.useEffect(() => {
    if (_.get(get_landing_page_created, "id")) {
      setLandingPageCreated(get_landing_page_created);
      setTimeout(() => {
        setToHome(true);
      }, 2000);
    }
  }, [get_landing_page_created]);

  React.useEffect(() => {
    // console.log(selStatus);
    if (id) {
      if (get_landing_pages[parseInt(id)]) {
        setEditOverall(get_landing_pages[parseInt(id)].content);
        setGenUrl(get_landing_pages[parseInt(id)].url);
      }
    }
  }, [get_landing_pages, id]);

  const getValidDates = function (currentDate) {
    // only allow today and future dates
    var yesterday = moment().subtract(1, "day");
    return currentDate.isAfter(yesterday);
  };

  const onSubmit = (data, e) => {
    if (id) {
      dispatch({
        type: UPDATE_LANDINGPAGE,
        payload: {
          id,
          heading: data.heading,
          meta_description: data.meta_description,
          url: data.url,
          content: data.content,
          status: data.status,
          inventory: data.inventories,
          // image: data.image,
          company: get_auth.dealer_id,
        },
      });

      setTimeout(() => {
        setToDetail(true);
        setAddTaskOpen(false);
      }, 2000);
    } else {
      dispatch({
        type: CREATE_LANDINGPAGE,
        payload: {
          heading: data.heading,
          meta_description: data.meta_description,
          url: data.url,
          status: data.status,
          company: get_auth.dealer_id,
          inventories: data.inventories,
        },
      });
    }
  };

  const CreateUrl = (str) => {
    setGenUrl(
      str.target.value
        .replace(/\s+/g, "-")
        .replace(/[^A-Z0-9]/gi, "-")
        .toLowerCase()
    );
  };

  setLabel(`${id ? "Edit " : "Create "}Landing Page`);
  return (
    <>
      {landingPageCreated ? (
        <Redirect to={`/admin/landing-page-detail/${landingPageCreated.id}`} />
      ) : null}
      {toDetail ? <Redirect to={`/admin/landing-page-detail/${id}`} /> : null}
      <div className="content">
        <Alert
          color="danger"
          isOpen={visible}
          toggle={() => setVisible(!visible)}
        >
          {/* {pageerrors.errors? pageerrors.errors['detail']: JSON.stringify(pageerrors.errors)} */}
          {pageerrors.errors ? JSON.stringify(pageerrors.errors) : ""}
        </Alert>

        {/* {JSON.stringify(pageerrors.errors)} */}
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              {/* <Card>
                <CardBody>
                  <CardTitle tag="h4">
                    {id ? "Edit " : "Create "}Landing Page
                  </CardTitle> */}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Heading </label>
                  <Input
                    type="text"
                    name="heading"
                    onChange={CreateUrl}
                    defaultValue={landingPages ? landingPages.heading : ""}
                    innerRef={register({
                      required: "Please give a heading for this page",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.heading && <span>{errors.heading.message}</span>}
                  </p>
                </FormGroup>
                <FormGroup>
                  <label>URL (has to be unique)</label>
                  <Input
                    type="text"
                    name="url"
                    disabled
                    value={genUrl}
                    defaultValue={landingPages ? landingPages.url : ""}
                    innerRef={register({
                      required: "Please give a unique url for this page",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.url && <span>{errors.url.message}</span>}
                  </p>
                </FormGroup>
                <FormGroup>
                  <label>
                    Meta Description (remaining{" "}
                    {metaDesc ? 150 - metaDesc.length : "150"} Chars){" "}
                  </label>
                  <Input
                    type="textarea"
                    maxLength={150}
                    onChange={(e) => setMetaDesc(e.target.value)}
                    defaultValue={
                      landingPages ? landingPages.meta_description : ""
                    }
                    name="meta_description"
                    id="meta_description"
                    innerRef={register({
                      required: "Please give a heading for this page",
                    })}
                  />

                  <p className="text-danger small">
                    {errors.meta_description && (
                      <span>{errors.meta_description.message}</span>
                    )}
                  </p>
                </FormGroup>
                <FormGroup>
                  <label>Data Query </label>
                  <Input
                    type="text"
                    name="data_query"
                    defaultValue={landingPages ? landingPages.data_query : ""}
                    innerRef={register({
                      required: "Please enter a query for this page",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.data_query && (
                      <span>{errors.data_query.message}</span>
                    )}
                  </p>
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <MUIButton color="preAuto" variant="contained" type="submit">
                    {id ? "Update " : "Save "}
                  </MUIButton>
                </div>
              </Form>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateLandingPage;

export const countryCode = [{ "name": "Afghanistan (+93)", "value": "+93", "code": "AF", "flag": "🇦🇫" }
    , { "name": "Albania (+355)", "value": "+355", "code": "AL", "flag": "🇦🇱" }
    , { "name": "Algeria (+213)", "value": "+213", "code": "DZ", "flag": "🇩🇿" }
    , { "name": "AmericanSamoa (+1684)", "value": "+1684", "code": "AS", "flag": "🇦🇸" }
    , { "name": "Andorra (+376)", "value": "+376", "code": "AD", "flag": "🇦🇩" }
    , { "name": "Angola (+244)", "value": "+244", "code": "AO", "flag": "🇦🇴" }
    , { "name": "Anguilla (+1264)", "value": "+1264", "code": "AI", "flag": "🇦🇮" }
    , { "name": "Antarctica (+672)", "value": "+672", "code": "AQ", "flag": "🇦🇶" }
    , { "name": "Antigua and Barbuda (+1268)", "value": "+1268", "code": "AG", "flag": "🇦🇬" }
    , { "name": "Argentina (+54)", "value": "+54", "code": "AR", "flag": "🇦🇷" }
    , { "name": "Armenia (+374)", "value": "+374", "code": "AM", "flag": "🇦🇲" }
    , { "name": "Aruba (+297)", "value": "+297", "code": "AW", "flag": "🇦🇼" },
{ "name": "Australia (+61)", "value": "+61", "code": "AU", "preferred": true, "flag": "🇦🇺" }
    , { "name": "Austria (+43)", "value": "+43", "code": "AT", "flag": "🇦🇹" }
    , { "name": "Azerbaijan (+994)", "value": "+994", "code": "AZ", "flag": "🇦🇿" }
    , { "name": "Bahamas (+1242)", "value": "+1242", "code": "BS", "flag": "🇧🇸" }
    , { "name": "Bahrain (+973)", "value": "+973", "code": "BH", "flag": "🇧🇭" }
    , { "name": "Bangladesh (+880)", "value": "+880", "code": "BD", "flag": "🇧🇩" }
    , { "name": "Barbados (+1246)", "value": "+1246", "code": "BB", "flag": "🇧🇧" }
    , { "name": "Belarus (+375)", "value": "+375", "code": "BY", "flag": "🇧🇾" }
    , { "name": "Belgium (+32)", "value": "+32", "code": "BE", "flag": "🇧🇪" }
    , { "name": "Belize (+501)", "value": "+501", "code": "BZ", "flag": "🇧🇿" }
    , { "name": "Benin (+229)", "value": "+229", "code": "BJ", "flag": "🇧🇯" }
    , { "name": "Bermuda (+1441)", "value": "+1441", "code": "BM", "flag": "🇧🇲" }
    , { "name": "Bhutan (+975)", "value": "+975", "code": "BT", "flag": "🇧🇹" }
    , { "name": "Bolivia, Plurinational State of (+591)", "value": "+591", "code": "BO", "flag": "🇧🇴" }
    , { "name": "Bosnia and Herzegovina (+387)", "value": "+387", "code": "BA", "flag": "🇧🇦" }
    , { "name": "Botswana (+267)", "value": "+267", "code": "BW", "flag": "🇧🇼" }
    , { "name": "Brazil (+55)", "value": "+55", "code": "BR", "flag": "🇧🇷" }
    , { "name": "British Indian Ocean Territory (+246)", "value": "+246", "code": "IO", "flag": "🇮🇴" }
    , { "name": "Brunei Darussalam (+673)", "value": "+673", "code": "BN", "flag": "🇧🇳" }
    , { "name": "Bulgaria (+359)", "value": "+359", "code": "BG", "flag": "🇧🇬" }
    , { "name": "Burkina Faso (+226)", "value": "+226", "code": "BF", "flag": "🇧🇫" }
    , { "name": "Burundi (+257)", "value": "+257", "code": "BI", "flag": "🇧🇮" }
    , { "name": "Cambodia (+855)", "value": "+855", "code": "KH", "flag": "🇰🇭" }
    , { "name": "Cameroon (+237)", "value": "+237", "code": "CM", "flag": "🇨🇲" }
    , { "name": "Canada (+1)", "value": "+1", "code": "CA", "flag": "🇨🇦" }
    , { "name": "Cape Verde (+238)", "value": "+238", "code": "CV", "flag": "🇨🇻" }
    , { "name": "Cayman Islands (+345)", "value": "+345", "code": "KY", "flag": "🇰🇾" }
    , { "name": "Central African Republic (+236)", "value": "+236", "code": "CF", "flag": "🇨🇫" }
    , { "name": "Chad (+235)", "value": "+235", "code": "TD", "flag": "🇹🇩" }
    , { "name": "Chile (+56)", "value": "+56", "code": "CL", "flag": "🇨🇱" }
    , { "name": "China (+86)", "value": "+86", "code": "CN", "flag": "🇨🇳" }
    , { "name": "Christmas Island (+61)", "value": "+61", "code": "CX", "flag": "🇨🇽" }
    , { "name": "Cocos (Keeling) Islands (+61)", "value": "+61", "code": "CC", "flag": "🇨🇨" }
    , { "name": "Colombia (+57)", "value": "+57", "code": "CO", "flag": "🇨🇴" }
    , { "name": "Comoros (+269)", "value": "+269", "code": "KM", "flag": "🇰🇲" }
    , { "name": "Congo (+242)", "value": "+242", "code": "CG", "flag": "🇨🇬" }
    , { "name": "Congo, The Democratic Republic of the (+243)", "value": "+243", "code": "CD", "flag": "🇨🇩" }
    , { "name": "Cook Islands (+682)", "value": "+682", "code": "CK", "flag": "🇨🇰" }
    , { "name": "Costa Rica (+506)", "value": "+506", "code": "CR", "flag": "🇨🇷" }
    , { "name": "Cote d'Ivoire (+225)", "value": "+225", "code": "CI", "flag": "🇨🇮" }
    , { "name": "Croatia (+385)", "value": "+385", "code": "HR", "flag": "🇭🇷" }
    , { "name": "Cuba (+53)", "value": "+53", "code": "CU", "flag": "🇨🇺" }
    , { "name": "Cyprus (+537)", "value": "+537", "code": "CY", "flag": "🇨🇾" }
    , { "name": "Czech Republic (+420)", "value": "+420", "code": "CZ", "flag": "🇨🇿" }
    , { "name": "Denmark (+45)", "value": "+45", "code": "DK", "flag": "🇩🇰" }
    , { "name": "Djibouti (+253)", "value": "+253", "code": "DJ", "flag": "🇩🇯" }
    , { "name": "Dominica (+1767)", "value": "+1767", "code": "DM", "flag": "🇩🇲" }
    , { "name": "Dominican Republic (+1849)", "value": "+1849", "code": "DO", "flag": "🇩🇴" }
    , { "name": "Ecuador (+593)", "value": "+593", "code": "EC", "flag": "🇪🇨" }
    , { "name": "Egypt (+20)", "value": "+20", "code": "EG", "flag": "🇪🇬" }
    , { "name": "El Salvador (+503)", "value": "+503", "code": "SV", "flag": "🇸🇻" }
    , { "name": "Equatorial Guinea (+240)", "value": "+240", "code": "GQ", "flag": "🇬🇶" }
    , { "name": "Eritrea (+291)", "value": "+291", "code": "ER", "flag": "🇪🇷" }
    , { "name": "Estonia (+372)", "value": "+372", "code": "EE", "flag": "🇪🇪" }
    , { "name": "Ethiopia (+251)", "value": "+251", "code": "ET", "flag": "🇪🇹" }
    , { "name": "Falkland Islands (Malvinas) (+500)", "value": "+500", "code": "FK", "flag": "🇫🇰" }
    , { "name": "Faroe Islands (+298)", "value": "+298", "code": "FO", "flag": "🇫🇴" }
    , { "name": "Fiji (+679)", "value": "+679", "code": "FJ", "flag": "🇫🇯" }
    , { "name": "Finland (+358)", "value": "+358", "code": "FI", "flag": "🇫🇮" }
    , { "name": "France (+33)", "value": "+33", "code": "FR", "flag": "🇫🇷" }
    , { "name": "French Guiana (+594)", "value": "+594", "code": "GF", "flag": "🇬🇫" }
    , { "name": "French Polynesia (+689)", "value": "+689", "code": "PF", "flag": "🇵🇫" }
    , { "name": "Gabon (+241)", "value": "+241", "code": "GA", "flag": "🇬🇦" }
    , { "name": "Gambia (+220)", "value": "+220", "code": "GM", "flag": "🇬🇲" }
    , { "name": "Georgia (+995)", "value": "+995", "code": "GE", "flag": "🇬🇪" }
    , { "name": "Germany (+49)", "value": "+49", "code": "DE", "flag": "🇩🇪" }
    , { "name": "Ghana (+233)", "value": "+233", "code": "GH", "flag": "🇬🇭" }
    , { "name": "Gibraltar (+350)", "value": "+350", "code": "GI", "flag": "🇬🇮" }
    , { "name": "Greece (+30)", "value": "+30", "code": "GR", "flag": "🇬🇷" }
    , { "name": "Greenland (+299)", "value": "+299", "code": "GL", "flag": "🇬🇱" }
    , { "name": "Grenada (+1473)", "value": "+1473", "code": "GD", "flag": "🇬🇩" }
    , { "name": "Guadeloupe (+590)", "value": "+590", "code": "GP", "flag": "🇬🇵" }
    , { "name": "Guam (+1671)", "value": "+1671", "code": "GU", "flag": "🇬🇺" }
    , { "name": "Guatemala (+502)", "value": "+502", "code": "GT", "flag": "🇬🇹" }
    , { "name": "Guernsey (+44)", "value": "+44", "code": "GG", "flag": "🇬🇬" }
    , { "name": "Guinea (+224)", "value": "+224", "code": "GN", "flag": "🇬🇳" }
    , { "name": "Guinea-Bissau (+245)", "value": "+245", "code": "GW", "flag": "🇬🇼" }
    , { "name": "Guyana (+595)", "value": "+595", "code": "GY", "flag": "🇬🇾" }
    , { "name": "Haiti (+509)", "value": "+509", "code": "HT", "flag": "🇭🇹" }
    , { "name": "Holy See (Vatican City State) (+379)", "value": "+379", "code": "VA", "flag": "🇻🇦" }
    , { "name": "Honduras (+504)", "value": "+504", "code": "HN", "flag": "🇭🇳" }
    , { "name": "Hong Kong (+852)", "value": "+852", "code": "HK", "flag": "🇭🇰" }
    , { "name": "Hungary (+36)", "value": "+36", "code": "HU", "flag": "🇭🇺" }
    , { "name": "Iceland (+354)", "value": "+354", "code": "IS", "flag": "🇮🇸" }
    , { "name": "India (+91)", "value": "+91", "code": "IN", "preferred": true, "flag": "🇮🇳" }
    , { "name": "Indonesia (+62)", "value": "+62", "code": "ID", "flag": "🇮🇩" }
    , { "name": "Iran, Islamic Republic of (+98)", "value": "+98", "code": "IR", "flag": "🇮🇷" }
    , { "name": "Iraq (+964)", "value": "+964", "code": "IQ", "flag": "🇮🇶" }
    , { "name": "Ireland (+353)", "value": "+353", "code": "IE", "flag": "🇮🇪" }
    , { "name": "Isle of Man (+44)", "value": "+44", "code": "IM", "flag": "🇮🇲" }
    , { "name": "Israel (+972)", "value": "+972", "code": "IL", "flag": "🇮🇱" }
    , { "name": "Italy (+39)", "value": "+39", "code": "IT", "flag": "🇮🇹" }
    , { "name": "Jamaica (+1876)", "value": "+1876", "code": "JM", "flag": "🇯🇲" }
    , { "name": "Japan (+81)", "value": "+81", "code": "JP", "flag": "🇯🇵" }
    , { "name": "Jersey (+44)", "value": "+44", "code": "JE", "flag": "🇯🇪" }
    , { "name": "Jordan (+962)", "value": "+962", "code": "JO", "flag": "🇯🇴" }
    , { "name": "Kazakhstan (+77)", "value": "+77", "code": "KZ", "flag": "🇰🇿" }
    , { "name": "Kenya (+254)", "value": "+254", "code": "KE", "flag": "🇰🇪" }
    , { "name": "Kiribati (+686)", "value": "+686", "code": "KI", "flag": "🇰🇮" }
    , { "name": "Korea, Democratic People's Republic of (+850)", "value": "+850", "code": "KP", "flag": "🇰🇵" }
    , { "name": "Korea, Republic of (+82)", "value": "+82", "code": "KR", "flag": "🇰🇷" }
    , { "name": "Kuwait (+965)", "value": "+965", "code": "KW", "flag": "🇰🇼" }
    , { "name": "Kyrgyzstan (+996)", "value": "+996", "code": "KG", "flag": "🇰🇬" }
    , { "name": "Lao People's Democratic Republic (+856)", "value": "+856", "code": "LA", "flag": "🇱🇦" }
    , { "name": "Latvia (+371)", "value": "+371", "code": "LV", "flag": "🇱🇻" }
    , { "name": "Lebanon (+961)", "value": "+961", "code": "LB", "flag": "🇱🇧" }
    , { "name": "Lesotho (+266)", "value": "+266", "code": "LS", "flag": "🇱🇸" }
    , { "name": "Liberia (+231)", "value": "+231", "code": "LR", "flag": "🇱🇷" }
    , { "name": "Libyan Arab Jamahiriya (+218)", "value": "+218", "code": "LY", "flag": "🇱🇾" }
    , { "name": "Liechtenstein (+423)", "value": "+423", "code": "LI", "flag": "🇱🇮" }
    , { "name": "Lithuania (+370)", "value": "+370", "code": "LT", "flag": "🇱🇹" }
    , { "name": "Luxembourg (+352)", "value": "+352", "code": "LU", "flag": "🇱🇺" }
    , { "name": "Macao (+853)", "value": "+853", "code": "MO", "flag": "🇲🇴" }
    , { "name": "Macedonia, The Former Yugoslav Republic of (+389)", "value": "+389", "code": "MK", "flag": "🇲🇰" }
    , { "name": "Madagascar (+261)", "value": "+261", "code": "MG", "flag": "🇲🇬" }
    , { "name": "Malawi (+265)", "value": "+265", "code": "MW", "flag": "🇲🇼" }
    , { "name": "Malaysia (+60)", "value": "+60", "code": "MY", "flag": "🇲🇾" }
    , { "name": "Maldives (+960)", "value": "+960", "code": "MV", "flag": "🇲🇻" }
    , { "name": "Mali (+223)", "value": "+223", "code": "ML", "flag": "🇲🇱" }
    , { "name": "Malta (+356)", "value": "+356", "code": "MT", "flag": "🇲🇹" }
    , { "name": "Marshall Islands (+692)", "value": "+692", "code": "MH", "flag": "🇲🇭" }
    , { "name": "Martinique (+596)", "value": "+596", "code": "MQ", "flag": "🇲🇶" }
    , { "name": "Mauritania (+222)", "value": "+222", "code": "MR", "flag": "🇲🇷" }
    , { "name": "Mauritius (+230)", "value": "+230", "code": "MU", "flag": "🇲🇺" }
    , { "name": "Mayotte (+262)", "value": "+262", "code": "YT", "flag": "🇾🇹" }
    , { "name": "Mexico (+52)", "value": "+52", "code": "MX", "flag": "🇲🇽" }
    , { "name": "Micronesia, Federated States of (+691)", "value": "+691", "code": "FM", "flag": "🇫🇲" }
    , { "name": "Moldova, Republic of (+373)", "value": "+373", "code": "MD", "flag": "🇲🇩" }
    , { "name": "Monaco (+377)", "value": "+377", "code": "MC", "flag": "🇲🇨" }
    , { "name": "Mongolia (+976)", "value": "+976", "code": "MN", "flag": "🇲🇳" }
    , { "name": "Montenegro (+382)", "value": "+382", "code": "ME", "flag": "🇲🇪" }
    , { "name": "Montserrat (+1664)", "value": "+1664", "code": "MS", "flag": "🇲🇸" }
    , { "name": "Morocco (+212)", "value": "+212", "code": "MA", "flag": "🇲🇦" }
    , { "name": "Mozambique (+258)", "value": "+258", "code": "MZ", "flag": "🇲🇿" }
    , { "name": "Myanmar (+95)", "value": "+95", "code": "MM", "flag": "🇲🇲" }
    , { "name": "Namibia (+264)", "value": "+264", "code": "NA", "flag": "🇳🇦" }
    , { "name": "Nauru (+674)", "value": "+674", "code": "NR", "flag": "🇳🇷" }
    , { "name": "Nepal (+977)", "value": "+977", "code": "NP", "flag": "🇳🇵" }
    , { "name": "Netherlands (+31)", "value": "+31", "code": "NL", "flag": "🇳🇱" }
    , { "name": "Netherlands Antilles (+599)", "value": "+599", "code": "AN", "flag": "🇦🇳" }
    , { "name": "New Caledonia (+687)", "value": "+687", "code": "NC", "flag": "🇳🇨" }
    , { "name": "New Zealand (+64)", "value": "+64", "code": "NZ", "flag": "🇳🇿" }
    , { "name": "Nicaragua (+505)", "value": "+505", "code": "NI", "flag": "🇳🇮" }
    , { "name": "Niger (+227)", "value": "+227", "code": "NE", "flag": "🇳🇪" }
    , { "name": "Nigeria (+234)", "value": "+234", "code": "NG", "flag": "🇳🇬" }
    , { "name": "Niue (+683)", "value": "+683", "code": "NU", "flag": "🇳🇺" }
    , { "name": "Norfolk Island (+672)", "value": "+672", "code": "NF", "flag": "🇳🇫" }
    , { "name": "Northern Mariana Islands (+1670)", "value": "+1670", "code": "MP", "flag": "🇲🇵" }
    , { "name": "Norway (+47)", "value": "+47", "code": "NO", "flag": "🇳🇴" }
    , { "name": "Oman (+968)", "value": "+968", "code": "OM", "flag": "🇴🇲" }
    , { "name": "Pakistan (+92)", "value": "+92", "code": "PK", "flag": "🇵🇰" }
    , { "name": "Palau (+680)", "value": "+680", "code": "PW", "flag": "🇵🇼" }
    , { "name": "Palestinian Territory, Occupied (+970)", "value": "+970", "code": "PS", "flag": "🇵🇸" }
    , { "name": "Panama (+507)", "value": "+507", "code": "PA", "flag": "🇵🇦" }
    , { "name": "Papua New Guinea (+675)", "value": "+675", "code": "PG", "flag": "🇵🇬" }
    , { "name": "Paraguay (+595)", "value": "+595", "code": "PY", "flag": "🇵🇾" }
    , { "name": "Peru (+51)", "value": "+51", "code": "PE", "flag": "🇵🇪" }
    , { "name": "Philippines (+63)", "value": "+63", "code": "PH", "flag": "🇵🇭" }
    , { "name": "Pitcairn (+872)", "value": "+872", "code": "PN", "flag": "🇵🇳" }
    , { "name": "Poland (+48)", "value": "+48", "code": "PL", "flag": "🇵🇱" }
    , { "name": "Portugal (+351)", "value": "+351", "code": "PT", "flag": "🇵🇹" }
    , { "name": "Puerto Rico (+1939)", "value": "+1939", "code": "PR", "flag": "🇵🇷" }
    , { "name": "Qatar (+974)", "value": "+974", "code": "QA", "flag": "🇶🇦" }
    , { "name": "Romania (+40)", "value": "+40", "code": "RO", "flag": "🇷🇴" }
    , { "name": "Russia (+7)", "value": "+7", "code": "RU", "flag": "🇷🇺" }
    , { "name": "Rwanda (+250)", "value": "+250", "code": "RW", "flag": "🇷🇼" }
    , { "name": "Réunion (+262)", "value": "+262", "code": "RE", "flag": "🇷🇪" }
    , { "name": "Saint Barthélemy (+590)", "value": "+590", "code": "BL", "flag": "🇧🇱" }
    , { "name": "Saint Helena, Ascension and Tristan Da Cunha (+290)", "value": "+290", "code": "SH", "flag": "🇸🇭" }
    , { "name": "Saint Kitts and Nevis (+1869)", "value": "+1869", "code": "KN", "flag": "🇰🇳" }
    , { "name": "Saint Lucia (+1758)", "value": "+1758", "code": "LC", "flag": "🇱🇨" }
    , { "name": "Saint Martin (+590)", "value": "+590", "code": "MF", "flag": "🇲🇫" }
    , { "name": "Saint Pierre and Miquelon (+508)", "value": "+508", "code": "PM", "flag": "🇵🇲" }
    , { "name": "Saint Vincent and the Grenadines (+1784)", "value": "+1784", "code": "VC", "flag": "🇻🇨" }
    , { "name": "Samoa (+685)", "value": "+685", "code": "WS", "flag": "🇼🇸" }
    , { "name": "San Marino (+378)", "value": "+378", "code": "SM", "flag": "🇸🇲" }
    , { "name": "Sao Tome and Principe (+239)", "value": "+239", "code": "ST", "flag": "🇸🇹" }
    , { "name": "Saudi Arabia (+966)", "value": "+966", "code": "SA", "flag": "🇸🇦" }
    , { "name": "Senegal (+221)", "value": "+221", "code": "SN", "flag": "🇸🇳" }
    , { "name": "Serbia (+381)", "value": "+381", "code": "RS", "flag": "🇷🇸" }
    , { "name": "Seychelles (+248)", "value": "+248", "code": "SC", "flag": "🇸🇨" }
    , { "name": "Sierra Leone (+232)", "value": "+232", "code": "SL", "flag": "🇸🇱" }
    , { "name": "Singapore (+65)", "value": "+65", "code": "SG", "flag": "🇸🇬" }
    , { "name": "Slovakia (+421)", "value": "+421", "code": "SK", "flag": "🇸🇰" }
    , { "name": "Slovenia (+386)", "value": "+386", "code": "SI", "flag": "🇸🇮" }
    , { "name": "Solomon Islands (+677)", "value": "+677", "code": "SB", "flag": "🇸🇧" }
    , { "name": "Somalia (+252)", "value": "+252", "code": "SO", "flag": "🇸🇴" }
    , { "name": "South Africa (+27)", "value": "+27", "code": "ZA", "flag": "🇿🇦" }
    , { "name": "South Georgia and the South Sandwich Islands (+500)", "value": "+500", "code": "GS", "flag": "🇬🇸" }
    , { "name": "Spain (+34)", "value": "+34", "code": "ES", "flag": "🇪🇸" }
    , { "name": "Sri Lanka (+94)", "value": "+94", "code": "LK", "flag": "🇱🇰" }
    , { "name": "Sudan (+249)", "value": "+249", "code": "SD", "flag": "🇸🇩" }
    , { "name": "Suriname (+597)", "value": "+597", "code": "SR", "flag": "🇸🇷" }
    , { "name": "Svalbard and Jan Mayen (+47)", "value": "+47", "code": "SJ", "flag": "🇸🇯" }
    , { "name": "Swaziland (+268)", "value": "+268", "code": "SZ", "flag": "🇸🇿" }
    , { "name": "Sweden (+46)", "value": "+46", "code": "SE", "flag": "🇸🇪" }
    , { "name": "Switzerland (+41)", "value": "+41", "code": "CH", "flag": "🇨🇭" }
    , { "name": "Syrian Arab Republic (+963)", "value": "+963", "code": "SY", "flag": "🇸🇾" }
    , { "name": "Taiwan, Province of China (+886)", "value": "+886", "code": "TW", "flag": "🇹🇼" }
    , { "name": "Tajikistan (+992)", "value": "+992", "code": "TJ", "flag": "🇹🇯" }
    , { "name": "Tanzania, United Republic of (+255)", "value": "+255", "code": "TZ", "flag": "🇹🇿" }
    , { "name": "Thailand (+66)", "value": "+66", "code": "TH", "flag": "🇹🇭" }
    , { "name": "Timor-Leste (+670)", "value": "+670", "code": "TL", "flag": "🇹🇱" }
    , { "name": "Togo (+228)", "value": "+228", "code": "TG", "flag": "🇹🇬" }
    , { "name": "Tokelau (+690)", "value": "+690", "code": "TK", "flag": "🇹🇰" }
    , { "name": "Tonga (+676)", "value": "+676", "code": "TO", "flag": "🇹🇴" }
    , { "name": "Trinidad and Tobago (+1868)", "value": "+1868", "code": "TT", "flag": "🇹🇹" }
    , { "name": "Tunisia (+216)", "value": "+216", "code": "TN", "flag": "🇹🇳" }
    , { "name": "Turkey (+90)", "value": "+90", "code": "TR", "flag": "🇹🇷" }
    , { "name": "Turkmenistan (+993)", "value": "+993", "code": "TM", "flag": "🇹🇲" }
    , { "name": "Turks and Caicos Islands (+1649)", "value": "+1649", "code": "TC", "flag": "🇹🇨" }
    , { "name": "Tuvalu (+688)", "value": "+688", "code": "TV", "flag": "🇹🇻" }
    , { "name": "Uganda (+256)", "value": "+256", "code": "UG", "flag": "🇺🇬" }
    , { "name": "Ukraine (+380)", "value": "+380", "code": "UA", "flag": "🇺🇦" }
    , { "name": "United Arab Emirates (+971)", "value": "+971", "code": "AE", "preferred": true, "flag": "🇦🇪" }
    , { "name": "United Kingdom (+44)", "value": "+44", "code": "GB", "preferred": true, "flag": "🇬🇧" }
    , { "name": "United States (+1)", "value": "+1", "code": "US", "preferred": true, "flag": "🇺🇸" }
    , { "name": "Uruguay (+598)", "value": "+598", "code": "UY", "flag": "🇺🇾" }
    , { "name": "Uzbekistan (+998)", "value": "+998", "code": "UZ", "flag": "🇺🇿" }
    , { "name": "Vanuatu (+678)", "value": "+678", "code": "VU", "flag": "🇻🇺" }
    , { "name": "Venezuela, Bolivarian Republic of (+58)", "value": "+58", "code": "VE", "flag": "🇻🇪" }
    , { "name": "Viet Nam (+84)", "value": "+84", "code": "VN", "flag": "🇻🇳" }
    , { "name": "Virgin Islands, British (+1284)", "value": "+1284", "code": "VG", "flag": "🇻🇬" }
    , { "name": "Virgin Islands, U.S. (+1340)", "value": "+1340", "code": "VI", "flag": "🇻🇮" }
    , { "name": "Wallis and Futuna (+681)", "value": "+681", "code": "WF", "flag": "🇼🇫" }
    , { "name": "Yemen (+967)", "value": "+967", "code": "YE", "flag": "🇾🇪" }
    , { "name": "Zambia (+260)", "value": "+260", "code": "ZM", "flag": "🇿🇲" }
    , { "name": "Zimbabwe (+263)", "value": "+263", "code": "ZW", "flag": "🇿🇼" }
    , { "name": "Åland Islands (+358)", "value": "+358", "code": "AX", "flag": "🇦🇽" }
]
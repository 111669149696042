import React from "react";
// import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from 'lodash'

import {
    CREATE_PRODUCT_CONTRACT,
  UPDATE_PRODUCT_CONTRACT,
  ONE_PRODUCT_CONTRACT,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  // CardHeader,
  CardTitle,
  // CardFooter,
  Button,
  // Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";


const CreateSubscription = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [contract, setContract] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);


  React.useEffect(() => {
    // Run! Like go get some data from an API.
 
    if (props.contractId?.id) {
      dispatch({ type: ONE_PRODUCT_CONTRACT, payload: props.contractId?.id });
    }
  }, [dispatch]);

  const { get_product_contract, get_errors } = useSelector(
    (state) => ({
        get_product_contract: state.productcontract,
        get_errors: state.errors
    }),
    shallowEqual
  );


  React.useEffect(() => {
    // console.log(_.isEmpty(loginError.errors))
    if (!_.isEmpty(get_errors.errors)) {
      // console.log(loginError)
      setVisible(true)
      setPageErrors(get_errors)
    }
  }, [get_errors])

  React.useEffect(() => {
    if (get_product_contract && props.contractId?.id) {
        setContract(get_product_contract[props.contractId?.id]);
    }
  }, [get_product_contract, props.contractId?.id]);

  // console.log(selStatus);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  
  const onSubmit = (data) => {

    if (props.contractId) {

      dispatch({
        type: UPDATE_PRODUCT_CONTRACT,
        payload: {
          id:props.contractId.id,
          product: props.contractId?.product,
          company: props.company_id,
          price: data.price
        },
      });

     

    } else {
      dispatch({
        type: CREATE_PRODUCT_CONTRACT,
        payload: {
          product: props.id,
          company: props.company_id,
          price: data.price,
        },
      });
      
    }
    props.toggle()
    props.resetColWidth(Math.random())
    // <Redirect />
  };

  //   const ChangedStatus = (value) => {
  //     setSelStatus(value);
  //   };
  // console.log(errors);
  return (
    <>
      <div className="content">
      <Alert color="danger" isOpen={visible} toggle={setVisible}>
      {pageerrors.errors? pageerrors.errors['detail']: JSON.stringify(pageerrors.errors)}

      {/* {JSON.stringify(pageerrors.errors)} */}
    </Alert>
 
        <ErrorBoundary>
           <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <label>Price </label>
                      <Input
                        type="text"
                        name="price"
                        defaultValue={contract ? contract.price : ""}
                        innerRef={register({
                          required: "Please enter Contract Amount",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.price && <span>{errors.price.message}</span>}
                      </p>
                    </FormGroup>
                    
                    <Button className="btn-fill" 
                    color= {id ? "primary " : "secondary "} 
                    type="submit">
                      {id ? "Update " : "Save "}
                    </Button>
            </Form>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateSubscription;

import { GET_INVENTORYIMAGES, DELETE_INVENTORYIMAGE, ADD_INVENTORYIMAGE, CLEAR_ALL, 
    FETCH_INVENTORYIMAGE, EDIT_INVENTORYIMAGE }
    from "constants/actions";

const initialState = {
    inventoryimages: []
};

export default function (state = initialState, action) { 
    switch (action.type) {
        case GET_INVENTORYIMAGES:
            
            return {
                ...state,
                inventoryimages: action.payload
            };

        case FETCH_INVENTORYIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_INVENTORYIMAGE:
            if (state.inventoryimages.filter((an) => an.id === action.payload.id)[0]) {
                state = {
                  ...state,
                  inventoryimages: state.inventoryimages.filter(
                    (ans) => ans.id !== action.payload.id
                  ),
                };
              }
              return {
                ...state,
                inventoryimages: [...state.inventoryimages, action.payload],
              };
            // return { ...state, [action.payload.id]: action.payload };

        case DELETE_INVENTORYIMAGE:
           
            return {
                ...state,
                inventoryimages: state.inventoryimages.filter(img => img.id !== action.payload)
            };
        case ADD_INVENTORYIMAGE:
            return {
                ...state,
                inventoryimages: 
                [...state.inventoryimages,
                    action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                inventoryimages: []
            };
        default:
            return state;
    }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_inventoryimages:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, inventoryimages: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }

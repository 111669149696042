import React from "react";
import AppCard from "../../crema/AppCard";
import Box from "@mui/material/Box";

import Avatar from "@mui/material/Avatar";
import { Fonts } from "../../crema/constants/src/AppEnums";
import PropTypes from "prop-types";
import { getAssetsUrl } from "../../crema/helpers/src/UrlHelper";

const StatsItemCard = ({ stats }) => {
  return (
    <AppCard
      sxStyle={{
        height: 1,
        borderRadius: "16px",
        boxShadow: "0px 10px 10px 4px rgba(0, 0, 0, 0.04)",
        padding: "0px",
      }}
      className="card-hover"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: 1,
        }}
      >
        <Box
          sx={{
            mr: 1,
          }}
        >
          <Avatar
            sx={{
              width: 46,
              height: 46,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 32,
              backgroundColor: stats?.badgeColor,
              padding: 1,
            }}
          >
            {stats?.icon}
          </Avatar>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: {
              xs: "calc(100% - 62px)",
              lg: "calc(100% - 70px)",
              xl: "calc(100% - 76px)",
            },
          }}
        >
          <Box sx={{ overflow: "hidden" }}>
            <Box
              component="h3"
              sx={{
                margin: 0,
                fontWeight: "bold",
                color: "rgb(17, 24, 39)",
                fontWeight: 600,
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {stats.count}
            </Box>
            <Box
              component="h3"
              sx={{
                color: "text.secondary",
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginBottom: 0,
              }}
            >
              {stats.title}
            </Box>
          </Box>
          {/* <Box
            component="span"
            sx={{
              backgroundColor: stats.bgcolor,
              borderRadius: 30,
              color: stats.badgeColor,
              padding: "4px 12px",
              fontSize: 12,
              fontWeight: Fonts.MEDIUM,
              display: "inline-block",
              minWidth: 75,
              textAlign: "center",
              "@media only screen and (max-width: 1580px) and (min-width: 1200px)":
                {
                  display: "none",
                },
            }}
          >
            {stats.new}
          </Box> */}
        </Box>
      </Box>
    </AppCard>
  );
};

export default StatsItemCard;

StatsItemCard.propTypes = {
  stats: PropTypes.object,
};

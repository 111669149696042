export const serverApi = 'https://api.preautoco.com'
export const staginApi = 'https://api.datastruk.com'
export const localApi = 'http://localhost:8000'

export const currentApi = serverApi

export const config = {
    loginapi: `${currentApi}/api/user`,
    authapi: `${currentApi}/api/rest-auth`,
    serverapi: `${currentApi}/api/dealer`,
    website: `${currentApi}/api/web`,
    payment: `${currentApi}/api/payment`,
};
export default config

import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Button, Row, Col } from "reactstrap";

import ErrorGif from "assets/images/giphy-error.gif";
const type = "Image"; // Need to pass which type element can be draggable
const maxSize = 5048576;

const Image = ({ image, index, moveImage, deleteImage, imageError }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Move the content
      moveImage(dragIndex, hoverIndex);
      // Update the index for dragged item directly to avoid flickering when half dragged
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type, id: image.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // initialize drag and drop into the element
  drag(drop(ref));
  const clicked = (e) => {
    deleteImage(e);
  };
  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}
      className="file-item"
    >
      <img alt={`img - ${image.id}`} src={image.src} className="file-img" />

      <Row>
        <Col md="3">
          <Button color="danger" size="sm" onClick={() => {
            clicked(image.id)
            imageError(null)}}>
            Delete
          </Button>
        </Col>
        <Col md="9">
          <p className="text-danger">
            <small>
              {image.file.size > maxSize ? (
                <span>
                  {`Pic is more than ${Math.round(image.file.size / maxSize)} 
     MB reduce to 5 MB`}
                  <img src={ErrorGif} height="15px" width="30px" alt="error image" />
                </span>
              ) : null}

              {image.file.size > maxSize
                ? imageError(` Pic is ${Math.round(image.file.size / maxSize)} 
     MB reduce to 5 MB`)
                : null}
            </small>
          </p>
        </Col>
      </Row>
    </div>
  );
};

const ImageList = ({ images, moveImage, deleteImage, imageError }) => {
  // console.log(deleteImage)
  const clicked = (e) => {
    // console.log(e)
    deleteImage(e);
  };

  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        index={index}
        key={`${image.id}-image`}
        moveImage={moveImage}
        deleteImage={clicked}
        imageError={imageError}
      />
    );
  };

  return <section className="file-list">{images.map(renderImage)}</section>;
};

export default ImageList;

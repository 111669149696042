import {
    GET_LOANS,
    DELETE_LOAN,
    ADD_LOAN,
    FETCH_LOAN,
    EDIT_LOAN,
    CLEAR_LOAN,
    ALL_ERRORS,
    CLEAR_ALL
} from "constants/actions";

const initialState = {
    loans: [],
    pending: false
};


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LOANS:
            return {
                ...state,
                loans: action.payload
            };
        case FETCH_LOAN:
            return { 
                ...state, [action.payload.id]: action.payload, 
            };
        case EDIT_LOAN:
            return { 
                ...state, 
                [action.payload.id]: action.payload, 
            };
        case DELETE_LOAN:
            return {
                ...state,
                loans: state.loans.filter(
                        lead => lead.id !== action.payload
                    )
            };
        case ADD_LOAN:
            return {
                ...state,
                loans: [...state.loans, action.payload]
            };
        case CLEAR_LOAN:
            return {
                ...state,
                loans: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                loans: []
            };
        default:
            return state;
    }
}

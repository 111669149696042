import React from "react";

// reactstrap components
import {
  Table,
} from "reactstrap";

// import "./style.css"
const GenericEquipment = ({ equipment }) => {
  const [equipmentList, setEquipmentList] = React.useState([]);
  React.useEffect(() => {
    if (equipment) {
      if (equipment[0]) {
        setEquipmentList(equipment);
      }
    }
  }, [equipment]);

  let mySet = new Set();
  let LargeString = "";

  equipmentList.map((p) => mySet.add(p.group));

  for (let item of mySet.values()) {
    LargeString += `<strong>${item}</strong> <br />`;
    equipmentList
      .filter((fl) => fl.group === item)
      .map((lft) => {
        LargeString += `${lft.category} `;
        return lft.category;
      });
    LargeString += `<br />`;
  }

  return (
    <>
      <div className="content">
        <Table striped bordered>
          <tbody>
            {equipmentList
              ? equipmentList.map((p, k) => {
                  return (
                    <tr key={k}>
                      <td className="table-head-content">{p.group}</td>
                      <td>{p.category}</td>
                      <td>{p.header}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default GenericEquipment;

import React, { useState } from "react";

import AppDialog from "../../../crema/AppDialog";

import CreateEdit from "Vinupload/Basic/CreateEdit";

const AddNewLeadVinUpload = ({
  isAddTaskOpen,
  id,
  vinid,
  resetColWidth,
  setAddTaskOpen,
  onCloseAddTask,
  selectedDate,
  title,
}) => {
  const [label, setLabel] = useState("");
  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => onCloseAddTask()}
      title={label}
    >
      <CreateEdit
        id={id}
        vinid={vinid}
        resetColWidth={resetColWidth}
        setAddTaskOpen={setAddTaskOpen}
        setLabel={setLabel}
      />
    </AppDialog>
  );
};

export default AddNewLeadVinUpload;

import React from "react";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// import {
//     Modal,
//     ModalHeader,
//     ModalBody,
//     ModalFooter,
//     Button, Alert, Spinner, Fade, Table, Row, Col
//   } from "reactstrap";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { Button as MUIButton, TextField } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
// import _ from "lodash";
// reactstrap components
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
  Spinner,
  Fade,
  Row,
  Col,
} from "reactstrap";

import {
  LOAD_INVENTORY_LIST,
  SG_FETCH_RELATED_DEALER,
  LOAD_GSHEET_INVENTORY_LIST,
  LOAD_VALID_VIN_UPLOAD_LIST,
  SG_ADD_VIN_UPLOAD,
  SG_EDIT_MILEAGE,
  SG_EDIT_INVENTORY,
} from "constants/actions";
import inventory from "reducers/inventory";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";

// const columnMap = {
//     "VIN": "vin",
//     "Make": "make",
//     "Year": "year",
//     "Equip": "notes",
//     "Ext Color": "color",
//     "Kilometers": "mileage",
//     "Retail Price": "retail"
// };
// const LocationId = 4;

const ShowDetails = ({ Inventory }) => {
  if (Inventory) {
    return (
      <>
        Mfd Col: {Inventory.mfd_color_code ? Inventory.mfd_color_code : ""}
        <hr />
        Seats: {Inventory.pass_capacity ? Inventory.pass_capacity : ""}
        <hr />
        Engine: {Inventory.engine ? Inventory.engine : ""}
        <hr />
        Images: {Inventory.total_images ? Inventory.total_images : ""}
      </>
    );
  } else {
    return (
      <p style={{ background: "#f33333" }} className="text-white">
        <small>
          <strong>Resolve</strong>
        </small>
      </p>
    );
  }
};

const ViewBtn = ({ inventory }) => {
  // if (inventoryList.id === 350) {

  return (
    <Link to={`/admin/inventory-detail/${inventory.id}`}>
      <RemoveRedEyeOutlinedIcon color={"preAuto"} />
    </Link>
  );
};

const GSheetCompare = ({ selectedLocation }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [creating, setCreating] = React.useState();
  const [pending, setPending] = React.useState();
  const [inventoryList, setInventoryList] = React.useState([]);
  const [comparedList, setComparedList] = React.useState([]);
  const [columnMap, setColumnMap] = React.useState(false);
  const [vinupload, setVinupload] = React.useState([]);
  const [vinuploadList, setVinuploadList] = React.useState([]);

  const [pricesUpdated, setPricesUpdated] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  const [removeInvId, setRemoveInvId] = React.useState(false);
  const [gsheetVin, setGsheetVin] = React.useState([]);
  const [vinList, setVinList] = React.useState([]);

  const [delistModal, setDelistModal] = React.useState(false);

  const onDismiss = () => setVisible(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_INVENTORY_LIST });
    dispatch({ type: LOAD_VALID_VIN_UPLOAD_LIST });
    dispatch({ type: LOAD_GSHEET_INVENTORY_LIST });
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);

  const {
    get_inventories,
    get_compared,
    get_pending,
    get_vinupload,
    get_prices_updated,
    get_dealer_location,
  } = useSelector(
    (state) => ({
      get_inventories: state.inventories.inventoryList,
      get_compared: state.inventories.gsheet,
      get_pending: state.vinupload.pending,
      get_vinupload: state.vinupload,
      get_prices_updated: state.inventories.updated_prices,
      get_dealer_location: state.dealers.related,
    }),
    shallowEqual
  );

  const toggleDelist = () => setDelistModal(!delistModal);
  const DelistInventory = () => {
    // console.log(Invid)
    // setModal(!modal);
    if (removeInvId) {
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id: removeInvId.id,
          status: 11,
        },
      });
      // history.push("/admin/inventory");
      setDelistModal(false);
    }
  };

  // console.log(get_compared.results)
  // console.log(get_inventories)
  // console.log(selectedLocation)
  //   const newObj = {};

  //   if (get_dealer_location) {
  //     console.log(
  //       get_dealer_location.find(({ id }) => id === LocationId).data_url
  //     );
  //   }

  // SurveyList.find(({ id }) => id === surveyType)?.name

  React.useEffect(() => {
    if (get_inventories) {
      setInventoryList(
        get_inventories.filter(
          (p) => p.status < 11 && p.location === selectedLocation.id
        )
      );
    }
  }, [get_inventories]);

  React.useEffect(() => {
    if (get_compared) {
      //
      if (get_compared?.column_map) {
        setColumnMap(get_compared.column_map);
      }
    }
  }, [get_compared]);

  // console.log(get_compared.results)
  React.useEffect(() => {
    if (columnMap) {
      if (get_compared.results) {
        setComparedList(get_compared.results);
        get_compared.results.map((rst) => {
          for (var prop in rst) {
            if (rst.hasOwnProperty(prop) && prop in columnMap) {
              // console.log(rst[prop])
              rst[columnMap[prop]] = rst[prop];
              // console.log(rst[columnMap[prop]])
              delete rst[prop];
            }
          }
        });
      }
    } else {
      setComparedList(get_compared.results);
    }
    //  setComparedList(get_compared.results);
  }, [get_compared, comparedList, columnMap]);

  React.useEffect(() => {
    if (comparedList) {
      let _listVin1 = [];
      inventoryList.map((m) => _listVin1.push(m.vin));

      let _listVin2 = [];
      comparedList.map((m) => _listVin2.push(m.vin));

      // setVinList(...new Set([..._listVin1, ..._listVin2]))
      //     console.log(_listVin1)
      //     console.log(_listVin2)
      setVinList(Array.from(new Set([..._listVin1, ..._listVin2])));
    }
  }, [inventoryList, comparedList]);

  React.useEffect(() => {
    if (get_vinupload) {
      setVinupload(get_vinupload);
    }
  }, [get_vinupload]);

  React.useEffect(() => {
    if (get_prices_updated) {
      setPricesUpdated(get_prices_updated);
    }
  }, [get_prices_updated]);

  React.useEffect(() => {
    if (get_vinupload) {
      setVinuploadList(get_vinupload.validlist);
    }
  }, [get_vinupload]);

  // console.log(get_vinupload.validlist)
  // console.log(inventoryList)

  React.useEffect(() => {
    if (get_vinupload?.created?.id) {
      history.push(
        `/admin/full-vin-decode-edit/${get_vinupload.created.id}/${get_vinupload.created.inventory_id}?return-url=external-inventory?location=${selectedLocation.id}`
      );
    }
  }, [get_vinupload, get_pending]);

  React.useEffect(() => {
    setCreating(get_pending);
  }, [get_pending]);

  const PullRecent = () => {
    // dispatch({ type: LOAD_COMPARED_INVENTORY_LIST,
    //   payload: "pull=True" });
    setVisible(true);
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
        // console.log(p)
        const invId = inventoryList.filter((fl) => fl.vin === p.vin)[0];
        //  console.log(invId)
        dispatch({
          type: SG_EDIT_MILEAGE,
          payload: { id: invId.id, list_price: p.retail },
        });
      }
    });
  };

  const NotFound = [];
  if (comparedList && inventoryList) {
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
      } else {
        NotFound.push({
          vin: p.vin,
          year: p.year,
          make: p.make,
          mode: p.model,
        });
      }
    });
  }

  const PullFile = (e) => {
    // console.log(e);
    dispatch({
      type: SG_ADD_VIN_UPLOAD,
      payload: {
        ...e,
        location_id: selectedLocation.location_id,
        model: "",
        retail_price: e.retail,
        cost_price: Number(e?.cost),
        return_url: window.location,
      },
    });
  };
  // const _PullFile = (e) => {
  //   dispatch({
  //     type: SG_ADD_VIN_UPLOAD,
  //     payload: { ...e, location_id: LocationId, retail_price: e.retail },
  //   });
  // };
  // console.log(comparedList)
  // console.log(inventoryList)
  return (
    <>
      <div className="content">
        <Row>
          <Col
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <MUIButton
              color="preAuto"
              type="submit"
              variant="outlined"
              onClick={() => PullRecent()}
              sx={{
                mr: 2,
              }}
            >
              Update All Prices
            </MUIButton>

            <a
              //   className="btn"
              //   color="secondary"
              //   size="lg"
              target="_blank"
              rel="noopener noreferrer"
              href={
                get_dealer_location &&
                get_dealer_location.find(({ id }) => id === selectedLocation.id)
                  ?.data_url
              }
            >
              <MUIButton
                color="preAuto"
                type="submit"
                variant="outlined"
                sx={{
                  mr: 2,
                }}
              >
                View the Google Sheet
              </MUIButton>
            </a>
          </Col>
          <Col>
            {pricesUpdated.length > 0 ? (
              <Alert color="info" isOpen={visible} toggle={onDismiss}>
                <h6>
                  {pricesUpdated.reduce(
                    (total, x) => (x.status == 1 ? total + 1 : total),
                    0
                  )}{" "}
                  Inventory Prices have been updated
                </h6>
                {pricesUpdated.map((p, k) => (
                  <p key={k}>
                    {p.year} {p.make} {p.model} {p.list_price}
                  </p>
                ))}
              </Alert>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <div className={pending ? "" : "d-none mt-3"}>
          <Fade in={pending} tag="h5">
            <div className="d-flex justify-content-center">
              <Spinner>Loading...</Spinner>
            </div>
          </Fade>
        </div>

        {/* <Row>
                        <Col md="12">
                            <Table responsive>
                                <thead>
                                <tr>
                                        <th>No</th>
                                        <th>Purchased</th>
                                        <th>
                                            Year Make Model <br />
                                            VIN
                                        </th>
                                        <th>Color Engine Images</th>
                                        <th>Mileage</th>
                                        <th>Price</th>
                                        <th>Found</th>
                                        <th>More</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {comparedList
                                        ? comparedList.map((p, k) => (

                                            <p key={k}>{p.id}</p>

                                        ))
                                        : null}
                                </tbody>
                            </Table>
                            </Col>
                    </Row> */}

        {/* <div className={pending ? "d-none" : ""}>
                    <Row>
                        <Col md="12">
                            <Table responsive>
                                <thead className="table-active">
                                    <tr>
                                        <th>No</th>
                                        <th>Purchased</th>
                                        <th>
                                            Year Make Model <br />
                                            VIN
                                        </th>
                                        <th>Color Engine Images</th>
                                        <th>Mileage</th>
                                        <th>Price</th>
                                        <th>Found</th>
                                        <th>More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vinList
                                        ? vinList.map((p, k) => (

                                            <CheckIfInGsheet
                                                vin={p}
                                                comparedList={comparedList}
                                                inventoryList={inventoryList}
                                                id={k}
                                                vinuploadList={vinuploadList}
                                                selectedLocation={selectedLocation}
                                                PullFile={PullFile}
                                                key={k}
                                                delistModal={delistModal}
                                                setDelistModal={setDelistModal}
                                                setRemoveInvId={setRemoveInvId}
                                            />
                                        ))
                                        : null}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                </div> */}

        <>
          {/* <div className={pending ? "d-none" : ""}>
                        <Paper sx={{ width: "100%", overflow: "hidden" }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>NO</StyledTableCell>
                                            <StyledTableCell align="center">PURCHASED</StyledTableCell>
                                            <StyledTableCell align="center">YEAR MAKE MODEL VIN</StyledTableCell>
                                            <StyledTableCell align="center">COLOR ENGINE IMAGES</StyledTableCell>
                                            <StyledTableCell align="center">MILEAGE</StyledTableCell>
                                            <StyledTableCell align="center">PRICE</StyledTableCell>
                                            <StyledTableCell align="center">FOUND</StyledTableCell>
                                            <StyledTableCell align="center">MORE</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
         */}
          <div className={pending ? "" : "d-none mt-3"}>
            <Fade in={pending} tag="h5">
              <div className="d-flex justify-content-center">
                <Spinner>Loading...</Spinner>
              </div>
            </Fade>
          </div>
          <div className={pending ? "d-none" : ""}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>No</StyledTableCell>
                      <StyledTableCell align="center">
                        Purchased
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Year Make Model VIN
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Color Engine Images
                      </StyledTableCell>
                      <StyledTableCell align="center">Mileage</StyledTableCell>
                      <StyledTableCell align="center">Price</StyledTableCell>
                      <StyledTableCell align="center">Found</StyledTableCell>
                      <StyledTableCell align="center">MORE</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {vinList.map((row) => (
                  <StyledTableRow key={row}>
                    <StyledTableCell align="center">{row.p}</StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.make}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.model}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.color}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TextField
                        sx={{
                          maxWidth: "80px",
                          margin: 0,
                          padding: 0,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#1d4aa7",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1d4aa7",
                            },
                          },
                          " input": {
                            px: 1,
                            py: 0.5,
                          },
                        }}
                        defaultValue={row.kilometers}
                        //  onChange={(e) => onKilometerChange(e)}
                        //  onFocus={(e) => onKilometerFocus(e)}
                        onBlur={(e) => onKilometerBlur(e, row.id)}
                      />
                    
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.veh_in_date}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.list_price}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {
                        <Link to={`/admin/inventory-detail/${row.id}`}>
                          <p
                            style={{
                              color: "#1d4aa7",
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </p>
                        </Link>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))} */}
                    {vinList
                      ? vinList.map((p, k) => (
                          <CheckIfInGsheet
                            vin={p}
                            comparedList={comparedList}
                            inventoryList={inventoryList}
                            id={k}
                            vinuploadList={vinuploadList}
                            selectedLocation={selectedLocation}
                            PullFile={PullFile}
                            key={k}
                            delistModal={delistModal}
                            setDelistModal={setDelistModal}
                            setRemoveInvId={setRemoveInvId}
                          />
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </>

        <div className={pending ? "d-none" : ""}>
          <CSVLink
            data={NotFound}
            filename={"inventory-missing.csv"}
            // className="btn btn-primary"
            target="_blank"
          >
            <MUIButton
              color="preAuto"
              type="submit"
              variant="contained"
              sx={{
                mt: 2,
              }}
            >
              Download Missing
            </MUIButton>
          </CSVLink>
          <Modal isOpen={delistModal} toggle={toggleDelist}>
            <ModalHeader toggle={toggleDelist}>
              Delist {removeInvId.year} {removeInvId.make} {removeInvId.model}
            </ModalHeader>
            <ModalBody>
              This removes from Website: removes all associated images and
              retains some basic information in the backend. Use it when you
              have to remove the inventory from website.
              <p className="lead">
                {removeInvId.year} {removeInvId.make} {removeInvId.model}{" "}
                {removeInvId.color} with {removeInvId.kilometers} kms
              </p>
            </ModalBody>
            <ModalFooter className="pr-2">
              <Button color="warning" onClick={DelistInventory}>
                De List
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default GSheetCompare;

function CheckIfInGsheet({
  vin,
  comparedList,
  inventoryList,
  id,
  vinuploadList,
  selectedLocation,
  PullFile,
  delistModal,
  setDelistModal,
  setRemoveInvId,
}) {
  let _inventoryFound = null;
  let _gsheetFound = null;
  if (inventoryList) {
    _inventoryFound = inventoryList.filter((p) => p.vin === vin)[0];
  }
  if (comparedList) {
    _gsheetFound = comparedList.filter((p) => p.vin === vin)[0];
  }

  if (_inventoryFound && _gsheetFound) {
    return (
      <RegularRow
        id={Number(id)}
        inventory={_inventoryFound}
        vinuploadList={vinuploadList}
        selectedLocation={selectedLocation}
      />
    );
  }
  if (!_inventoryFound && _gsheetFound) {
    return (
      <DecodeRow id={Number(id)} inventory={_gsheetFound} PullFile={PullFile} />
    );
  }
  if (_inventoryFound && !_gsheetFound) {
    return (
      <RemoveRow
        id={Number(id)}
        inventory={_inventoryFound}
        delistModal={delistModal}
        setDelistModal={setDelistModal}
        setRemoveInvId={setRemoveInvId}
      />
    );
  }
  return (
    <p>
      {Number(id) + 1} Waiting {vin}{" "}
    </p>
  );
}

function RegularRow({ id, inventory, vinuploadList, selectedLocation }) {
  return (
    <tr
      className={
        inventory.status === 4
          ? "bg-success text-white"
          : "bg-primary text-white"
      }
    >
      <td>{id + 1}</td>
      <td>{inventory.purchased}</td>
      <td>
        {inventory.year} {inventory.make} {inventory.model} <br />
        {inventory.vin} <br />
        {inventory.notes}
      </td>
      <td>
        {inventory.color} <br />
        <ShowDetails Inventory={inventory} />
      </td>
      <td>{inventory.kilometers}</td>
      <td>{inventory.retail}</td>
      <td>
        {vinuploadList.filter((fl) => fl.vin === inventory.vin)[0] ? (
          <Link
            to={`/admin/full-vin-decode-edit/${
              vinuploadList.filter((fl) => fl.vin === inventory.vin)[0].id
            }/${
              vinuploadList.filter((fl) => fl.vin === inventory.vin)[0]
                .inventory_id
            }?return-url=external-inventory?location=${selectedLocation.id}`}
          >
            <MUIButton
              type="submit"
              variant="contained"
              style={{
                color: "#F5EDFC",
                backgroundColor: "#9E49E6",
                padding: "5px 5px",
              }}
            >
              Re Decode
            </MUIButton>
          </Link>
        ) : (
          ""
        )}
      </td>
      <td>
        <ViewBtn inventory={inventory} />
      </td>
    </tr>
  );
}

function RemoveRow({ id, inventory, setDelistModal, setRemoveInvId }) {
  function RemoveDelist() {
    setDelistModal(true);
    setRemoveInvId(inventory);
  }

  return (
    <StyledTableRow key={inventory}>
      <StyledTableCell align="center">{id + 1}</StyledTableCell>
      <StyledTableCell colSpan={4} component="th" scope="row" align="start">
        {inventory.year} {inventory.make}
        {inventory.model} {inventory.color} with {inventory.kilometers} kms has
        been deleted from Gsheet{" "}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.status_text}
      </StyledTableCell>
      <StyledTableCell align="center" onClick={() => RemoveDelist}>
        <MUIButton variant="contained" style={{ backgroundColor: "#ff0000" }}>
          Remove
        </MUIButton>
      </StyledTableCell>
      <StyledTableCell>
        <ViewBtn inventory={inventory} />
      </StyledTableCell>
    </StyledTableRow>
  );
}

function DecodeRow({ id, inventory, PullFile }) {
  // console.log(inventory)
  return (
    <StyledTableRow key={inventory}>
      <StyledTableCell align="center">{id + 1}</StyledTableCell>
      <StyledTableCell colSpan={4} component="th" scope="row" align="start">
        {inventory.vin} {"=>"} {inventory.year} {inventory.make}{" "}
        {inventory.model} {inventory.color} with {inventory.mileage} kms was
        found in backend inventory but not in website inventory
      </StyledTableCell>
      <StyledTableCell
        align="center"
        colSpan={3}
        onClick={() => PullFile(inventory)}
      >
        <MUIButton
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#F5EDFC",
            color: "#9E49E6",
          }}
        >
          Decode
        </MUIButton>
      </StyledTableCell>
    </StyledTableRow>

    //    <tr className="text-black">
    //         <td>{id + 1}</td>
    //         <td colSpan={4}> {inventory.vin} {'=>'} {inventory.year} {inventory.make}
    //             {' '}  {inventory.model} {inventory.color} with {inventory.mileage} kms was found in backend inventory but not in website inventory</td>

    // <StyledTableRow key={inventory}>
    //     <StyledTableCell align="center">{id + 1}</StyledTableCell>
    //     <StyledTableCell colSpan={4} component="th" scope="row" align="start">
    //         {inventory.vin} {'=>'} {inventory.year} {inventory.make}
    //         {' '}  {inventory.model} {inventory.color} with {inventory.mileage} kms was found in backend inventory but not in website inventory
    //     </StyledTableCell>
    //     <StyledTableCell align="center" colSpan={3} onClick={() => PullFile(inventory)}>
    //         <Button variant="contained" color="primary">
    //             Decode
    //         </Button>
    //     </StyledTableCell>
    // </StyledTableRow>

    //    <tr className="text-black">
    //         <td>{id + 1}</td>
    //         <td colSpan={4}> {inventory.vin} {'=>'} {inventory.year} {inventory.make}
    //             {' '}  {inventory.model} {inventory.color} with {inventory.mileage} kms was found in backend inventory but not in website inventory</td>

    //         <td> </td>
    //         <td><Button
    //             size="sm"
    //             color="success"
    //             onClick={() => PullFile(inventory)}
    //         > Decode
    //         </Button>
    //         </td>
    //         <td></td>
    //     </tr>
  );
}

function ConfirmDelist({ id, delistModal, setDelistModal }) {
  const dispatch = useDispatch();
  const toggleDelist = () => setDelistModal(!delistModal);
  const DelistInventory = () => {
    // console.log(Invid)
    // setModal(!modal);
    dispatch({ type: SG_EDIT_INVENTORY, payload: { id, status: 11 } });
    // history.push("/admin/inventory");
    setDelistModal(false);
  };

  return (
    <Modal isOpen={delistModal} toggle={toggleDelist}>
      <ModalHeader toggle={toggleDelist}>Confirm Delist</ModalHeader>
      <ModalBody>This action is not reversible!</ModalBody>
      <ModalFooter className="pr-2">
        <Button color="warning" onClick={DelistInventory}>
          De List
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

// function RemoveRow({ inventory, inventoryList, vinuploadList, k, selectedLocation, PullFile }) {
//     return (
//         <tr>
//             <td>{k + 1}</td>
//             <td
//                 className={
//                     inventoryList.filter((fl) => fl.vin === inventory.vin)[0]
//                         ? inventoryList.filter(
//                             (fl) => fl.vin === inventory.vin
//                         )[0].status === 4
//                             ? "bg-success"
//                             : "bg-primary"
//                         : "bg-danger"
//                 }
//             >
//                 {inventory.purchased} <br />
//                 <small>
//                     {inventoryList.filter((fl) => fl.vin === inventory.vin)[0]
//                         ? inventoryList.filter(
//                             (fl) => fl.vin === inventory.vin
//                         )[0].status_text
//                         : "Not Found"}
//                 </small>
//             </td>
//             <td>
//                 {inventory.year} {inventory.make} {inventory.model} <br />
//                 {inventory.vin} <br />
//                 {inventory.notes}
//             </td>
//             <td>
//                 {inventory.color} <br />
//                 <ShowDetails Inventory={inventory} />
//             </td>
//             <td>{inventory.mileage}</td>
//             <td>
//                 Backend: {inventory.retail}
//                 <hr />
//                     Website:
//                     {inventoryList.filter((fl) => fl.vin === inventory.vin)[0]
//                     ? inventoryList.filter(
//                         (fl) => fl.vin === inventory.vin
//                     )[0].list_price
//                     : "Not Found"}
//             </td>
//             <td>
//                 {vinuploadList.filter(
//                     (fl) => fl.vin === inventory.vin
//                 )[0] ? (
//                     <Link
//                         to={`/admin/full-vin-decode-edit/${vinuploadList.filter(
//                             (fl) => fl.vin === inventory.vin
//                         )[0].id
//                             }/${vinuploadList.filter(
//                                 (fl) => fl.vin === inventory.vin
//                             )[0].inventory_id
//                             }?return-url=external-inventory?location=${selectedLocation.id}`}
//                     >
//                         <Button size="sm" color="info">
//                             Resolve
//                         </Button>
//                     </Link>
//                 ) : (
//                     <Button
//                         size="sm"
//                         color="success"
//                         onClick={() => PullFile(inventory)}
//                     >
//                         Decode
//                     </Button>
//                 )}
//             </td>
//             <td>
//                 <ViewBtn inventory={inventory} inventoryList={inventoryList} />
//             </td>
//         </tr>
//     )
// }

import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_LEAD_STAGE,
  LOAD_DEFAULT_LEAD_STAGE,
  SG_ADD_LEAD_STAGE,
} from "constants/actions";

import ReactPaginate from "react-paginate";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile, deviceType
// } from "react-device-detect";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  // Table,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AddNewLeadStage from "./AddNewLeadStage";

const Leadsource = (props) => {
  const dispatch = useDispatch();
  const [params, setParams] = React.useState(props.params);
  const [leadsources, setLeadsources] = React.useState([]);
  const [generateRows, setGenerateRows] = React.useState(false);

  React.useEffect(() => {
    // console.log(params)

    dispatch({ type: LOAD_LEAD_STAGE, payload: params });
  }, [dispatch, params]);

  const { get_lead_stage, get_default_lead_stage } = useSelector(
    (state) => ({
      get_lead_stage: state.leadstage.leadstages,
      get_default_lead_stage: state.leadstage.default,
    }),
    shallowEqual
  );

  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)

  React.useEffect(() => {
    if (get_lead_stage) {
      setLeadsources(get_lead_stage);
    }
  }, [get_lead_stage]);

  const GenerateDefault = () => {
    dispatch({ type: LOAD_DEFAULT_LEAD_STAGE });
    setGenerateRows(true);
  };

  React.useEffect(() => {
    if (get_default_lead_stage) {
      if (generateRows) {
        get_default_lead_stage.map((p) => {
          dispatch({
            type: SG_ADD_LEAD_STAGE,
            payload: { ...p, company: parseInt(props.id) },
          });
        });
        setGenerateRows(false);
      }
    }
  }, [get_default_lead_stage, generateRows]);

  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Lead Stages</CardTitle>
                <h5 className="card-category">Evaluate all Lead Stages</h5>
              </Col>
              <Col className="ml-auto" md="2">
                <MUIButton
                  color="preAuto"
                  onClick={onOpenAddTask}
                  variant="contained"
                >
                  Create New
                </MUIButton>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                {leadsources.length > 0 ? (
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="center">
                              Sort Order
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Created On
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leadsources.map((p, k) => (
                            <StyledTableRow key={k}>
                              <StyledTableCell>{p.name}</StyledTableCell>
                              <StyledTableCell align="center">
                                {p.sort_order}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {moment(p.start_date).format("YYYY-MM-DD")}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Link to={`/admin/lead-stage-detail/${p.id}`}>
                                  <RemoveRedEyeOutlinedIcon
                                    size={40}
                                    color={"preAuto"}
                                  />
                                </Link>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
           rowsPerPageOptions={[12]}
           component="div"
           count={totalResults}
           rowsPerPage={12}
           page={page}
           onPageChange={handleChangePage}
           ActionsComponent={TablePaginationActions}
           sx={{
             ".MuiTablePagination-selectLabel": {
               marginTop: "auto",
             },
             ".MuiTablePagination-displayedRows": {
               marginTop: "auto",
             },
           }}
         /> */}
                  </Paper>
                ) : props.showDefault ? (
                  <Button color="warning" size="sm" onClick={GenerateDefault}>
                    Generate Default
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <AddNewLeadStage
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default Leadsource;

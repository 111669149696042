import React from "react";

import { Link } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { useParams } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useForm, Controller } from "react-hook-form";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";

import {
  SG_FETCH_CUSTOMER,
  UPDATE_CUSTOMER,
  LOAD_ALL_EMPLOYEES,
} from "constants/actions";

import "Inventory/style.css";
import CreditApplications from "CreditApplications/List";
import LeadList from "Leads/List";
import TaskList from "TaskLists/List";
import TradeInList from "TradeIns/List";

import TestDriveList from "CustomerAppointments/List";
import WebCommentsList from "WebComments/List";
import InventoryInterested from "Leads/InventoryInterested";

const CONTACT_CHOICES = [
  {value: 0, label: 'No Information'},
  {value: 1, label:'Contact me with offers'},
  {value: 2, label:'Contact me for specific announcements'},
  {value: 9, label:'No Contact Whatsoever'},
]

const Detail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const [customer, setCustomer] = React.useState([]);
  const [selEmployee, setSelEmployee] = React.useState([]);

  const [creditApplicationList, setCreditApplicationList] = React.useState(
    false
  );
  const [inventorylist, setInventorylist] = React.useState(false);
  const [leadlist, setLeadlist] = React.useState(false);
  const [taskst, setTaskst] = React.useState(false);
  const [showTradeinlist, setShowTradeinlist] = React.useState(false);

  const [showTestDriveList, setShowTestDriveList] = React.useState(false);
  const [showWebCommentsList, setShowWebCommentsList] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_CUSTOMER, payload: id });
      dispatch({ type: LOAD_ALL_EMPLOYEES });
    }
  }, [dispatch]);

  const { get_customers, get_employees, get_auth, get_errors } = useSelector(
    (state) => ({
      get_customers: state.customer[parseInt(id)],
      get_employees: state.employees.employees,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelEmployee(get_employees.filter(p => p.status === 1));
  }, [get_employees]);

  React.useEffect(() => {
    if (get_customers) {
      setCustomer(get_customers);
    }
  }, [get_customers]);
  // console.log(customer)

  React.useEffect(() => {
    // console.log(selStatus);
    if (id) {
      if (get_customers) {
        if (get_customers.employee) {
          let emp = get_employees.filter(em => em.id === get_customers.employee)[0]
          setValue("employee", emp);
        }
      }
    } 
  }, [get_customers, get_employees, id]);

  React.useEffect(() => {
    let emp = CONTACT_CHOICES.filter(em => em.value  === customer?.contact_status)[0]
    setValue("contact_status", emp);
  }, [customer, CONTACT_CHOICES]);

  const onSubmit = (data, e) => {
    dispatch({
      type: UPDATE_CUSTOMER,
      payload: {
        id: id,
        employee: data.employee.id,
        contact_status: data.contact_status.value,
      },
    });
  };
 
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Customer Details</CardTitle>
                  </Col>

                  <Col className="ml-auto " md="2">
                    {/* <Button 
                    color={callHistory? "success" : "default"}
                    outline
                    size="sm"
                    onClick={() => setCallHistory(!callHistory)}>
                    {callHistory?  "Hide": "Call History"}
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h4"></CardTitle>
                <Row>
                  <Col>
                    <label>First Name </label>
                    <p>{customer ? customer.first_name : ""}</p>
                  </Col>

                  <Col>
                    <label>Middle Name </label>
                    <p>{customer ? customer.middle_name : ""}</p>
                  </Col>

                  <Col>
                    <label>Last Name </label>
                    <p>{customer ? customer.last_name : ""}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Phone</label>
                    <p>{customer ? customer.phone : ""}</p>
                  </Col>

                  <Col>
                    <label>Email</label>
                    <p>{customer ? customer.email : ""}</p>
                  </Col>
                </Row>
                <Row>
                  
                  
                  <Col>
                    <label>Created</label>
                    <p>{moment(customer.created_at).format("YYYY-MM-DD")}</p>
                  </Col>

                  <Col>
                    <label>Last Updated</label>
                    <p>{moment(customer.update_at).format("YYYY-MM-DD")}</p>
                  </Col>
                </Row>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                  <Col md="8">
                      <label>Change Owner</label>
                      <Controller
                        as={<Select />}
                        name="employee"
                        options={selEmployee}
                        getOptionLabel={(option) =>
                          `${option.first_name} ${option.last_name} - ${option.role_text} (${option.status_text})`
                        }
                        getOptionValue={(option) => `${option.id}`}
                        className="text-dark"
                        control={control}
                        rules={{ required: "Please select Employee" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.employee && (
                          <span>{errors.employee.message}</span>
                        )}
                      </p>
                    </Col>
                   
                  </Row>
               
                  <Row>
                    <Col md="8">
                    <label>Change Contact Settings</label>
                      <Controller
                        as={<Select />}
                        name="contact_status"
                        options={CONTACT_CHOICES}
                        className="text-dark"
                        control={control}
                        // defaultValue={emp}
                        rules={{ required: "Please select Status" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.contact_status && (
                          <span>{errors.contact_status.message}</span>
                        )}
                      </p>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                      <Button
                        className="btn-fill mt-4"
                        color="primary"
                        type="submit"
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Credit Applications</CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={creditApplicationList ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() =>
                        setCreditApplicationList(!creditApplicationList)
                      }
                    >
                      {creditApplicationList ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {creditApplicationList ? (
                  <CreditApplications params={`customer=${id}`} />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Leads</CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={leadlist ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setLeadlist(!leadlist)}
                    >
                      {leadlist ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {leadlist ? <LeadList pageParams={`customer=${id}`} /> : ""}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Inventories</CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={inventorylist ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setInventorylist(!inventorylist)}
                    >
                      {inventorylist ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {inventorylist ? <InventoryInterested customer_id={id} /> : ""}
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Tasks for Customer</CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={taskst ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setTaskst(!taskst)}
                    >
                      {taskst ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {taskst ? <TaskList params={`customer=${id}`} /> : ""}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Trade Ins </CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={showTradeinlist ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setShowTradeinlist(!showTradeinlist)}
                    >
                      {showTradeinlist ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {showTradeinlist ? (
                  <TradeInList params={`customer=${id}`} />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Web Comments </CardTitle>
                  </Col>
                  
                  
                  <Col className="ml-auto" md="2">
                    <Button
                      color={showWebCommentsList ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setShowWebCommentsList(!showWebCommentsList)}
                    >
                      {showWebCommentsList ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {showWebCommentsList ? (
                  <WebCommentsList params={`customer=${id}`} />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Test Drives</CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={showTestDriveList ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setShowTestDriveList(!showTestDriveList)}
                    >
                      {showTestDriveList ? "Hide" : "Show More"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {showTestDriveList ? (
                  <TestDriveList params={`customer=${id}`} />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Detail;

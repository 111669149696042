import {
  GET_LEADSOURCES_PENDING,
  GET_LEADSOURCES,
  GET_DEFAULT_LEADSOURCES,
  LOAD_LEADSOURCES,
  FETCH_LEADSOURCES,
  DELETE_LEADSOURCE,
  ADD_LEADSOURCE,
  UPDATE_LEADSOURCES,
  CLEAR_LEADSOURCES,
  EDIT_LEADSOURCES,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  leadsources: [],
  loadingleadsources: false,
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEADSOURCES_PENDING:
      return {
        ...state,
        pending: true,
        loadingleadsources: true,
      };

    case GET_LEADSOURCES:
      return {
        ...state,
        leadsources: action.payload,
        loadingleadsources: false,
        pending: false,
      };

      case GET_DEFAULT_LEADSOURCES:
      return {
        ...state,
        default: action.payload,
        loadingleadsources: false,
        pending: false,
      };

    case FETCH_LEADSOURCES:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loadingleadsources: false,
      };

    case EDIT_LEADSOURCES:
      // console.log(action.payload)
      return {
        ...state,
        [action.payload.id]: action.payload,
        loadingleadsources: false,
      };

    case DELETE_LEADSOURCE:
      return {
        ...state,
        leadsources: state.leadsources.filter(
          (Lead) => Lead.id !== action.payload
        ),
      };

    case ADD_LEADSOURCE:
      return {
        ...state,
        leadsources: [...state.leadsources, action.payload],
        loadingleadsources: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        leadsources: [],
      };

    default:
      return state;
  }
}

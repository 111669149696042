import {
  GET_VIN_UPLOAD_PENDING,
  NEW_VIN_UPLOAD_CREATING,
  GET_VIN_UPLOAD,
  LOAD_VIN_UPLOAD,
  FETCH_VIN_UPLOAD_PENDING,
  FETCH_VIN_UPLOAD,
  EDIT_VIN_UPLOAD,
  ADD_VIN_UPLOAD,
  DELETE_VIN_UPLOAD,
  UPDATE_VIN_UPLOAD,
  SG_UPDATE_VIN_UPLOAD,
  GET_VALID_VIN_UPLOAD_LIST,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  vinuploads: [],
  updating: [],
  updated: [],
  validlist: [],
  created: [],
  loading: false,
  pending: false,
  return: false,
  creating: false,
  flash: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VIN_UPLOAD_PENDING:
      return {
        ...state,
        pending: true,
        loading: true,
        created: [],
      };
    case NEW_VIN_UPLOAD_CREATING:
      return {
        ...state,
        creating: true,
        created: [],

      };
    case GET_VIN_UPLOAD:
      return {
        ...state,
        vinuploads: action.payload,
        loading: false,
        pending: false,
      };

    case GET_VALID_VIN_UPLOAD_LIST:
      return {
        ...state,
        validlist: action.payload,
        loading: false,
        pending: false,
      };

    case FETCH_VIN_UPLOAD_PENDING:
      return {
        ...state,
        loading: true,
        pending: true,
        created: [],
      };

    case UPDATE_VIN_UPLOAD:
      return {
        ...state,
        updated: {
          ...state.updated,
          [action.payload.vinupload_id]: action.payload,
        },
        flash: "Updated",
        loading: false,
        pending: false,
      };

    case FETCH_VIN_UPLOAD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        created: [],
        loading: false,
        pending: false,
      };

    case EDIT_VIN_UPLOAD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loading: false,
        pending: false,
      };

    case ADD_VIN_UPLOAD:
      return {
        ...state,
        [action.payload.vin]: action.payload,
        created: action.payload,
        loading: false,
        pending: false,
        creating: false,
      };

    case DELETE_VIN_UPLOAD:
      return {
        ...state,
        vinuploads: state.vinuploads.filter(
          (File) => File.id !== action.payload
        ),
      };

    case CLEAR_ALL:
      return {
        ...state,
        fileuploads: [],
      };
    default:
      return state;
  }
}

import {
  GET_YEAR_MAKE_MODEL_TRIM,
  FETCH_YEAR_MAKE_MODEL_TRIM,
  SUBMIT_YEAR_MAKE_MODEL_TRIM,
  CLEAR_ALL,
} from "constants/actions";
const initialState = {
  yearmakemodels: [],
  selected: [],
  submited: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_YEAR_MAKE_MODEL_TRIM:
      return {
        ...state,
        yearmakemodels: action.payload,
      };
    case FETCH_YEAR_MAKE_MODEL_TRIM:
      return { ...state, selected: action.payload };

     case SUBMIT_YEAR_MAKE_MODEL_TRIM:
        return {
            ...state,
            submited: [...state.submited, action.payload]
        };
 
    case CLEAR_ALL:
      return {
        ...state,
        yearmakemodels: [],
        selected: [],
        submited: [],
      };
    default:
      return state;
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment-timezone";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm } from "react-hook-form";
// reactstrap components
import {
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { UPDATE_TASK_LISTS, SG_FETCH_TASK_LISTS } from "constants/actions";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button as MuiButton,
  Slide,
} from "@mui/material";
import IntlMessages from "crema/helpers/src/IntlMessages";
import AppTooltip from "crema/AppTooltip";
import StatusToggleButton from "./StatusToggleButton";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import clsx from "clsx";

// import InteractionHistory from "Leads/InteractionHistory";
// import TaskList from "TaskLists/List";
// import CarsInterested from "Leads/CarsInterested";

const Detail = () => {
  const { id, slug } = useParams();
  const dispatch = useDispatch();
  const [taskList, setTaskList] = React.useState([]);
  const [selectedTaskStatus, setSelectedTaskStatus] = React.useState("");
  const [selectedTaskDescription, setSelectedTaskDescription] =
    React.useState("");
  const [rSelected, setRSelected] = React.useState(1);
  const { messages } = useIntl();

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const containerRef = React.useRef(null);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_TASK_LISTS, payload: id });
    }
  }, [dispatch]);

  const { get_task_list, get_auth, get_errors } = useSelector(
    (state) => ({
      get_task_list: state.tasklist[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_task_list) {
      setTaskList(get_task_list);
      setRSelected(get_task_list.status);
    }
  }, [get_task_list, id]);

  const closeTask = (e) => {
    setRSelected(e);
    dispatch({
      type: UPDATE_TASK_LISTS,
      payload: {
        id: id,
        status: e,
      },
    });
  };

  const onSubmit = (data, e) => {
    dispatch({
      type: UPDATE_TASK_LISTS,
      payload: {
        id: id,
        description: selectedTaskDescription,
      },
    });

    // e.target.reset();
  };

  useEffect(() => {
    setSelectedTaskStatus(taskList.status_text);
    setSelectedTaskDescription(taskList.description);
  }, [taskList]);

  // console.log(taskList)
  // return (
  //   <div className="content">
  //     <Row>
  //       <Col className="ml-auto mr-auto" md="10">
  //         <Card>
  //           <CardHeader>
  //             <Row>
  //               <Col md="6">
  //                 <CardTitle tag="h4">Task Details</CardTitle>
  //               </Col>

  //               <Col className="ml-auto " md="3">
  //               {taskList.customer ? (
  //                 <Button
  //                   color="default"
  //                   to={`/admin/customer-detail/${taskList.customer}`}
  //                   tag={Link}
  //                   outline
  //                   size="sm"
  //                 >
  //                   Customer
  //                 </Button>
  //                 ) : (
  //                   ""
  //                 )}
  //               </Col>
  //               {taskList.lead ? (
  //                 <Col md="2">
  //                   <Button
  //                     color="default"
  //                     to={`/admin/lead-detail/${taskList.lead}`}
  //                     tag={Link}
  //                     outline
  //                     size="sm"
  //                   >
  //                     My Tasks
  //                   </Button>
  //                 </Col>
  //               ) : (
  //                 ""
  //               )}
  //             </Row>
  //           </CardHeader>
  //           <CardBody>
  //             <Row>
  //               <Col >
  //                 <FormGroup>
  //                   <label>Task Name </label>
  //                   <p>{taskList ? taskList.name : ""}</p>
  //                 </FormGroup>

  //                 <Row>
  //                   <Col>
  //                     <label>Schedule to Close On</label>
  //                     <FormGroup>
  //                       <p>
  //                         {moment(taskList.scheduled_at).format(
  //                           "YYYY-MM-DD hh:mm A"
  //                         )}
  //                       </p>
  //                     </FormGroup>
  //                   </Col>

  //                   <Col>
  //                     <div className="d-flex justify-content-end">
  //                           <div>
  //                             <label>
  //                               Status: {rSelected === 2 ? "Closed" : "Open"}
  //                             </label>
  //                             <br />
  //                             <ButtonGroup>
  //                             {rSelected === 2 ?  <Button
  //                                 color="danger"
  //                                 size="sm"
  //                                 onClick={() => closeTask(1)}

  //                               >
  //                               Click to Open
  //                               </Button> : '' }
  //                               {rSelected === 1 ?  <Button
  //                                 color="success"
  //                                 size="sm"
  //                                 onClick={() => closeTask(2)}

  //                               >
  //                                Click to Close
  //                               </Button> : '' }
  //                             </ButtonGroup>
  //                           </div>
  //                         </div>
  //                   </Col>

  //                 </Row>
  //               </Col>

  //             </Row>
  //             <Row>

  //             </Row>
  //           </CardBody>
  //         </Card>
  //       </Col>
  //     </Row>
  //     <Row>
  //     <Col className="ml-auto mr-auto" md="10">
  //       <Card>
  //         <CardBody>
  //           <Form onSubmit={handleSubmit(onSubmit)}>
  //             <FormGroup>
  //                       <label>Description</label>
  //                       <Input
  //                         type="textarea"
  //                         defaultValue={taskList ? taskList.description : ""}
  //                         name="description"
  //                         id="description"
  //                         innerRef={register}
  //                       />
  //                     </FormGroup>
  //                     <div className="d-flex justify-content-end">
  //                       <Button
  //                         className="btn-fill"
  //                         color="primary"
  //                         type="submit"
  //                       >
  //                         Update Task
  //                       </Button>
  //                     </div>
  //                   </Form>
  //         </CardBody>

  //       </Card>
  // </Col>
  //     </Row>
  //   </div>
  // );

  const navigate = useHistory();

  const [show, setShow] = React.useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  const statusList = [
    {
      id: "Closed",
      name: "Closed",
      type: 1,
    },
    {
      id: "Open",
      name: "Open",
      type: 2,
    },
  ];

  return (
    <>
      <Slide direction="left" in={show}>
        <Box component={"div"} >
          {/* <Row
            style={{
              marginTop: 100,
              width: "100%",
              height: "100%",
              position: "relative",
              flexShrink: 0,
            }}
          > */}
          <Col
            className="ml-auto mr-auto"
            md="12"
            container={containerRef.current}
            // sx={{
            //   height: "100%",
            // }}
          >
            <Card
              ref={containerRef}
              style={{
                height: "80vh",
              }}
            >
              <CardHeader>
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  component="span"
                  mr={{ xs: 2, sm: 4 }}
                  onClick={() => {
                    setShow(false);
                    navigate.goBack();
                  }}
                >
                  {/* <AppTooltip title={<IntlMessages id="common.back" />}> */}
                  <ArrowBackIcon
                    sx={{
                      color: "text.secondary",
                    }}
                  />
                  {/* </AppTooltip> */}
                </Box>
                <StatusToggleButton
                  selectedTask={taskList}
                  closeTask={closeTask}
                />
              </CardHeader>
              <CardBody
                style={{
                  padding: "16px 20px",
                  margin: 0,
                }}
              >
                <Divider
                  sx={{
                    mb: "16px",
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: 3.5,
                      fontSize: 16,
                      fontWeight: 500,
                      mb: { xs: 3, sm: 0 },
                    }}
                  >
                    {taskList ? taskList.name : ""}
                  </Typography>
                </Box>

                <Typography
                  component="h2"
                  variant="h2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: 3.5,
                    marginTop: 2,
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "1.5",
                    mb: { xs: 4, sm: 0, lg: 2 },
                    color: "#6b7280",
                  }}
                >
                  {taskList ? taskList.description : ""}
                </Typography>
                <Divider
                  sx={{
                    my: "16px",
                  }}
                />
                <Box
                  mr={5}
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <FormControl style={{ width: "150px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedTaskStatus}
                      label="Status"
                      sx={{
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "1.4375em",
                        color: "#111827",
                        // position: "relative",
                        boxSizing: "border-box",
                        transition:
                          "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        "& div": {
                          // Add your custom styles for the input here
                          borderRadius: "8px",
                          padding: "10px 32px 10px 14px",
                        },
                        // padding: "10px 32px 10px 14px",
                      }}
                      onChange={(e) => {
                        if (e.target.value === "Closed") {
                          closeTask(2);
                        } else {
                          closeTask(1);
                        }
                        setSelectedTaskStatus(e.target.value);
                      }}
                    >
                      {statusList.map((status) => {
                        return (
                          <MenuItem key={status.type} value={status.name}>
                            {status.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: 3.5,

                      marginLeft: 2,
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "1.5",
                      // mb: { xs: 4, sm: 0, lg: 2 },
                      color: "#111827",
                    }}
                  >
                    Schedule to Close On{" "}
                    <span
                      style={{
                        color: "#6b7280",
                      }}
                    >
                      {moment(taskList.scheduled_at).format(
                        "YYYY-MM-DD hh:mm A"
                      )}
                    </span>
                  </Typography>

                  {/* <TaskStatus selectedTask={selectedTask} /> */}
                </Box>
                <Divider
                  sx={{
                    my: "16px",
                  }}
                />
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "auto",
                    marginTop: 2,
                  }}
                >
                  <TextField
                    multiline
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        padding: "10px 15px",
                        borderRadius: "8px",
                      },
                      // "& .div": {
                      //   // Add your custom styles for the input here
                      //   borderRadius: "8px",
                      // },
                      // borderRadius: "8px",
                    }}
                    minRows={2}
                    maxRows={4}
                    variant="outlined"
                    placeholder={messages["Update Description..."]}
                    defaultValue={selectedTaskDescription}
                    onChange={(event) =>
                      setSelectedTaskDescription(event.target.value)
                    }
                  />
                  <MuiButton
                    sx={{
                      position: "relative",
                      width: 41,
                      height: 41,
                      minWidth: 41,
                      padding: 2,
                      marginLeft: 2,
                      // "&:focus": {
                      //   outline: "none",
                      // },
                      // "& svg": {
                      //   marginLeft: 0.75,
                      // },
                    }}
                    variant="contained"
                    color="preAuto"
                    onClick={onSubmit}
                  >
                    <SendOutlinedIcon />
                  </MuiButton>
                </Box>
              </CardBody>
            </Card>
          </Col>
          {/* </Row> */}
        </Box>
      </Slide>
    </>
  );
};

export default Detail;

import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

// import task_data from "Data/task_data.json"
import List from "TaskLists/List";
import Search from "./Search";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Zoom } from "@mui/material";
import AddNewTask from "./AddNewTask";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import Detail from "./Detail";

export default (props) => {
  const { id } = useParams();

  const [pageParams, setPageParams] = React.useState(props.params);

  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card
              className={clsx({
                show: !id,
              })}
              style={{
                height: "100%",
              }}
            >
              <CardBody>
                <Row>
                  <Col>
                    <CardHeader style={{ padding: 0 }}>
                      <CardTitle tag="h4" style={{ padding: 0, margin: 0 }}>
                        Tasks
                      </CardTitle>
                      {/* <h5 className="card-category">My Tasks</h5> */}
                    </CardHeader>
                  </Col>
                  <Col className="ml-auto" md={4} lg={4} xl={2} sm={6}>
                    <Box
                      sx={{
                        p: 0,
                        m: 0,
                      }}
                    >
                      {/* <Zoom
                        in
                        style={{ transitionDelay: "300ms" }}
                        sx={{
                          padding: "8px 28px",
                          m: 0,
                          borderRadius: 30,
                        }}
                      > */}
                      <Button
                        variant="outlined"
                        color="preAuto"
                        sx={{
                          borderRadius: 30,
                          "& .MuiSvgIcon-root": {
                            fontSize: 26,
                          },
                          fontSize: 14,
                          // "&:focus": {
                          //   outline: "none",
                          // },
                        }}
                        startIcon={<AddIcon />}
                        onClick={onOpenAddTask}
                      >
                        CREATE NEW
                      </Button>
                      {/* </Zoom> */}
                    </Box>
                    {/* <Button
                      className="btn btn-gradient-success btn-fw"
                      color="default"
                      size="sm"
                      to="/admin/task-list-create"
                      tag={Link}
                    >
                      Create New
                    </Button> */}
                  </Col>
                </Row>

                <Search setPageParams={setPageParams} />
                <Box
                  className={clsx({
                    show11: !id,
                  })}
                  sx={{
                    opacity: 0,
                    visibility: "hidden",
                    height: "0vh",
                    "&.show11": {
                      opacity: 1,
                      visibility: "visible",
                      height: ""
                    },
                  }}
                >
                  <List />
                </Box>
              </CardBody>
            </Card>
            <Box
              sx={{
                transition: "all 1s ease",
                transform: "translateX(100%)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                opacity: 0,
                visibility: "hidden",
                // backdropFilter: "blur(3px)",
                // backgroundColor: "rgba(0,0,0,0.3)",
                "&.show": {
                  transform: "translateX(0)",
                  opacity: 1,
                  visibility: "visible",
                },
              }}
              className={clsx({
                show: id,
              })}
            >
              <Detail />
            </Box>
          </Col>
        </Row>
      </div>

      <AddNewTask
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

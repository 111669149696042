import React from "react";
import {
    Tooltip,
} from "reactstrap";


const ToolTipComp = (props) => {
    const { product } = props
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <Tooltip placement="right" isOpen={tooltipOpen} target={`tool-tip-for-${product.id}`} toggle={toggle}>
            View on website
        </Tooltip>
    )

}
export default ToolTipComp;
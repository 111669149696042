import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

import { UPLOAD_REQUEST, SG_FETCH_RELATED_DEALER } from "constants/actions";
import Select from "react-select";
import { Button as MUIButton } from "@mui/material";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Input,
  FormGroup,
  Progress,
  Button,
  Row,
  Col,
} from "reactstrap";

// you can use React.forwardRef to pass the ref too
// const Select = React.forwardRef(({ label, register }, ref) => (
//   <>
//     <label>{label}</label>
//     <select name={label} ref={ref}>
//       <option value="20">20</option>
//       <option value="30">30</option>
//     </select>
//   </>
// ));

const UploadFile = () => {
  const dispatch = useDispatch();
  const { control, register, handleSubmit, errors, reset } = useForm();

  const [auth, setAuth] = React.useState([]);
  const [locationid, setLocationid] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [fileErrors, setFileErrors] = React.useState([]);

  // const notify2 = () => toast.success(`Uploaded File successfully`);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log("Load Inventory", activePage, pageparams)
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);

  const { related_dealer, get_errors, get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      related_dealer: state.dealers.related,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (related_dealer) {
      // console.log(related_dealer)
      setItems(related_dealer);
      setLocationid(related_dealer[0].id);
    }
    if (get_errors) {
      setFileErrors(get_errors);
    }
    if (get_auth) {
      setAuth(get_auth);
    }
  }, [related_dealer, get_errors, get_auth]);

  function onSelectChange(e) {
    // e.preventDefault();
    // console.log(e.target.value)
    setLocationid(e.target.value);
  }
  const onSubmit = (data, e) => {
    // console.log(data.location)
    dispatch({
      type: UPLOAD_REQUEST,
      payload: {
        file_type: 1,
        name: "Vin decoder File Upload",
        company: auth.dealer_id,
        location_id: data.location.id,
        data_file: data.uploadfile[0],
      },
    });
    e.target.reset(); // reset after form submit
    // notify2()
  };

  // console.log(auth.dealer_id)

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Upload VIN List</CardTitle>
                {/* <form onSubmit={onFormSubmit}> */}
                <p className="text-danger small">
                  {fileErrors.length > 0
                    ? JSON.stringify(fileErrors.errors)
                    : ""}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <label>Select Location</label>
                    <Controller
                      as={Select}
                      options={items}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => `${option.id}`}
                      name="location"
                      className="text-dark"
                      control={control}
                      innerRef={register}
                      rules={{ required: "You must select a Location" }}
                    />
                    <p className="text-danger small">
                      {errors.location && (
                        <span>{errors.location.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  {/* <FormGroup>
                  <Label for="exampleSelect">Select Location</Label>
                  <Input
                    type="select"
                    name="location"
                    id="locationSelect"
                    onChange={(e) => onSelectChange(e)}
                 
                    innerRef={register({
                      required: "You must select a Location"
                    })}
                  >
                    {items.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                  <p className="text-danger small">
                    {errors.location && <span>{errors.location.message}</span>}
                  </p>
                </FormGroup>

                <br /> */}

                  <Input
                    type="file"
                    name="uploadfile"
                    innerRef={register({
                      required: "You must select a file",
                    })}
                  />

                  <p className="text-danger small">
                    {errors.uploadfile && (
                      <span>{errors.uploadfile.message}</span>
                    )}
                  </p>

                  <MUIButton
                    variant="contained"
                    color={"preAuto"}
                    type="submit"
                  >
                    Upload
                  </MUIButton>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UploadFile;

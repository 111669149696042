import { 
GET_LEADS_PENDING, GET_LEADS, LOAD_LEADS, FETCH_LEADS_PENDING, FETCH_LEADS, SG_FETCH_LEADS,  
ADD_LEAD, EDIT_LEADS, UPDATE_LEADS, DELETE_LEAD, CLEAR_LEADS, CLEAR_ALL } from "constants/actions";


const initialState = {
    leads: [],
    loadingleads: false,
    pending: false
};


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LEADS_PENDING:
            return {
                ...state,
                pending: true,
                loadingleads: true,
            }
        case GET_LEADS:
            return {
                ...state,
                leads: action.payload
            };
            case FETCH_LEADS:
                return { ...state, [action.payload.id]: action.payload, pending: true, loadingleads: false };
    
        case UPDATE_LEADS:
            return {
                ...state,
                leads: action.payload
            };

        case EDIT_LEADS:
                return { ...state, [action.payload.id]: action.payload, pending: true, loadingleads: false };

        case DELETE_LEAD:
            return {
                ...state,
                leads: state.leads.filter(lead => lead.id !== action.payload)
            };
        case ADD_LEAD:
            return {
                ...state,
                leads: [...state.leads, action.payload]
            };
        case CLEAR_LEADS:
            return {
                ...state,
                leads: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                leads: []
            };
        default:
            return state;
    }
}
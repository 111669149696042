import React from "react";

import Gallery from "react-photo-gallery";
import Photo from "./Photo";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { useParams } from "react-router";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_INVENTORYIMAGES,
  SG_EDIT_INVENTORYIMAGE,
} from "constants/actions";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import Placeholder from "assets/img/image_placeholder.jpg";
import ImageCard from "./ImageCard";

// import { photos } from "./photos";

const SortablePhoto = SortableElement((item, i) => {
  // console.log(item)
return <Photo {...item} />});
// const SortablePhoto = SortableElement((item, i) => <ImageCard {...item} />);
const SortableGallery = SortableContainer((p) => {
  // console.log(p);
  return (
    <Gallery
      photos={p.items}
      renderImage={(props) => {
        // console.log(props);
        return <SortablePhoto {...props} />;
      }}
    />
  );
});

const ImageGallery = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [carImages, setCarImages] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // console.log("sorting ...", oldIndex, newIndex);
    // console.log(carImages[oldIndex].id, carImages[oldIndex].sort, newIndex)
    // console.log(carImages[newIndex].id, carImages[newIndex].sort, oldIndex)
    // setItems(arrayMove(items, oldIndex, newIndex));
    if (oldIndex !== newIndex) {
      if (oldIndex < newIndex) {
        // console.log("moved down", oldIndex, newIndex);
        dispatch({
          type: SG_EDIT_INVENTORYIMAGE,
          payload: { id: carImages[oldIndex].id,
            sort_order: newIndex, 
            param: id },
        })



      }
      if (oldIndex > newIndex) {
        // console.log("moved up", oldIndex, newIndex);
        dispatch({
          type: SG_EDIT_INVENTORYIMAGE,
          payload: { id: carImages[oldIndex].id,
            sort_order: newIndex, 
            param: id },
        })
      }

    }

  };

  // console.log(carImages);


  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log("Load API")
    dispatch({ type: LOAD_INVENTORYIMAGES, payload: id });
  }, [dispatch, id]);

  const { get_inventory_images, get_errors, get_error_state } = useSelector(
    (state) => ({
      get_inventory_images: state.inventoryimages.inventoryimages,
      get_errors: state.errors,
      get_error_state: state.errorstate,
    }),
    shallowEqual
  );

  // console.log(id);
  
  React.useEffect(() => {
    if (get_inventory_images) {
      const CarPhotos = [];
      get_inventory_images.map((p) => {
        CarPhotos.push({
          id: p.id,
          sort: p.sort_order,
          src: p.regimage,
          height: p.height,
          width: p.width,
        });
      });
      setCarImages(CarPhotos.sort((a, b) => (a.sort > b.sort) ? 1 : -1));
    }
  }, [get_inventory_images]);

  return (
    <Container>
      <Row>
        {carImages ? (
          <SortableGallery
            items={carImages}
            onSortEnd={onSortEnd}
            axis={"xy"}
          />
        ) : (
          ""
        )}

        {/* {carImages.map((p, k) => (
                 <Col md="4" sm="12" key={k}>
                <ProductCard 
                        imageUrl={p.regimage}
                        id={p.id}
                        name={p.image_name}
                       
                    />
                
                </Col>
            )

            )} */}
      </Row>
    </Container>
  );
};

export default ImageGallery;

import React, { useReducer } from "react";
import moment from "moment-timezone";
// reactstrap components
import { Alert, Button, Table, Row, Col, Badge } from "reactstrap";

import {
  Paper,
  Table as MUITable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
  Box,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const AddDeduct = ({ blackbook }) => {
  const [bbadddeduct, setBbadddeduct] = React.useState([]);
  React.useEffect(() => {
    setBbadddeduct(blackbook);
  }, [blackbook]);

  // console.log(blackbook)

  return (
    <>
      {bbadddeduct
        ? bbadddeduct.map((p, k) => {
            return (
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <MUITable
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="center">avg</StyledTableCell>
                        <StyledTableCell align="center">xclean</StyledTableCell>
                        <StyledTableCell align="center">clean</StyledTableCell>
                        <StyledTableCell align="center">rough</StyledTableCell>
                        <StyledTableCell align="center">auto</StyledTableCell>
                        <StyledTableCell align="center">uoc</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key={k}>
                        <StyledTableCell align="left">
                          <h6>{p.name} </h6>
                        </StyledTableCell>

                        <StyledTableCell>{p.avg}</StyledTableCell>
                        <StyledTableCell>{p.xclean}</StyledTableCell>
                        <StyledTableCell>{p.clean}</StyledTableCell>
                        <StyledTableCell>{p.rough}</StyledTableCell>
                        <StyledTableCell>{p.auto}</StyledTableCell>
                        <StyledTableCell>{p.uoc}</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                        <StyledTableCell>Resid 12k</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>{p.resid12}</StyledTableCell>
                        <StyledTableCell>{p.resid24}</StyledTableCell>
                        <StyledTableCell>{p.resid30}</StyledTableCell>
                        <StyledTableCell>{p.resid36}</StyledTableCell>
                        <StyledTableCell>{p.resid42}</StyledTableCell>
                        <StyledTableCell>{p.resid48}</StyledTableCell>
                        <StyledTableCell>{p.resid60}</StyledTableCell>
                        <StyledTableCell>{p.resid72}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </MUITable>
                </TableContainer>
              </Paper>
            );
          })
        : ""}
    </>
  );
};
const Kilometers = ({
  blackbook,
  colXcleanClass,
  colCleanClass,
  colAvgClass,
  colRoughClass,
  kilometers,
  getCategory,
  showBar = false,
}) => {
  const [bbkm, setBbkm] = React.useState([]);
  React.useEffect(() => {
    setBbkm(blackbook);
  }, [blackbook]);

  // console.log(blackbook)

  return (
    <>
      {showBar && (
        <Alert color="primary">
          The vehicles mileage <Badge color="dark"> {kilometers} KMS</Badge> is{" "}
          <Badge color="secondary"> {getCategory}</Badge>
        </Alert>
      )}

      <Table bordered>
        <thead>
          <tr>
            <td className="table-head-content text-right">Xclean KM</td>
            <td className="table-head-content text-right">Clean KM</td>
            <td className="table-head-content text-right">Average KM</td>
            <td className="table-head-content text-right">Rough KM</td>
            <td className="table-head-content text-right">Cents Per KM</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`text-right ${colXcleanClass}`}>
              {bbkm ? bbkm.xclean_km_threshold : ""}
            </td>
            <td className={`text-right ${colCleanClass}`}>
              {bbkm ? bbkm.clean_km_threshold : ""}
            </td>
            <td className={`text-right ${colAvgClass}`}>
              {bbkm ? bbkm.avg_km_threshold : ""}
            </td>
            <td className={`text-right ${colRoughClass}`}>
              {bbkm ? bbkm.rough_km_threshold : ""}
            </td>
            <td className="text-right">
              {bbkm ? bbkm.cents_per_kilometer : ""}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export const BlackBookDetailedLog = ({ blackbook, category }) => {
  const [blackbookInfo, setBlackbookInfo] = React.useState(true);

  return (
    <>
      <div className="content">
        <div className="d-block d-flex justify-content-end"></div>

        <div className={blackbookInfo ? "" : "d-none"}>
          {blackbook ? (
            <AddDeduct_List
              blackbook={blackbook.add_deduct_list}
              category={category}
            />
          ) : (
            ""
          )}

          {blackbook ? (
            <>
              <h6>Customer Value</h6>
              <Table>
                <tbody>
                  <tr>
                    <td className="table-head-content text-right">High</td>
                    <td className="text-right">
                      {blackbook.canadian_tradein_high
                        ? blackbook.canadian_tradein_high
                        : "Not found"}
                    </td>
                    <td className="table-head-content text-right">Low</td>
                    <td className="text-right">
                      {blackbook.canadian_tradein_low
                        ? blackbook.canadian_tradein_low
                        : "Not found"}
                    </td>
                    <td className="table-head-content text-right">Best</td>
                    <td className="text-right">
                      {blackbook.canadian_tradein_value
                        ? blackbook.canadian_tradein_value
                        : "Not found"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            ""
          )}

          {blackbook ? (
            <Table striped>
              <tbody>
                <tr>
                  <td className="table-head-content">Risk Score</td>
                  <td className="text-right">{blackbook.risk_score}</td>
                  <td className="table-head-content">MSRP</td>
                  <td className="text-right">{blackbook.msrp}</td>
                  <td className="table-head-content">Retail Equipped</td>
                  <td className="text-right">{blackbook.retail_equipped}</td>
                  <td className="table-head-content">Price Includes</td>
                  <td className="text-right">{blackbook.price_includes}</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            ""
          )}

          {blackbook ? (
            <Kilometers blackbook={blackbook.kilometer_adjustments} />
          ) : (
            ""
          )}
          {blackbook ? (
            <Table striped bordered>
              <thead>
                <tr>
                  <th className="table-head-content"></th>
                  <th className="table-head-content">Base</th>
                  <th className="table-head-content">Mileage</th>
                  <th className="table-head-content">Add/Deduct</th>
                  <th className="table-head-content">Regional</th>
                  <th className="table-head-content">Adjusted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-head-content">X-clean</td>
                  <td className="text-right">{blackbook.base_whole_xclean}</td>
                  <td className="text-right">
                    {blackbook.mileage_whole_xclean}
                  </td>
                  <td className="text-right">
                    {blackbook.add_deduct_whole_xclean}
                  </td>
                  <td className="text-right">
                    {blackbook.regional_whole_xclean}
                  </td>
                  <td className="text-right">
                    {blackbook.adjusted_whole_xclean}
                  </td>
                </tr>
                <tr>
                  <td className="table-head-content">Clean</td>
                  <td className="text-right">{blackbook.base_whole_clean}</td>
                  <td className="text-right">
                    {blackbook.mileage_whole_clean}
                  </td>
                  <td className="text-right">
                    {blackbook.add_deduct_whole_clean}
                  </td>
                  <td className="text-right">
                    {blackbook.regional_whole_clean}
                  </td>
                  <td className="text-right">
                    {blackbook.adjusted_whole_clean}
                  </td>
                </tr>
                <tr>
                  <td className="table-head-content">Average</td>
                  <td className="text-right">{blackbook.base_whole_avg}</td>
                  <td className="text-right">{blackbook.mileage_whole_avg}</td>
                  <td className="text-right">
                    {blackbook.add_deduct_whole_avg}
                  </td>
                  <td className="text-right">{blackbook.regional_whole_avg}</td>
                  <td className="text-right">{blackbook.adjusted_whole_avg}</td>
                </tr>
                <tr>
                  <td className="table-head-content">Rough</td>
                  <td className="text-right">{blackbook.base_whole_rough}</td>
                  <td className="text-right">
                    {blackbook.mileage_whole_rough}
                  </td>
                  <td className="text-right">
                    {blackbook.add_deduct_whole_rough}
                  </td>
                  <td className="text-right">
                    {blackbook.regional_whole_rough}
                  </td>
                  <td className="text-right">
                    {blackbook.adjusted_whole_rough}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            ""
          )}
          {blackbook ? <AddDeduct blackbook={blackbook.add_deduct_list} /> : ""}

          <h6>Specifications</h6>
          {blackbook ? (
            <Table striped bordered>
              <tbody>
                <tr>
                  <td className="table-head-content">Wheel base</td>
                  <td className="text-right">{blackbook.wheel_base}</td>
                  <td className="table-head-content">Tire size</td>
                  <td className="text-right">{blackbook.tire_size}</td>
                  <td className="table-head-content">gvw</td>
                  <td className="text-right">{blackbook.gvw}</td>
                  <td className="table-head-content">ext doors</td>
                  <td className="text-right">{blackbook.ext_doors}</td>
                </tr>
                <tr>
                  <td className="table-head-content">payload cap</td>
                  <td className="text-right">{blackbook.payload_cap}</td>
                  <td className="table-head-content">airbags</td>
                  <td className="text-right">{blackbook.airbags}</td>
                  <td className="table-head-content">engine displacement</td>
                  <td className="text-right">
                    {blackbook.engine_displacement}
                  </td>
                  <td className="table-head-content">num gears</td>
                  <td className="text-right">{blackbook.num_gears}</td>
                </tr>
                <tr>
                  <td className="table-head-content">seat cap</td>
                  <td className="text-right">{blackbook.seat_cap}</td>
                  <td className="table-head-content">fuel type</td>
                  <td className="text-right">{blackbook.fuel_type}</td>
                  <td className="table-head-content">fuel cap</td>
                  <td className="text-right">{blackbook.fuel_cap}</td>
                  <td className="table-head-content">fuel delivery</td>
                  <td className="text-right">{blackbook.fuel_delivery}</td>
                </tr>
                <tr>
                  <td className="table-head-content">hwy mpg</td>
                  <td className="text-right">{blackbook.hwy_mpg}</td>
                  <td className="table-head-content">city mpg</td>
                  <td className="text-right">{blackbook.city_mpg}</td>
                  <td className="table-head-content">engine description</td>
                  <td className="text-right">{blackbook.engine_description}</td>
                  <td className="table-head-content">cylinders</td>
                  <td className="text-right">{blackbook.cylinders}</td>
                </tr>
                <tr>
                  <td className="table-head-content">base hp</td>
                  <td className="text-right">{blackbook.base_hp}</td>
                  <td className="table-head-content">torque</td>
                  <td className="text-right">{blackbook.torque}</td>
                  <td className="table-head-content">transmission</td>
                  <td className="text-right">{blackbook.transmission}</td>
                  <td className="table-head-content">drivetrain</td>
                  <td className="text-right">{blackbook.drivetrain}</td>
                </tr>
                <tr>
                  <td className="table-head-content text-center" colSpan="8">
                    Warranty
                  </td>
                </tr>
                <tr>
                  <td className="table-head-content">anti corrosion</td>
                  <td className="text-right">
                    {blackbook.anti_corrosion_warranty}
                  </td>
                  <td className="table-head-content">basic</td>
                  <td className="text-right">{blackbook.basic_warranty}</td>
                  <td className="table-head-content">powertrain</td>
                  <td className="text-right">
                    {blackbook.powertrain_warranty}
                  </td>
                  <td className="table-head-content">road assist</td>
                  <td className="text-right">
                    {blackbook.road_assist_warranty}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
// import "./style.css"
// function HighLightClass(category) {
//   console.log(category)
//   switch (category) {
//     case 'xclean_km_threshold':
//       return "text-right bg-success"
//     case 'clean_km_threshold':
//         return "text-right bg-success"
//     case 'avg_km_threshold':
//         return "text-right bg-success"
//     case 'rough_km_threshold':
//         return "text-right bg-success"
//     default:
//     return "text-right"
//   }
// }

export const AddDeduct_List = ({ blackbook, category }) => {
  const [bbadddeduct, setBbadddeduct] = React.useState([]);
  React.useEffect(() => {
    setBbadddeduct(blackbook);
  }, [blackbook]);

  // console.log(blackbook)
  // 'xclean_km_threshold'
  // , 'clean_km_threshold',
  //  'avg_km_threshold',
  //  'rough_km_threshold',

  return (
    <>
      <Table striped bordered>
        <tbody>
          <tr>
            <th></th>
            <th className="table-head-content text-right">xclean</th>
            <th className="table-head-content text-right">clean</th>
            <th className="table-head-content text-right">avg</th>
            <th className="table-head-content text-right">rough</th>
          </tr>
          {bbadddeduct
            ? bbadddeduct.map((p, k) => {
                return (
                  <tr key={k}>
                    <td className="table-head-content">
                      <h6>{p.name} </h6>
                    </td>
                    <td className="text-right"> {p.xclean} </td>
                    <td className="text-right"> {p.clean}</td>
                    <td className="text-right"> {p.avg} </td>
                    <td className="text-right"> {p.rough} </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  );
};

function BlacbookCategory(blackBookData, tradein) {
  let _blacbookCategory = "Not Selected or no Mileage";
  if (blackBookData?.kilometer_adjustments) {
    if (tradein?.kilometers) {
      // remove cents
      let bBookList = blackBookData.kilometer_adjustments;
      delete bBookList.cents_per_kilometer;

      const XCLEAN = bBookList["xclean_km_threshold"];
      const CLEAN = bBookList["clean_km_threshold"];
      const AVERAGE = bBookList["avg_km_threshold"];
      const ROUGH = bBookList["rough_km_threshold"];

      // console.log(bBookList)
      // console.log(XCLEAN)
      // console.log(Number(tradein.kilometers))
      // console.log(Number(tradein.kilometers)  <= XCLEAN)
      //       avg_km_threshold: 150000
      // clean_km_threshold: 110000
      // rough_km_threshold: 170000
      // xclean_km_threshold: 85000
      switch (true) {
        case Number(tradein.kilometers) <= XCLEAN:
          _blacbookCategory = "xclean_km_threshold";
          break;
        case Number(tradein.kilometers) <= CLEAN:
          _blacbookCategory = "clean_km_threshold";
          break;
        case Number(tradein.kilometers) <= AVERAGE:
          _blacbookCategory = "avg_km_threshold";
          break;
        case Number(tradein.kilometers) <= ROUGH:
          _blacbookCategory = "rough_km_threshold";
          break;
        default:
          _blacbookCategory = "above rough threshold";
          break;
      }

      // const sorted_object = Object.fromEntries(
      //   Object.entries(bBookList).sort(([, v1], [, v2]) => +v2 - +v1)
      // );

      // let selected = "";

      // for (const [key, value] of Object.entries(sorted_object)) {

      //   console.log(sorted_object)
      //   console.log('value', value)
      //   console.log('kilometers', tradein.kilometers)
      //   console.log(value <= tradein.kilometers)

      //   selected = key;
      //   if (value <= tradein.kilometers) {
      //     break;
      //   }
      // }
      // _blacbookCategory = selected;
      // console.log(selected)
    }

    // console.log(blackBookCategory)
  }
  // console.log(_blacbookCategory)
  return _blacbookCategory;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "addPart":
      return {
        ...state,
        xclean: state.xclean + action.payload.xclean,
        clean: state.clean + action.payload.clean,
        avg: state.avg + action.payload.avg,
        rough: state.rough + action.payload.rough,
        parts: [...state.parts, action.payload.parts],
      };
    case "removePart":
      return {
        ...state,
        xclean: state.xclean - action.payload.xclean,
        clean: state.clean - action.payload.clean,
        avg: state.avg - action.payload.avg,
        rough: state.rough - action.payload.rough,
        parts: state.parts.filter((pt) => pt !== action.payload.parts),
      };
    default:
      return state;
  }
};

export const BlackBookDetails = ({ blackbook, category, tradein }) => {
  const [state, dispatch] = useReducer(reducer, {
    xclean: 0,
    clean: 0,
    avg: 0,
    rough: 0,
    parts: [],
  });
  const { xclean, clean, avg, rough, parts } = state;

  const [blackbookInfo, setBlackbookInfo] = React.useState(false);

  const [bbadddeduct, setBbadddeduct] = React.useState([]);
  React.useEffect(() => {
    if (blackbook?.add_deduct_list) {
      setBbadddeduct(blackbook.add_deduct_list);
    }
  }, [blackbook]);

  const [colXcleanClass, setColXcleanClass] = React.useState("text-right");
  const [colCleanClass, setColCleanClass] = React.useState("text-right");
  const [colAvgClass, setColAvgClass] = React.useState("text-right");
  const [colRoughClass, setColRoughClass] = React.useState("text-right");

  const [totalXclean, setTotalXclean] = React.useState(0);
  const [totalClean, setTotalClean] = React.useState(0);
  const [totalAvg, setTotalAvg] = React.useState(0);
  const [totalRough, setTotalRough] = React.useState(0);

  // console.log(category)
  let getCategory = "";
  React.useEffect(() => {
    getCategory = category ? category : BlacbookCategory(blackbook, tradein);
    // let VehClass = HighLightClass(Category)
    setColXcleanClass("text-right");
    setColCleanClass("text-right");
    setColAvgClass("text-right");
    setColRoughClass("text-right");

    if (getCategory === "xclean_km_threshold") {
      setColXcleanClass("text-right bg-success");
    }
    if (getCategory === "clean_km_threshold") {
      setColCleanClass("text-right bg-success");
    }
    if (getCategory === "avg_km_threshold") {
      setColAvgClass("text-right bg-success");
    }
    if (getCategory === "rough_km_threshold") {
      setColRoughClass("text-right bg-success");
    }
    // , '',
    //  '',
    //  '',
  }, [blackbook, tradein]);

  // console.log(blackbook)
  // console.log(tradein)
  // console.log(getCategory)
  // console.log(BlacbookCategory(blackbook, tradein))

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div className="mobile-content">
              {bbadddeduct ? (
                bbadddeduct.map((p, k) => (
                  <div
                    key={k}
                    className={k % 2 ? "mb-3 shaded-background" : "mb-3"}
                  >
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <h6>{p.name}</h6>{" "}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className={`p-2 ${colXcleanClass}`}>xclean</div>
                      <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                        {p.xclean}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className={`p-2 ${colCleanClass}`}>clean</div>
                      <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                        {p.clean}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className={`p-2 ${colAvgClass}`}>Avg</div>
                      <div className={`p-2 flex-grow-1 ${colAvgClass}`}>
                        {p.avg}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className={`p-2 ${colRoughClass}`}>Rough</div>
                      <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                        {p.rough}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      {parts.includes(p.name) ? (
                        <Button
                          size="sm"
                          color="warning"
                          onClick={() =>
                            dispatch({
                              type: "removePart",
                              payload: {
                                xclean: p.xclean,
                                clean: p.clean,
                                avg: p.avg,
                                rough: p.rough,
                                parts: p.name,
                              },
                            })
                          }
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          color="success"
                          onClick={() =>
                            dispatch({
                              type: "addPart",
                              payload: {
                                xclean: p.xclean,
                                clean: p.clean,
                                avg: p.avg,
                                rough: p.rough,
                                parts: p.name,
                              },
                            })
                          }
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex">
                  <div className="p-2">No Add Deducts</div>
                </div>
              )}
              <div className="mb-3">
                <div className={`d-flex flex-row  ${colXcleanClass}`}>
                  <div className={`p-2`}>
                    <h6> X Clean</h6>{" "}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colXcleanClass}`}>Base</div>
                  <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                    {blackbook.base_whole_xclean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colXcleanClass}`}>Mileage</div>
                  <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                    {blackbook.mileage_whole_xclean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colXcleanClass}`}>Add/Deduct</div>
                  <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                    {xclean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colXcleanClass}`}>Regional</div>
                  <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                    {blackbook.regional_whole_xclean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colXcleanClass}`}>Original</div>
                  <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                    {blackbook.adjusted_whole_xclean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colXcleanClass}`}>Revised</div>
                  <div className={`p-2 flex-grow-1 ${colXcleanClass}`}>
                    {xclean
                      ? blackbook.base_whole_xclean +
                        blackbook.mileage_whole_xclean +
                        blackbook.regional_whole_xclean +
                        xclean
                      : 0}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className={`d-flex flex-row ${colCleanClass}`}>
                  <div className={`p-2`}>
                    <h6>Clean</h6>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colCleanClass}`}>Base</div>
                  <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                    {blackbook.base_whole_clean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colCleanClass}`}>Mileage</div>
                  <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                    {blackbook.mileage_whole_clean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colCleanClass}`}>Add/Deduct</div>
                  <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                    {clean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colCleanClass}`}>Regional</div>
                  <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                    {blackbook.regional_whole_clean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colCleanClass}`}>Original</div>
                  <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                    {blackbook.adjusted_whole_clean}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colCleanClass}`}>Revised</div>
                  <div className={`p-2 flex-grow-1 ${colCleanClass}`}>
                    {clean
                      ? blackbook.base_whole_clean +
                        blackbook.mileage_whole_clean +
                        blackbook.regional_whole_clean +
                        clean
                      : 0}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className={`d-flex flex-row ${colAvgClass}`}>
                  <div className={`p-2`}>
                    <h6>Avg</h6>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colAvgClass}`}>Base</div>
                  <div className={`p-2 flex-grow-1 ${colAvgClass}`}>
                    {blackbook.base_whole_avg}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colAvgClass}`}>Mileage</div>
                  <div className={`p-2 flex-grow-1 ${colAvgClass}`}>
                    {blackbook.mileage_whole_avg}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colAvgClass}`}>Add/Deduct</div>
                  <div className={`p-2 flex-grow-1 ${colAvgClass}`}>{avg}</div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colAvgClass}`}>Regional</div>
                  <div className={`p-2 flex-grow-1 ${colAvgClass}`}>
                    {blackbook.regional_whole_avg}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colAvgClass}`}>Original</div>
                  <div className={`p-2 flex-grow-1 ${colAvgClass}`}>
                    {blackbook.adjusted_whole_avg}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colAvgClass}`}>Revised</div>
                  <div className={`p-2 flex-grow-1 ${colAvgClass}`}>
                    {avg
                      ? blackbook.base_whole_avg +
                        blackbook.mileage_whole_avg +
                        blackbook.regional_whole_avg +
                        clean
                      : 0}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className={`d-flex flex-row ${colRoughClass}`}>
                  <div className={`p-2`}>
                    <h6>Rough</h6>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colRoughClass}`}>Base</div>
                  <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                    {blackbook.base_whole_rough}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colRoughClass}`}>Mileage</div>
                  <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                    {blackbook.mileage_whole_rough}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colRoughClass}`}>Add/Deduct</div>
                  <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                    {rough}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colRoughClass}`}>Regional</div>
                  <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                    {blackbook.regional_whole_rough}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colRoughClass}`}>Original</div>
                  <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                    {blackbook.adjusted_whole_rough}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className={`p-2 ${colRoughClass}`}>Revised</div>
                  <div className={`p-2 flex-grow-1 ${colRoughClass}`}>
                    {rough
                      ? blackbook.base_whole_rough +
                        blackbook.mileage_whole_rough +
                        blackbook.regional_whole_rough +
                        clean
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="desktop-content">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <MUITable sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    {/* <StyledTableCell align="center"></StyledTableCell> */}
                    <StyledTableCell align="left">xclean</StyledTableCell>
                    <StyledTableCell align="left">clean</StyledTableCell>
                    <StyledTableCell align="left">avg</StyledTableCell>
                    <StyledTableCell align="left">rough</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bbadddeduct
                    ? bbadddeduct.map((p, k) => (
                        <StyledTableRow key={k}>
                          <StyledTableCell align="left">
                            {parts.includes(p.name) ? (
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() =>
                                  dispatch({
                                    type: "removePart",
                                    payload: {
                                      xclean: p.xclean,
                                      clean: p.clean,
                                      avg: p.avg,
                                      rough: p.rough,
                                      parts: p.name,
                                    },
                                  })
                                }
                              >
                                Remove
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                color="success"
                                onClick={() =>
                                  dispatch({
                                    type: "addPart",
                                    payload: {
                                      xclean: p.xclean,
                                      clean: p.clean,
                                      avg: p.avg,
                                      rough: p.rough,
                                      parts: p.name,
                                    },
                                  })
                                }
                              >
                                Add
                              </Button>
                            )}

                            <h6>{p.name}</h6>
                          </StyledTableCell>

                          <StyledTableCell>{p.xclean}</StyledTableCell>
                          <StyledTableCell>{p.clean}</StyledTableCell>
                          <StyledTableCell>{p.avg}</StyledTableCell>
                          <StyledTableCell>{p.rough}</StyledTableCell>
                        </StyledTableRow>
                      ))
                    : null}
                </TableBody>
              </MUITable>
            </TableContainer>
          </Paper>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <MUITable sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>X-clean</StyledTableCell>
                    <StyledTableCell>Clean</StyledTableCell>
                    <StyledTableCell>Average</StyledTableCell>
                    <StyledTableCell>Rough</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <h6>Base</h6>
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.base_whole_xclean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.base_whole_clean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.base_whole_avg}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.base_whole_rough}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <h6>Mileage</h6>
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.mileage_whole_xclean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.mileage_whole_clean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.mileage_whole_avg}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.mileage_whole_rough}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <h6>Add/Deduct</h6>
                    </StyledTableCell>
                    <StyledTableCell>{xclean}</StyledTableCell>
                    <StyledTableCell>{clean}</StyledTableCell>
                    <StyledTableCell>{avg}</StyledTableCell>
                    <StyledTableCell>{rough}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <h6>Regional</h6>
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.regional_whole_xclean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.regional_whole_clean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.regional_whole_avg}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.regional_whole_rough}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <h6>Orignal</h6>
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.adjusted_whole_xclean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.adjusted_whole_clean}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.adjusted_whole_avg}
                    </StyledTableCell>
                    <StyledTableCell>
                      {blackbook.adjusted_whole_rough}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell>
                      <h6>Revised Total</h6>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>
                        {xclean
                          ? blackbook.base_whole_xclean +
                            blackbook.mileage_whole_xclean +
                            blackbook.regional_whole_xclean +
                            xclean
                          : 0}
                      </strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>
                        {clean
                          ? blackbook.base_whole_clean +
                            blackbook.mileage_whole_clean +
                            blackbook.regional_whole_clean +
                            clean
                          : 0}
                      </strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>
                        {avg
                          ? blackbook.base_whole_avg +
                            blackbook.mileage_whole_avg +
                            blackbook.regional_whole_avg +
                            avg
                          : 0}
                      </strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>
                        {rough
                          ? blackbook.base_whole_rough +
                            blackbook.mileage_whole_rough +
                            blackbook.regional_whole_rough +
                            rough
                          : 0}
                      </strong>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </MUITable>
            </TableContainer>
          </Paper>

          <Kilometers
            blackbook={blackbook.kilometer_adjustments}
            colXcleanClass={colXcleanClass}
            colCleanClass={colCleanClass}
            colAvgClass={colAvgClass}
            colRoughClass={colRoughClass}
            kilometers={tradein?.kilometers}
            getCategory={BlacbookCategory(blackbook, tradein)}
            showBar={true}
          />
        </div>
        {/* <div>
          {blackbook ? (
            <>
              <h6>Customer Retail Value (this is what customer would see across)</h6>
              <Table>
                <tbody>
                  <tr>
                    <td className="table-head-content text-right">High</td>
                    <td className="table-head-content text-right">Low</td>
                    <td className="table-head-content text-right">Best</td>
                  </tr>
                  <tr>
                    <td className="text-right">
                      {blackbook.canadian_tradein_high
                        ? blackbook.canadian_tradein_high
                        : "Not found"}
                    </td>
                    <td className="text-right">
                      {blackbook.canadian_tradein_low
                        ? blackbook.canadian_tradein_low
                        : "Not found"}
                    </td>
                    <td className="text-right">
                      {blackbook.canadian_tradein_value
                        ? blackbook.canadian_tradein_value
                        : "Not found"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            ""
          )}
        </div> */}
        <div className="d-block d-flex justify-content-between">
          <h6>View Log</h6>
          <MUIButton
            color={"preAuto"}
            variant="contained"
            size="sm"
            onClick={() => setBlackbookInfo(!blackbookInfo)}
          >
            {blackbookInfo ? "Hide Log" : "View Log"}
          </MUIButton>
        </div>
        <div className={blackbookInfo ? "" : "d-none"}>
          {/*        
                  {category === "rough_km_threshold" ? p.rough : ""}
                  {category === "clean_km_threshold" ? p.clean : ""}
                  {category === "xclean_km_threshold" ? p.xclean : ""} */}

          <BlackBookDetailedLog blackbook={blackbook} category={category} />
        </div>
      </div>
    </>
  );
};

export default BlackBookDetails;

import { GET_PRODUCT_CONTRACTS, 
    FETCH_PRODUCT_CONTRACT, 
    DELETE_PRODUCT_CONTRACT, 
    ADD_PRODUCT_CONTRACT, 
    EDIT_PRODUCT_CONTRACT, 
    CLEAR_PRODUCT_CONTRACT, 
    CLEAR_ALL } 
    from "constants/actions";
 
const initialState = {
    productcontracts: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_CONTRACTS:
            return {
                ...state,
                productcontracts: action.payload
            };
        case FETCH_PRODUCT_CONTRACT:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_PRODUCT_CONTRACT:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_PRODUCT_CONTRACT:
            return {
                ...state,
                productcontracts: state.productcontracts.filter(webheader => webheader.id !== action.payload)
            };
        case ADD_PRODUCT_CONTRACT:
            return {
                ...state,
                productcontracts: [...state.productcontracts, action.payload]
            };
        case CLEAR_PRODUCT_CONTRACT:
            return {
                ...state,
                productcontracts: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                productcontracts: []
            };
        default:
            return state;
    }
}

import React, { useState, useEffect } from "react";
// import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// import { addDays, subDays } from "date-fns";
import { toast } from "react-toastify";

import Select from "react-select";
import { Button as MUIButton } from "@mui/material";

// import { RHFInput } from 'react-hook-form-input';
import {
  UPDATE_LEADSOURCES,
  SG_ADD_LEADSOURCE,
  SG_FETCH_LEADSOURCES,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  // CardHeader,
  CardTitle,
  // CardFooter,
  Button,
  // Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

const StatusOptions = [
  { value: 1, label: "New" },
  { value: 2, label: "Active" },
  { value: 3, label: "Retired" },
];

const OriginOptions = [
  { value: 1, label: "Online" },
  { value: 2, label: "Offline" },
];

const CreateLeadSource = ({ setAddTaskOpen, setLabel, ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [leadSourceId, setLeadSourceId] = useState(false);
  const [leadSource, setLeadSource] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState((addDays, new Date()), 30);
  const [selStatus, setSelStatus] = useState([]);
  const [selOrigin, setSelOrigin] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pageerrors, setPageErrors] = useState([]);

  const [toDetail, setToDetail] = useState(false);
  const [toHome, setToHome] = useState(false);
  const [uniqueUID, setUniqueUID] = useState(uuidv4());

  // console.log(id);

  useEffect(() => {
    if (id) {
      setLeadSourceId(Number(id));
    }
  }, [id]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log(id);
    if (leadSourceId) {
      dispatch({ type: SG_FETCH_LEADSOURCES, payload: leadSourceId });
    }
  }, [dispatch, leadSourceId]);

  const { get_lead_source, get_auth, get_errors } = useSelector(
    (state) => ({
      get_lead_source: state.leadsources,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  // [parseInt(id)]
  // console.log(get_errors);
  // console.log(pageerrors)
  // console.log("🚀 ~ file: Create.js:104 ~ useEffect ~ get_errors:", get_errors?.errors?.data?.detail) //

  useEffect(() => {
    // console.log(_.isEmpty(loginError.errors))
    if (
      get_errors?.errors?.data?.detail &&
      get_errors?.errors?.data?.detail !== "Not found."
    ) {
      // console.log(get_errors?.errors)
      setVisible(true);
      setPageErrors(get_errors);
    }
  }, [get_errors]);

  useEffect(() => {
    if (leadSourceId) {
      if (get_lead_source[parseInt(leadSourceId)]) {
        setLeadSource(get_lead_source[parseInt(leadSourceId)]);

        const SelectedOption = OriginOptions.filter(
          (fl) => fl.value === get_lead_source[parseInt(leadSourceId)].origin
        )[0];
        setSelOrigin(SelectedOption);

        const SelectedStatus = StatusOptions.filter(
          (fl) => fl.value === get_lead_source[parseInt(leadSourceId)].status
        )[0];
        setSelStatus(SelectedStatus);

        // console.log(get_lead_source[parseInt(leadSourceId)]);
        // console.log(SelectedOption);

        // console.log(SelectedStatus);
      }
    }
  }, [get_lead_source, leadSourceId]);
  const notify2 = (props) => toast.success(`Sucessfully created!`);

  // console.log(selStatus);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    if (leadSourceId) {
      if (selStatus) {
        // console.log(selStatus);
        setValue("status", selStatus);
      }
    }
  }, [get_lead_source, leadSourceId, selStatus]);

  useEffect(() => {
    if (leadSourceId) {
      if (selOrigin) {
        // console.log(selOrigin);
        setValue("origin", selOrigin);
      }
    }
  }, [get_lead_source, leadSourceId, selOrigin]);

  useEffect(() => {
    // console.log(selStatus);
    if (leadSourceId) {
      if (get_lead_source[parseInt(leadSourceId)]) {
        setValue(
          "start_date",
          moment(get_lead_source[parseInt(leadSourceId)].start_date)
        );
      }
    }
  }, [get_lead_source, leadSourceId]);
  useEffect(() => {
    // console.log(selStatus);
    if (leadSourceId) {
      if (get_lead_source[parseInt(leadSourceId)]) {
        setValue(
          "end_date",
          moment(get_lead_source[parseInt(leadSourceId)].end_date)
        );
      }
    }
  }, [get_lead_source, leadSourceId]);

  useEffect(() => {
    register({ name: "origin" }, { required: "Please select a Origin Source" });
    register(
      { name: "status" },
      { required: "Please select the Status of Lead Source" }
    );
    //
    register(
      { name: "start_date" },
      { required: "Please select a Start Date" }
    );
    register({ name: "end_date" }, { required: "Please select an End Date" });
  }, [register]);

  const onSubmit = (data) => {
    if ([parseInt(leadSourceId)] > 0) {
      dispatch({
        type: UPDATE_LEADSOURCES,
        payload: {
          id: leadSourceId,
          cost: data.cost,
          description: data.description,
          name: data.name,
          revenue: data.revenue,
          //   source_id: data.source_id,
          end_date: moment(data.end_date),
          start_date: moment(data.start_date),
          origin: data.origin.value,
          status: data.status.value,
        },
      });
      if (_.isEmpty(get_errors?.errors)) {
        setTimeout(() => {
          setToDetail(true);
        }, 2000);
      }
    } else {
      dispatch({
        type: SG_ADD_LEADSOURCE,
        payload: {
          company: 2,
          cost: data.cost,
          description: data.description,
          name: data.name,
          revenue: data.revenue,
          source_id: data.source_id,
          end_date: moment(data.end_date),
          start_date: moment(data.start_date),
          origin: data.origin.value,
          status: data.status.value,
        },
      });
      if (_.isEmpty(get_errors?.errors)) {
        setTimeout(() => {
          setToHome(true);
        }, 2000);
      }
    }
    notify2();
    // <Redirect />
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  //   const ChangedStatus = (value) => {
  //     setSelStatus(value);
  //   };
  useEffect(() => {
    if (leadSource?.source_id) {
      setUniqueUID(leadSource?.source_id);
      setValue("source_id", leadSource?.source_id);
    } else {
      setValue("source_id", uniqueUID);
    }
  }, [leadSource]);

  return (
    <>
      <div className="content">
        {pageerrors.errors?.type &&
          pageerrors.errors?.type !== "ALL_ERRORS" && (
            <Alert
              color="danger"
              isOpen={visible}
              toggle={() => setVisible(!visible)}
            >
              {pageerrors.errors
                ? pageerrors.errors["detail"]
                : JSON.stringify(pageerrors.errors)}

              {/* {JSON.stringify(pageerrors)} */}
            </Alert>
          )}

        {toDetail ? (
          <Redirect to={`/admin/lead-campaign-detail/${leadSourceId}`} />
        ) : null}
        {toHome ? <Redirect to={`/admin/lead-campaigns`} /> : null}
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <p>
                Lead source is for external lead sources like Facebook Campaign,
                Google Campaign, Kijii, Trader. Anything that is automated and
                external. You can run multiple and many campaigns each
                identified by its own source code.
              </p>

              <CardTitle tag="h4">
                {leadSourceId ? "Edit " : "Create "}Lead Campaigns
              </CardTitle>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Name </label>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={leadSource ? leadSource.name : ""}
                    innerRef={register({
                      required: "Please enter Lead Source Name",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.name && <span>{errors.name.message}</span>}
                  </p>
                </FormGroup>
                <Row>
                  <Col>
                    <label>Status</label>
                    {/* <Select
                          name="status"
                          options={StatusOptions}
                          className="text-dark"
                          value={selStatus}
                          onChange={setSelStatus}
                        /> */}
                    <Controller
                      as={<Select />}
                      name="status"
                      options={StatusOptions}
                      className="text-dark"
                      control={control}
                      // onChange={([selected]) => {
                      //   // React Select return object instead of value for selection
                      //   return { value: selected };
                      // }}
                      rules={{ required: "Please enter Status" }}
                    />{" "}
                    <p className="text-danger small">
                      {errors.status && <span>{errors.status.message}</span>}
                    </p>
                  </Col>
                  <Col>
                    <label>Origin</label>
                    {/* <Select
                          name="origin"
                          options={OriginOptions}
                          className="text-dark"
                          value={selOrigin}
                          onChange={setSelOrigin}
                          // isClearable={true}
                        /> */}
                    <Controller
                      as={<Select options={OriginOptions} />}
                      name="origin"
                      control={control}
                    />{" "}
                    <p className="text-danger small">
                      {errors.origin && <span>{errors.origin.message}</span>}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Source ID or Code for Online Leads </label>
                      <Input
                        type="text"
                        disabled={leadSourceId ? true : false}
                        name="source_id"
                        defaultValue={uniqueUID}
                        innerRef={register({
                          required: "Please enter source id or email id",
                          maxLength: {
                            value: 50,
                            message: "Source ID cannot exceed 50 char",
                          },
                        })}
                      />
                      <p className="text-danger small">
                        {errors.source_id && (
                          <span>{errors.source_id.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      To use the url in Google or Facebook ads use the source
                      code like below
                    </p>
                    <p>?campaign-code={uniqueUID}</p>
                    <p>
                      https://example.com/credit-application?campaign-code=
                      {uniqueUID}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Start Date</label>
                    <FormGroup>
                      {/* <ReactDatetime
                              name="start_date"
                              dateFormat="YYYY-MM-DD"
                              value={startDate}
                            //   defaultValue={startDate}
                              inputProps={{
                                className: "form-control",
                                placeholder: "Date Picker Here",
                              }}
                              onChange={setStartDate}
                              timeFormat={false}
                            /> */}

                      <Controller
                        as={ReactDatetime}
                        control={control}
                        name="start_date"
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect={true}
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                        }}
                        timeFormat={false}
                      />
                      <p className="text-danger small">
                        {errors.start_date && (
                          <span>{errors.start_date.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <label>End Date</label>
                    <FormGroup>
                      {/* <ReactDatetime
                     name="end_date"
                    dateFormat="YYYY-MM-DD"
                    value={endDate}
                
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here"
                      }}
                      onChange={setEndDate} 
                      timeFormat={false}
                    /> */}

                      <Controller
                        as={ReactDatetime}
                        control={control}
                        name="end_date"
                        closeOnSelect={true}
                        dateFormat="YYYY-MM-DD"
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                        }}
                        timeFormat={false}
                      />
                      <p className="text-danger small">
                        {errors.end_date && (
                          <span>{errors.end_date.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Cost (Assumption)</label>
                      <Input
                        type="text"
                        name="cost"
                        defaultValue={leadSource ? leadSource.cost : ""}
                        innerRef={register({
                          required: "Please enter expected Cost or 0",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.cost && <span>{errors.cost.message}</span>}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Revenue (Expected)</label>
                      <Input
                        type="text"
                        name="revenue"
                        defaultValue={leadSource ? leadSource.revenue : ""}
                        innerRef={register({
                          required: "Please enter expected revenue or 0",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.revenue && (
                          <span>{errors.revenue.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <label>Description</label>
                  <Input
                    type="textarea"
                    defaultValue={leadSource ? leadSource.description : ""}
                    name="description"
                    id="description"
                    innerRef={register}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <MUIButton color="preAuto" variant="contained" type="submit">
                    {leadSourceId ? "Update " : "Save "}
                  </MUIButton>
                </div>
              </Form>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateLeadSource;

import {
  GET_UPLOAD_FILES_PENDING,
  GET_UPLOAD_FILES,
  LOAD_UPLOAD_FILES,
  ADD_FILE_UPLOAD,
  UPLOAD_PROGRESS,
  UPLOAD_FAILURE,
  FETCH_UPLOAD_FILES_PENDING,
  FETCH_UPLOAD_FILES,
  DELETE_UPLOAD_FILE,
 UPDATE_PROGRESS,
  CLEAR_ALL,
} from "constants/actions";
const initialState = {
  fileuploads: [],
  updated: [],
  loadingfile: false,
  pending: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_UPLOAD_FILES_PENDING:
      return {
          ...state,
          pending: true,
          loadingfile: true,
      }
    case GET_UPLOAD_FILES:
      return {
        ...state,
        fileuploads: action.payload,
        loadingfile: false,
        pending: false
      };

    case FETCH_UPLOAD_FILES:
      return { ...state, 
        [action.payload.id]: action.payload,
        loadingfile: false,
        pending: false };

    case DELETE_UPLOAD_FILE:
      return {
        ...state,
        fileuploads: state.fileuploads.filter(
          File => File.id !== action.payload
        )
      };
    case ADD_FILE_UPLOAD:
      console.log(action.payload)
      return {
        ...state,
        fileuploads: [...state.fileuploads, action.payload],
        loadingfile: false,
        pending: false
      };
    case CLEAR_ALL:
      return {
        ...state,
        fileuploads: []
      };
    default:
      return state;
  }
}

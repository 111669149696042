import React from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_TRADE_INS } from "constants/actions";

import {BlackBookDetailedLog} from "Inventory/InventoryDetail/BlackBookDetails"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

const Detail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [user, setUser] = React.useState([]);
  const [tradein, setTradein] = React.useState([]);
  const [blackbook, setBlackbook] = React.useState([])

  React.useEffect(() => {
    // console.log(user)
    dispatch({ type: SG_FETCH_TRADE_INS, payload: {id} });
  }, [dispatch]);

  const { load_tradeins } = useSelector(
    (state) => ({
      load_tradeins: state.tradein[parseInt(id)],
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setTradein(load_tradeins);
  }, [load_tradeins]);

  React.useEffect(() => {
    if (load_tradeins) {
       if (load_tradeins.blackbook_response) {
        setBlackbook(load_tradeins.blackbook_response.used_vehicles.used_vehicle_list[0]);
     }
      
    }
    
  }, [load_tradeins]);

  console.log(load_tradeins)

  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardHeader>
            <CardTitle tag="h4">Trade In Details</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <label>First Name </label>
                <p>{tradein ? tradein.first_name : ""}</p>
              </Col>

              <Col>
                <label>Last Name </label>
                <p>{tradein ? tradein.last_name : ""}</p>
              </Col>
              <Col>
                {tradein ? (
                  <Button
                    color="info"
                    size="sm"
                    to={`/admin/customer-detail/${tradein.customer}`}
                    tag={Link}
                  >
                    Customer
                  </Button>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Year </label>
                <p>{tradein ? tradein.year : ""}</p>
              </Col>

              <Col>
                <label>Make</label>
                <p>{tradein ? tradein.make : ""}</p>
              </Col>

              <Col>
                <label>Model</label>
                <p>{tradein ? tradein.model : ""}</p>
              </Col>

              <Col>
                <label>Trim</label>
                <p>{tradein ? tradein.trim : ""}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <label>Kilometers </label>
                <p>{tradein ? tradein.kilometers : ""}</p>
              </Col>

              <Col>
                <label>Expected Price</label>
                <p>{tradein ? tradein.expected_price : ""}</p>
              </Col>

              
              <Col>
              {/* {tradein ? (
                  <Button
                    color="info"
                    size="sm"
                    to={`/admin/trade-in-create/${tradein.id}`}
                    tag={Link}
                  >
                    Edit
                  </Button>
                ) : null} */}
                
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">BlackBook Details</CardTitle>
          </CardHeader>
          <CardBody>
            <Table>

            </Table>
{blackbook? <BlackBookDetailedLog blackbook={blackbook} /> : ''}
          </CardBody>
        </Card>
                  
          
      
      </div>
    </>
  );
};

export default Detail;

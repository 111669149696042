import { GET_COMPANY_INFORMATION_PENDING, 
    LOAD_COMPANY_INFORMATION, GET_COMPANY_INFORMATIONS, 
    DELETE_COMPANY_INFORMATION, REMOVE_COMPANY_INFORMATION, 
    ADD_COMPANY_INFORMATION,  
    ADD_DEFAULT_COMPANY_INFORMATION,
    CLEAR_COMPANY_INFORMATION, 
    FETCH_COMPANY_INFORMATION, 
    SG_FETCH_COMPANY_INFORMATION, EDIT_COMPANY_INFORMATION, 
    UPDATE_COMPANY_INFORMATION, CLEAR_ALL,
} from "constants/actions";

const initialState = {
    companyinformations: [],
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMPANY_INFORMATION_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_COMPANY_INFORMATIONS:
            return {
                ...state,
                pending: false,
                companyinformations: action.payload
            };
                  
        case FETCH_COMPANY_INFORMATION:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_COMPANY_INFORMATION:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_COMPANY_INFORMATION:
            return {
                ...state,
                companyinformations: state.companyinformations.filter(cmp => cmp.id !== action.payload)
            };
        case ADD_DEFAULT_COMPANY_INFORMATION:
            return {
                ...state,
                default: [ action.payload]
            };

        case ADD_COMPANY_INFORMATION:
            return {
                ...state,
                companyinformations: [...state.companyinformations, action.payload]
            };
        case CLEAR_COMPANY_INFORMATION:
            return {
                ...state,
                companyinformations: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                companyinformations: []
            };
        default:
            return state;
    }
}
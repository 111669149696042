import React, { useState } from "react";
import _ from "lodash";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";

import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import Select from "react-select";
import Moment from "moment";
import { Button as MUIButton } from "@mui/material";

import {
  FETCH_INVENTORY_ONE,
  SG_FETCH_VIN_UPLOAD,
  SG_EDIT_INVENTORY,
  CREATE_INVENTORY,
  DESTROY_INVENTORY,
  SG_FETCH_RELATED_DEALER,
  SG_ADD_VIN_UPLOAD,
} from "constants/actions";
// reactstrap components
import {
  // CustomInput,
  Collapse,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

const VehicleOptions = [
  { value: "Car", label: "Car" },
  { value: "SUV", label: "SUV" },
  { value: "Truck", label: "Truck" },
  { value: "Van", label: "Van" },
  { value: "Other", label: "Other" },
];

const TransmissionOptions = [
  { value: 1, label: "Automatic" },
  { value: 2, label: "Manual" },
  { value: 3, label: "CVT" },
  { value: 4, label: "Other" },
];
const ConditionOptions = [
  { value: 1, label: "New" },
  { value: 2, label: "Used" },
  { value: 3, label: "Trade In" },
];

const StatusOptions = [
  { value: 1, label: "Coming" },
  { value: 2, label: "New" },
  { value: 3, label: "Under Process" },
  { value: 4, label: "Live" },
  { value: 5, label: "On Hold" },
  { value: 6, label: "Sold" },
  { value: 7, label: "De Listed" },
];

const CreatEdit = ({ setAddTaskOpen, setLabel, ...props }) => {
  const dispatch = useDispatch();

  const [inventory, setInventory] = React.useState([]);
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
  const [selVehType, setSelVehType] = React.useState([]);
  const [selStatus, setSelStatus] = React.useState([]);
  const [selTranmission, setSelTranmission] = React.useState([]);
  const [selCondition, setSelCondition] = React.useState([]);
  const [vinForm, setVinForm] = React.useState(false);
  const [decodedVin, setDecodedVin] = React.useState([]);
  const [specification, setSpecification] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [vindecodeId, setVindecodeId] = useState(props.vinid);
  const [disableVinDecode, setDisableVinDecoder] = useState(true);

  const [cityMileage, setCityMileage] = useState(0);
  const [highwayMileage, setHighwayMileage] = useState(0);

  const [inventoryCreated, setInventoryCreated] = React.useState(null);
  const [toHome, setToHome] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const [selOpenOnly, setSelOpenOnly] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch(getPaginatedInventories(activePage, pageparams))
    if (props.id) {
      dispatch({ type: FETCH_INVENTORY_ONE, payload: props.id });
    }
    if (props.vinid) {
      dispatch({ type: SG_FETCH_VIN_UPLOAD, payload: props.vinid });
    }
  }, [dispatch, props.vinid, props.id]);

  const { get_inventory, decoded_vin, inventory_created } = useSelector(
    (state) => ({
      get_inventory: state.inventories[parseInt(props.id)],
      decoded_vin: state.vinupload,
      inventory_created: state.inventories.created[0],
    }),
    shallowEqual
  );

  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (inventory_created) {
      setInventoryCreated(inventory_created);
      setTimeout(() => {
        setToHome(true);
      }, 2000);
    }
  }, [inventory_created]);

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    // console.log(vinForm.length, inventory)
    if (vinForm.length === 17 && inventory.length === 0) {
      // console.log(vinForm.length, inventory.length === 0)
      setDisableVinDecoder(false);
    } else {
      // console.log(vinForm.length, inventory)
      setDisableVinDecoder(true);
    }
  }, [vinForm, inventory]);

  React.useEffect(() => {
    setIsOpen(false);
    if (decoded_vin) {
      if (decoded_vin[vinForm]?.list_multiple) {
        if (IsJsonString(decoded_vin[vinForm].list_multiple)) {
          setVindecodeId(decoded_vin[vinForm].id);

          const object1 = JSON.parse(
            decoded_vin[vinForm].list_multiple
          ).specification;
          const obj = object1;

          setSpecification(obj);

          if (obj.length > 1) {
            setIsOpen(true);
          } else {
            setDecodedVin(obj[0]);

            if (!inventory) {
              setCityMileage(obj[0].city_mileage);
              setHighwayMileage(obj[0].highway_mileage);
            }
          }
        } else {
          setSpecification([]);
        }
      }
    }
  }, [decoded_vin, vinForm]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    // console.log(get_inventory);
    if (get_inventory) {
      setInventory(get_inventory);
      setVinForm(get_inventory.vin);
      setCityMileage(get_inventory.city_mileage);
      setHighwayMileage(get_inventory.highway_mileage);
      // setStartDate(Date.parse(get_inventory.veh_in_date))
      setStartDate(moment(get_inventory.veh_in_date).format("YYYY-MM-DD"));

      setSelCondition(
        ConditionOptions.filter((fl) => fl.value === get_inventory.condition)[0]
      );
      setSelStatus(
        StatusOptions.filter((fl) => fl.value === get_inventory.status)[0]
      );
      setSelTranmission(
        TransmissionOptions.filter(
          (fl) => fl.value === get_inventory.transmission
        )[0]
      );
      setSelVehType(
        VehicleOptions.filter((fl) => fl.value === get_inventory.veh_type)[0]
      );
    }
  }, [get_inventory]);

  // console.log(selTranmission);
  React.useEffect(() => {
    if (props.id) {
      if (selCondition) {
        // console.log(selCondition);
        setValue("condition_select", selCondition);
      }

      if (selTranmission) {
        setValue("transmission_select", selTranmission);
      }

      if (selStatus) {
        // console.log(selStatus);
        setValue("status_select", selStatus);
      }
      if (selVehType) {
        // console.log(selStatus);
        setValue("veh_type", selVehType);
      }
    }
    if (highwayMileage) {
      setValue("highway_mileage", Math.round(highwayMileage));
    }
    if (cityMileage) {
      setValue("city_mileage", Math.round(cityMileage));
    }
  }, [
    selCondition,
    selTranmission,
    selStatus,
    selVehType,
    highwayMileage,
    cityMileage,
  ]);

  // _.get(decodedVin, 'decodedVin.specification')
  const VinDecode = () => {
    // console.log(vinForm, "here")
    dispatch({ type: SG_ADD_VIN_UPLOAD, payload: { vin: vinForm } });
    setDisableVinDecoder(true);
  };

  const onSubmit = (data) => {
    // vehicle_in_date
    if (props.id) {
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id: props.id,
          ...data,
          city_mileage: data.city_mileage
            ? data.city_mileage
            : Math.round(cityMileage),
          highway_mileage: data.highway_mileage
            ? data.highway_mileage
            : Math.round(highwayMileage),
          transmission: data.transmission_select.value,
          status: data.status_select.value,
          condition: data.condition_select.value,
          veh_type: data.veh_type.value,
          veh_in_date: moment(startDate).format("YYYY-MM-DDThh:mm"),
        },
      });
    } else {
      dispatch({
        type: CREATE_INVENTORY,
        payload: {
          ...data,
          vin_decoder_id: vindecodeId,
          city_mileage: data.city_mileage
            ? data.city_mileage
            : Math.round(cityMileage),
          highway_mileage: data.highway_mileage
            ? data.highway_mileage
            : Math.round(highwayMileage),
          transmission: data.transmission_select.value,
          status: data.status_select.value,
          condition: data.condition_select.value,
          veh_type: data.veh_type.value,
          veh_in_date: moment(startDate).format("YYYY-MM-DDThh:mm"),
        },
      });
    }
    if (props.resetColWidth) {
      props.resetColWidth(false);
    }
  };

  // const veh_in_date = Date(inventory.veh_in_date);
  // const veh_in_date = Date.parse(inventory.veh_in_date);
  const ChangedStatus = (value) => {
    setSelStatus(value);
  };
  const setSelVinInventory = (e) => {
    setDecodedVin(specification.filter((flt) => flt.trim === e.trim)[0]);
  };

  function isInt(value) {
    return (
      !isNaN(value) &&
      ((parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))) ||
        (parseFloat(Number(value)) == value && !isNaN(parseFloat(value, 10))))
    );
  }

  const sliderOnOpen = () => {
    // decodedVin.highway_mileage? decodedVin.highway_mileage:inventory.highway_mileage
    // decodedVin.city_mileage? decodedVin.city_mileage:inventory.city_mileage
    setSelOpenOnly(!selOpenOnly);
    // 235.214583
    if (highwayMileage && isInt(highwayMileage)) {
      console.log(highwayMileage);
      if (selOpenOnly) {
        // setValue("highway_mileage", highwayMileage);
        setHighwayMileage(282.48 / highwayMileage);
      } else {
        setHighwayMileage(235.21 / highwayMileage);
      }
    }
    if (cityMileage && isInt(cityMileage)) {
      console.log(cityMileage);
      if (selOpenOnly) {
        // setValue("highway_mileage", highwayMileage);
        setCityMileage(282.48 / cityMileage);
      } else {
        setCityMileage(235.21 / cityMileage);
        // setValue("highway_mileage", highwayMileage);
      }

      // setValue("city_mileage", _cityMil);
    }
  };

  setLabel(`${props.id ? "Edit Inventory" : "Create Inventory"}`);
  return (
    <>
      {toHome ? (
        <Redirect to={`/admin/inventory-detail/${inventoryCreated.id}`} />
      ) : null}
      <div className="content">
        <ErrorBoundary>
          {/* <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <CardTitle tag="h4">
                  {props.id ? "Edit Inventory" : "Create Inventory"}
                </CardTitle>
                {props.resetColWidth ? (
                  <Button
                    color="link"
                    onClick={() => props.resetColWidth(false)}
                  >
                    <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                  </Button>
                ) : (
                  ""
                )}
              </div> */}
          <Col className="ml-auto mr-auto" md="10">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <label>
                VIN {props.id ? "(Cannot Edit VIN, delete and reupload)" : ""}{" "}
              </label>
              <FormGroup>
                <Row>
                  <Col md="8">
                    <Input
                      type="text"
                      name="vin"
                      onChange={(e) => setVinForm(e.target.value)}
                      disabled={props.id ? true : false}
                      defaultValue={vinForm ? vinForm : inventory.vin}
                      innerRef={register({
                        required: "Please enter your VIN",
                        minLength: {
                          value: 17,
                          message: "VIN must have at least 17 characters",
                        },
                      })}
                    />
                    <p className="text-danger small">
                      {errors.vin && <span>{errors.vin.message}</span>}
                    </p>
                  </Col>
                  <Col md="3">
                    <Button
                      className="btn-fill mt-n1"
                      disabled={disableVinDecode}
                      onClick={() => VinDecode()}
                      color={disableVinDecode ? "secondary" : "primary"}
                    >
                      Decode
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
              <Collapse isOpen={isOpen}>
                <div className="p-3 bg-info my-2 rounded">
                  <Row>
                    <Col className="text-center">
                      <p>Multiple Vehicles found please select one</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        name="multipleVin"
                        getOptionLabel={(option) => `${option.trim}`}
                        getOptionValue={(option) => `${option.trim}`}
                        options={specification}
                        className="text-dark"
                        onChange={(e) => setSelVinInventory(e)}
                        // isClearable={true}
                      />
                    </Col>
                    <Col md="3">
                      <Button
                        className="btn-fill mt-n1"
                        disabled={vinForm.length != 17}
                        onClick={toggle}
                        color="primary"
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <Row>
                <Col>
                  <FormGroup>
                    <label>Year</label>
                    <Input
                      type="text"
                      name="year"
                      defaultValue={
                        decodedVin.year ? decodedVin.year : inventory.year
                      }
                      innerRef={register({
                        required: "Please enter model year",
                        minLength: {
                          value: 4,
                          message: "Model Year must have at least 4 characters",
                        },
                      })}
                    />
                    <p className="text-danger small">
                      {errors.year && <span>{errors.year.message}</span>}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Make</label>
                    <Input
                      type="text"
                      name="make"
                      defaultValue={
                        decodedVin.make ? decodedVin.make : inventory.make
                      }
                      innerRef={register({
                        required: "Please enter the Make",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.make && <span>{errors.make.message}</span>}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Model</label>
                    <Input
                      type="text"
                      name="model"
                      defaultValue={
                        decodedVin.model ? decodedVin.model : inventory.model
                      }
                      innerRef={register({
                        required: "Please enter model",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.model && <span>{errors.model.message}</span>}
                    </p>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <label>Trim</label>
                <Input
                  type="text"
                  name="trim"
                  defaultValue={
                    decodedVin.trim ? decodedVin.trim : inventory.trim
                  }
                  innerRef={register}
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Kilometers (Mileage)</label>
                    <Input
                      type="text"
                      name="kilometers"
                      defaultValue={inventory.kilometers}
                      innerRef={register({
                        required: "Please enter odometer reading, or hours",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.kilometers && (
                        <span>{errors.kilometers.message}</span>
                      )}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Color</label>
                    <Input
                      type="text"
                      name="color"
                      defaultValue={inventory.color}
                      innerRef={register({
                        required: "Please enter Color",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.color && <span>{errors.color.message}</span>}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Manufacturer Color</label>
                    <Input
                      type="text"
                      name="mfd_color_code"
                      defaultValue={inventory.mfd_color_code}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <label>Body Type</label>
                    <Input
                      type="text"
                      name="body_type"
                      defaultValue={
                        decodedVin.style
                          ? decodedVin.style
                          : inventory.body_type
                      }
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Driving Wheels</label>
                    <Input
                      type="text"
                      name="driving_wheels"
                      defaultValue={
                        decodedVin.drive_type
                          ? decodedVin.drive_type
                          : inventory.driving_wheels
                      }
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <label>Market Class</label>
                <Input
                  type="text"
                  name="market_class"
                  defaultValue={inventory.market_class}
                  innerRef={register}
                />
              </FormGroup>
              <Row>
                <Col>
                  <label>Engine</label>
                  <Input
                    type="text"
                    name="engine"
                    defaultValue={
                      decodedVin.engine ? decodedVin.engine : inventory.engine
                    }
                    innerRef={register}
                  />
                </Col>
                <Col>
                  <label>steering type</label>
                  <Input
                    type="text"
                    name="steering_type"
                    defaultValue={
                      decodedVin.steering_type
                        ? decodedVin.steering_type
                        : inventory.steering
                    }
                    innerRef={register}
                  />
                </Col>
                <Col>
                  <label>Transmission Details</label>
                  <Input
                    type="text"
                    name="transmission_details"
                    defaultValue={
                      decodedVin.transmission
                        ? decodedVin.transmission
                        : inventory.transmission_details
                    }
                    innerRef={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Vehicle Type</label>
                    <Controller
                      as={Select}
                      options={VehicleOptions}
                      name="veh_type"
                      className="text-dark"
                      control={control}
                      innerRef={register}
                      rules={{ required: "Please enter Vehicle Type" }}
                    />
                    <p className="text-danger small">
                      {errors.veh_type && (
                        <span>{errors.veh_type.message}</span>
                      )}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <label>Condition</label>
                  <Controller
                    as={Select}
                    options={ConditionOptions}
                    name="condition_select"
                    className="text-dark"
                    control={control}
                    innerRef={register}
                    rules={{ required: "Please enter Condition" }}
                  />
                  <p className="text-danger small">
                    {errors.condition_select && (
                      <span>{errors.condition_select.message}</span>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Status</label>
                  <Controller
                    as={<Select />}
                    name="status_select"
                    options={StatusOptions}
                    className="text-dark"
                    control={control}
                    rules={{ required: "Please enter Status" }}
                  />{" "}
                  <p className="text-danger small">
                    {errors.status_select && (
                      <span>{errors.status_select.message}</span>
                    )}
                  </p>
                </Col>
                <Col>
                  <label>Transmission </label>
                  <Controller
                    as={<Select />}
                    name="transmission_select"
                    options={TransmissionOptions}
                    value={selTranmission}
                    className="text-dark"
                    control={control}
                    rules={{ required: "Please enter Transmission" }}
                    // innerRef={register({
                    //   required: "Please enter Transmission",
                    // })}
                  />
                  <p className="text-danger small">
                    {errors.transmission_select && (
                      <span>{errors.transmission_select.message}</span>
                    )}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <label>List Price</label>
                    <Input
                      type="text"
                      name="list_price"
                      defaultValue={inventory.list_price}
                      innerRef={register({
                        required: "Please enter List Price (0 for blank)",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.list_price && (
                        <span>{errors.list_price.message}</span>
                      )}
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Doors</label>
                    <Input
                      type="text"
                      name="pass_doors"
                      defaultValue={inventory.pass_doors}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Seats (Capacity)</label>
                    <Input
                      type="text"
                      name="pass_capacity"
                      defaultValue={
                        decodedVin.standard_seating
                          ? decodedVin.standard_seating
                          : inventory.pass_capacity
                      }
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="d-flex flex-row">
                <label className="mr-2">Miles per Gallon</label>

                <Input
                  type="switch"
                  id="showOpen"
                  onClick={sliderOnOpen}
                  defaultChecked={selOpenOnly}
                  name="showOpen"
                />
                <label className="ml-1">L / 100 KM</label>
              </div>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Highway mileage</label>
                    <Input
                      type="text"
                      name="highway_mileage"
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>City Mileage</label>
                    <Input
                      type="text"
                      name="city_mileage"
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Displacement CC</label>
                    <Input
                      type="text"
                      name="displacement_value"
                      defaultValue={inventory.displacement_value}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Horsepower</label>
                    <Input
                      type="text"
                      name="horsepower_value"
                      defaultValue={inventory.horsepower_value}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <label>Torque</label>
                    <Input
                      type="text"
                      name="torque_value"
                      defaultValue={inventory.torque_value}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Cylinders</label>
                    <Input
                      type="text"
                      name="cylinders"
                      defaultValue={inventory.cylinders}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Blackbook Category</label>
                    <Input
                      type="text"
                      disabled
                      name="blackbook_category"
                      defaultValue={inventory.blackbook_category}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <label>Vehicle In-date </label>
                  <FormGroup>
                    <ReactDatetime
                      name="veh_in_date"
                      dateFormat="YYYY-MM-DD"
                      value={startDate}
                      // defaultValue={inventory.veh_in_date}
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                      }}
                      onChange={setStartDate}
                      timeFormat={false}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                             <Controller
                  as={ReactDatetime}
                  control={control}
                  name="veh_in_date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control border-input"
                   selected={startDate}
                   maxDate={addDays(new Date(), 30)}
                   minDate={subDays(new Date(), 30)}
                   type="text"
                   onChange={date => setStartDate(date)} 
                  //   onChange={handleDateTimeChange}
                /> 
                          </FormGroup> */}
                </Col>
                <Col>
                  <FormGroup>
                    <label>Seat Rows</label>
                    <Input
                      type="text"
                      name="seat_rows"
                      defaultValue={inventory.seat_rows}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <label>Meta Description</label>
                <Input
                  type="textarea"
                  name="meta_description"
                  defaultValue={inventory.meta_description}
                  id="meta_description"
                  innerRef={register}
                />
              </FormGroup>
              <div className="d-flex justify-content-end">
                <MUIButton color="preAuto" variant="contained" type="submit">
                  Save
                </MUIButton>
              </div>
            </Form>
          </Col>
          {/* </CardBody>
          </Card> */}
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreatEdit;

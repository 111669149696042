import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
} from "reactstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  CMS_CREATE_CONTACT_PAGE_TYPE,
  LOAD_CMS_CONTACT_PAGE_TYPE,
  UPDATE_CMS_CONTACT_PAGE_TYPE,
} from "constants/actions";
import { EditorInLineConvertToHTML } from "Inventory/InventoryDetail/EditorConvertToHTML";
export default function ContactPageCMS() {
  const [dealerId, setDealerId] = React.useState();
  const { id, slug } = useParams();
  const { register, handleSubmit } = useForm();
  const [cashforcars, setCashForCars] = React.useState([]);
  const [editSubject, setEditSubject] = React.useState("");
  const [editNewContent, setEditContent] = React.useState("");
  const dispatch = useDispatch();
  const notify = () => toast.success(`Data Updated successfully`);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_CMS_CONTACT_PAGE_TYPE, payload: id });
  }, [dispatch, id]);

  const { get_auth, get_contactpage } = useSelector(
    (state) => ({
      get_contactpage: state.contactpage.contactpage,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_contactpage) {
      console.log("in if");
      setEditSubject(get_contactpage["results"]?.[0]?.subjectContent);
      setEditContent(get_contactpage["results"]?.[0]?.locationContent);  
    } 
  }, [get_contactpage]);

  const BtnCallBackContactPage = (data) => {
    dispatch({
      type: UPDATE_CMS_CONTACT_PAGE_TYPE,
      payload: {
        id: get_contactpage["results"]?.[0]?.id,
        subjectContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };

  const BtnCallBackContactPageBottom = (data) => {
    dispatch({
      type: UPDATE_CMS_CONTACT_PAGE_TYPE,
      payload: {
        id: get_contactpage["results"]?.[0]?.id,
        locationContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };

  // const onSubmit = (data) => {
  //   dispatch({
  //     type: CMS_CREATE_CONTACT_PAGE_TYPE,
  //     payload: {
  //       subjectContent: data.subjectContent,
  //       locationContent: data.locationContent,
  //       company: dealerId ? dealerId : get_auth.dealer_id,
  //     },
  //   });
  //      notify()
  // };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardHeader>
                    <CardTitle tag="h4">Contact Page CMS</CardTitle>
                    {/* <h5 className="card-category">My Tasks</h5> */}
                  </CardHeader>
                </Col>
              </Row>
              

              {/* Create Api for future use */}
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <label>Subject And Content</label>
                      <Input
                        type="text"
                        name="subjectContent"
                        innerRef={register({
                          required: "Please enter First Name",
                        })}
                      />                  
                    </FormGroup>
                    <FormGroup>
                      <label>Location Content </label>
                      <Input
                        type="text"
                        name="locationContent"
                        innerRef={register({
                          required: "Please enter Last Name",
                        })}
                      />                 
                    </FormGroup>    
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn-fill"
                        color="primary"
                        type="submit"
                      >
                       Save
                      </Button>
                    </div>
                  </Form> */}

                 
                  <Row className="pt-1">
                    <Col md="12">
                      <EditorInLineConvertToHTML
                        heading="Subject & Content "
                        editContent={editSubject}
                        BtnCallBack={BtnCallBackContactPage}
                      />
                    </Col>
                  </Row>
                  
                  <br />
                  <Row className="pt-1">
                    <Col md="12">
                      <EditorInLineConvertToHTML
                        heading="Location Content "
                        editContent={editNewContent}
                        BtnCallBack={BtnCallBackContactPageBottom}
                      />
                    </Col>
                  </Row>
            
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

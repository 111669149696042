import { GET_SERVICE_REQUESTS, DELETE_SERVICE_REQUEST, ADD_SERVICE_REQUEST, FETCH_SERVICE_REQUEST, EDIT_SERVICE_REQUEST , 
    CLEAR_SERVICE_REQUEST, GET_SERVICE_REQUEST_PENDING, CLEAR_ALL } from "constants/actions";

const initialState = {
    servicerequests: [],
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SERVICE_REQUEST_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_SERVICE_REQUESTS:
            return {
                ...state,
                servicerequests: action.payload,
                pending: false
            };
        case FETCH_SERVICE_REQUEST:
            return { ...state, [action.payload.id]: action.payload, pending: false };

        case EDIT_SERVICE_REQUEST:
            return { ...state, [action.payload.id]: action.payload , pending: false};

        case DELETE_SERVICE_REQUEST:
            return {
                ...state,
                servicerequests: state.servicerequests.filter(dealer => dealer.id !== action.payload),
                pending: false
            };
        case ADD_SERVICE_REQUEST:
            return {
                ...state,
                servicerequests: [...state.servicerequests, action.payload],
                pending: false
            };
        case CLEAR_SERVICE_REQUEST:
            return {
                ...state,
                servicerequests: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                servicerequests: []
            };
        default:
            return state;
    }
}


import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";


import SimpleList from "Inventory/SimpleList";


export default () => {
 

  return (
    <>
      <div className="content">
      

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">All Inventory List</CardTitle>
                  </Col>
                  
                </Row>
              </CardHeader>

              <CardBody>
                <SimpleList />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

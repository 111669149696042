import React from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SG_DELETE_INVENTORYIMAGE } from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  Row,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";

const imgWithClick = { cursor: "grabbing" };

const ProductCard = (props) => {
  const { id, slug } = useParams();
  const dispatch = useDispatch();

  const handleRemove = (e) => {
    //
    dispatch({
      type: SG_DELETE_INVENTORYIMAGE,
      payload: { id: e, inventory_id: id },
    });
  };


  return (
    <>
      <Card className="ml-1 mr-1 card-product card-plain">
        {/* <Link to={`/product/${props.slug}`}> </Link> */}
        <CardBody>
          <div className="card-image">
            <img alt="..." className="img" src={props.imageUrl} />
            {/* <img
                      alt="..."
                      className="img"
                      src={`/images/vehicles/${props.imageUrl}`}
                    /> */}
          </div>
          {props.editable?
         <>
          <p>
            <span
              className={
                props.imgWidth === 1280 ? "text-success" : "text-danger"
              }
            >
              Width: {props.imgWidth}{" "}
              <span className="text-danger">
                {props.imgWidth === 1280 ? "" : "Must be 1280px"}
              </span>
            </span>
            <span
              className={
                props.imgHeight === 960 ? "text-success" : "text-danger"
              }
            >
              Height: {props.imgHeight}{" "}
              <span className="text-danger">
                {props.imgHeight === 960 ? "" : "Must be 960px"}
              </span>
            </span>
          </p>

          <Button
            color="danger"
            size="sm"
            className="btn-round"
            onClick={() => handleRemove(props.id)}
          >
            <i className="fa fa-times" />
            Remove
          </Button>
          </> : '' }
        </CardBody>
      </Card>
    </>
  );
};

export default ProductCard;

import React from "react";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  CREATE_COMPANY,
  SG_FETCH_COMPANY,
  UPDATE_COMPANY,
} from "constants/actions";
import { useHistory } from "react-router-dom";
import { STRIPE_CREATE_CUSTOMER_TYPE } from "constants/actions";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";

// core components

const offTypeOptions = [
  { value: 1, label: "Main Office" },
  { value: 2, label: "Branch" },
  { value: 3, label: "Regional Office" },
  { value: 4, label: "Sales Office" },
  { value: 5, label: "Warehouse" },
  { value: 6, label: "Service Centre" },
];

const activeOptions = [
  { value: 1, label: "Active" },
  { value: 2, label: "Paused" },
  { value: 3, label: "To be deleted" },
];

const dataSourceType = [
  { value: 1, label: "JSON" },
  { value: 2, label: "GSheet" },
  // { value: 3, label: "XML" },
  // { value: 4, label: "Office365" },
  { value: 5, label: "Other" },
];

function getRandomString(length) {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

const CreateEdit = (props) => {
  const { register, handleSubmit, watch, setValue, errors, control, reset } =
    useForm();
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  let history = useHistory();

  const [selCompany, setSelCompany] = React.useState([]);
  const [companyCreated, setCompanyCreated] = React.useState(null);
  const [toDetail, setToDetail] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);

  const [selOffTypes, setSelOffTypes] = React.useState();
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_COMPANY, payload: id });
    }
  }, [dispatch]);

  const [randomCode, setRandomCode] = React.useState(getRandomString(6));
  const { get_company, get_auth, get_errors, get_company_created } =
    useSelector(
      (state) => ({
        get_company: state.company,
        get_company_created: state.company.created,
        get_auth: state.auth,
        get_errors: state.errors,
      }),
      shallowEqual
    );

  React.useEffect(() => {
    // console.log(_.isEmpty(get_errors.errors))
    if (!_.isEmpty(get_errors.errors)) {
      // console.log(get_errors)

      setVisible(true);
      setPageErrors(get_errors);
    }
  }, [get_errors]);

  React.useEffect(() => {
    if (get_company[parseInt(id)]) {
      setSelCompany(get_company[parseInt(id)]);
    }
  }, [get_company, id]);

  React.useEffect(() => {
    if (get_company_created) {
      setCompanyCreated(get_company_created);
    }
  }, [get_company_created]);
  //   const notify = () => toast.success(`Thanks! We will get it touch with soon`);
  // console.log(props.solutions)
  // console.log(selectedOptions, options.length)
  //   React.useEffect(() => {

  //   },);
  console.log(randomCode);
  const onSubmit = (data, e) => {
    if (id) {
      dispatch({
        type: UPDATE_COMPANY,
        payload: {
          id: id,
          ...data,
          data_source: data.data_source.value,
          company_type: data.company_type.value,
        },
      });

      setTimeout(() => {
        setToDetail(true);
      }, 2000);
    } else {


      dispatch({
        type: CREATE_COMPANY,
        payload: {
          ...data,
          data_source: data.data_source.value,
          company_type: data.company_type.value,
        },
      });
    }
  };

  //   React.useEffect(() => {
  //     register({ name: "solutions" },
  //     { required: "Please select an Solution" });
  //   }, [register]);
  React.useEffect(() => {
    setValue(
      "company_type",
      offTypeOptions.filter((oft) => oft.value === selCompany.company_type)
    );
    setValue(
      "data_source",
      dataSourceType.filter((oft) => oft.value === selCompany.data_source)
    );
  }, [selCompany]);

  const email_validation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const website_validation =
    /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

  return (
    <>
      {companyCreated ? (
        <Redirect to={`/admin/company-detail/${companyCreated.id}`} />
      ) : null}
      {toDetail ? <Redirect to={`/admin/company-detail/${id}`} /> : null}
      <div className="content">
        <Alert color="danger" isOpen={visible} toggle={setVisible}>
          {/* {pageerrors.errors? pageerrors.errors['detail']: JSON.stringify(pageerrors.errors)} */}
          {pageerrors.errors
            ? pageerrors.errors["url"]
            : JSON.stringify(pageerrors.errors)}
        </Alert>

        <div className={props.classname ? props.classname : ""}>
          {props.heading ? (
            <h4 className="text-center headline-a pt-3">{props.heading} </h4>
          ) : null}
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="8" md="8" sm="12" xs="12">
                <div className="flat-card">
                  <CardBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <FormGroup className={errors.name ? "has-danger" : ""}>
                        <Label className="text-dark">Company</Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Company (Dealer Name)"
                          defaultValue={selCompany ? selCompany.name : ""}
                          innerRef={register({
                            required: true,
                            maxlength: 150,
                          })}
                        />
                        <div className="form-control-feedback">
                          {errors.name && <span>Company name is required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup className={errors.address ? "has-danger" : ""}>
                        <Label className="text-dark">Address</Label>
                        <Input
                          type="text"
                          name="address"
                          placeholder="Address"
                          defaultValue={selCompany ? selCompany.address : ""}
                          innerRef={register({
                            required: true,
                            maxlength: 250,
                          })}
                        />
                        <div className="form-control-feedback">
                          {errors.address && <span>Address required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">Address</Label>
                        <Input
                          type="text"
                          name="address2"
                          placeholder="Address"
                          defaultValue={selCompany ? selCompany.address2 : ""}
                          innerRef={register({ maxlength: 250 })}
                        />
                      </FormGroup>
                      <FormGroup className={errors.city ? "has-danger" : ""}>
                        <Label className="text-dark">City</Label>
                        <Input
                          type="text"
                          name="city"
                          placeholder="City"
                          defaultValue={selCompany ? selCompany.city : ""}
                          innerRef={register({ required: true, maxlength: 50 })}
                        />
                        <div className="form-control-feedback">
                          {errors.city && <span>City required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup
                        className={errors.postalcode ? "has-danger" : ""}
                      >
                        <Label className="text-dark">Postal code</Label>
                        <Input
                          type="text"
                          name="postalcode"
                          placeholder="postalcode"
                          defaultValue={selCompany ? selCompany.postalcode : ""}
                          innerRef={register({ required: true, maxlength: 7 })}
                        />
                        <div className="form-control-feedback">
                          {errors.postalcode && (
                            <span>Postal code required</span>
                          )}
                        </div>
                      </FormGroup>

                      <FormGroup
                        className={errors.province ? "has-danger" : ""}
                      >
                        <Label className="text-dark">Province</Label>
                        <Input
                          type="text"
                          name="province"
                          placeholder="province"
                          defaultValue={selCompany ? selCompany.province : ""}
                          innerRef={register({ required: true, maxlength: 50 })}
                        />
                        <div className="form-control-feedback">
                          {errors.province && <span>Province required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup
                        className={errors.province ? "has-danger" : ""}
                      >
                        <Label className="text-dark">Province Code</Label>
                        <Input
                          type="text"
                          maxLength="2"
                          name="province_code"
                          placeholder="province_code"
                          defaultValue={
                            selCompany ? selCompany.province_code : ""
                          }
                          innerRef={register({ required: true, maxlength: 50 })}
                        />
                        <div className="form-control-feedback">
                          {errors.province_code && (
                            <span>Province required</span>
                          )}
                        </div>
                      </FormGroup>

                      <FormGroup className={errors.country ? "has-danger" : ""}>
                        <Label className="text-dark">Country</Label>
                        <Input
                          type="text"
                          name="country"
                          placeholder="country"
                          defaultValue={selCompany ? selCompany.country : ""}
                          innerRef={register({ required: true, maxlength: 50 })}
                        />
                        <div className="form-control-feedback">
                          {errors.country && <span>Country required</span>}
                        </div>
                      </FormGroup>

                      <FormGroup className={errors.email ? "has-danger" : ""}>
                        <Label className="text-dark">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email Id"
                          defaultValue={selCompany ? selCompany.email : ""}
                          innerRef={register({
                            required: true,
                            pattern: email_validation,
                            maxlength: 20,
                          })}
                        />
                        <div className="form-control-feedback">
                          {errors.email && <span>Email is required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup className={errors.phone ? "has-danger" : ""}>
                        <Label className="text-dark">Phone</Label>
                        <Input
                          type="number"
                          name="phone"
                          placeholder="Phone"
                          defaultValue={selCompany ? selCompany.phone : ""}
                          innerRef={register({
                            required: true,
                            min: 100000000,
                            max: 199999999999,
                            maxlength: 20,
                          })}
                        />
                        <div className="form-control-feedback">
                          {errors.phone && <span>Phone is required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup className={errors.fax ? "has-danger" : ""}>
                        <Label className="text-dark">Fax</Label>
                        <Input
                          type="text"
                          name="fax"
                          placeholder="Fax"
                          defaultValue={selCompany ? selCompany.fax : ""}
                          innerRef={register({
                            required: true,
                            // pattern: /^[A-Za-z]+$/i,
                            maxlength: 20,
                          })}
                        />
                        <div className="form-control-feedback">
                          {errors.fax && <span>Fax is required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">Toll Free</Label>
                        <Input
                          type="text"
                          name="tollfree"
                          placeholder="tollfree"
                          defaultValue={selCompany ? selCompany.tollfree : ""}
                          innerRef={register({
                            // pattern: /^[A-Za-z]+$/i,
                            maxlength: 20,
                          })}
                        />
                        <div className="form-control-feedback">
                          {errors.tollfree && <span>tollfree is required</span>}
                        </div>
                      </FormGroup>
                      <FormGroup className={errors.website ? "has-danger" : ""}>
                        <Label className="text-dark">Website</Label>
                        <Input
                          type="text"
                          name="website"
                          placeholder="website"
                          defaultValue={selCompany ? selCompany.website : ""}
                          innerRef={register({
                            //   required: true,
                            //   pattern: website_validation,
                            //   maxlength: 20,
                          })}
                        />
                        {/* <div className="form-control-feedback">
                          {errors.website && (
                            <span>website is required</span>
                          )}
                          {errors.website && errors.website.type === "pattern" && (
                            <span>{' '}Please enter complete website with https</span>
                          )}
                        </div> */}
                      </FormGroup>
                      <FormGroup
                        className={errors.location ? "has-danger" : ""}
                      >
                        <Label className="text-dark">Web Port (Location)</Label>
                        <Input
                          type="text"
                          name="location"
                          placeholder="Port (location)"
                          defaultValue={selCompany ? selCompany.location : ""}
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <p>Company Type</p>

                        <Controller
                          as={Select}
                          options={offTypeOptions}
                          // value={selCompany ? selCompany : null}
                          name="company_type"
                          className="text-dark"
                          control={control}
                          // onChange={onEmploymentChange}
                        />
                        <p className="text-danger small">
                          {selCompany
                            ? ""
                            : errors.company_type && (
                                <span>{errors.company_type.message}</span>
                              )}
                        </p>
                      </FormGroup>

                      <FormGroup>
                        <Label className="text-dark">
                          company external code
                        </Label>
                        <Input
                          type="text"
                          name="company_external_code"
                          placeholder="company external code"
                          defaultValue={
                            selCompany
                              ? selCompany.company_external_code
                              : randomCode
                          }
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">map_lng</Label>
                        <Input
                          type="text"
                          name="map_lng"
                          placeholder="map_lng"
                          defaultValue={selCompany ? selCompany.map_lng : ""}
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">map_lat</Label>
                        <Input
                          type="text"
                          name="map_lat"
                          placeholder="map_lat"
                          defaultValue={selCompany ? selCompany.map_lat : ""}
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">
                          dealer_registeration_code
                        </Label>
                        <Input
                          type="text"
                          name="dealer_registeration_code"
                          placeholder="dealer_registeration_code"
                          defaultValue={
                            selCompany
                              ? selCompany.dealer_registeration_code
                              : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="text-dark">kijiji_code</Label>
                        <Input
                          type="text"
                          name="kijiji_code"
                          placeholder="kijiji_code"
                          defaultValue={
                            selCompany ? selCompany.kijiji_code : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="text-dark">carfax_code</Label>
                        <Input
                          type="text"
                          name="carfax_code"
                          placeholder="carfax_code"
                          defaultValue={
                            selCompany ? selCompany.carfax_code : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="text-dark">facebook_code</Label>
                        <Input
                          type="text"
                          name="facebook_code"
                          placeholder="facebook_code"
                          defaultValue={
                            selCompany ? selCompany.facebook_code : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label className="text-dark">google_code</Label>
                        <Input
                          type="text"
                          name="google_code"
                          placeholder="google_code"
                          defaultValue={
                            selCompany ? selCompany.google_code : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">Facebook Feed URL</Label>
                        <Input
                          type="text"
                          name="facebook_feed_url"
                          placeholder="facebook_feed_url"
                          defaultValue={
                            selCompany ? selCompany.facebook_feed_url : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">Google Feed URL</Label>
                        <Input
                          type="text"
                          name="google_feed_url"
                          placeholder="google_feed_url"
                          defaultValue={
                            selCompany ? selCompany.google_feed_url : ""
                          }
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <p>Data Source Type</p>

                        <Controller
                          as={Select}
                          options={dataSourceType}
                          name="data_source"
                          className="text-dark"
                          control={control}
                        />
                        <p className="text-danger small">
                          {selCompany
                            ? ""
                            : errors.data_source && (
                                <span>{errors.data_source.message}</span>
                              )}
                        </p>
                      </FormGroup>

                      <FormGroup>
                        <Label className="text-dark">
                          Data Source Name (Gsheet Name)
                        </Label>
                        <Input
                          type="text"
                          name="data_name"
                          placeholder="company external code"
                          defaultValue={selCompany ? selCompany.data_name : ""}
                          innerRef={register()}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-dark">Data Source URL</Label>
                        <Input
                          type="text"
                          name="data_url"
                          placeholder="company external code"
                          defaultValue={selCompany ? selCompany.data_url : ""}
                          innerRef={register()}
                        />
                      </FormGroup>

                      <FormGroup
                        className={errors.description ? "has-danger" : ""}
                      >
                        <Label className="text-dark">Notes</Label>
                        <Input
                          type="textarea"
                          rows="3"
                          name="notes"
                          defaultValue={selCompany ? selCompany.notes : ""}
                          placeholder="Notes / Instructions"
                          innerRef={register({ required: false })}
                        />
                      </FormGroup>

                      <Button
                        block
                        className="btn"
                        type="submit"
                        color={id ? "info" : "danger"}
                      >
                        {id ? "Update" : "Create"}
                      </Button>
                    </Form>
                  </CardBody>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default CreateEdit;

import React from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_TRADE_INS, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";

// reactstrap components
import { Row, Col } from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TablePaginationActions from "mui/tables/TablePaginationActions";

const List = (props) => {
  const dispatch = useDispatch();

  const [tradeins, setTradeins] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  // pagination end

  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams) LOAD_ADMIN_TRADE_INS

    dispatch({
      type: LOAD_TRADE_INS,
      payload: {
        page: pageactivePage,
        params: `show-customer=True&${pageParams}`,
      },
    });
  }, [dispatch, pageParams, pageactivePage]);

  const { get_trade_ins, get_auth, get_errors } = useSelector(
    (state) => ({
      get_trade_ins: state.tradein.tradeins,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]

  React.useEffect(() => {
    setTradeins(get_trade_ins.results);
  }, [get_trade_ins]);

  // console.log("application", application)
  // console.log("Browser", isBrowser, deviceType)

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_trade_ins) {
      setTotalResults(get_trade_ins.count);
      if (get_trade_ins.page_size) {
        setPerPage(get_trade_ins.page_size);
      }
      setTotalPages(Math.ceil(get_trade_ins.count / perPage));
    }
  }, [get_trade_ins]);

  // console.log(user)
  // const handleClick = (page) => {
  //   dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: page.selected });
  //   setActivePage(page.selected);
  //   setPageActivePage(page.selected + 1);
  // };
  const [page, setPage] = React.useState(0);

  const handleClick = (event, newPage) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "trade", page: newPage + 1 },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Customer</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">Year</StyledTableCell>
                <StyledTableCell align="center">Make</StyledTableCell>
                <StyledTableCell align="center">Model</StyledTableCell>
                <StyledTableCell align="center">Kms</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tradeins
                ? tradeins.map((p, k) => (
                    <StyledTableRow key={k}>
                      <StyledTableCell>{p.first_name}</StyledTableCell>
                      <StyledTableCell align="center">
                        {p.last_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">{p.year}</StyledTableCell>

                      <StyledTableCell align="center">{p.make}</StyledTableCell>
                      <StyledTableCell align="center">
                        {p.model}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {p.kilometers}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link to={`/admin/trade-in-detail/${p.id}`}>
                          <RemoveRedEyeOutlinedIcon
                            size={40}
                            color={"preAuto"}
                          />
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : " No tasks found"}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[12]}
            component="div"
            count={totalResults || 0}
            rowsPerPage={
              totalPages > 12 ? tradeins.length || 0 : totalPages || 0
            }
            page={page}
            onPageChange={handleClick}
            ActionsComponent={TablePaginationActions}
            sx={{
              ".MuiTablePagination-selectLabel": {
                marginTop: "auto",
              },
              ".MuiTablePagination-displayedRows": {
                marginTop: "auto",
              },
            }}
          />
        </TableContainer>
      </Paper>

      {/* <Row>
        <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col>
        <Col md="12">
          <Table responsive>
            <thead className="table-active">
              <tr>
                <th>Customer</th>
                <th></th>
                <th>Year</th>
                <th>Make</th>
                <th>Model</th>
                <th className="text-right">Kms</th>
              </tr>
            </thead>
            <tbody>
              {tradeins
                ? tradeins.map((p, k) => (
                    <tr key={k}>
                      <td>{p.first_name}</td>
                      <td>{p.last_name}</td>
                      <td>{p.year}</td>
                      <td>{p.make}</td>
                      <td>{p.model}</td>
                      <td className="text-right">{p.kilometers}</td>
                      <td>
                      <Link to={`/admin/trade-in-detail/${p.id}`}>
                          View More
                        </Link>
                      </td>
                    </tr>
                  ))
                :  <tr>
                <td>
                  No Trade Ins
                </td>
                </tr>}
            </tbody>
          </Table>
        </Col>
        <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default List;

import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Company from "../Company/index";
import PrivateRoute from "Routes/PrivateRoute.js";
import routes from "routes.js";

import {
  SG_EMPLOYEE_AUTH_LOADED,
  SG_DEALER_AUTH_LOADED,
  LOAD_PRODUCT_CONTRACTS,
  SG_FETCH_RELATED_DEALER,
  LOAD_ALL_EMPLOYEES,
} from "constants/actions";

import _ from "lodash";

import NotificationAlert from "react-notification-alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Container, Alert } from "reactstrap";
import {
  SG_FETCH_COMPANY_INFORMATION,
  SG_FETCH_COMPANY,
} from "constants/actions";
import AppHeader from "components/AppHeader";
import { useEffect } from "react";
import Detail from "Company/Detail";
import { useLocation } from "react-router-dom";

var ps;

function Admin(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let notificationAlert = React.createRef();
  const location = useLocation()
  const [userroleid, setUserroleid] = React.useState(99);
  const [errorState, setErrorState] = React.useState(false);
  const [companyId, setCompanyId] = React.useState("");

  React.useEffect(() => {
    dispatch({
      type: SG_FETCH_COMPANY,
      payload: Number(logged_user?.dealer_id),
    });
  }, [dispatch]);
  const currentUrl = window.location.href;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentUrl]);
  const {
    logged_user,
    get_error_state,
    get_errors,
    employee,
    dealer,
    get_company,
  } = useSelector(
    (state) => ({
      logged_user: state.auth,
      get_error_state: state.error_state,
      get_errors: state.errors,
      employee: state.auth.employee,
      dealer: state.auth.dealer,
      get_company: state.company,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_company) {
      setCompanyId(get_company?.[logged_user?.dealer_id]?.id);
      selectedRoutes(get_company?.[logged_user?.dealer_id]?.all_obj)
    }
  }, [get_company]);

  React.useEffect(() => {
    if (logged_user) {
      setUserroleid(logged_user.role_id);
    }
  }, [logged_user]);
  React.useEffect(() => {
    if (get_errors?.msg) {
      setErrorState(get_errors?.msg);
    }
  }, [get_errors]);
  React.useEffect(() => {
    if (errorState) {
      // console.log(errorState.msg);
      // console.log(errorState.errors);
      // console.log(errorState.status);
      // if (errorState.msg.errors === "Request failed with status code 401") {
      //   history.push("/auth/logout");
      // }
      // return <Redirect to="/auth/logout" />
      if (get_errors?.msg?.status === 401) {
        history.push("/auth/logout");
      }
      //
      // setVisible(true)
      // setCriticalError("Critical Error - please logout and login again!")
    }
  }, [errorState]);

  React.useEffect(() => {
    if (!_.isEmpty(get_error_state.msg)) {
      // console.log(get_error_state);
      // return <Redirect to="/auth/logout" />
      history.push("/auth/logout");
      // setVisible(true)
      // setCriticalError("Critical Error - please logout and login again!")
    }
  }, [get_error_state]);

  // console.log("Logged User",logged_user)
  // console.log("User", user)
  React.useEffect(() => {
    if (_.get(logged_user, "employee_id")) {
      dispatch({
        type: SG_EMPLOYEE_AUTH_LOADED,
        payload: logged_user.employee_id,
      });
    }
    if (_.get(logged_user, "dealer_id")) {
      dispatch({
        type: SG_DEALER_AUTH_LOADED,
        payload: logged_user.dealer_id,
      });
      dispatch({ type: LOAD_PRODUCT_CONTRACTS });
    }
  }, [dispatch]);

  // console.log("get_errors", JSON.stringify(pageerrors))
  // if (!logged_user) {
  //   history.push( "/auth/login")
  // }

  const [backgroundColor, setbackgroundColor] = React.useState("black");
  const [activeColor, setactiveColor] = React.useState("info");
  const [sidebarMini, setsidebarMini] = React.useState(false);
  const [routeSelected, setRouteSelected] = useState({});
  const { id, slug } = useParams();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // console.log(logged_user, userroleid, prop.role, prop.role > userroleid, prop.isStaff)

      // if (get_company?.["2"]?.id === 2 && prop.name === "Vin Decode Full") {
      //   return null;
      // }
      // if (get_company?.["2"]?.id === 2 && prop.name === "BlackBook") {
      //   return null;
      // }
      // if (get_company?.["2"]?.id === 2 && prop.name === "CMS") {
      //   return null;
      // }


      if (routeSelected[prop.name]) {

        return null

      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      // console.log(prop.isStaff, prop.path, logged_user.is_staff)
      if (prop.layout === "/admin") {
        // is staff
        if (logged_user.is_staff === "true" || logged_user.is_staff === true) {
          if (prop.isStaff === false) {
            return null;
          }
        }


        if (
          (logged_user.is_staff === "false" ||
            logged_user.is_staff === false) && Number(logged_user.role_id) != 10
        ) {
          if (prop.isStaff === true) {
            return null;
          }
        }



        if (Number(logged_user.role_id) !== 10 && Number(logged_user.role_id) !== 8 && Number(logged_user.role_id) !== 6) {
       
          if (prop.role < userroleid) {
            return null;
         }
        }



        if (
          (logged_user.is_staff === "true" || logged_user.is_staff === true) &&
          prop.path === "/dashboard-sales"
        ) {
          prop.component = Company;
          prop.name = "All Dealers";
          prop.role = 9;
        }


        if (logged_user.role_id == 10) {
          if (prop?.path != "/dashboard-sales" && prop?.path !== "/logout" && prop?.path !== "/user-profile" && !prop.isDealer) {

            return null;
          }
        }

        // if (
        //   logged_user?.role_id == 3 &&
        //   prop.path === "/dashboard-sales" && get_company?.[logged_user?.dealer_id]?.all_obj.includes(8)==false && location.pathname.split('/').pop()=='dashboard-sales' 
        // ) {
        //   console.log(prop.path);

        //   prop.component =InventoryReport;
        //   prop.name="Leads";
        //   prop.role=12

        // }

        if (
          logged_user?.role_id == 10 &&
          prop.path === "/dashboard-sales"
        ) {
          prop.component = Detail;
          prop.name = "Profile";
          prop.role = 11;
          prop.param = logged_user?.dealer_id
        }
     
        if (
          (logged_user?.role_id == 3 || Number(logged_user.role_id) === 8 || Number(logged_user.role_id) === 6) &&
          get_company?.[logged_user?.dealer_id]?.all_obj.includes(8) == false && location.pathname.split('/').pop() == 'dashboard-sales'
        ) {
          return <Redirect to={'dashboard-inventory'} />;
        }
        
        else {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const selectedRoutes = (comp) => {
    let ndata = {}
    if (Number(logged_user.role_id) === 3 || Number(logged_user.role_id) === 8 || Number(logged_user.role_id) === 6) {
      routes.map((prop, key) => {
        if (!prop.id || comp?.includes(prop.id)) {
          prop.views?.length && prop.views.map((p) => {
            if (!p.id || comp?.includes(p?.id)) {
              ndata = { ...ndata, [p.name]: false }
            }
            else {
              ndata = { ...ndata, [p.name]: true }
            }
          })
          ndata = { ...ndata, [prop.name]: false }
        }
        else {
          prop.views?.length && prop.views.map((p) => {
            ndata = { ...ndata, [p.name]: true }

            // if(p.id || !comp?.all_obj.includes(p?.id)){
            //   // console.log(p.name=='BlackBook');

            // }
          })

          ndata = { ...ndata, [prop.name]: true }

        }
      })
      // console.log(comp);
      // // data['BlackBook'] = !comp?.inventory_blackbook_inventory;
      // data['Landing Page'] = !comp?.landing_pages;
      // data['Leads'] = !comp?.lead_management;
      // data['Lead Settings'] = !comp?.lead_management;
      // data['Credit Applications'] = !comp?.cradit_application;
      // data['lead_management'] = !comp?.lead_management;
      // data['Vin Decode Full'] = !comp?.vin_decode;
      // data['Vin Decode'] = !comp?.vin_decode;
      // data['CMS'] = !comp?.cms

      // data['Factory Images'] = !comp?.factory_images
      setRouteSelected(ndata)
    }
    return ndata;


  }

  const handleMiniClick = () => {
    var message = "Side menu ";
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
      // message += "fixed...";
    } else {
      setsidebarMini(true);
      // message += "collpased...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      // message: message,
      type: "primary",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    // notificationAlert.current.notificationAlert(options);
  };

  const { pathname } = useLocation();
  const [isNavCollapsed, setNavCollapsed] = useState(false);

  const toggleNavCollapsed = () => {
    setNavCollapsed(!isNavCollapsed);
  };
  useEffect(() => {
    if (isNavCollapsed) setNavCollapsed(!isNavCollapsed);
  }, [pathname]);

  return (
    <div className="wrapper">
      {/* <NotificationAlert ref={notificationAlert} /> */}
      <ToastContainer autoClose={8000} />
      <Sidebar
        {...props}
        routes={routes}
        routeSelected={routeSelected}
        isStaff={logged_user.is_staff}
        userroleid={userroleid}
        bgColor={backgroundColor}
        activeColor={activeColor}
        logged_user={employee}
        company_id={companyId}
        isNavCollapsed={isNavCollapsed}
        toggleNavCollapsed={toggleNavCollapsed}
      />
      <div className="main-panel">
        <AppHeader
          toggleNavCollapsed={toggleNavCollapsed}
          isNavCollapsed={isNavCollapsed}
          user={employee}
        />
        {/* <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          logged_user={employee}
        /> */}

        {/* <PwaAlert /> */}
        <Switch>{getRoutes(routes)}</Switch>

        <Footer fluid />
      </div>
    </div>
  );
}

export default Admin;

import React from "react";

import moment from "moment-timezone";

import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SG_FETCH_LEADSOURCES, SG_DELETE_LEADSOURCE } from "constants/actions";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { Button as MUIButton } from "@mui/material";
import AddNewLeadCampaign from "./AddNewLeadCampaign";

const Detail = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { id, slug } = useParams();
  const [leadSource, setLeadSource] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)]) {
      dispatch({ type: SG_FETCH_LEADSOURCES, payload: id });
    }
  }, [dispatch]);

  const { get_lead_source, get_auth, get_errors } = useSelector(
    (state) => ({
      get_lead_source: state.leadsources[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_lead_source) {
      setLeadSource(get_lead_source);
    }
  }, [get_lead_source]);

  function DeleteCampaign(id) {
    dispatch({ type: SG_DELETE_LEADSOURCE, payload: id });
    history.push("/admin/company-detail/17");
  }
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };
  // console.log(leadSource);
  // console.log(get_lead_source);
  return (
    <>
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Lead Source Details</CardTitle>
                    <h5 className="card-category">Evaluate all Lead Sources</h5>
                  </Col>
                  <Col className="mr-auto" md="2">
                    <MUIButton
                      style={{
                        backgroundColor: "red",
                      }}
                      onClick={() => DeleteCampaign(id)}
                      variant="contained"
                    >
                      DELETE
                    </MUIButton>
                    {/* <Button
                      color="danger"
                      size="sm"
                      onClick={() => DeleteCampaign(id)}
                      tag={Link}
                    >
                      Delete
                    </Button> */}
                  </Col>
                  <Col className="ml-auto" md="2">
                    <MUIButton
                      color="preAuto"
                      onClick={onOpenAddTask}
                      variant="contained"
                    >
                      Edit
                    </MUIButton>
                    {/* <Button
                      color="primary"
                      size="sm"
                      to={`/admin/lead-campaign-create/${id}`}
                      tag={Link}
                    >
                      Edit
                    </Button> */}
                  </Col>
                </Row>

                <FormGroup>
                  <label>Name </label>
                  <p>{leadSource ? leadSource.name : ""}</p>
                </FormGroup>
                <Row>
                  <Col>
                    <label>Status</label>
                    <p>{leadSource ? leadSource.status_text : ""}</p>
                  </Col>
                  <Col>
                    <label>Origin</label>
                    <p>{leadSource ? leadSource.origin_text : ""}</p>
                  </Col>
                  <Col>
                    <label>Start Date </label>
                    {/* start_date */}
                    <p>
                      {leadSource
                        ? moment(leadSource.start_date).format("YYYY-MM-DD")
                        : ""}
                    </p>
                  </Col>
                  <Col>
                    <label>End Date</label>

                    <p>
                      {leadSource
                        ? moment(leadSource.end_date).format("YYYY-MM-DD")
                        : ""}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Source ID or Code for Online Leads </label>
                      <p>{leadSource ? leadSource.source_id : ""}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Cost (Assumption)</label>
                      <p>{leadSource ? leadSource.cost : ""}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Revenue (Expected)</label>
                      <p>{leadSource ? leadSource.revenue : ""}</p>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Description</label>
                    <p>{leadSource ? leadSource.description : ""}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <AddNewLeadCampaign
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default Detail;

import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";

import ListAdmin from "TradeIns/ListAdmin";
import CreateEdit from "./CreateEdit";
import VinCreate from "./VinCreate";
import { Box, Button as MUIButton } from "@mui/material";
import "./style.css";
import AddNewLead from "./AddNewLead";
const AdminList = (props) => {
  const [showTradeIn, setShowTradeIn] = React.useState(0);
  const [updated, setUpdated] = React.useState(false);
  const [colWidth, setColWidth] = React.useState([12, 0]);
  const [updatePage, setUpdatePage] = React.useState(false);
  const SelectedTrade = (id = 0) => {
    setShowTradeIn(id);
    if (colWidth[0] === 6) {
      setColWidth([12, 0]);
    } else {
      setColWidth([0, 12]);
      onOpenAddTask();
    }
  };

  const resetColWidth = (updated) => {
    setUpdated(updated);
    setColWidth([12, 0]);
    setShowTradeIn(false);
    onCloseAddTask();
  };

  function ShowDetails(id) {
    setShowTradeIn(id);
  }

  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  // <Col className={colWidth[1] > 0 ? "d-block" : "d-none"}>
  //  console.log(colWidth)
  //  console.log(showTradeIn)

  const [label, setLabel] = useState("");

  return (
    <>
      <div className="content">
        <Row>
          <Col className={colWidth[0] > 0 ? "d-block" : "d-none"}>
            <Card className="card-tasks">
              <CardBody
                style={{
                  padding: 10,
                }}
              >
                <Row
                  style={
                    {
                      // alignItems: "center",
                    }
                  }
                >
                  <Col>
                    <CardHeader
                      style={{
                        padding: 0,
                      }}
                    >
                      <CardTitle tag="h4">Blackbook Value</CardTitle>
                      <h5 className="card-category">Get Black book value</h5>
                    </CardHeader>
                  </Col>
                  <Col
                    className="ml-auto"
                    md="2"
                    style={{
                      // textAlign: "end",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <MUIButton
                      color="preAuto"
                      // to={`/admin/employee-create`}
                      // tag={Link}
                      onClick={() => SelectedTrade(0)}
                      variant="outlined"
                      size="sm"
                    >
                      Add New
                    </MUIButton>
                  </Col>
                </Row>

                <ListAdmin
                  setColWidth={setColWidth}
                  setShowTradeIn={setShowTradeIn}
                  updatePage={updatePage}
                />
              </CardBody>
              <CardFooter>
                <hr />
                {/* <div className="stats">
                    <i className="fa fa-refresh spin" />
                    Updated 3 minutes ago
                </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col
            className={colWidth[1] > 0 && !isAddTaskOpen ? "d-block" : "d-none"}
          >
            <VinCreate
              setUpdatePage={setUpdatePage}
              resetColWidth={resetColWidth}
              tradeIn={showTradeIn}
              ShowDetails={ShowDetails}
              label={label}
              setLabel={setLabel}
            />
          </Col>
        </Row>
      </div>
      <Box className={colWidth[1] > 0 ? "d-block" : "d-none"}>
        <AddNewLead
          isAddTaskOpen={isAddTaskOpen}
          onCloseAddTask={onCloseAddTask}
          setAddTaskOpen={setAddTaskOpen}
          setUpdatePage={setUpdatePage}
          resetColWidth={resetColWidth}
          tradeIn={showTradeIn}
          ShowDetails={ShowDetails}
          label={label}
          setLabel={setLabel}
        />
      </Box>
    </>
  );
};

export default AdminList;

import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import moment from 'moment'
import { useForm, Controller } from "react-hook-form";
import {
  SG_UPDATE_ACTIVE_PAGE,
  LOAD_WEB_LEAD,
  LOAD_LEADTYPES,
  LOAD_LEAD_STAGE,
  LOAD_LEADSOURCES,
  LOAD_ALL_EMPLOYEES,
} from "constants/actions";

import Select from "react-select";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactDatetime from "react-datetime";

const Search = (props) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [pageParams, setPageParams] = React.useState(props.params);

  const [selInventory, setSelInventory] = React.useState([]);
  const [selLeadSource, setSelLeadSource] = React.useState([]);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);
  const [selEmployee, setSelEmployee] = React.useState([]);

  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams)

    dispatch({ type: LOAD_LEADSOURCES });
    dispatch({ type: LOAD_LEADTYPES });
    dispatch({ type: LOAD_LEAD_STAGE });
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();
  const {
    get_auth,
    get_errors,
    get_employees,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      get_employees: state.employees.employees,
      lead_sources: state.leadsources.leadsources,
      lead_stage: state.leadstage.leadstages,
      lead_type: state.leadtypes.leadtypes,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelLeadType(lead_type);
  }, [lead_type]);

  React.useEffect(() => {
    setSelLeadStage(lead_stage);
  }, [lead_stage]);

  React.useEffect(() => {
    setSelLeadSource(lead_sources);
  }, [lead_sources]);
  React.useEffect(() => {
    setSelEmployee(get_employees);
  }, [get_employees]);

  // React.useEffect(() => {
  //   if (id) {
  //     if (get_web_leads[parseInt(id)]) {
  //       setValue("expected_purchase_date", moment(get_web_leads[parseInt(id)].expected_purchase_date));
  //       setValue("web_lead_stage", get_web_leads[parseInt(id)].web_lead_stage);
  //       setValue("web_lead_type", get_web_leads[parseInt(id)].web_lead_type);
  //       setValue("web_lead_source", get_web_leads[parseInt(id)].web_lead_source);
  //       setValue("inventory", get_web_leads[parseInt(id)].inventory);
  //       setValue("employee", get_web_leads[parseInt(id)].employee);

  //     }
  //   } else {
  //     setValue("expected_purchase_date", moment());
  //     setValue("employee", get_auth.employee);
  //   }
  // }, [get_web_leads]);

  const onSubmit = (data) => {

    let param_string = ''
    param_string += data.web_lead_source? `&web_lead_source=${data.web_lead_source.id}` : ''
    param_string += data.web_lead_type? `&web_lead_type=${data.web_lead_type.id}` : ''
    param_string += data.web_lead_stage? `&web_lead_stage=${data.web_lead_stage.id}` : ''
    param_string += data.employee? `&employee=${data.employee.id}` : ''
    param_string += data.startdate? `&startdate=${moment(data.startdate).format('YYYY-MM-DD')}` : ''
    param_string += data.enddate? `&enddate=${moment(data.enddate).format('YYYY-MM-DD')}` : ''
   
    props.setPageParams(param_string)
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "lead", page: 0 },
    });
    dispatch({
      type: LOAD_WEB_LEAD,
      payload: {
        page: 1,
        params: param_string
        
      },
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>

        <Col>
          <label>From Date</label>
                       
                          <Controller
                            as={ReactDatetime}
                            control={control}
                            name="startdate"
                            dateFormat="YYYY-MM-DD"
                            closeOnSelect={ true }
                            inputProps={{
                              className: "form-control",
                              placeholder: "Select Date",
                            }}
                            timeFormat={false}
                          />
          </Col>

          <Col>
          <label>To Date</label>
                       
                          <Controller
                            as={ReactDatetime}
                            control={control}
                            name="enddate"
                            closeOnSelect={ true }
                            dateFormat="YYYY-MM-DD"
                            inputProps={{
                              className: "form-control",
                              placeholder: "Select Date",
                            }}
                            timeFormat={false}
                          />
          </Col>

                    <Col>
            <label>Lead Source</label>
            <Controller
              as={<Select />}
              name="web_lead_source"
              placeholder="Select All"
              options={selLeadSource}
              isClearable
              getOptionLabel={(option) =>
                `${option.origin_text} - ${option.name}`
              }
              getOptionValue={(option) => `${option.id}`}
              className="text-dark"
              control={control}
            />{" "}
          </Col>
        </Row>
        <Row className="mb-5 mt-2">
          

          <Col>
            <label>Lead Type</label>
            <Controller
              as={<Select />}
              name="web_lead_type"
              placeholder="Select All"
              options={selLeadType}
              isClearable
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              className="text-dark"
              control={control}
            />{" "}
          </Col>
          <Col>
            <label>Lead Stage</label>
            <Controller
              as={<Select />}
              name="web_lead_stage"
              placeholder="Select All"
              options={selLeadStage}
              isClearable
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              className="text-dark"
              control={control}
            />{" "}
          </Col>
          <Col>
            <label>Assign To</label>
            <Controller
              as={<Select />}
              name="employee"
              placeholder="Select All"
              options={selEmployee}
              isClearable
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name} - ${option.role_text} (${option.status_text})`
              }
              getOptionValue={(option) => `${option.id}`}
              className="text-dark"
              control={control}
            />{" "}
          </Col>
          <Col>
            <Button className="btn-fill mt-4" color="primary" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Search;

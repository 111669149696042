import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const StyledSimpleBarReact = styled(SimpleBarReact)(() => ({
  height: "100%",
  width: "100%",
  scroll,
}));

const AppScrollbar = (props) => {
  const { children, ...others } = props;

  return (
    <StyledSimpleBarReact
      {...others}
      style={{
        overflowX: "hidden",
        maxHeight: "100%",
      }}
    >
      {children}
    </StyledSimpleBarReact>
  );
};

export default AppScrollbar;

AppScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

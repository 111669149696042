import { call, put, all, takeLatest } from 'redux-saga/effects';


import {
    GET_VIN_UPLOAD_PENDING, 
    NEW_VIN_UPLOAD_CREATING,
    FETCH_VIN_UPLOAD_PENDING,
    GET_VIN_UPLOAD, LOAD_VIN_UPLOAD, 
 
    FETCH_VIN_UPLOAD, SG_FETCH_VIN_UPLOAD,
    ADD_VIN_UPLOAD, SG_ADD_VIN_UPLOAD,
    EDIT_VIN_UPLOAD, SG_EDIT_VIN_UPLOAD, 
    DELETE_VIN_UPLOAD, 
    UPDATE_VIN_UPLOAD, SG_UPDATE_VIN_UPLOAD, 
    GET_VALID_VIN_UPLOAD_LIST,
    LOAD_VALID_VIN_UPLOAD_LIST,
    LOAD_BLACKBOOK_VALUES,
    LOAD_BLACKBOOK_PENDING,
    GET_BLACKBOOK_VALUES,
    CLEAR_ALL, ALL_ERRORS
  } from "constants/actions";

  import * as api from 'constants/api';
  // 


  function* listVindecoder(action) {
    yield put({type: GET_VIN_UPLOAD_PENDING});
  
    try {
    const json = yield call(api.GET_ALL_VINDECODER_ROWS, action.payload)
    // console.log(json.data)
    yield put({ type: GET_VIN_UPLOAD, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* vindecoderListRequest() {
   yield takeLatest(LOAD_VIN_UPLOAD, listVindecoder);
  }

  function* listValidVindecoder(action) {
    yield put({type: GET_VIN_UPLOAD_PENDING});
    // console.log("Vinupload Id",action)
    try {
    const json = yield call(api.GET_VALID_VINDECODER_ROWS, action.payload)
    // console.log(json.data)
    yield put({ type: GET_VALID_VIN_UPLOAD_LIST, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* vindecoderValidListRequest() {
   yield takeLatest(LOAD_VALID_VIN_UPLOAD_LIST, listValidVindecoder);
  }

  function* updateVindecoder(action) {
    yield put({type: FETCH_VIN_UPLOAD_PENDING});
    // console.log("Vinupload Id",action)
    try {
    const json = yield call(api.UPDATE_MULTI_VIN_RESPONSE, action.payload)
    // console.log(json.data)
    yield put({ type: UPDATE_VIN_UPLOAD, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* vindecoderUpdateRequest() {
   yield takeLatest(SG_UPDATE_VIN_UPLOAD, updateVindecoder);
  }

  //Add
  function* addVindecoder(action) {
    yield put({type: NEW_VIN_UPLOAD_CREATING});
    try {
    const json = yield call(api.ADD_VIN_UPLOAD, action.payload)
    // console.log(json.data)
    yield put({ type:ADD_VIN_UPLOAD, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* vindecoderAdd() {
   yield takeLatest(SG_ADD_VIN_UPLOAD, addVindecoder);
  }

  // Edit
  function* editVindecoder(action) {
    // console.log("Vinupload Edit",action.payload)
    try {
    const json = yield call(api.EDIT_VIN_UPLOAD, action.payload.id, action.payload)
    // console.log(json.data)
    yield put({ type:EDIT_VIN_UPLOAD, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* vindecoderEdit() {
   yield takeLatest(SG_EDIT_VIN_UPLOAD, editVindecoder);
  }

  // Fetch One
  function* fetchVindecoder(action) {
    // console.log("Vinupload Fetch",action.payload)
    try {
    const json = yield call(api.FETCH_VIN_UPLOAD, action.payload)
    // console.log(json.data)
    yield put({ type:FETCH_VIN_UPLOAD, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* vindecoderFetch() {
    yield takeLatest(SG_FETCH_VIN_UPLOAD, fetchVindecoder);
   }
   


  function* listBlackbook(action) {

    yield put({type: LOAD_BLACKBOOK_PENDING});
  
    try {
    const json = yield call(api.GET_BLACKBOOK_VALUES, action.payload)
    // console.log(json.data)
    yield put({ type: GET_BLACKBOOK_VALUES, payload: json.data });
    }
    catch (e) {
    yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* blackbkookRequest() {
   yield takeLatest(LOAD_BLACKBOOK_VALUES, listBlackbook);
  }
export default function* index() {
    yield all([ vindecoderUpdateRequest(), 
      vindecoderListRequest(),
      vindecoderValidListRequest(),
      vindecoderAdd(),
      vindecoderEdit(),
      vindecoderFetch(),
      blackbkookRequest()]);
  }

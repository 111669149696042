import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Fonts } from "../../../../../crema/constants/src/AppEnums";
import IntlMessages from "../../../../../crema/helpers/src/IntlMessages";
import Member from "./Member";
import PropTypes from "prop-types";
import AppGridContainer from "../../../../../crema/AppGridContainer";

const ProfileConnection = ({ profileConnection }) => {
  console.log({ profileConnection });
  return (
    <Box sx={{ position: "relative" }}>
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 1.3, lg: 2.5 },
        }}
      >
        <IntlMessages id="Team Members" />
      </Typography>
      <AppGridContainer spacing={2}>
        {profileConnection.map((member, index) => (
          <Grid item xs={12} sm={12} md={4} lg={6} xl={3} key={index}>
            <Member member={member} />
          </Grid>
        ))}
      </AppGridContainer>
    </Box>
  );
};

export default ProfileConnection;

ProfileConnection.propTypes = {
  profileConnection: PropTypes.array,
};

import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const AddDeduct_List = ({ blackbook, category }) => {
  const [bbadddeduct, setBbadddeduct] = React.useState([]);
  React.useEffect(() => {
    setBbadddeduct(blackbook);
  }, [blackbook]);

  // console.log(blackbook)
  // 'xclean_km_threshold'
  // , 'clean_km_threshold',
  //  'avg_km_threshold',
  //  'rough_km_threshold',

  return (
    <>
      <Table striped bordered>
        <tbody>
          {bbadddeduct? bbadddeduct.map((p, k) => {
            return (
              <tr key={k}>
                <td className="table-head-content" colSpan="2">
                  <h6>{p.name} </h6>
                </td>

                <td className="text-right">
                  {category === "avg_km_threshold" ? p.avg : ""}
                  {category === "rough_km_threshold" ? p.rough : ""}
                  {category === "clean_km_threshold" ? p.clean : ""}
                  {category === "xclean_km_threshold" ? p.xclean : ""}
                  {/* {p.rough}
                  {p.xclean}
                  {p.clean} */}
                </td>
              </tr>
            );
          }): null}
        </tbody>
      </Table>
    </>
  );
};

const AddDeduct = ({ blackbook }) => {
  const [bbadddeduct, setBbadddeduct] = React.useState([]);
  React.useEffect(() => {
    setBbadddeduct(blackbook);
  }, [blackbook]);

  // console.log(blackbook)

  return (
    <>
      {bbadddeduct? bbadddeduct.map((p, k) => {
        return (
          <Table striped bordered key={k}>
            <thead>
              <tr>
                <th colSpan="2"></th>
                <th className="table-head-content text-right">avg</th>
                <th className="table-head-content text-right">xclean</th>
                <th className="table-head-content text-right">clean</th>
                <th className="table-head-content text-right">rough</th>
                <th className="table-head-content text-right">auto</th>
                <th className="table-head-content text-right">uoc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-head-content" colSpan="2">
                  <h6>{p.name} </h6>
                </td>
                <td className="text-right">{p.avg}</td>
                <td className="text-right">{p.xclean}</td>
                <td className="text-right">{p.clean}</td>
                <td className="text-right">{p.rough}</td>
                <td className="text-right">{p.auto}</td>
                <td className="text-right">{p.uoc}</td>
              </tr>
              <tr>
                <td className="table-head-content">Resid 12k</td>
                <td className="table-head-content">Resid 24k</td>
                <td className="table-head-content">Resid 30k</td>
                <td className="table-head-content">Resid 36k</td>
                <td className="table-head-content">Resid 42k</td>
                <td className="table-head-content">Resid 48k</td>
                <td className="table-head-content">Resid 60k</td>
                <td className="table-head-content">Resid 72k</td>
              </tr>
              <tr>
                <td className="text-right">{p.resid12}</td>
                <td className="text-right">{p.resid24}</td>
                <td className="text-right">{p.resid30}</td>
                <td className="text-right">{p.resid36}</td>
                <td className="text-right">{p.resid42}</td>
                <td className="text-right">{p.resid48}</td>
                <td className="text-right">{p.resid60}</td>
                <td className="text-right">{p.resid72}</td>
              </tr>
            </tbody>
          </Table>
        );
      }): ''}
    </>
  );
};
const Kilometers = ({ blackbook }) => {
  const [bbkm, setBbkm] = React.useState([]);
  React.useEffect(() => {
    setBbkm(blackbook);
  }, [blackbook]);

  return (

    <Table bordered>
      <thead>
        <tr>
          <td className="table-head-content text-right">Xclean KM</td>
          <td className="table-head-content text-right">Clean KM</td>
          <td className="table-head-content text-right">Average KM</td>
          <td className="table-head-content text-right">Rough KM</td>
          <td className="table-head-content text-right">Cents Per KM</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-right">{bbkm? bbkm.xclean_km_threshold: ''}</td>
          <td className="text-right">{bbkm? bbkm.clean_km_threshold: ''}</td>
          <td className="text-right">{bbkm? bbkm.avg_km_threshold: ''}</td>
          <td className="text-right">{bbkm? bbkm.rough_km_threshold: ''}</td>
          <td className="text-right">{bbkm? bbkm.cents_per_kilometer: ''}</td>
        </tr>
      </tbody>
    </Table>
    
    
  );
};

export const BlackBookDetailedLog = ({ blackbook, category }) => {
  const [blackbookInfo, setBlackbookInfo] = React.useState(true);

  return (
    <>
      <div className="content">
        <div className="d-block d-flex justify-content-end">
          
        </div>

        <div className={blackbookInfo ? "" : "d-none"}>
        {blackbook?
        <AddDeduct_List
         blackbook={blackbook.add_deduct_list}
         category={category}
       />
      : ''}

     {blackbook? 
<>
<h6>Customer Value</h6>
<Table>
  <tbody>
    <tr>
      <td className="table-head-content text-right">High</td>
      <td className="text-right">
        {blackbook.canadian_tradein_high? 
        blackbook.canadian_tradein_high: 'Not found'}
        </td>
      <td className="table-head-content text-right">Low</td>
      <td className="text-right">
        {blackbook.canadian_tradein_low?
        blackbook.canadian_tradein_low: 'Not found'}
        </td>
      <td className="table-head-content text-right">Best</td>
      <td className="text-right">
        {blackbook.canadian_tradein_value?
        blackbook.canadian_tradein_value : 'Not found'}
      </td>
    </tr>

  </tbody>
</Table>

</>

: ''}

{blackbook? 

          <Table striped>
            <tbody>
              <tr>
                <td className="table-head-content">Risk Score</td>
                <td className="text-right">{blackbook.risk_score}</td>
                <td className="table-head-content">MSRP</td>
                <td className="text-right">{blackbook.msrp}</td>
                <td className="table-head-content">Retail Equipped</td>
                <td className="text-right">{blackbook.retail_equipped}</td>
                <td className="table-head-content">Price Includes</td>
                <td className="text-right">{blackbook.price_includes}</td>
              </tr>
            </tbody>
          </Table>
          : ''}



          {blackbook?
          <Kilometers blackbook={blackbook.kilometer_adjustments} />
          : ''}
          {blackbook?
          <Table striped bordered>
            <thead>
              <tr>
                <th className="table-head-content"></th>
                <th className="table-head-content">Base</th>
                <th className="table-head-content">Mileage</th>
                <th className="table-head-content">Add/Deduct</th>
                <th className="table-head-content">Regional</th>
                <th className="table-head-content">Adjusted</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-head-content">X-clean</td>
                <td className="text-right">{blackbook.base_whole_xclean}</td>
                <td className="text-right">{blackbook.mileage_whole_xclean}</td>
                <td className="text-right">
                  {blackbook.add_deduct_whole_xclean}
                </td>
                <td className="text-right">
                  {blackbook.regional_whole_xclean}
                </td>
                <td className="text-right">
                  {blackbook.adjusted_whole_xclean}
                </td>
              </tr>
              <tr>
                <td className="table-head-content">Clean</td>
                <td className="text-right">{blackbook.base_whole_clean}</td>
                <td className="text-right">{blackbook.mileage_whole_clean}</td>
                <td className="text-right">
                  {blackbook.add_deduct_whole_clean}
                </td>
                <td className="text-right">{blackbook.regional_whole_clean}</td>
                <td className="text-right">{blackbook.adjusted_whole_clean}</td>
              </tr>
              <tr>
                <td className="table-head-content">Average</td>
                <td className="text-right">{blackbook.base_whole_avg}</td>
                <td className="text-right">{blackbook.mileage_whole_avg}</td>
                <td className="text-right">{blackbook.add_deduct_whole_avg}</td>
                <td className="text-right">{blackbook.regional_whole_avg}</td>
                <td className="text-right">{blackbook.adjusted_whole_avg}</td>
              </tr>
              <tr>
                <td className="table-head-content">Rough</td>
                <td className="text-right">{blackbook.base_whole_rough}</td>
                <td className="text-right">{blackbook.mileage_whole_rough}</td>
                <td className="text-right">
                  {blackbook.add_deduct_whole_rough}
                </td>
                <td className="text-right">{blackbook.regional_whole_rough}</td>
                <td className="text-right">{blackbook.adjusted_whole_rough}</td>
              </tr>
              <tr>
                <td className="table-head-content text-center" colSpan="6">
                  Trade-In
                </td>
              </tr>
              <tr>
                <td className="table-head-content">Clean</td>
                <td className="text-right">{blackbook.base_tradein_clean}</td>
                <td className="text-right">
                  {blackbook.mileage_tradein_clean}
                </td>
                <td className="text-right">
                  {blackbook.add_deduct_tradein_clean}
                </td>
                <td className="text-right">
                  {blackbook.regional_tradein_clean}
                </td>
                <td className="text-right">
                  {blackbook.adjusted_tradein_clean}
                </td>
              </tr>
              <tr>
                <td className="table-head-content">Average</td>
                <td className="text-right">{blackbook.base_tradein_avg}</td>
                <td className="text-right">{blackbook.mileage_tradein_avg}</td>
                <td className="text-right">
                  {blackbook.add_deduct_tradein_avg}
                </td>
                <td className="text-right">{blackbook.regional_tradein_avg}</td>
                <td className="text-right">{blackbook.adjusted_tradein_avg}</td>
              </tr>
              <tr>
                <td className="table-head-content">Rough</td>
                <td className="text-right">{blackbook.base_tradein_rough}</td>
                <td className="text-right">
                  {blackbook.mileage_tradein_rough}
                </td>
                <td className="text-right">
                  {blackbook.add_deduct_tradein_rough}
                </td>
                <td className="text-right">
                  {blackbook.regional_tradein_rough}
                </td>
                <td className="text-right">
                  {blackbook.adjusted_tradein_rough}
                </td>
              </tr>
            </tbody>
          </Table>
: ''}
       {blackbook?  
        <AddDeduct blackbook={blackbook.add_deduct_list} /> 
        : ''}

          <h6>Specifications</h6>
          {blackbook?  
          <Table striped bordered>
            <tbody>
              <tr>
                <td className="table-head-content">Wheel base</td>
                <td className="text-right">{blackbook.wheel_base}</td>
                <td className="table-head-content">Tire size</td>
                <td className="text-right">{blackbook.tire_size}</td>
                <td className="table-head-content">gvw</td>
                <td className="text-right">{blackbook.gvw}</td>
                <td className="table-head-content">ext doors</td>
                <td className="text-right">{blackbook.ext_doors}</td>
              </tr>
              <tr>
                <td className="table-head-content">payload cap</td>
                <td className="text-right">{blackbook.payload_cap}</td>
                <td className="table-head-content">airbags</td>
                <td className="text-right">{blackbook.airbags}</td>
                <td className="table-head-content">engine displacement</td>
                <td className="text-right">{blackbook.engine_displacement}</td>
                <td className="table-head-content">num gears</td>
                <td className="text-right">{blackbook.num_gears}</td>
              </tr>
              <tr>
                <td className="table-head-content">seat cap</td>
                <td className="text-right">{blackbook.seat_cap}</td>
                <td className="table-head-content">fuel type</td>
                <td className="text-right">{blackbook.fuel_type}</td>
                <td className="table-head-content">fuel cap</td>
                <td className="text-right">{blackbook.fuel_cap}</td>
                <td className="table-head-content">fuel delivery</td>
                <td className="text-right">{blackbook.fuel_delivery}</td>
              </tr>
              <tr>
                <td className="table-head-content">hwy mpg</td>
                <td className="text-right">{blackbook.hwy_mpg}</td>
                <td className="table-head-content">city mpg</td>
                <td className="text-right">{blackbook.city_mpg}</td>
                <td className="table-head-content">engine description</td>
                <td className="text-right">{blackbook.engine_description}</td>
                <td className="table-head-content">cylinders</td>
                <td className="text-right">{blackbook.cylinders}</td>
              </tr>
              <tr>
                <td className="table-head-content">base hp</td>
                <td className="text-right">{blackbook.base_hp}</td>
                <td className="table-head-content">torque</td>
                <td className="text-right">{blackbook.torque}</td>
                <td className="table-head-content">transmission</td>
                <td className="text-right">{blackbook.transmission}</td>
                <td className="table-head-content">drivetrain</td>
                <td className="text-right">{blackbook.drivetrain}</td>
              </tr>
              <tr>
                <td className="table-head-content text-center" colSpan="8">
                  Warranty
                </td>
              </tr>
              <tr>
                <td className="table-head-content">anti corrosion</td>
                <td className="text-right">
                  {blackbook.anti_corrosion_warranty}
                </td>
                <td className="table-head-content">basic</td>
                <td className="text-right">{blackbook.basic_warranty}</td>
                <td className="table-head-content">powertrain</td>
                <td className="text-right">{blackbook.powertrain_warranty}</td>
                <td className="table-head-content">road assist</td>
                <td className="text-right">{blackbook.road_assist_warranty}</td>
              </tr>
            </tbody>
          </Table>
            : ''}
        </div>
      </div>
    </>
  );
};
// import "./style.css"
export const BlackBookDetails = ({ blackbook, category }) => {
  const [blackbookInfo, setBlackbookInfo] = React.useState(false);

    // 'xclean_km_threshold'
  // , 'clean_km_threshold',
  //  'avg_km_threshold',
  //  'rough_km_threshold',

  return (
    <>
      <div className="content">
        {blackbook?
        <AddDeduct_List
         blackbook={blackbook.add_deduct_list}
         category={category}
       />
      : ''}
         
       
            

        <Table striped bordered>
            <thead>
              <tr>
                <th className="table-head-content"></th>
                <th className="table-head-content">Base</th>
                <th className="table-head-content">Mileage</th>
                <th className="table-head-content">Add/Deduct</th>
                <th className="table-head-content">Regional</th>
                <th className="table-head-content">Adjusted</th>
              </tr>
            </thead>
            <tbody>
            {category === "xclean_km_threshold" ?
              <tr>
                <td className="table-head-content">X-clean</td>
                <td className="text-right">{blackbook.base_whole_xclean}</td>
                <td className="text-right">{blackbook.mileage_whole_xclean}</td>
                <td className="text-right">
                  {blackbook.add_deduct_whole_xclean}
                </td>
                <td className="text-right">
                  {blackbook.regional_whole_xclean}
                </td>
                <td className="text-right">
                  {blackbook.adjusted_whole_xclean}
                </td>
              </tr>
              : null}
              
              {category === "clean_km_threshold" ?
              <tr>
                <td className="table-head-content">Clean</td>
                <td className="text-right">{blackbook.base_whole_clean}</td>
                <td className="text-right">{blackbook.mileage_whole_clean}</td>
                <td className="text-right">
                  {blackbook.add_deduct_whole_clean}
                </td>
                <td className="text-right">{blackbook.regional_whole_clean}</td>
                <td className="text-right">{blackbook.adjusted_whole_clean}</td>
              </tr>
              : null}
              
              {category === "avg_km_threshold" ?
              <tr>
                <td className="table-head-content">Average</td>
                <td className="text-right">{blackbook.base_whole_avg}</td>
                <td className="text-right">{blackbook.mileage_whole_avg}</td>
                <td className="text-right">{blackbook.add_deduct_whole_avg}</td>
                <td className="text-right">{blackbook.regional_whole_avg}</td>
                <td className="text-right">{blackbook.adjusted_whole_avg}</td>
              </tr>
              : null}
              
              {category === "rough_km_threshold" ?
              <tr>
                <td className="table-head-content">Rough</td>
                <td className="text-right">{blackbook.base_whole_rough}</td>
                <td className="text-right">{blackbook.mileage_whole_rough}</td>
                <td className="text-right">
                  {blackbook.add_deduct_whole_rough}
                </td>
                <td className="text-right">{blackbook.regional_whole_rough}</td>
                <td className="text-right">{blackbook.adjusted_whole_rough}</td>
              </tr>
              : null}
              
             
              <tr>
                <td className="table-head-content text-center" colSpan="6">
                  Trade-In
                </td>
              </tr>
              {category === "clean_km_threshold" ?
              <tr>
                <td className="table-head-content">Clean</td>
                <td className="text-right">{blackbook.base_tradein_clean}</td>
                <td className="text-right">
                  {blackbook.mileage_tradein_clean}
                </td>
                <td className="text-right">
                  {blackbook.add_deduct_tradein_clean}
                </td>
                <td className="text-right">
                  {blackbook.regional_tradein_clean}
                </td>
                <td className="text-right">
                  {blackbook.adjusted_tradein_clean}
                </td>
              </tr>
              : null}
              
              {category === "avg_km_threshold" ?
              <tr>
                <td className="table-head-content">Average</td>
                <td className="text-right">{blackbook.base_tradein_avg}</td>
                <td className="text-right">{blackbook.mileage_tradein_avg}</td>
                <td className="text-right">
                  {blackbook.add_deduct_tradein_avg}
                </td>
                <td className="text-right">{blackbook.regional_tradein_avg}</td>
                <td className="text-right">{blackbook.adjusted_tradein_avg}</td>
              </tr>
              : null}
              
              {category === "rough_km_threshold" ?
              <tr>
                <td className="table-head-content">Rough</td>
                <td className="text-right">{blackbook.base_tradein_rough}</td>
                <td className="text-right">
                  {blackbook.mileage_tradein_rough}
                </td>
                <td className="text-right">
                  {blackbook.add_deduct_tradein_rough}
                </td>
                <td className="text-right">
                  {blackbook.regional_tradein_rough}
                </td>
                <td className="text-right">
                  {blackbook.adjusted_tradein_rough}
                </td>
              </tr>
              : null}
              

            </tbody>
          </Table>
          <div className="d-block d-flex justify-content-between">
          <h6>View Log</h6>
          <Button
            id="BasePopover"
            className="btn"
            color="info"
            size="sm"
            onClick={() => setBlackbookInfo(!blackbookInfo)}
          >
            {blackbookInfo ? "Hide Log" : "View Log"}
          </Button>
        </div>
        <div className={blackbookInfo ? "" : "d-none"}>
     

{/*        
                  {category === "rough_km_threshold" ? p.rough : ""}
                  {category === "clean_km_threshold" ? p.clean : ""}
                  {category === "xclean_km_threshold" ? p.xclean : ""} */}
          
          
         

          
        
        <BlackBookDetailedLog blackbook={blackbook}
            category={category} />
        </div>
      </div>
    </>
  );
};

export default BlackBookDetails;

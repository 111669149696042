import { EditorInLineConvertToHTML } from "Inventory/InventoryDetail/EditorConvertToHTML";
import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import {
  CASH_FOR_CARS_CMS,
  FETCH_CASH_FOR_CARS_CMS_VALUE,
  LOAD_GET_CASHFORCARS,
  UPDATE_CASH_FOR_CARS,
  CMS_PRIVACY_POLICY_TYPE,
  LOAD_CMS_PRIVACY_TYPE,
  UPDATE_CMS_PRIVACY_PAGE_TYPE,
} from "constants/actions";
export default function PrivacyPolicyCMS() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [dealerId, setDealerId] = React.useState();
  const { id, slug } = useParams();
  const [editSubject, setEditSubject] = React.useState("");
  const notify = () => toast.success(`Data Updated successfully`);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_CMS_PRIVACY_TYPE, payload: id });
  }, [dispatch, id]);

  const { get_auth, get_privacy } = useSelector(
    (state) => ({
     get_privacy: state.privacy.privacy,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_privacy) {
      console.log("in if");
      setEditSubject(get_privacy["results"]?.[0]?.subjectContent);
    } 
  }, [get_privacy]);


  const BtnCallBackPrivacyPage = (data) => {
    dispatch({
      type: UPDATE_CMS_PRIVACY_PAGE_TYPE,
      payload: {
        id: get_privacy["results"]?.[0]?.id,
        subjectContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };

  // const onSubmit = (data) => {
  //   dispatch({
  //     type: CMS_PRIVACY_POLICY_TYPE,
  //     payload: {
  //       subjectContent: data.subjectContent,
  //       company: dealerId ? dealerId : get_auth.dealer_id,
  //     },
  //   });
  //   notify()
  // };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardHeader>
                    <CardTitle tag="h4">Privacy Policy CMS</CardTitle>
                    {/* <h5 className="card-category">My Tasks</h5> */}
                  </CardHeader>
                </Col>  
               
              </Row>
              {/* Create Api for future use */}
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Subject And Content</label>
                  <Input
                    type="text"
                    name="subjectContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form> */}

              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Subject & Content "
                    editContent={editSubject}
                     BtnCallBack={BtnCallBackPrivacyPage}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

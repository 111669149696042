import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { LOAD_ALL_EMPLOYEES } from "constants/actions";

import { Box, Tab, Tabs } from "@mui/material";
import IntlMessages from "../crema/helpers/src/IntlMessages";
import { BiUser } from "react-icons/bi";
import { AiOutlineLock } from "react-icons/ai";
import { IoShareSocialOutline } from "react-icons/io5";
import { Fonts } from "../crema/constants/src/AppEnums";
import {
  AccountTabsWrapper,
  ChangePassword,
  PersonalInfo,
  Social,
} from "./account/MyProfile";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabs = [
  { id: 1, icon: <BiUser />, name: <IntlMessages id="Personal Info" /> },
  {
    id: 2,
    icon: <AiOutlineLock />,
    name: <IntlMessages id="Change Password" />,
  },
  {
    id: 3,
    icon: <IoShareSocialOutline />,
    name: <IntlMessages id="Team Members" />,
  },
];

function UserProfile() {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState(null);
  const [dealer, setDealer] = React.useState([]);
  const [employees, SetEmployees] = React.useState([]);
  const [value, setValue] = React.useState(0);

  const onTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const { logged_user } = useSelector(
    (state) => ({
      logged_user: state.auth,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const { all_employees, employee, this_dealer } = useSelector(
    (state) => ({
      employee: state.auth.employee,
      this_dealer: state.auth.dealer,
      all_employees: state.employees.employees,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (employee) {
      if (Object.keys(employee).length > 0) {
        setUser(employee);
      }
    }
    if (all_employees) {
      SetEmployees(all_employees);
    }
  }, [employee, all_employees]);

  return (
    <>
      <div
        className="content"
        style={{
          marginLeft: 30,
        }}
      >
        <Box
          component="h2"
          variant="h2"
          sx={{
            fontSize: 16,
            color: "text.primary",
            fontWeight: Fonts.SEMI_BOLD,
            mb: {
              xs: 2,
              lg: 4,
            },
          }}
        >
          My Account
        </Box>

        <AccountTabsWrapper key="2">
          <Tabs
            className="account-tabs"
            value={value}
            onChange={onTabsChange}
            aria-label="basic tabs example"
            orientation="vertical"
          >
            {tabs.map((tab, index) => (
              <Tab
                className="account-tab"
                label={tab.name}
                icon={tab.icon}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          <Box className="account-tabs-content">
            {value === 0 && <PersonalInfo user={user} />}
            {value === 1 && <ChangePassword />}
            {value === 2 && <Social social={employees} />}
          </Box>
        </AccountTabsWrapper>
      </div>
    </>
  );
}

export default UserProfile;

{
  /* <div className="content">

<Row>
  <Col md="4">
    <Card className="card-user">
      
      <CardBody>
        <div className="author">
        <Avatar name={`${user.first_name} ${user.last_name}`} />
            
            <h5 className="title">{user.first_name} {user.last_name}</h5>
          
          <p className="description">{user.email}</p>
          <p className="description">{user.designation} {user.role_id}</p>
        </div>
        <p className="description text-center">
          {user.notes}
        </p>
      </CardBody>

    </Card>
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Team Members</CardTitle>
      </CardHeader>
      <CardBody>
        <ul className="list-unstyled team-members">
{employees.map((p,k) => (


          <li key={k}>
            <Row>
              <Col md="2" xs="2">
                <div className="avatar">
                <Avatar name={`${p.first_name} ${p.last_name}`} />
                </div>
              </Col>
              <Col md="7" xs="7">
                {p.first_name} {" "} {p.last_name}<br />
                <span className="text-muted">
                  <small>{p.designation}</small>
                </span>
              </Col>
              <Col className="text-right" md="3" xs="3">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  outline
                  size="sm"
                >
                  <i className="fa fa-envelope" />
                </Button>
              </Col>
            </Row>
          </li>
))}
         
        </ul>
      </CardBody>
    </Card>
  </Col>
  <Col md="8">
    <Card>
      <CardHeader>
        <h5 className="title">Profile</h5>
      </CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col className="pr-1" md="6">
              <FormGroup>
                <label>Company (disabled)</label>
                <Input
                  defaultValue={user.company_name}
                  disabled
                  placeholder="Dealer"
                  type="text"
                />
              </FormGroup>
            </Col>
            // {<Col className="px-1" md="3">
            //   <FormGroup>
            //     <label>Username</label>
            //     <Input
            //       defaultValue={user.first_name? user.first_name.toLowerCase() + "_" + user.username : ""}
            //       placeholder="Username"
            //       type="text"
            //     />
            //   </FormGroup>
            // </Col> 
            <Col className="pl-1" md="6">
              <FormGroup>
                <label htmlFor="exampleInputEmail1">
                  Email address / Username
                </label>
                <Input defaultValue={user.email} placeholder="Email" type="email" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="6">
              <FormGroup>
                <label>First Name</label>
                <Input
                  defaultValue={user.first_name}
                  placeholder="First Name"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="pl-1" md="6">
              <FormGroup>
                <label>Last Name</label>
                <Input
                  defaultValue={user.last_name}
                  placeholder="Last Name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <FormGroup>
                <label>About Me</label>
                <Input
                  className="textarea"
                  type="textarea"
                  cols="80"
                  rows="4"
                  value={user.notes}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  
  <ChangePassword />
  </Col>
</Row>

</div> */
}

import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";

import { toast } from "react-toastify";
import { useForm, Controller} from "react-hook-form";

import { UPLOAD_LANDINGPAGE_IMAGE } from "constants/actions";

// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Input,
  FormGroup,
  Progress,
  Button,
  Row,
  Col
} from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";


const NewImage = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const { control, register, handleSubmit, errors, reset } = useForm();

  const [file, setFile] = React.useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage)

  const [auth, setAuth] = React.useState([]);
  const [imageprofileid, setImageprofileid] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [fileErrors, setFileErrors] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [imageSize, setImageSize] = React.useState(0);

  const notify2 = () => toast.success(`Uploaded File successfully`);

  const { get_image_profiles, get_errors, get_auth } = useSelector(
    state => ({
      get_auth: state.auth,
      get_image_profiles: state.imageprofiles.imageprofiles,
      get_errors: state.errors

    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_image_profiles) {
      // console.log(get_image_profiles)
      setItems(get_image_profiles);
      setImageprofileid(get_image_profiles[0].id)
    }
    if (get_errors) {
      setFileErrors(get_errors)
    }
    if (get_auth) {
      setAuth(get_auth)
    }
  }, [get_image_profiles, get_errors, get_auth]);

  function onSelectChange(e) {
    // e.preventDefault();
    // console.log(e.target.value)
    setImageprofileid(e.target.value)
  }
  const onSubmit = (data, e) => {
    // console.log(data.location)
    dispatch({type: UPLOAD_LANDINGPAGE_IMAGE, payload: { id,
    image: data.uploadfile[0]} }) // 
    e.target.reset(); // reset after form submit
    notify2()
    setImagePreviewUrl(defaultImage)
  };

// console.log(auth.dealer_id)
const handleImageChange =(e) => {
  e.preventDefault();
  let reader = new FileReader();
  let file_size = e.target.files[0].size;
  if (file_size > 1000000 ) {
    setFile(null)
    setImagePreviewUrl(defaultImage)
    setVisible(true)
    setImageSize((file_size / 1000000).toFixed(2))
    return
  }
  else {

  let file = e.target.files[0];
  reader.onloadend = () => {
    setFile(file)
      setImagePreviewUrl(reader.result)
  };
  reader.readAsDataURL(file);
  }
  
}


const handleRemove = () => {
  setFile(null)
  setImagePreviewUrl(defaultImage)
}


  return (
    <>
    <div className="content">
    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
      The image you are uploading is {imageSize}MB the max upload size is 1MB
     
    </Alert>
      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardBody>
            <div className="fileinput text-center">
          
                           
                            <div className={"thumbnail"}>
                            <img src={imagePreviewUrl} alt="..." />
                            </div>
                            <div>
                           {file? <Button onClick={handleRemove}>Remove</Button> : '' } 
                            </div>
                        </div>
              <CardTitle tag="h4">Upload Image</CardTitle>
              <p className="text-danger small">
                   {fileErrors.length > 0? JSON.stringify(fileErrors.errors) : ""}
                  </p>
              <form onSubmit={handleSubmit(onSubmit)}>
              
                

                <Input
                  type="file"
                  name="uploadfile"
                  onChange={handleImageChange}
                  innerRef={register({
                    required: "You must select a file"
                  })}
                />

                <p className="text-danger small">
                  {errors.uploadfile && (
                    <span>{errors.uploadfile.message}</span>
                  )}
                </p>

                <Button type="submit">Upload</Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </div>
    </>
  );
};

export default NewImage;

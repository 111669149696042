import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "../constants/api";

import {
  GET_TRADE_INS_PENDING,
  GET_TRADE_INS,
  LOAD_TRADE_INS,
  LOAD_ADMIN_TRADE_INS,
  GET_ADMIN_TRADE_INS,
  FETCH_TRADE_INS_PENDING,
  FETCH_TRADE_INS,
  SG_FETCH_TRADE_INS,
  DELETE_TRADE_IN,
  REMOVE_TRADE_IN,
  NEW_TRADE_IN,
  ADD_TRADE_IN,
  CREATE_TRADE_IN,
  EDIT_TRADE_INS,
  UPDATE_TRADE_INS,
  CLEAR_TRADE_INS,
  ALL_ERRORS,
} from "constants/actions";

function* loadTradein(action) {
  yield put({ type: GET_TRADE_INS_PENDING });
  try {
    const json = yield call(api.GET_CUSTOMER_TRADE_INS, action.payload);

    yield put({ type: GET_TRADE_INS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* tradeinLoad() {
  yield takeLatest(LOAD_TRADE_INS, loadTradein);
}

function* loadAdminTradein(action) {
  yield put({ type: GET_TRADE_INS_PENDING });
  try {
    const json = yield call(api.GET_ADMIN_TRADE_INS, action.payload);

    yield put({ type: GET_ADMIN_TRADE_INS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* admintradeinLoad() {
  yield takeLatest(LOAD_ADMIN_TRADE_INS, loadAdminTradein);
}

function* fetchTradein(action) {
  yield put({ type: FETCH_TRADE_INS_PENDING });
  try {
    const json = yield call(api.FETCH_CUSTOMER_TRADE_IN, action.payload);

    yield put({ type: FETCH_TRADE_INS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* tradeinFetchOne() {
  yield takeLatest(SG_FETCH_TRADE_INS, fetchTradein);
}

function* editTradein(action) {

  try {
    const json = yield call(api.EDIT_CUSTOMER_TRADE_IN, action.payload);
    yield put({ type: EDIT_TRADE_INS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* tradeinEdit() {
  yield takeLatest(UPDATE_TRADE_INS, editTradein);
}

function* createTradein(action) {
  yield put({ type: NEW_TRADE_IN });
  try {
    const json = yield call(api.ADD_CUSTOMER_TRADE_IN, action.payload);
    yield put({ type: ADD_TRADE_IN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* tradeinCreate() {
  yield takeLatest(CREATE_TRADE_IN, createTradein);
}

function* deleteTradein(action) {
  try {
    const json = yield call(api.DELETE_CUSTOMER_TRADE_IN, action.payload);
    yield put({ type: DELETE_TRADE_IN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* tradeinDelete() {
  yield takeLatest(REMOVE_TRADE_IN, deleteTradein);
}

export default function* index() {
  yield all([
    tradeinLoad(),
    admintradeinLoad(),
    tradeinFetchOne(),
    tradeinEdit(),
    tradeinCreate(),
    tradeinDelete(),
  ]);
}

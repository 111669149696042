import { all } from 'redux-saga/effects';

import Auth from './Auth';
import {websiteLoad} from './Website';
import Customers from './Customers';
import Company from './Company';
import CompanyInformation from './CompanyInformation';
import CreditApplication from './CreditApplication'
import CustomerAppointment from './CustomerAppointment'
// import {garageLoad} from './Garage'
// import {serviceRequestLoad} from './Service'
import {carYearLoad, carMakeLoad, carModelLoad, carTrimLoad} from './YearMakeModel'
import Paginate from './Paginate'
import Inventory from './Inventory'
import InventoryEngine from "./InventoryEngine"
import InventoryNotes from "./InventoryNotes"
import InventoryImage from "./InventoryImage"
import InventoryImgProfile from "./InventoryImgProfile"
import KijijiListing from "./KijijiListing"
import Employee from "./Employee"
import Dealer from "./Dealer"

import Loan from "./Loan"
import LeadSource from "./LeadSource"
import LeadStage from './LeadStage'
import LeadType from "./LeadType"
import LandingPages from "./LandingPages"
import Leads from "./Leads"
import Tasklist from "./TaskList"
import Tradein from "./Tradein"
import CMS from "./CMS"
import UploadFile from "./UploadFile"
import Vinupload from "./Vinupload"
import ClearAll from "./ClearAll"
import WebLeads from './WebLeads'

import WebComments from './WebComments'

import Product from './Product'
import ProductContract from './ProductContract'
import ProductGroup from './ProductGroup'
import LoginTrail from './LoginTrail'
import YearMake from "./YearMake"
// import YearMakeModel from './YearMakeModel' YearMakeModeTrimSelect YearMakeModelTrimSelect
import YearMakeModelTrimSelect from './YearMakeModeTrimSelect'
import {updateModelsLoad, updateMakesLoad, updateYearLoad} from './ResetPage'

import Reports from './Reports'

export function* rootSaga() {
  yield all([
    // fetchWebsiteDetails(),
    // websiteRequest(),
    // websiteLoadUser(),
    ClearAll(),
    Auth(),
    websiteLoad(),
    Company(),
    CompanyInformation(),
    CreditApplication(),
    CustomerAppointment(),
    Customers(),
    carYearLoad(),
    carMakeLoad(),
    carModelLoad(),
    carTrimLoad(),
    Inventory(),
    Loan(),
    Employee(),
    CMS(),
    Dealer(),
    LeadSource(),
    LeadStage(),
    LeadType(),
    LandingPages(),
    KijijiListing(),
    Product(),
    ProductContract(),
    ProductGroup(),
    LoginTrail(),
    Leads(),
    Paginate(),
    Reports(),
    Tasklist(),
    Tradein(),
    InventoryEngine(),
    InventoryImage(),
    InventoryImgProfile(),
    InventoryNotes(),
    updateModelsLoad(),
    updateMakesLoad(),
    updateYearLoad(),
    UploadFile(),
    Vinupload(),
    // YearMakeModel(),
    YearMake(),
    YearMakeModelTrimSelect(),
    WebComments(),
    WebLeads(),

  ]);
}

export default rootSaga
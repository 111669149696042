import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import { GET_IMAGEPROFILES, SG_GET_IMAGEPROFILES, DELETE_IMAGEPROFILE, 
    ADD_IMAGEPROFILE, CLEAR_IMAGEPROFILE, IMAGE_PROFILE_PENDING,
    FETCH_IMAGEPROFILE, SG_FETCH_IMAGEPROFILE, EDIT_IMAGEPROFILE, SG_EDIT_IMAGEPROFILE, ALL_ERRORS } from "constants/actions"

import * as api from 'constants/api';

function* loadImageProfiles(action) {
    yield put({ type: IMAGE_PROFILE_PENDING });
    try {
      const json = yield call(api.GET_IMAGE_PROFILES, action.payload)
      yield put({ type: GET_IMAGEPROFILES, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* imageProfilesLoad() {
    yield takeLatest(SG_GET_IMAGEPROFILES, loadImageProfiles);
  }
  
  function* fetchImageProfile(action) {
    yield put({ type: IMAGE_PROFILE_PENDING });
    try {
     const json = yield call(api.FETCH_IMAGE_PROFILE, action.payload)
      yield put({ type: FETCH_IMAGEPROFILE, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});;
    }
  }
  
  export function* imageProfileFetchOne() {
    yield takeLatest(SG_FETCH_IMAGEPROFILE, fetchImageProfile);
  }
  
  
  export default function* index() {
    yield all([imageProfilesLoad(), imageProfileFetchOne()]);
  }
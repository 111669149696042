import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import PersonalInfoForm from "./PersonalInfoForm";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const PersonalInfo = ({ user }) => {
  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: 550,
      }}
    >
      <PersonalInfoForm user={user} />
    </Box>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha, Avatar, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Fonts } from "../../../../../crema/constants/src/AppEnums";
import AppCard from "../../../../../crema/AppCard";
import { EmailOutlined } from "@material-ui/icons";

const Member = ({ member }) => {
  const [connect, setConnect] = useState(false);

  const onConnect = () => {
    setConnect(!connect);
  };

  const onDisconnect = () => {
    setConnect(!connect);
  };

  return (
    <AppCard
      title={member.title}
      sxStyle={{
        boxShadow: "none",
        borderRadius: 1,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        height: "100%",
      }}
      titleStyle={{
        fontSize: 12,
        fontWeight: Fonts.SEMI_BOLD,
        color: (theme) => theme.palette.text.secondary,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            mr: 1.25,
          }}
        >
          <Avatar
            src={member.srcImg}
            alt={member.title}
            sx={{
              backgroundColor: `rgba(${Math.floor(
                Math.random() * 128
              )}, ${Math.floor(Math.random() * 128)}, ${Math.floor(
                Math.random() * 128
              )}, 0.5)`,
            }}
          >
            {member.first_name?.[0]}
            {member.last_name?.[0]}
          </Avatar>
        </Box>
        <Box sx={{ width: "calc(100% - 50px)", display: "flex" }}>
          <Typography
            sx={{
              fontWeight: Fonts.MEDIUM,
              mb: 0.625,
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {member.first_name} &nbsp;{member.last_name}
          </Typography>
          <Button
            sx={{
              minWidth: 0,
              margin: 0,
              padding: 0,
              // boxShadow: "none",
              // backgroundColor: (theme) =>
              //   alpha(theme.palette.secondary.main, 0.1),
              // color: (theme) => theme.palette.secondary.main,
              // fontSize: 12,
              // fontWeight: Fonts.BOLD,
              // pt: 0.5,
              // pb: 0.75,
              // px: 1.5,
              // maxWidth: "100%",
              // "&:hover, &:focus": {
              //   backgroundColor: (theme) =>
              //     alpha(theme.palette.secondary.main, 0.1),
              //   color: (theme) => theme.palette.secondary.main,
              // },
            }}
          >
            <Box
              component="span"
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <EmailOutlined />
            </Box>
          </Button>
        </Box>
      </Box>
    </AppCard>
  );
};

export default Member;

Member.propTypes = {
  member: PropTypes.object,
};

import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import {
  UPDATE_LANDINGPAGE,
  CREATE_LANDINGPAGE,
  SG_FETCH_LANDINGPAGE,
} from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Fade,
  CardTitle,
  CardFooter,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";
import { Button as MUIButton } from "@mui/material";

import EditorConvertToHTML from "Draftjs/EditorConvertToHTML";
import Image from "./Image";
import AddNewLandingPage from "./AddNewLandingPage";

const Detail = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [landingPages, setLandingPages] = React.useState([]);
  const [editOverall, setEditOverall] = React.useState();

  const [fadeIn, setFadeIn] = React.useState(false);
  const [genUrl, setGenUrl] = React.useState("");
  const [metaDesc, setMetaDesc] = React.useState("");

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_LANDINGPAGE, payload: id });
    }
  }, [dispatch]);

  const { get_landing_pages, get_auth, get_errors } = useSelector(
    (state) => ({
      get_landing_pages: state.landingpages,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_landing_pages[parseInt(id)]) {
      setLandingPages(get_landing_pages[parseInt(id)]);
      setEditOverall(get_landing_pages[parseInt(id)].content);
    }
  }, [get_landing_pages, id]);

  const BtnCallBackOverall = (data) => {
    dispatch({
      type: UPDATE_LANDINGPAGE,
      payload: {
        id,
        content: data,
      },
    });
  };

  const BtnPublish = (data) => {
    dispatch({
      type: UPDATE_LANDINGPAGE,
      payload: {
        id,
        status: data,
      },
    });
  };
  const toggle = () => setFadeIn(!fadeIn);
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };
  return (
    <>
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h4">Landing Page Details</CardTitle>
                    </Col>

                    <Col>
                      {/* <Button
                        color="info"
                        to={`/admin/landing-page-create/${id}`}
                        tag={Link}
                        outline
                        size="sm"
                      >
                        Edit
                      </Button> */}
                      <MUIButton
                        color="preAuto"
                        onClick={onOpenAddTask}
                        variant="contained"
                      >
                        Edit
                      </MUIButton>
                    </Col>
                  </Row>

                  <FormGroup>
                    <label>Heading </label>
                    <p>{landingPages ? landingPages.heading : ""}</p>
                  </FormGroup>
                  <FormGroup>
                    <label>URL (has to be unique)</label>
                    <p>{landingPages ? landingPages.url : ""}</p>
                  </FormGroup>
                  <FormGroup>
                    <label>Meta Description </label>
                    <p>{landingPages ? landingPages.meta_description : ""}</p>
                  </FormGroup>

                  <Row>
                    <Col md="3">
                      <label>
                        Status # {landingPages ? landingPages.status_text : ""}
                      </label>
                    </Col>
                    <Col className="ml-auto" md="8">
                      <ButtonGroup>
                        <Button
                          size="sm"
                          color={
                            landingPages.status === 1 ? "success" : "secondary"
                          }
                          onClick={() => BtnPublish(1)}
                          disabled={landingPages.status === 1 ? true : false}
                        >
                          New / Edit
                        </Button>
                        <Button
                          size="sm"
                          color={
                            landingPages.status === 2 ? "success" : "secondary"
                          }
                          onClick={() => BtnPublish(2)}
                          disabled={landingPages.status === 2 ? true : false}
                        >
                          Publish (Live)
                        </Button>
                        <Button
                          size="sm"
                          color={
                            landingPages.status === 3 ? "success" : "secondary"
                          }
                          onClick={() => BtnPublish(3)}
                          disabled={landingPages.status === 3 ? true : false}
                        >
                          Archive (Remove)
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>

                  {landingPages.image ? (
                    <Row>
                      <Col>
                        <img
                          src={landingPages ? landingPages.image : ""}
                          alt="..."
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Row className="pt-1">
              <Col md="12">
                <EditorConvertToHTML
                  heading="Content"
                  editContent={editOverall}
                  BtnCallBack={BtnCallBackOverall}
                />
              </Col>
            </Row>
            <Button color="primary" onClick={toggle}>
              Upload Image
            </Button>
            <Fade in={fadeIn} className="mt-3">
              <Row>
                <Col>
                  <Image />
                </Col>
              </Row>
            </Fade>
          </div>
        </ErrorBoundary>
      </div>
      <AddNewLandingPage
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default Detail;

import { LOAD_CUSTOMER_APPOINTMENTS,
 GET_CUSTOMER_APPOINTMENTS_PENDING,
 GET_CUSTOMER_APPOINTMENTS,
 DELETE_CUSTOMER_APPOINTMENT,
 SG_DELETE_CUSTOMER_APPOINTMENT,
 ADD_CUSTOMER_APPOINTMENT,
 SG_ADD_CUSTOMER_APPOINTMENT,
 FETCH_CUSTOMER_APPOINTMENT,
 SG_FETCH_CUSTOMER_APPOINTMENT,
 UPDATE_CUSTOMER_APPOINTMENT,
 EDIT_CUSTOMER_APPOINTMENT,
 CLEAR_CUSTOMER_APPOINTMENT,
 CLEAR_ALL,
} from "constants/actions";

const initialState = {
    customerappointments: [],
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMER_APPOINTMENTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case GET_CUSTOMER_APPOINTMENTS:
            return {
                ...state,
                customerappointments: action.payload,
                pending: false
            };
        case FETCH_CUSTOMER_APPOINTMENT:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CUSTOMER_APPOINTMENT:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CUSTOMER_APPOINTMENT:
            return {
                ...state,
                customerappointments: state.customerappointments.filter(dealer => dealer.id !== action.payload)
            };
        case ADD_CUSTOMER_APPOINTMENT:
            return {
                ...state,
                customerappointments: [...state.customerappointments, action.payload]
            };
        case CLEAR_CUSTOMER_APPOINTMENT:
            return {
                ...state,
                customerappointments: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                customerappointments: []
            };
        default:
            return state;
    }
}


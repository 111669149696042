import { CREATE_MESSAGE, UPDATE_MESSAGE } from "constants/actions";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_MESSAGE:
            return (state = action.payload);
        case UPDATE_MESSAGE:
            return (state = action.payload);
        default:
            return state;
    }
}
import { SET_ERROR_STATE } from "constants/actions";;

const initialState = {
    msg: {},
    status: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ERROR_STATE:
            // console.log('reducer error payload', action.payload)
            return {
                msg: action,
                status: action
            };
        
        default:
            return state;
    }
}
import React, { useEffect, useMemo } from "react";
import { Icon, ListItemText } from "@mui/material";
import clsx from "clsx";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import VerticalNavItem from "./VerticalNavItem";
import { useLocation } from "react-router-dom";
import AppBadge from "../../AppBadge";
import AppNavLink from "../../AppNavLink";

const VerticalItem = ({ level, item }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === item.path && document.getElementById(pathname)) {
      setTimeout(() => {
        document
          .getElementById(pathname)
          .scrollIntoView({ behavior: "smooth", block: "center" });
      }, 1);
    }
  }, [pathname]);

  if (!item.visible) return null;
  if (!item.path) return null;

  return (
    <VerticalNavItem
      level={level}
      button
      id={item.path}
      component={AppNavLink}
      to={item.path}
      activeClassName="active"
      exact={item.exact}
      item={item}
    >
      {item.icon && (
        <Box
          component="span"
          sx={{
            display: "block",
            mr: 4,
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          {/* <Icon
            sx={{
              display: "block",
              mr: 4,
              textAlign: "center",
            }}
            className={clsx("nav-item-icon", "material-icons-outlined")}
            color="action"
          > */}
          {item.icon}
          {/* <i className={`${item.icon} white-iii`}></i> */}
          {/* </Icon> */}
        </Box>
      )}
      <ListItemText
        className="nav-item-content"
        primary={item.name}
        classes={{ primary: "nav-item-text" }}
      />
      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
    </VerticalNavItem>
  );
};

// VerticalItem.propTypes = {
//   item: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     title: PropTypes.string,
//     icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
//     permittedRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
//     exact: PropTypes.bool,
//     messageId: PropTypes.string,
//     count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     url: PropTypes.string,
//     color: PropTypes.string,
//   }),
//   level: PropTypes.number,
// };

// VerticalItem.defaultProps = {};

export default VerticalItem;

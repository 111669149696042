import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

import { Button as MUIButton } from "@mui/material";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import { LOAD_ALL_EMPLOYEES, CREATE_TASK_LIST } from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Form,
  Input,
  Fade,
  Collapse,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import ErrorBoundary from "ErrorBoundary";
import AddNewWebCommentUpload from "WebComments/AddNewWebCommentUpload";

const CreateTask = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [taskList, setTaskList] = React.useState([]);
  const [rSelected, setRSelected] = React.useState(1);
  const [fadeIn, setFadeIn] = React.useState(false);
  const [currentDateTime, setCurrentDateTime] = React.useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );

  const [selEmployee, setSelEmployee] = React.useState([]);

  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const { get_task_list, get_employees, get_auth, get_errors } = useSelector(
    (state) => ({
      get_task_list: state.tasklist,
      get_employees: state.employees.employees,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelEmployee(get_employees);
  }, [get_employees]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const getValidDates = function (currentDate) {
    // only allow today and future dates
    var yesterday = moment().subtract(1, "day");
    return currentDate.isAfter(yesterday);
  };

  //   var getValidTimes = function (dateTime) {
  //     // date is today, so only allow future times
  //     if (moment().isSame(dateTime, "day")) {
  //       return {
  //         hours: {
  //           min: dateTime.hours(),
  //           max: 18,
  //           step: 1,
  //         },
  //         minutes: {
  //           min: dateTime.minutes(),
  //           max: 59,
  //           step: 5,
  //         },
  //       };
  //     }
  //   };

  const onSubmit = (data, e) => {
    dispatch({
      type: CREATE_TASK_LIST,
      payload: {
        name: data.name,
        description: data.description,
        scheduled_at: moment(currentDateTime),
        status: rSelected,
        web_leads: props.lead_id,
        customer: props.customer_id,
        inventory: props.inventory_id,
        company: get_auth.dealer_id,
        employee: data.employee.id,
      },
    });
    props.CreatedNewTask(Math.floor(Math.random() * 1001));
    e.target.reset();
  };

  React.useEffect(() => {
    // console.log(selStatus);
    if (selEmployee) {
      setValue(
        "employee",
        get_employees.filter((employee) => employee.id === get_auth.employee_id)
      );

      setValue("scheduled_at", moment().format("YYYY-MM-DD hh:mm A"));
    }
  }, [selEmployee]);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  return (
    <>
      {/* {toDetail? <Redirect to={`/admin/lead-detail/${id}`}  /> : null}
    {toHome? <Redirect to={`/admin/leads`}  /> : null} */}
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Row>
                <Col>
                  {/* <Button color="primary" onClick={() => setFadeIn(!fadeIn)}>
            Add Task
          </Button> */}
                  <MUIButton
                    color="preAuto"
                    variant="contained"
                    size="sm"
                    onClick={() => {
                      onOpenAddTask();
                    }}
                    sx={{
                      mb: 2,
                    }}
                  >
                    Add Task
                  </MUIButton>
                </Col>

                {/* <Col md="8">
                  <Collapse isOpen={isAddTaskOpen} className="mt-3">
                    <Card>
                      <CardBody>
                        <CardTitle tag="h4">Create Task</CardTitle>
                        <hr />
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <FormGroup>
                            <label>Task Name </label>
                            <Input
                              type="text"
                              name="name"
                              defaultValue={taskList ? taskList.name : ""}
                              innerRef={register({
                                required: "Please give a Name for this task",
                              })}
                            />
                            <p className="text-danger small">
                              {errors.name && <span>{errors.name.message}</span>}
                            </p>
                          </FormGroup>
                          <FormGroup>
                            <label>Description</label>
                            <Input
                              type="textarea"
                              defaultValue={taskList ? taskList.description : ""}
                              name="description"
                              id="description"
                              innerRef={register}
                            />
                          </FormGroup>

                          <Row>
                            <Col>
                              <label>Schedule at</label>
                              <FormGroup>
                                
                                <ReactDatetime isValidDate={getValidDates}
                                  value={currentDateTime}
                                  // timeConstraints={getValidTimes(currentDateTime)}
                                  name="scheduled_at"
                                  dateFormat="YYYY-MM-DD"
                                  onChange={setCurrentDateTime}
                                  inputProps={{
                                    className: "form-control",
                                    placeholder: "Select Date",
                                  }}
                                  timeFormat={true} />
                                
                                <p className="text-danger small">
                                  {errors.scheduled_at && (
                                    <span>{errors.scheduled_at.message}</span>
                                  )}
                                </p>
                              </FormGroup>
                            </Col>

                            <Col>
                              <div className="d-flex justify-content-end">
                                <div>
                                  <label>
                                    Status: {rSelected === 2 ? "Closed" : "Open"}
                                  </label>
                                  <br />
                                  <ButtonGroup>
                                    <Button
                                      color={
                                        rSelected === 1 ? "danger" : "secondary"
                                      }
                                      size="sm"
                                      onClick={() => setRSelected(1)}
                                      active={rSelected === 1}
                                    >
                                      Open
                                    </Button>
                                    <Button
                                      color={
                                        rSelected === 2 ? "success" : "secondary"
                                      }
                                      size="sm"
                                      onClick={() => setRSelected(2)}
                                      active={rSelected === 2}
                                    >
                                      Closed
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <FormGroup>
                            <label>Assign To</label>
                            <Controller
                              as={<Select />}
                              name="employee"
                              options={selEmployee}
                              getOptionLabel={(option) =>
                                `${option.first_name} ${option.last_name} - ${option.role_text}`
                              }
                              getOptionValue={(option) => `${option.id}`}
                              className="text-dark"
                              control={control}
                              rules={{ required: "Please enter Lead Stage" }}
                            />{" "}
                            <p className="text-danger small">
                              {errors.employee && <span>{errors.employee.message}</span>}
                            </p>
                          </FormGroup>


                          <div className="d-flex justify-content-end">
                            <Button
                              className="btn-fill"
                              color="primary"
                              type="submit"
                            >
                              Save
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>

      <AddNewWebCommentUpload
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default CreateTask;

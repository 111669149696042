import React from "react";

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import { Alert, Spinner, Fade, Table, Row, Col } from "reactstrap";
import Select from "react-select";

import JsonData from "Inventory/BackendCompare/JsonData"
import GsheetData from "Inventory/BackendCompare/GsheetData"

import {
  SG_FETCH_RELATED_DEALER,
} from "constants/actions";

// const LocationId = 2;

const BackendCompare = () => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  // let LocationId = params.get("location");
  const [locationId, setLocationId] = React.useState(params.get("location"))
  const [fetchData, setFetchData] = React.useState('no data')

  const [dataSources, setDataSources] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState('');


  React.useEffect(() => {
    // Run! Like go get some data from an API.

    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);

  const {

    get_related,
  } = useSelector(
    (state) => ({

      get_related: state.dealers.related,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_related) {
      setDataSources(get_related);
      if (locationId) {
        setSelectedLocation(get_related.filter(p => p.id === Number(locationId))[0])
      } else {
        setSelectedLocation('')
      }

    }
  }, [locationId, get_related]);

  function SetLocattion(e) {
    if (e) {
      setLocationId(e?.id)
    } else {
      setLocationId(null)
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" className="mt-2">
            <Select
              name="set_datasource"
              options={dataSources}
              isClearable
              value={selectedLocation}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              onChange={(e) => SetLocattion(e)}
            />
          </Col>

        </Row>
        <div className={fetchData === 'no data' ? "d-none mt-3" : ''}>
          <Fade in={fetchData === true} tag="h5">
            <div className="d-flex justify-content-center">
              <Spinner>
                Loading...
              </Spinner>
            </div>
          </Fade>
        </div>
        <div className={fetchData === true ? "d-none" : ""}>
          <ReturnRelevant selectedLocation={selectedLocation}
            setFetchData={setFetchData} />
        </div>
      </div>
    </>
  );
};

export default BackendCompare;



function ReturnRelevant({ selectedLocation, setFetchData }) {
  
  

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  
  
  const [state, setState] = React.useState({
    open: true,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  if (selectedLocation?.data_source === 1) {
    return <JsonData
      selectedLocation={selectedLocation}
      setFetchData={setFetchData} />
  } else if (selectedLocation?.data_source === 2) {
    return <GsheetData
      selectedLocation={selectedLocation}
      setFetchData={setFetchData} />
  }
    return  <Snackbar
    sx={{mt:4}}
    anchorOrigin={{ vertical, horizontal }}
    open={open}
    message="Please select a data source from above"
    key={vertical + horizontal}
  >   
    <Alert severity="error" sx={{ backgroundColor: '#63c5da'}}>Please select a data source from above</Alert>
    </Snackbar>
  // <Alert color="primary">
  //   Please select a data source from above
  // </Alert>
}
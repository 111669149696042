import React from "react";
// import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

import Select from "react-select";

import {
  LOAD_PRODUCT_GROUPS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  ONE_PRODUCT,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  // CardHeader,
  CardTitle,
  // CardFooter,
  Button,
  // Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

const ActiveOptions = [
  { value: 'New', label: "New" },
  { value: "Active", label: "Active" },
  { value: 'Promotion', label: "Promotion" },
  { value: 'Retired', label: "Retired" },
  { value: 'Un Available', label: 'Un Available' },
  { value: 'Expired', label: 'Expired' },
];

const BillCycleOptions = [
  { value: 'Year', label: "Year" },
  { value: "Month", label: "Month" },
  { value: 'Week', label: "Week" },
  { value: 'Hour', label: 'Hour' },
  { value: 'UnLimited', label: 'UnLimited' },
  { value: 'Instance', label: 'Instance' },
  { value: 'Fixed', label: 'Fixed' },
  { value: 'None', label: 'None' },
];

const CreateEdit = (props) => {
  const dispatch = useDispatch();
  const [productId, setProductId] = React.useState(props.id);
  const [product, setProduct] = React.useState([]);
  const [productGroup, setProductGroup] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);

  const [activeSelected, setActiveSelected] = React.useState([]);
  const [groupSelcted, setGroupSelcted] = React.useState([]);
  const [billCycleSelected, setBillCycleSelected] = React.useState([]);

  React.useEffect(() => {
    setProductId(props.id);
  }, [props.id]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_PRODUCT_GROUPS });
    if (productId) {
      dispatch({ type: ONE_PRODUCT, payload: productId });
    }
  }, [dispatch, productId]);

  const { get_product_list, get_product_group, get_errors } = useSelector(
    (state) => ({
      get_product_list: state.product,
      get_product_group: state.productgroup.productgroups,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  // [parseInt(id)]

  React.useEffect(() => {
    // console.log(_.isEmpty(loginError.errors))
    if (!_.isEmpty(get_errors.errors)) {
      // console.log(loginError)
      setVisible(true);
      setPageErrors(get_errors);
    }
  }, [get_errors]);

  React.useEffect(() => {
    if (get_product_group) {
      setProductGroup(get_product_group);
    }
  }, [get_product_group]);

  React.useEffect(() => {
    if (get_product_list && productId) {
      setProduct(get_product_list[productId]);
    }
  }, [get_product_list, productId]);

  React.useEffect(() => {
    if (product)
    {
        if (get_product_group.length > 0) {
            setGroupSelcted(
                get_product_group.filter(
                    (fl) => fl.id === product.product_group)[0]
            );
        }
        setActiveSelected(
            ActiveOptions.filter((fl) => fl.value === product.status)[0]
        );
        setBillCycleSelected(
          BillCycleOptions.filter((fl) => fl.value === product.bill_cycle)[0]
        )
    }
  }, [product, get_product_group]);

  // console.log(selStatus);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    if (activeSelected) {
      setValue("status", activeSelected);
    }
  }, [activeSelected]);
  
  React.useEffect(() => {
    if (groupSelcted) {
      setValue("product_group", groupSelcted);
    }
  }, [groupSelcted]);

  React.useEffect(() => {
    if (billCycleSelected) {
      setValue("bill_cycle", billCycleSelected);
    }
  }, [billCycleSelected]);

  const onSubmit = (data) => {
    if (productId) {
      dispatch({
        type: UPDATE_PRODUCT,
        payload: {
          id: productId,
          ...data,
          product_group: data.product_group.id,
          status: data.status.value,
          bill_cycle: data.bill_cycle.value
        },
      });
    } else {
      dispatch({
        type: CREATE_PRODUCT,
        payload: {
          ...data,
          product_group: data.product_group.id,
          status: data.status.value,
          bill_cycle: data.bill_cycle.value
        },
      });
    }
    props.resetColWidth(false)
    props.resetColWidth(Math.random())
  };

  return (
    <>
      <div className="content">
        <Alert color="danger" isOpen={visible} toggle={setVisible}>
          {pageerrors.errors
            ? pageerrors.errors["detail"]
            : JSON.stringify(pageerrors.errors)}
        </Alert>

        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <CardTitle tag="h4">
                      {productId ? "Edit " : "Create "}Product
                    </CardTitle>
                    {props.resetColWidth ? (
                      <Button
                        color="link"
                        onClick={() => props.resetColWidth(false)}
                      >
                        <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <label>Product Code </label>
                      <Input
                        type="text"
                        name="product_no"
                        defaultValue={product ? product.product_no : ""}
                        innerRef={register({
                          required: "Please enter Product No",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.product_no && (
                          <span>{errors.product_no.message}</span>
                        )}
                      </p>
                    </FormGroup>

                    <FormGroup>
                      <label>Sort Order </label>
                      <Input
                        type="text"
                        name="sort_order"
                        defaultValue={product ? product.sort_order : ""}
                        innerRef={register({
                          required: "Please enter sort_order",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.sort_order && (
                          <span>{errors.sort_order.message}</span>
                        )}
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <label>Name </label>
                      <Input
                        type="text"
                        name="name"
                        defaultValue={product ? product.name : ""}
                        innerRef={register({
                          required: "Please enter Name",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.name && <span>{errors.name.message}</span>}
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <label>Price </label>
                      <Input
                        type="text"
                        name="price"
                        defaultValue={product ? product.price : ""}
                        innerRef={register({
                          required: "Please enter Price",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.name && <span>{errors.price.message}</span>}
                      </p>
                    </FormGroup>

                    <FormGroup>
                      <label>Group</label>
                      <Controller
                        as={<Select />}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.id}`}
                        name="product_group"
                        options={productGroup}
                        className="text-dark"
                        control={control}
                        rules={{ required: "Please select Group" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.product_group && <span>{errors.product_group.message}</span>}
                      </p>
                    </FormGroup>

                    <FormGroup>
                      <label>Active Status</label>
                      <Controller
                        as={<Select />}
                        name="status"
                        options={ActiveOptions}
                        className="text-dark"
                        control={control}
                        rules={{ required: "Please enter Status" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.status && <span>{errors.status.message}</span>}
                      </p>
                    </FormGroup>

                    <FormGroup>
                      <label>Bill Cycle</label>
                      <Controller
                        as={<Select />}
                        name="bill_cycle"
                        options={BillCycleOptions}
                        className="text-dark"
                        control={control}
                        rules={{ required: "Please enter Bill Cycle" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.bill_cycle && <span>{errors.bill_cycle.message}</span>}
                      </p>
                    </FormGroup>
                    <Button
                      className="btn-fill"
                      color={productId ? "primary " : "secondary "}
                      type="submit"
                    >
                      {productId ? "Update " : "Save "}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateEdit;

import { GET_PRODUCT_GROUPS, 
    FETCH_PRODUCT_GROUP, 
    DELETE_PRODUCT_GROUP, 
    ADD_PRODUCT_GROUP, 
    EDIT_PRODUCT_GROUP, 
    CLEAR_PRODUCT_GROUP, 
    CLEAR_ALL } 
    from "constants/actions";
 
const initialState = {
    productgroups: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_GROUPS:
            return {
                ...state,
                productgroups: action.payload
            };
        case FETCH_PRODUCT_GROUP:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_PRODUCT_GROUP:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_PRODUCT_GROUP:
            return {
                ...state,
                productgroups: state.productgroups.filter(webheader => webheader.id !== action.payload)
            };
        case ADD_PRODUCT_GROUP:
            return {
                ...state,
                productgroups: [...state.productgroups, action.payload]
            };
        case CLEAR_PRODUCT_GROUP:
            return {
                ...state,
                productgroups: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                productgroups: []
            };
        default:
            return state;
    }
}

import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  useSelector,
  useDispatchshallowEqual,
  useDispatch,
  shallowEqual,
} from "react-redux";
import { useForm } from "react-hook-form";
// import { registerUser } from "actions/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import * as yup from "yup";
import { Form, Formik } from "formik";
import keyConfig from "../_config";
import { Link } from "react-router-dom";
import AppTextField from "../crema/AppFormComponents/AppTextField";
import AppHtmlLogo from "assets/img/html-5.png";
// import { BsGithub } from 'react-icons/bs';
// import { FaFacebookF } from 'react-icons/fa';
// import { useAuthMethod } from '@crema/hooks/AuthHooks';

// reactstrap components
// import {
//   Card,
//   CardHeader,
//   CardTitle,
//   CardBody,
//   CardFooter,
//   Container,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   InputGroup,
//   // InputGroupAddon,
//   InputGroupText,
//   Input,
//   Label,
//   Button,
// } from "reactstrap";

// core components
import bgImage from "assets/img/bg/jan-sendereks.jpg";
import { REGISTER_USER } from "constants/actions";
import { FormControlLabel } from "@mui/material";
import AuthWrapper from "./auth/AuthWrapper";
import { Typography } from "@mui/material";
import { Fonts } from "crema/constants/src";

const validationSchema = yup.object({
  dealer_code: yup.string().required("Code Required"),
  email: yup.string().email("Email Format").required("Email Required"),
  // password: yup.string().required("password Required"),
  // confirmPassword: yup.string().required("Confirm Password")
  password: yup.string().min(8).required("Password is required"),
  repeat_password: yup
    .string()
    .min(8)
    .required("Repeat Password is required")
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  agreeTerms: yup
    .boolean()
    .oneOf([true], "You must agree to the terms and conditions"),
});

const RegisterPage = () => {
  const { register, handleSubmit, watch, errors, control } = useForm();

  const [alert, setAlert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [emailFocus, setemailFocus] = React.useState(false);
  const [passwordFocus, setpasswordFocus] = React.useState(false);
  const [password1Focus, setpassword1Focus] = React.useState(false);
  const [registered, setRegistered] = React.useState(false);

  const [regError, setRegError] = React.useState(false);

  const password = React.useRef({});
  password.current = watch("password", "");

  const dispatch = useDispatch();
  let history = useHistory();

  // React.useEffect(() => {
  //   document.body.classList.add("register-page");
  //   // console.log("component mounted")
  // }, []);
  // React.useEffect(() => {
  //   return () => {
  //     document.body.classList.remove("register-page");
  //     // console.log("component UN-mounted")
  //   }
  // }, []);

  const { authenticated, registerError, registerFail, get_registered } =
    useSelector(
      (state) => ({
        auth: state.auth,
        get_registered: state.auth.registered,
        registerError: state.errors.msg,
        registerFail: state.auth.registererror,
        authenticated: state.auth.isAuthenticated,
      }),
      shallowEqual
    );

  // console.log(registerError)
  // console.log(registerFail)

  if (authenticated) {
    // notify()
    history.push("/user/dashboard-sales");
  }

  React.useEffect(() => {
    if (get_registered.status === 201) {
      setIsLoading(false);

      setRegistered(get_registered);
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => goLogin()}
          confirmBtnBsStyle="info"
        >
          You have sucessfully Registered, you may login!
        </SweetAlert>
      );
    }
  }, [get_registered]);

  React.useEffect(() => {
    setIsLoading(false);

    if (_.get(registerError, "data")) {
      setRegError(registerError.data);
      // setSubmitting(false);
    }
  }, [registerError]);

  const onSubmit = (data) => {
    setIsLoading(true);

    // console.log(data)
    // dispatch(registerUser({...data, dealer_token: keyConfig.portalkey}))
    dispatch({ type: REGISTER_USER, payload: data });

    // notify()
    // history.push( "/auth/login-page")
  };

  function goLogin() {
    history.push("/auth/login");
  }
  // const hideAlert = () => {
  //   setAlert(null)
  //   goLogin()
  // status(pin):201
  // statusText(pin):"Created"
  //  }
  const getAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Sucess"
        onConfirm={() => goLogin()}
        confirmBtnBsStyle="info"
      >
        You have sucessfully Registered, you may login!
      </SweetAlert>
    );
  };
  return (
    <>
      <AuthWrapper
        rightSection={
          <>
            <Box
              sx={{
                maxWidth: 320,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "2em",
                  }}
                >
                  {/* <img
                  style={{
                    width: 100,
                    // height: 100,
                    objectFit: "cover",
                  }}
                  src={AppHtmlLogo}
                  alt="app-logo"
                /> */}
                  <i className="nc-icon nc-tv-2" />
                </Box>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 30,
                    mb: 2,
                    mt: 0.8,
                  }}
                >
                  Secure
                </Typography>
              </Box>
              <Typography>
                Now your information is secure in cloud with named user login
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: 320,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                {/* <img
                  style={{
                    width: 100,
                    // height: 100,
                    objectFit: "cover",
                  }}
                  src={AppHtmlLogo}
                  alt="app-logo"
                /> */}
                <Box
                  sx={{
                    fontSize: "2em",
                    mt: 0.8,
                  }}
                >
                  <i className="nc-icon nc-html5" />
                </Box>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 30,
                    mt: 2,
                    mb: 2,
                  }}
                >
                  All Information
                </Typography>
              </Box>
              <Typography>
                You have all the information to help you work effeciently
              </Typography>
            </Box>
          </>
        }
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", mb: 1.5 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                mb: 2,
              }}
            >
              Register
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                mb: 1.5,
              }}
            >
              Only Pre-Registered and Authorized employees can register
            </Box>
            <Formik
              validateOnChange={true}
              initialValues={{
                dealer_code: "",
                email: "",
                password: "",
                repeat_password: "",
                agreeTerms: false,
              }}
              validationSchema={validationSchema}
              // onSubmit={(data) => {
              //   setIsLoading(true);
              //   console.log("data", data);
              //   onSubmit(data);
              //   // registerUserWithEmailAndPassword(data);
              //   console.log(
              //     "registerUserWithEmailAndPassword"
              //     // registerUserWithEmailAndPassword
              //   );
              //   dispatch({ type: REGISTER_USER, payload: data });
              // }}
              onSubmit={onSubmit}
            >
              {({ field, values, errors, setFieldValue }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Dealer Code"
                      name="dealer_code"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Email"
                      name="email"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="password"
                      name="password"
                      type="password"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Repeat Password"
                      name="repeat_password"
                      type="password"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>
                  <div className="text-danger">
                    {regError && regError.length ? regError[0] : null}
                    {/* {regError ? JSON.stringify(regError) : ""} <br /> */}
                    {/*      <span>{registerFail}</span> */}
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 14,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={values.agreeTerms}
                          onChange={() => {
                            setFieldValue("agreeTerms", event.target.checked);
                          }}
                          color="primary"
                          sx={{ ml: -1.5 }}
                        />
                      }
                      label={
                        <>
                          <Box
                            component="span"
                            sx={{
                              mr: 0.5,
                              color: "grey.500",
                              fontSize: 14,
                              mb: -0.5,
                            }}
                          >
                            I agree to the
                            <Link
                              to={"/auth/acceptable-use-policy"}
                              style={{
                                textDecoration: "none",
                                color: "#1d4aa7",
                                marginLeft: "5px",
                              }}
                            >
                              terms and conditions.
                            </Link>
                          </Box>
                        </>
                      }
                    />
                  </Box>
                  {errors.agreeTerms && (
                    <Box
                      component="span"
                      sx={{
                        color: "red",
                        fontSize: 12,
                      }}
                    >
                      {errors.agreeTerms}
                    </Box>
                  )}

                  <div>
                    <Button
                      variant="contained"
                      color="preAuto"
                      disabled={isLoading}
                      sx={{
                        minWidth: 160,
                        fontWeight: 400,
                        fontSize: 16,
                        padding: "4px 16px 8px",
                        textTransform: "inherit !important",
                      }}
                      type="submit"
                    >
                      Get Started
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>

          <Box
            sx={{
              color: "grey.500",
              mb: { xs: 1, md: 0 },
            }}
          >
            <span style={{ marginRight: 4 }}>Already Have Account</span>
            <Box
              component="span"
              sx={{
                fontWeight: 500,
                "& a": {
                  color: "#1d4aa7",
                  textDecoration: "none",
                },
              }}
            >
              <Link to="/signIn">Sign In</Link>
            </Box>
          </Box>
        </Box>
        {alert}
      </AuthWrapper>
    </>
  );
};

export default RegisterPage;

// <>
// <div className="register-page">
//   <Container>
//     <Row className="justify-content-center">
//       <Col className="ml-auto" lg="5" md="5">
//         <div className="info-area info-horizontal mt-5">
//           <div className="icon icon-primary">
//             <i className="nc-icon nc-tv-2" />
//           </div>
//           <div className="description">
//             <h5 className="info-title">Secure</h5>
//             <p className="description">
//               Now your information is secure in cloud with named user
//               login
//             </p>
//           </div>
//         </div>
//         <div className="info-area info-horizontal">
//           <div className="icon icon-primary">
//             <i className="nc-icon nc-html5" />
//           </div>
//           <div className="description">
//             <h5 className="info-title">All Information</h5>
//             <p className="description">
//               You have all the information to help you work effeciently
//             </p>
//           </div>
//         </div>
//         <div className="info-area info-horizontal">
//           <div className="icon icon-info">
//             <i className="nc-icon nc-atom" />
//           </div>
//           <div className="description">
//             <h5 className="info-title"></h5>
//             <p className="description"></p>
//           </div>
//         </div>
//       </Col>

//       <Col lg={6} md={8} xs={12}>
//         <Card className="card-signup">
//           <CardHeader className="text-center">
//             <CardTitle tag="h4">Register</CardTitle>
//             <p className="card-description">
//               Only Pre-Registered and Authorized employees can register
//             </p>
//           </CardHeader>
//           <CardBody>
//             <div className="text-danger">
//               {regError ? JSON.stringify(regError) : ""} <br />
//               {/*      <span>{registerFail}</span> */}
//             </div>

//             <Form onSubmit={handleSubmit(onSubmit)}>
//               <FormGroup
//                 className={errors.dealer_code ? "has-danger" : ""}
//               >
//                 <Label className="text-dark">Dealer Code</Label>
//                 <Input
//                   type="text"
//                   name="dealer_code"

//                   placeholder="Dealer Code"
//                   innerRef={register({
//                     required: "Dealer Code is required",
//                     maxlength: 20,
//                   })}
//                 />
//                 <p className="text-danger small">
//                   {errors.dealer_code && (
//                     <span>Dealer Code is required</span>
//                   )}
//                 </p>
//               </FormGroup>

//               <FormGroup className={errors.email ? "has-danger" : ""}>
//                 <Label className="text-dark">Email</Label>
//                 <Input
//                   type="email"
//                   name="email"

//                   placeholder="Email Id"
//                   innerRef={register({
//                     required: "Email is required",
//                     pattern: {
//                       value: email_validation,
//                       message: "invalid email address",
//                     },
//                     maxlength: 20,
//                   })}
//                 />
//                 <p className="text-danger small">
//                   {errors.email && <span>{errors.email.message}</span>}
//                   {/* {errors.email && errors.email.pattern  && (
//                         <span>Email format not valid</span>
//                       )} */}
//                 </p>
//               </FormGroup>

//               <FormGroup className={errors.password ? "has-danger" : ""}>
//                 <Label className="text-dark">Password</Label>
//                 <InputGroup
//                   className={passwordFocus ? "input-group-focus" : ""}
//                 >
//                   {/* <InputGroupAddon addonType="prepend"> */}
//                     <InputGroupText>
//                       <i className="now-ui-icons ui-1_lock-circle-open" />
//                     </InputGroupText>
//                   {/* </InputGroupAddon> */}
//                   <Input
//                     type="password"
//                     name="password"
//                     placeholder="Password"
//                     innerRef={register({
//                       required: "You must specify a password",
//                       minLength: {
//                         value: 8,
//                         message:
//                           "Password must have at least 8 characters",
//                       },
//                     })}
//                   />
//                 </InputGroup>
//                 <p className="text-danger small">
//                   {errors.password && (
//                     <span>{errors.password.message}</span>
//                   )}
//                 </p>
//               </FormGroup>

//               <FormGroup className={errors.password ? "has-danger" : ""}>
//                 <Label className="text-dark">Repeat Password</Label>
//                 <InputGroup
//                   className={passwordFocus ? "input-group-focus" : ""}
//                 >
//                   {/* <InputGroupAddon addonType="prepend"> */}
//                     <InputGroupText>
//                       <i className="now-ui-icons ui-1_lock-circle-open" />
//                     </InputGroupText>
//                   {/* </InputGroupAddon> */}
//                   <Input
//                     type="password"
//                     name="password2"
//                     placeholder="Re Type Password"
//                     innerRef={register({
//                       validate: (value) =>
//                         value === password.current ||
//                         "The passwords do not match",
//                     })}
//                   />
//                 </InputGroup>
//                 <Label className="text-danger">
//                   {errors.password2 && (
//                     <span>{errors.password2.message}</span>
//                   )}
//                 </Label>
//               </FormGroup>

//               <FormGroup check>
//                 <Label check>
//                   <Input
//                     type="checkbox"
//                     name="terms"
//                     innerRef={register({
//                       required: "You must agree to Terms and Condition",
//                     })}
//                   />
//                   <span className="form-check-sign" />
//                   <div>
//                     I agree to the{" "}
//                     <a href="#something">terms and conditions</a>.
//                   </div>
//                 </Label>
//                 <Label className="text-danger">
//                   {errors.terms && <p>{errors.terms.message}</p>}
//                 </Label>
//               </FormGroup>
//             </Form>
//           </CardBody>
//           <CardFooter className="text-center">
//             <Button
//               color="primary"
//               size="lg"
//               className="btn-round"
//               onClick={handleSubmit(onSubmit)}
//             >
//               Get Started
//             </Button>
//           </CardFooter>
//         </Card>
//       </Col>
//     </Row>
//   </Container>
// </div>
// <div
//   className="full-page-background"
//   style={{ backgroundImage: "url(" + bgImage + ")" }}
// />
// {alert}
// </>

import React from "react";



// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col
} from "reactstrap";

import UploadFile from './Upload';
import UploadList from './UploadList';

const UploadedFiles = () => {
  


  return (
    <>
    <div className="content">
      <UploadList />
      
      <UploadFile />
      </div>
    </>
  );
};

export default UploadedFiles;

import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import "./style.css";

const BaseInfo = ({
  inventory,
  SelectedVinDecode,
  toggleDelete,
  toggleDelist,
  inventoryId,
}) => {
  const IsLive =
    inventory.status >= 4 <= 6 &&
    inventory.make &&
    inventory.model &&
    inventory.color;

  // console.log(inventory.status >= 4  <= 6)
  return (
    <>
      <Row>
        <Col className="ml-auto mr-auto" md="12">
          <Card>
            <CardBody>
              <CardTitle tag="h4">
                {inventory.year} {inventory.make} {inventory.model}{" "}
                <small>(Vehicle ID {inventory.id})</small>
              </CardTitle>
              <Row>
                <Col>
                  <label>Year</label>
                  <p>{inventory.year}</p>
                </Col>
                <Col>
                  <label>Make</label>
                  <p>{inventory.make}</p>
                </Col>
                <Col>
                  <label>Model</label>
                  <p>{inventory.model}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>VIN</label>
                  <p>{inventory.vin}</p>
                </Col>
                <Col>
                  <label>Kilometers (Mileage)</label>
                  <p>{inventory.kilometers}</p>
                </Col>
                <Col>
                  <label>Fuel</label>
                  <p>{inventory?.fuel_type}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Trim</label>
                  <p>{inventory.trim}</p>
                </Col>

                <Col>
                  <label>Color</label>
                  <p>{inventory.color}</p>
                </Col>
                <Col>
                  <label>Manufacturer Color</label>
                  <p>{inventory.mfd_color_code}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Type</label>
                  <p>{inventory.veh_type}</p>
                </Col>
                <Col>
                  <label>Body Type</label>
                  <p>{inventory.body_type}</p>
                </Col>
                <Col>
                  <label>Driving Wheels</label>
                  <p>{inventory.driving_wheels}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Condition</label>
                  <p>{inventory.condition_text}</p>
                </Col>
                <Col>
                  <label>Blackbook Category</label>
                  <p>{inventory.blackbook_category}</p>
                </Col>
                <Col>
                  <label>Transmission</label>
                  <p>{inventory.transmission_text}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Engine</label>
                  <p>{inventory.engine}</p>
                </Col>
                <Col>
                  <label>City Mileage</label>
                  <p>{inventory.city_mileage}</p>
                </Col>
                <Col>
                  <label>Highway Mileage</label>
                  <p>{inventory.highway_mileage}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>List Price</label>
                  <p>{inventory.list_price}</p>
                </Col>
                <Col>
                  <label>Doors</label>
                  <p>{inventory.pass_doors}</p>
                </Col>
                <Col>
                  <label>Seats (Capacity)</label>
                  <p>{inventory.pass_capacity}</p>
                </Col>
                <Col>
                  <label>Seat Rows</label>
                  <p>{inventory.seat_rows}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Highway mileage</label>
                  <p>{inventory.highway_mileage}</p>
                </Col>
                <Col>
                  <label>City Mileage</label>
                  <p>{inventory.city_mileage}</p>
                </Col>
                <Col>
                  <label>Displacement</label>
                  <p>{inventory.displacement_value}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Package Code</label>
                  <p>{inventory?.package_code}</p>
                </Col>
                <Col>
                  <label>Wheelbase</label>
                  <p>{inventory?.wheelbase}</p>
                </Col>
                <Col>
                  <label>Wheelbase Type</label>
                  <p>{inventory?.wheelbase_type}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Cab Type</label>
                  <p>{inventory?.cab_type}</p>
                </Col>
                <Col>
                  <label>Pickup Bedtype</label>
                  <p>{inventory?.pickup_bedtype}</p>
                </Col>
                <Col>
                  <label>Pickup Bedlength</label>
                  <p>{inventory?.pickup_bedlength}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Horsepower</label>
                  <p>{inventory.horsepower_value}</p>
                </Col>

                <Col>
                  <label>Torque</label>
                  <p>{inventory.torque_value}</p>
                </Col>
                <Col>
                  <label>Cylinders</label>
                  <p>{inventory.cylinders}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Location</label>
                  <p>{inventory.location_name}</p>
                </Col>
              </Row>
              <Alert
                // color={
                //   inventory.status >= 4 && inventory.status <= 6
                //     ? "success"
                //     : inventory.status < 6
                //     ? "warning"
                //     : "secondary"
                // }
                style={{
                  backgroundColor:
                    inventory.status >= 4 && inventory.status <= 6
                      ? "#1d4aa7"
                      : inventory.status < 6
                      ? "warning"
                      : "secondary",
                }}
              >
                <Row>
                  <Col className="text-right">Status : </Col>
                  <Col className="text-left">
                    {inventory.status >= 4 && inventory.status <= 6 ? (
                      <div className="icon icon-secondary">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                    ) : (
                      <div className="icon icon-danger">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    )}
                  </Col>
                  <Col className="text-right">Make : </Col>
                  <Col className="text-left">
                    {inventory.make ? (
                      <div className="icon icon-secondary">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                    ) : (
                      <div className="icon icon-danger">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    )}
                  </Col>
                  <Col className="text-right">Model :</Col>
                  <Col className="text-left">
                    {inventory.model ? (
                      <div className="icon icon-secondary">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                    ) : (
                      <div className="icon icon-danger">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    )}
                  </Col>

                  <Col className="text-right">Color :</Col>
                  <Col className="text-left">
                    {inventory.mfd_color_code ? (
                      <div className="icon icon-secondary">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                    ) : (
                      <div className="icon icon-danger">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </div>
                    )}
                  </Col>
                </Row>
              </Alert>
              <Row>
                <Col>
                  <label>Status</label>
                  <p>{inventory.status_text}</p>
                </Col>
                <Col>
                  <label>Vehicle In-date </label>
                  <p>
                    {inventory.veh_in_date} <br />
                    <small>
                      <Moment fromNow>{inventory.veh_in_date}</Moment>
                    </small>
                  </p>
                </Col>
                <Col>
                  <label>Vehicle Sold </label>
                  <p>
                    {inventory.sold_date} <br />
                    <small>
                      <Moment fromNow>{inventory.sold_date}</Moment>
                    </small>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Market Class</label>
                  <p>{inventory.market_class}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Web Page Slug</label>
                  <p>{inventory.slug}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Web Page Title</label>
                  <p>{inventory.title}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Meta Description</label>
                  <p>{inventory.meta_description}</p>
                </Col>
              </Row>
              <Row className="border-top pt-2 pb-2">
                <Col>
                  <label>Website views</label>
                  <p>{inventory.page_views}</p>
                </Col>
                <Col>
                  {/* <label>Leads</label>
                  <p>{inventory.page_views}</p> */}
                </Col>
              </Row>

              <div className="d-block d-flex justify-content-between">
                {inventoryId ? (
                  ""
                ) : (
                  <>
                    <Button
                      id="BaseRemove"
                      className="btn"
                      color="danger"
                      size="sm"
                      onClick={toggleDelete}
                    >
                      Full Delete
                    </Button>

                    {inventory.status > 6 ? (
                      ""
                    ) : (
                      <Button
                        id="BaseDelist"
                        className="btn"
                        color="warning"
                        size="sm"
                        onClick={toggleDelist}
                      >
                        Remove from Website
                      </Button>
                    )}

                    <Button
                      id="BasePopover"
                      className="btn"
                      disabled={inventory.status > 6}
                      color="info"
                      size="sm"
                      onClick={() => SelectedVinDecode(inventory.id)}
                      // to={`/admin/inventory-edit-1/${inventory.id}`}
                      // tag={Link}
                    >
                      {inventory.status > 6 ? "Sold" : "Edit Above Details"}
                    </Button>
                  </>
                )}
              </div>
              <Row>
                <Col>
                  <p className="description-for-button">
                    Full Delete: removes completely from website does not store
                    any information in the backend. Use it when you have to
                    delete every trace of vehicle from the backend.
                  </p>
                </Col>
                <Col>
                  <p className="description-for-button">
                    Remove from Website: removes all associated images and
                    retains some basic information in the backend. Use it when
                    you have to remove the inventory from website.
                  </p>
                </Col>
                <Col>
                  <p className="description-for-button">
                    Edit above details, Edit all information about the vehicle.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BaseInfo;


import { GET_IMAGEPROFILES, FETCH_IMAGEPROFILE, DELETE_IMAGEPROFILE, ADD_IMAGEPROFILE, EDIT_IMAGEPROFILE, CLEAR_ALL } from "constants/actions";
// const initialState = {
//     inventory: []
// };

export default function (state = {}, action) { 
    switch (action.type) {
        case GET_IMAGEPROFILES:
            return {
                ...state,
                imageprofiles: action.payload
            };

        case FETCH_IMAGEPROFILE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_IMAGEPROFILE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_IMAGEPROFILE:
            return {
                ...state,
                imageprofiles: state.imageprofiles.filter(Imageprofile => Imageprofile.id !== action.payload)
            };
        case ADD_IMAGEPROFILE:
            // console.log('Post Inventory ID', action.payload)
            return {
                ...state,
                imageprofiles: [...state.imageprofiles, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                imageprofiles: []
            };
        default:
            return state;
    }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_IMAGEPROFILEs:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, imageprofiles: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }

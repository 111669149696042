import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";

import _ from "lodash";

import {
  SG_FETCH_CUSTOMER_APPOINTMENT,
  UPDATE_WEB_COMMENT,
  LOAD_WEB_LEAD_TYPE,
  LOAD_WEB_LEAD_STAGE,
  SG_GET_SELECT_INVENTORIES,
  LOAD_LEADSOOURCES,
} from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

import MiniCreate from "TaskLists/MiniCreate";
import TaskList from "TaskLists/List";

import ButtonGrouped from "Utilities/ButtonGrouped";

const commentOptions = [
  { id: 1, name: "New" },
  { id: 2, name: "Under Review" },
  { id: 3, name: "Approved" },
  { id: 4, name: "Removed" },
  { id: 9, name: "Junk" },
];

const WebCommentDetail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const [webLead, setWebLead] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(null);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  const [newTaskAdded, setNewTaskAdded] = React.useState(0);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_CUSTOMER_APPOINTMENT, payload: id });
    }
  }, [dispatch]);

  const { get_customer_appointments, get_auth, get_errors } = useSelector(
    (state) => ({
      get_customer_appointments: state.customerappointment[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]

  React.useEffect(() => {
    if (get_customer_appointments) {
      setWebLead(get_customer_appointments);
      setCustomerId(get_customer_appointments.customer);
    }
  }, [get_customer_appointments, id]);
  
  function CreatedNewTask(value) {
    setNewTaskAdded(value);
  }


  // console.log('newTaskAdded', newTaskAdded)
  return (
    <>
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle tag="h4">Test Drive Details</CardTitle>

                      <Link to={`/admin/test-drives/`}>View Entire List</Link>
                    </Col>
                    <Col className="ml-auto" md="2">
                      {/* <Button
                        color="default"
                        size="sm"
                        to={`/admin/web-comment-create/${id}`}
                        tag={Link}
                      >
                        Edit
                      </Button> */}
                    </Col>
                  </Row>

                  <CardTitle tag="h4"></CardTitle>

                  <Row>
                    <Col>
                      <label>First Name </label>
                      <p>{webLead ? webLead.first_name : ""}</p>
                    </Col>

                    <Col>
                      <label>Last Name </label>
                      <p>{webLead ? webLead.last_name : ""}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {webLead.customer ? (
                        <Link to={`/admin/customer-detail/${webLead.customer}`}>
                          View Customer Details
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Phone</label>
                      <p>{webLead ? webLead.phone : ""}</p>
                    </Col>

                    <Col>
                      <label>Email</label>
                      <p>{webLead ? webLead.email : ""}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label>Vehicle Purchased</label>
                      {webLead.inventory ? (
                        <ListGroup flush>
                          <ListGroupItem className="list-group-item list-group-item-success">
                            <Row>
                              <Col>
                                <p>
                                  {webLead.inventory.year}{" "}
                                  {webLead.inventory.make}{" "}
                                  {webLead.inventory.model}{" "}
                                </p>
                              </Col>
                              <Col>
                                <Link
                                  to={`/admin/inventory-detail/${webLead.inventory.id}`}
                                >
                                  View
                                </Link>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      ) : (
                        <p>"None Selected"</p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label>Scheduled At</label>
                      <p>
                        {webLead
                          ? moment(webLead.scheduled_at).format(
                              "YYYY-MM-DD"
                            )
                          : ""}
                        <br />
                        {webLead
                          ? webLead.scheduled_at_text
                          : ""}
                      </p>
                    </Col>
                    <Col>
                      <label>Scheduled At</label>
                      <p>{webLead ? webLead.location_text : ""}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {customerId ? (
            <MiniCreate
              lead_id={id}
              customer_id={customerId}
              CreatedNewTask={CreatedNewTask}
            />
          ) : (
            ""
          )}

          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <CardTitle tag="h4">
                    Open Task Lists for this Customer
                  </CardTitle>
                  {customerId ? (
                    <TaskList
                      params={`customer=${customerId}&status=1`}
                      newTaskAdded={newTaskAdded}
                    />
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default WebCommentDetail;

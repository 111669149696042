import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";
import dayjs from "dayjs";
// import { Button as MUIButton } from "@mui/material";
import IntlMessages from "../crema/helpers/src/IntlMessages";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import { LOAD_ALL_EMPLOYEES, CREATE_TASK_LIST } from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Form,
  Input,
  Fade,
  Collapse,
  Row,
  Col,
} from "reactstrap";
// import Select from "react-select";
import ErrorBoundary from "ErrorBoundary";
import AddNewWebCommentUpload from "WebComments/AddNewWebCommentUpload";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Button as MUIButton,
} from "@mui/material";

const NewTask = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [taskList, setTaskList] = React.useState([]);
  const [rSelected, setRSelected] = React.useState(1);
  //   const [fadeIn, setFadeIn] = React.useState(false);
  const [currentDateTime, setCurrentDateTime] = React.useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );

  const [selEmployee, setSelEmployee] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const { get_task_list, get_employees, get_auth, get_errors } = useSelector(
    (state) => ({
      get_task_list: state.tasklist,
      get_employees: state.employees.employees,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelEmployee(get_employees);
  }, [get_employees]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const getValidDates = function (currentDate) {
    // only allow today and future dates
    var yesterday = moment().subtract(1, "day");
    return currentDate.isAfter(yesterday);
  };

  //   var getValidTimes = function (dateTime) {
  //     // date is today, so only allow future times
  //     if (moment().isSame(dateTime, "day")) {
  //       return {
  //         hours: {
  //           min: dateTime.hours(),
  //           max: 18,
  //           step: 1,
  //         },
  //         minutes: {
  //           min: dateTime.minutes(),
  //           max: 59,
  //           step: 5,
  //         },
  //       };
  //     }
  //   };
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  const onSubmit = (data, e) => {
    dispatch({
      type: CREATE_TASK_LIST,
      payload: {
        name: data.name,
        description: data.description,
        scheduled_at: moment(currentDateTime),
        status: rSelected,
        web_leads: props.lead_id,
        customer: props.customer_id,
        inventory: props.inventory_id,
        company: get_auth.dealer_id,
        employee: data.employee.id,
      },
    });
    props.CreatedNewTask(Math.floor(Math.random() * 1001));
    e.target.reset();
  };

  React.useEffect(() => {
    // console.log(selStatus);
    if (selEmployee) {
      setValue(
        "employee",
        get_employees.filter((employee) => employee.id === get_auth.employee_id)
      );

      setValue("scheduled_at", moment().format("YYYY-MM-DD hh:mm A"));
    }
  }, [selEmployee]);

  return (
    <>
      {/* {toDetail? <Redirect to={`/admin/lead-detail/${id}`}  /> : null}
    {toHome? <Redirect to={`/admin/leads`}  /> : null} */}
      <div className="content">
        <ErrorBoundary>
          <Row
            style={{
              margin: 0,
            }}
          >
            <Col className="ml-auto mr-auto" md="10">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <TextField
                    id="name"
                    name="name"
                    label="Task Name"
                    defaultValue={taskList ? taskList.name : ""}
                    inputRef={register({
                      required: "Please give a Name for this task",
                    })}
                    sx={{
                      width: "100%",
                    }}
                  />

                  {/* <label>Task Name </label>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={taskList ? taskList.name : ""}
                    innerRef={register({
                      required: "Please give a Name for this task",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.name && <span>{errors.name.message}</span>}
                  </p> */}
                </FormGroup>

                <Row>
                  <Col>
                    {/* <label>Schedule at</label> */}
                    <FormGroup>
                      <DatePicker
                        label="Schedule at"
                        value={dayjs(currentDateTime)}
                        onChange={(newValue) => {
                          setCurrentDateTime(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            error={null}
                          />
                        )}
                        inputRef={register({
                          required: "Please select a date",
                        })}
                        minDate={dayjs()}
                      />

                      {/* scheduled_at_text */}
                      {/* <ReactDatetime
                        isValidDate={getValidDates}
                        value={currentDateTime}
                        // timeConstraints={getValidTimes(currentDateTime)}
                        name="scheduled_at"
                        dateFormat="YYYY-MM-DD"
                        onChange={setCurrentDateTime}
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select Date",
                        }}
                        timeFormat={true}
                      /> */}
                      {/* <Controller
                              as={ReactDatetime}
                              control={control}
                              // value={currentDateTime}
                              // onChange={setCurrentDateTime}
                              isValidDate={getValidDates}
                              // timeConstraints={getValidTimes(currentDateTime)}
                              name="scheduled_at"
                              dateFormat="YYYY-MM-DD hh:mm A"
                              inputProps={{
                                className: "form-control",
                                placeholder: "Select Date",
                              }}
                              timeFormat={true}
                            /> */}
                      <p className="text-danger small">
                        {errors.scheduled_at && (
                          <span>{errors.scheduled_at.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>

                  <Col>
                    <div className="d-flex justify-content-end">
                      <div>
                        <label>
                          Status: {rSelected === 2 ? "Closed" : "Open"}
                        </label>
                        <br />
                        <ButtonGroup>
                          <Button
                            color={rSelected === 1 ? "danger" : "secondary"}
                            size="sm"
                            onClick={() => setRSelected(1)}
                            active={rSelected === 1}
                          >
                            Open
                          </Button>
                          <Button
                            color={rSelected === 2 ? "success" : "secondary"}
                            size="sm"
                            onClick={() => setRSelected(2)}
                            active={rSelected === 2}
                          >
                            Closed
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Col>
                </Row>

                <FormGroup>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel id="assigned-to-select-outlined-label">
                      <IntlMessages id="Assign To" />
                    </InputLabel>
                    <Select
                      labelId="assigned-to-select-outlined-label"
                      name="employee"
                      label={<IntlMessages id="Assign To" />}
                      onChange={(event) =>
                        setSelectedEmployee(event.target.value)
                      }
                      sx={{ width: "100%", height: 53 }}
                      inputProps={{ id: "employee", ...register("employee") }}
                    >
                      {selEmployee.map((staff) => {
                        return (
                          <MenuItem value={staff.id} key={staff.id}>
                            <Box display="flex" alignItems="center">
                              {staff.image ? (
                                <Avatar
                                  sx={{
                                    marginRight: 2,
                                  }}
                                  src={staff.image}
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    marginRight: 2,
                                  }}
                                >
                                  {staff.first_name.charAt(0)}
                                </Avatar>
                              )}
                              <span>
                                {staff.first_name + staff.last_name} -{" "}
                                {staff.role_text}
                              </span>
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </FormGroup>

                <FormGroup>
                  <TextField
                    multiline
                    rows="4"
                    id="description"
                    name="description"
                    label="Description"
                    defaultValue={taskList ? taskList.description : ""}
                    inputRef={register({
                      required: "Please give a Description for this task",
                    })}
                    sx={{
                      width: "100%",
                    }}
                  />

                  {/* <label>Description</label>
                  <Input
                    type="textarea"
                    defaultValue={taskList ? taskList.description : ""}
                    name="description"
                    id="description"
                    innerRef={register}
                  /> */}
                </FormGroup>

                {/* <FormGroup>
                  <label>Assign To</label>
                  <Controller
                    as={<Select />}
                    name="employee"
                    options={selEmployee}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name} - ${option.role_text}`
                    }
                    getOptionValue={(option) => `${option.id}`}
                    className="text-dark"
                    control={control}
                    rules={{ required: "Please enter Lead Stage" }}
                  />{" "}
                  <p className="text-danger small">
                    {errors.employee && <span>{errors.employee.message}</span>}
                  </p>
                </FormGroup> */}

                {/* <div className="d-flex justify-content-end">
                  
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </div> */}
                <div style={{ textAlign: "right" }}>
                  <div className="d-flex justify-content-end">
                    <MUIButton
                      color="preAuto"
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </MUIButton>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default NewTask;

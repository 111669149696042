import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

const InventoryLocation = ({ relatedDealers }) => {
  console.log(relatedDealers)
  return (
    <>
      <Row>
        <Col md="12">

          <Card>
            <CardHeader>
              <CardTitle tag="h4">Office Locations</CardTitle>
            </CardHeader>
            <CardBody>
              <ul className="list-unstyled team-members">
                {relatedDealers.map((p, k) => (
                  <li key={k}>
                    <Row>
                      <Col md="4" xs="12">
                        <strong>
                          {p.name}
                        </strong>

                      </Col>
                      <Col md="5" xs="12">
                        {p.address}
                      </Col>
                      <Col className="text-right" md="2" xs="12">
                        {p.phone}
                        {/* <Button
                            className="btn"
                            color="success"
                            outline
                            size="sm"
                          >
                            More Details
                          </Button> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                          {p.data_url}
                      </Col>
                    </Row>
                  </li>
                ))}



              </ul>
            </CardBody>
          </Card>
        </Col>

      </Row>

    </>
  );
};

export default InventoryLocation;
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { Table } from "react-bootstrap";
import { STRIPE_FETCH_INVOICE_TYPE } from "constants/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { FaAmazonPay } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";
export default function Invoice() {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    formState,
  } = useForm();
  const [invoices, setInvoices] = useState([]);

  const { get_invoices, get_auth } = useSelector(
    (state) => ({
      get_invoices: state.stripeinvoice.stripeinvoice,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_auth) {
      setAuth(get_auth?.dealer_id);
      dispatch({
        type: STRIPE_FETCH_INVOICE_TYPE,
        payload: {
          company_id: get_auth?.dealer_id,
        },
      });
      console.log("Auth", auth);
    }
    console.log("New Auth", auth);
  }, [get_auth]);

  React.useEffect(() => {
    if (get_invoices) {
      setInvoices(get_invoices?.data);
      console.log("datatratatta", get_invoices?.data);
    }
  }, [get_invoices]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Invoice</CardTitle>
                    {/* <h5 className="card-category">My Leads</h5> */}
                  </Col>
                  <Col className="ml-auto" md="2"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {/* <Elements stripe={stripePromise} options={options}>
                      ddddddd
                      <CheckoutForm />
                    </Elements> */}
                    {/* {invoices ? (
                invoices.map((invoice, k) => (
                  <tr key={k}>
                    <td>{invoice.hosted_invoice_url}</td>
                   
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No customers found</td>
                </tr>
              )} */}
                    {/* <Table responsive>
                      <thead className="table-active">
                        <tr>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Due</th>
                          <th>Payment Link</th>
                          <th>Invoice PDF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices ? (
                          invoices.map((invoice, k) => (
                            <tr key={k}>
                              <td>{(invoice.total / 100).toFixed(2)}</td>
                              <td>{invoice.status}</td>
                              <td>{invoice?.due_date}</td>
                              <td>
                                <a
                                  href={invoice?.hosted_invoice_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Pay
                                </a>
                              </td>
                              <td>
                                {" "}
                                <a
                                  href={invoice?.invoice_pdf}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Invoice PDF
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Invoice found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table> */}
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Amount</StyledTableCell>
                              <StyledTableCell align="center">
                                Status
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Due
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Payment Link
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Invoice PDF
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoices
                              ? invoices.map((invoice, k) => (
                                  <StyledTableRow key={k}>
                                    <StyledTableCell>
                                      {(invoice.total / 100).toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {invoice.status}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {invoice?.due_date}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      <a
                                        href={invoice?.hosted_invoice_url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <FaAmazonPay
                                          size={25}
                                          color="#1d4aa7"
                                        />
                                      </a>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <a
                                        href={invoice?.invoice_pdf}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <TbFileInvoice
                                          size={25}
                                          color="#1d4aa7"
                                        />
                                      </a>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              : "No Invoice found"}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
                    </Paper>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

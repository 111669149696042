import LeadsReport from "Dashboard/LeadsDashboard";
import InventoryReport from "Dashboard/InventoryDashboard";

import Company from "Company/index";
import CompanyDetail from "Company/Detail";
import CompanyCreate from "Company/CreateEdit";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import CompanyInformation from "CompanyInformation/index";
import CompanyInformationDetail from "CompanyInformation/Detail";
import CompanyInformationCreate from "CompanyInformation/CreateEdit";

import Dealer from "Dealer/index";

import Inventory from "Inventory/index";
// import LiveInventory from "Inventory/LiveInventory";
import InventoryDetail from "Inventory/InventoryDetails";
import InventoryList from "Inventory/InventoryList";
import BackendCompare from "Inventory/BackendCompare";
import XmlCompare from "Inventory/XmlCompare";
import GSheetCompare from "Inventory/GSheetCompare";
// import InventoryImageUpload from "Inventory/Images/NewImage.js";

// import BaseNew from "Inventory/CreateEdit/BaseNew";

// import BaseEdit from "Inventory/CreateEdit/BaseEdit";
// import Base1 from "Inventory/CreateEdit/Base1";
// import Base2 from "Inventory/CreateEdit/Base2";
// import Base3 from "Inventory/CreateEdit/Base3";
// import Base4 from "Inventory/CreateEdit/Base4";

// import BasicVinFileUpload from "Vinupload/Basic/index";
import FullVinFileUpload from "Vinupload/Full/index";

// import VinFileUploadDetail from "Vinupload/Basic/Detail";
// import BasicCreate from "Vinupload/Basic/BasicCreate";
// import BasicEdit from "Vinupload/Basic/BasicEdit";
// import BasicVinList from "Vinupload/Basic/VinList";

import FullVinFileUploadDetail from "Vinupload/Full/Detail";
import FullCreate from "Vinupload/Full/Create";
import FullEdit from "Vinupload/Full/Edit";
import FullVinList from "Vinupload/Full/VinList";

// import CreateWithVin from "Inventory/UploadVin/CreateWithVin"
// import VinEditUpdate from "Inventory/UploadVin/EditUpdate"
// import VinFileUploadList from "Inventory/UploadVin/List";
// import VinFileUploadNew from "Inventory/UploadVin/New";
// import VinFileUploadDetail from "Inventory/UploadVin/Detail";

import LandingPage from "LandingPage";
import CreateLandingPage from "LandingPage/Create";
import DetailLandingPage from "LandingPage/Detail";

import Employee from "Employees";
import CreateEmployee from "Employees/CreateEdit";
import DetailEmployee from "Employees/Detail";

import Customers from "Customers/index";
import CustomerDetail from "Customers/Detail";
import CustomerHistory from "Customers/History";
import CreditApplications from "CreditApplications/index";
import CreditApplicationDetail from "CreditApplications/Detail";

import Product from "Product/index";

import Leads from "Leads/index";
import LeadDetail from "Leads/Details";
import AllLeads from "Leads/AllLeads";
import CreateWebLead from "Leads/Create";

import TaskLists from "TaskLists/index";
import TaskListDetail from "TaskLists/Detail";
// import AllTaskLists from "TaskLists/AllTasks";
import TaskListCreate from "TaskLists/Create";

import LeadCampaign from "LeadCampaign";
import LeadCampaignDetail from "LeadCampaign/Detail";
import LeadCampaignCreate from "LeadCampaign/Create";

import LeadType from "LeadType";
import LeadTypeDetail from "LeadType/Detail";
import LeadTypeCreate from "LeadType/Create";

import LeadStage from "LeadStage";
import LeadStageDetail from "LeadStage/Detail";
import LeadStageCreate from "LeadStage/Create";

import TradeIns from "TradeIns";
import TradeInDetail from "TradeIns/Detail";

import TradeInAdmin from "TradeIns/AdminList";
import TradeInCreateEdit from "TradeIns/CreateEdit";

import TestDrive from "CustomerAppointments/index";
import TestDriveDetail from "CustomerAppointments/Details";
// import CreateTestDrive from "CustomerAppointments/Create"

import WebComments from "WebComments/index";
import WebCommentDetail from "WebComments/Details";
import CreateWebComment from "WebComments/Create";

import Login from "pages/Login.jsx";
import Logout from "pages/Logout.js";
import ForgotPassword from "pages/ForgotPassword.js";
import AcceptableUse from "pages/AcceptableUse.js";
import RegisterPage from "pages/RegisterPage.jsx";
import RegisterDealer from "pages/RegisterDealer.jsx";
import ResetPassword from "pages/ResetPassword.js";

import UserProfile from "User/UserProfile.jsx";

import LockScreen from "pages/LockScreen.jsx";
import Timeline from "pages/Timeline.jsx";

import NotFound from "pages/NotFound";
import UnAuthorized from "pages/UnAuthorized";
import Error from "pages/Error";
import Cms from "CMS/Cms";
import CashForCars from "CMS/CashForCars";
import ContactPageCMS from "CMS/ContactPageCMS";
import AboutCMS from "CMS/AboutCMS";
import TermsCMS from "CMS/TermsCMS";
import PrivacyPolicyCMS from "CMS/PrivacyPolicyCMS";
import Invoice from "invoice/Invoice";
import AdminInvioice from "invoice/AdminInvioice";
import CreateInvoice from "invoice/CreateInvoice";
import PaymentSuccess from "pages/paymentSuccess/PaymentSuccess";
import PaymentFailure from "pages/PaymentFailure/PaymentFailure";
import { SiGoogleadsense } from "react-icons/si";
import {
  FaTasks,
  FaUserCircle,
  FaUsers,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { AiFillDollarCircle, AiOutlinePieChart } from "react-icons/ai";
import { GoCodeOfConduct } from "react-icons/go";
import { MdOutlineInventory, MdPowerSettingsNew } from "react-icons/md";
import { TbSettingsFilled } from "react-icons/tb";
import { RiBankLine, RiPagesFill, RiTimerFlashLine } from "react-icons/ri";
import { GiCardExchange, GiEarthAmerica } from "react-icons/gi";
import { HiOutlineLogout, HiOutlineUser } from "react-icons/hi";
import { PiUserCircleFill } from "react-icons/pi";
import { LiaMosqueSolid } from "react-icons/lia";
import { BsFillJournalBookmarkFill } from "react-icons/bs";

const routes = [
  {
    id: 8,
    path: "/dashboard-sales",
    visible: true,
    authentication: true,
    isDealer: true,
    // isStaff: false,
    name: "Dashboard",
    // role: 99,
    icon: <SiGoogleadsense size={25} />,
    component: LeadsReport,
    layout: "/admin",
  },
  {
    collapse: true,
    visible: true,
    authentication: true,
    role: 9,
    isStaff: true,
    name: "Dealers",
    icon: <RiBankLine size={25} />,
    state: "companyCollapse",
    views: [
      {
        path: "/company",
        visible: true,
        authentication: true,
        name: "All",
        mini: "DL",
        role: 5,
        isStaff: true,
        component: Company,
        layout: "/admin",
      },
      {
        path: "/company-create/:id?",
        isDealer: true,

        visible: false,
        authentication: true,
        name: "Create Edit",
        role: 5,
        isStaff: true,
        component: CompanyCreate,
        layout: "/admin",
      },
      {
        path: "/company-detail/:id",
        visible: false,
        authentication: true,
        name: "Details",
        role: 5,
        isStaff: true,
        component: CompanyDetail,
        layout: "/admin",
      },
      {
        path: "/comp-info",
        visible: false,
        authentication: true,
        name: "Info",
        mini: "DL",
        role: 5,
        isStaff: true,
        component: CompanyInformation,
        layout: "/admin",
      },
      {
        path: "/comp-info-create/:id?",
        visible: false,
        authentication: true,
        name: "Info Create Edit",
        role: 5,
        isStaff: true,
        component: CompanyInformationCreate,
        layout: "/admin",
      },
      {
        path: "/comp-info-detail/:id",
        visible: false,
        authentication: true,
        name: "Info Details",
        role: 5,
        isStaff: true,
        component: CompanyInformationDetail,
        layout: "/admin",
      },
      {
        path: "/employees/:companyid?",
        visible: false,
        authentication: true,
        role: 5,
        name: "List",
        role: 1,
        isStaff: true,
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/employee-create/:companyid/:id?",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "Create/Update",

        component: CreateEmployee,
        layout: "/admin",
      },
      {
        path: "/employee-detail/:companyid/:id",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "Profile",
        component: DetailEmployee,
        layout: "/admin",
      },
      {
        path: "/product-list",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "Product List",
        mini: "IC",
        component: Product,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/admin-invoice",
    visible: true,
    authentication: true,
    isStaff: true,
    name: "Invoice",
    role: 9,
    icon: <AiOutlinePieChart size={25} />,
    component: AdminInvioice,
    layout: "/admin",
  },
  {
    path: "/create-invoice",
    visible: false,
    authentication: true,
    isStaff: true,
    name: "Invoice",
    role: 9,
    icon: <AiOutlinePieChart size={25} />,
    component: CreateInvoice,
    layout: "/admin",
  },

  {
    path: "/dashboard-inventory",
    visible: true,
    authentication: true,
    isStaff: false,
    name: "Sales Metrics",
    role: 99,
    icon: <AiFillDollarCircle size={25} />,
    component: InventoryReport,
    layout: "/admin",
  },
  {
    path: "/task-lists",
    visible: true,
    authentication: true,
    isStaff: false,
    role: 99,
    icon: <FaTasks size={25} />,
    name: "Tasks",
    mini: "T",
    component: TaskLists,
    layout: "/admin",
  },

  {
    id: 8,
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    role: 9,
    name: "Leads",
    icon: <GoCodeOfConduct size={25} />,
    state: "creditAppCollapse",
    views: [
      {
        path: "/leads",
        visible: true,
        isStaff: false,
        authentication: true,
        role: 9,
        name: "All Leads",
        mini: "I",
        component: Leads,
        layout: "/admin",
      },
      {
        id: 1300,
        path: "/credit-applications",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Credit Applications",
        mini: "A",
        component: CreditApplications,
        layout: "/admin",
      },
      {
        id: 1300,
        path: "/credit-application-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Credit Applications",
        mini: "A",
        component: CreditApplicationDetail,
        layout: "/admin",
      },
      {
        path: "/web-comments",
        visible: true,
        authentication: true,
        name: "Website Comments",
        role: 9,
        isStaff: false,
        mini: "C",
        component: WebComments,
        layout: "/admin",
      },
      {
        path: "/web-comment-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Comments Detail",
        mini: "D",
        component: WebCommentDetail,
        layout: "/admin",
      },
      {
        path: "/web-comment-create/:id?",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "New Comments",
        mini: "C",
        component: CreateWebComment,
        layout: "/admin",
      },
      {
        path: "/trade-ins",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Trade Ins",
        mini: "T",
        component: TradeIns,
        layout: "/admin",
      },
      {
        path: "/trade-in-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Trade Ins Details",
        mini: "T",
        component: TradeInDetail,
        layout: "/admin",
      },
      {
        path: "/trade-in-create/:id?",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Trade Create Edit",
        mini: "T",
        component: TradeInCreateEdit,
        layout: "/admin",
      },
      {
        path: "/test-drives",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Test Drives",
        mini: "D",
        component: TestDrive,
        layout: "/admin",
      },
      {
        path: "/test-drive-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Test Drive Details",
        mini: "T",
        component: TestDriveDetail,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    visible: false,
    isStaff: false,
    authentication: true,
    role: 99,
    name: "Task List",
    icon: <RiTimerFlashLine size={25} />,
    state: "taskListCollapse",
    views: [
      {
        path: "/task-list-detail/:id",
        visible: false,
        authentication: true,
        role: 99,
        isStaff: false,
        name: "Task List Detail",
        mini: "T",
        component: TaskLists,
        layout: "/admin",
      },
      {
        path: "/task-list-create/:id?",
        visible: false,
        authentication: true,
        role: 99,
        isStaff: false,
        name: "Task List Detail",
        mini: "T",
        component: TaskListCreate,
        layout: "/admin",
      },
    ],
  },
  {
    id: 8,
    collapse: true,
    visible: false,
    authentication: true,
    isStaff: false,
    name: "Leads",
    role: 9,
    icon: <HiOutlineUser size={25} />,
    state: "leadCollapse",
    views: [
      {
        path: "/lead-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Lead Details",
        mini: "L",
        component: LeadDetail,
        layout: "/admin",
      },
      {
        path: "/lead-create/:id?",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Lead Details",
        mini: "L",
        component: CreateWebLead,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    visible: false,
    isStaff: false,
    authentication: true,
    name: "Web Comments",
    role: 9,
    icon: <PiUserCircleFill size={25} />,
    state: "webcommentCollapse",
    views: [
      {
        path: "/web-comment-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Comments Detail",
        mini: "D",
        component: WebCommentDetail,
        layout: "/admin",
      },
      {
        path: "/web-comment-create/:id?",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "New Comments",
        mini: "C",
        component: CreateWebComment,
        layout: "/admin",
      },
    ],
  },
  // {
  //   collapse: true,
  //   visible: true,
  //   isStaff: false,
  //   authentication: true,
  //   role: 5,
  //   name: "Lead Reports",
  //   icon: "nc-icon nc-vector",
  //   state: "leadTypeCollapse",
  //   views: [
  //      {
  //       path: "/all-leads",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "All Leads",
  //       mini: "L",
  //       component: AllLeads,
  //       layout: "/admin"
  //     },
  //   ]
  // },
  // {
  //   collapse: true,
  //   visible: true,
  //   authentication: true,
  //   role: 5,
  //   name: "Team",
  //   icon: "nc-icon nc-badge",
  //   state: "teamCollapse",
  //   views: [
  //     {
  //       path: "/all-task-lists",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       name: "All Tasks",
  //       mini: "T",
  //       component: AllTaskLists,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/all-leads",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       name: "All Leads",
  //       mini: "T",
  //       component: AllLeads,
  //       layout: "/admin"
  //     },

  //   ]
  // },
  {
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    role: 9,
    name: "Inventory",
    icon: <MdOutlineInventory size={25} />,
    state: "inventoryCollapse",
    views: [
      // {
      //   path: "/live-inventory",
      //   visible: true,
      //   authentication: true,
      //   role: 9,
      //   isStaff: false,
      //   name: "Live Inventory",
      //   mini: "I",
      //   component: LiveInventory,
      //   layout: "/admin"
      // },
      {
        path: "/inventory",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "All Inventory",
        mini: "I",
        component: Inventory,
        layout: "/admin",
      },

      {
        path: "/inventory-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Inventory Detail",
        mini: "IC",
        component: InventoryDetail,
        layout: "/admin",
      },
      {
        path: "/list-inventory",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Inventory List",
        mini: "IL",
        component: InventoryList,
        layout: "/admin",
      },
      {
        path: "/external-inventory",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Backend Data",
        mini: "BD",
        component: BackendCompare,
        layout: "/admin",
      },
      {
        path: "/xml-inventory",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Backend Compare",
        mini: "X",
        component: XmlCompare,
        layout: "/admin",
      },
      {
        path: "/gsheet-inventory",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Gsheet Compare",
        mini: "X",
        component: GSheetCompare,
        layout: "/admin",
      },
      {
        id: 1200,
        path: "/admin/trade-in-create",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "BlackBook",
        mini: "BB",
        component: TradeInAdmin,
        layout: "/admin",
      },
      //
    ],
  },
  //
  // {
  //   collapse: true,
  //   visible: true,
  //   authentication: true,
  //   isStaff: false,
  //   name: "Vin Decode Basic",
  //   role: 5,
  //   icon: "nc-icon nc-circle-10",
  //   state: "videcodebasicCollapse",
  //   views: [
  //     {
  //       path: "/basic-vin-decode-create/",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "Create Vin - Basic",
  //       mini: "IC",
  //       component: BasicCreate,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/basic-vin-decode-file",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "File Upload",
  //       mini: "IC",
  //       component: BasicVinFileUpload,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/basic-vin-decode-list",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "Vin Decoded List",
  //       mini: "IC",
  //       component: BasicVinList,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vin-decode-file-detail/:id",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "File Upload Detail",
  //       mini: "IC",
  //       component: VinFileUploadDetail,
  //       layout: "/admin"
  //     },

  //     {
  //       path: "/basic-vin-decode-edit/:id?",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "Basic Vin Edit",
  //       mini: "IC",
  //       component: BasicEdit,
  //       layout: "/admin"
  //     },
  //     // {
  //     //   path: "/basic-vin-decode-create-edit/:id?",
  //     //   visible: true,
  //     //   authentication: true,
  //     //   role: 5,
  //     //   isStaff: false,
  //     //   name: "Basic Vin Create Edit",
  //     //   mini: "VC",
  //     //   component: BasicCreateEdit,
  //     //   layout: "/admin"
  //     // },
  //     // /
  //   ]
  //   },
  //       import  from "Vinupload/Full/Detail";
  // import  from "Vinupload/Full/Create";
  // import  from "Vinupload/Basic/Edit";
  {
    id: 1100,
    collapse: true,
    visible: true,
    authentication: true,
    isStaff: false,
    name: "Vin Decode Full",
    role: 5,
    icon: <FaUserCircle size={25} />,
    state: "videcodeFullCollapse",
    views: [
      {
        path: "/full-vin-decode-create/",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Create Vin - Full",
        mini: "IC",
        component: FullCreate,
        layout: "/admin",
      },
      {
        path: "/full-vin-decode-file",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "File Upload",
        mini: "IC",
        component: FullVinFileUpload,
        layout: "/admin",
      },
      {
        path: "/full-vin-decode-list",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Vin Decoded List",
        mini: "IC",
        component: FullVinList,
        layout: "/admin",
      },
      {
        path: "/full-vin-decode-file-detail/:id",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "File Upload Detail",
        mini: "IC",
        component: FullVinFileUploadDetail,
        layout: "/admin",
      },

      {
        path: "/full-vin-decode-edit/:vinid?/:id?/:fileid?",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Full Vin Edit",
        mini: "IC",
        component: FullEdit,
        layout: "/admin",
      },

      // {
      //   path: "/basic-vin-decode-create-edit/:id?",
      //   visible: true,
      //   authentication: true,
      //   role: 5,
      //   isStaff: false,
      //   name: "Basic Vin Create Edit",
      //   mini: "VC",
      //   component: BasicCreateEdit,
      //   layout: "/admin"
      // },
      //
    ],
  },

  // {
  //   collapse: true,
  //   visible: true,
  //   authentication: true,
  //   isStaff: false,
  //   name: "Vin Decode Full",
  //   role: 5,
  //   icon: "nc-icon nc-circle-10",
  //   state: "fileuploadCollapse",
  //   views: [
  //     {
  //       path: "/file-upload",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "File Upload",
  //       mini: "IC",
  //       component: VinFileUploadList,
  //       layout: "/admin"
  //     },

  //     {
  //       path: "/image-inventory-upload",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "Inventory Image Upload",
  //       mini: "IM",
  //       component: InventoryImageUpload,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/file-upload-new",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "File Upload",
  //       mini: "IC",
  //       component: VinFileUploadNew,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/file-uploaded/:id",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "File Upload Detail",
  //       mini: "IC",
  //       component: VinFileUploadDetail,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/create-inventory",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "New Manual",
  //       mini: "IC",
  //       component: BaseNew,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vin-create-inventory",
  //       visible: true,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "New with VIN",
  //       mini: "IC",
  //       component: CreateWithVin,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/inventory-vin-edit/:id",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "Edit VIN",
  //       mini: "IC",
  //       component: VinEditUpdate,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/inventory-edit-1/:id",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "Edit Inventory",
  //       mini: "IC",
  //       component: BaseEdit,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/inventory-edit-notes/:id",
  //       visible: false,
  //       authentication: true,
  //       role: 5,
  //       isStaff: false,
  //       name: "New Inventory",
  //       mini: "IC",
  //       component: Base1,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/inventory-edit-2/:id",
  //       visible: false,
  //       authentication: true,
  //       name: "New Inventory",
  //       role: 5,
  //       isStaff: false,
  //       mini: "IC",
  //       component: Base2,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/inventory-edit-3/:id",
  //       visible: false,
  //       authentication: true,
  //       name: "New Inventory",
  //       role: 5,
  //       isStaff: false,
  //       mini: "IC",
  //       component: Base3,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/inventory-edit-4/:id",
  //       visible: false,
  //       authentication: true,
  //       name: "New Inventory",
  //       role: 5,
  //       isStaff: false,
  //       mini: "IC",
  //       component: Base4,
  //       layout: "/admin"
  //     },
  //   ]

  // },
  {
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    name: "Customers",
    role: 9,
    icon: <FaUsers size={25} />,
    state: "customerCollapse",
    views: [
      {
        path: "/customers",
        visible: true,
        authentication: true,
        name: "All Customers",
        role: 9,
        isStaff: false,
        mini: "C",
        component: Customers,
        layout: "/admin",
      },
      {
        path: "/customer-detail/:id",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Customer Detail",
        mini: "D",
        component: CustomerDetail,
        layout: "/admin",
      },
      {
        path: "/customer-history",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Customer History",
        mini: "H",
        component: CustomerHistory,
        layout: "/admin",
      },
      {
        path: "/customer-create",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "New Customer",
        mini: "C",
        component: Customers,
        layout: "/admin",
      },
      {
        path: "/customer-edit",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Edit Customer",
        mini: "C",
        component: Customers,
        layout: "/admin",
      },
    ],
  },

  {
    id: 8,
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    role: 5,
    name: "Lead Settings",
    icon: <TbSettingsFilled size={25} />,
    state: "leadCampaignCollapse",
    views: [
      {
        path: "/lead-campaigns",
        isDealer: true,
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead-Campaigns",
        mini: "I",
        component: LeadCampaign,
        layout: "/admin",
      },
      {
        path: "/lead-campaign-detail/:id",
        isDealer: true,
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead Campaign Details",
        mini: "A",
        component: LeadCampaignDetail,
        layout: "/admin",
      },
      {
        path: "/lead-campaign-detail/:id",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "lead Campaign Details",
        mini: "A",
        component: LeadCampaignDetail,
        layout: "/admin",
      },
      {
        path: "/lead-campaign-create/:id?",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead Campaign Create",
        mini: "A",
        component: LeadCampaignCreate,
        layout: "/admin",
      },
      {
        path: "/lead-campaign-create/:id?",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "lead Campaign Create",
        mini: "A",
        component: LeadCampaignCreate,
        layout: "/admin",
      },
      {
        path: "/lead-types",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "lead types",
        mini: "I",
        component: LeadType,
        layout: "/admin",
      },
      {
        path: "/lead-type-detail/:id",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "lead Type Details",
        mini: "A",
        component: LeadTypeDetail,
        layout: "/admin",
      },
      {
        path: "/lead-type-create/:id?",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "lead Type Create",
        mini: "A",
        component: LeadTypeCreate,
        layout: "/admin",
      },
      {
        path: "/lead-stages",
        visible: true,
        isDealer: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead stage",
        mini: "I",
        component: LeadStage,
        layout: "/admin",
      },
      {
        path: "/lead-stage-detail/:id",
        visible: false,
        isDealer: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead Stage Details",
        mini: "A",
        component: LeadStageDetail,
        layout: "/admin",
      },
      {
        path: "/lead-stage-detail/:id",
        visible: false,
        isDealer: true,
        authentication: true,
        role: 5,
        isStaff: true,
        name: "lead Stage Details",
        mini: "A",
        component: LeadStageDetail,
        layout: "/admin",
      },
      {
        path: "/lead-stage-create/:id?",
        visible: false,
        authentication: true,
        isDealer: true,
        role: 5,
        isStaff: false,
        name: "lead Stage Create",
        mini: "A",
        component: LeadStageCreate,
        layout: "/admin",
      },
      {
        path: "/lead-stage-create/:id?",
        visible: false,
        authentication: true,
        isDealer: true,
        role: 5,
        isStaff: true,
        name: "lead Stage Create",
        mini: "A",
        component: LeadStageCreate,
        layout: "/admin",
      },
      {
        path: "/lead-typess",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead Type",
        mini: "I",
        component: LeadType,
        layout: "/admin",
      },
      {
        path: "/lead-type-detail/:id",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead type Details",
        mini: "A",
        component: LeadTypeDetail,
        layout: "/admin",
      },
      {
        path: "/lead-type-create/:id?",
        isDealer: true,
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "lead type Create",
        mini: "A",
        component: LeadTypeCreate,
        layout: "/admin",
      },
    ],
  },

  {
    id: 1400,
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    role: 5,
    name: "Landing Page",
    icon: <RiPagesFill size={25} />,
    state: "landingpageCollapse",
    views: [
      {
        path: "/landing-pages",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "landing page",
        mini: "I",
        component: LandingPage,
        layout: "/admin",
      },
      {
        path: "/landing-page-detail/:id",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "landing page Details",
        mini: "A",
        component: DetailLandingPage,
        layout: "/admin",
      },
      {
        path: "/landing-page-create/:id?",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "landing page Create",
        mini: "A",
        component: CreateLandingPage,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    role: 5,
    name: "Dealer",
    icon: <GiCardExchange size={25} />,
    state: "dealerCollapse",
    views: [
      {
        path: "/dealer",
        visible: true,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Profile",
        mini: "I",
        component: Dealer,
        layout: "/admin",
      },
    ],
  },
  {
    id: 9900,
    collapse: true,
    visible: true,
    isStaff: false,
    authentication: true,
    role: 9,
    name: "Website Editor",
    icon: <GiEarthAmerica size={25} />,
    state: "cmsAppCollapse",
    views: [
      {
        path: "/home-page",
        visible: true,
        isStaff: false,
        authentication: true,
        role: 9,
        name: "Home Page",
        mini: "I",
        component: Cms,
        layout: "/admin",
      },
      {
        path: "/contact-page",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Contact Page",
        mini: "I",
        component: ContactPageCMS,
        layout: "/admin",
      },
      {
        path: "/cash-for-car",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Cash For Cars",
        mini: "I",
        component: CashForCars,
        layout: "/admin",
      },
      {
        path: "/about-page",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "About",
        mini: "I",
        component: AboutCMS,
        layout: "/admin",
      },
      {
        path: "/terms-&-conditions",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Terms & Conditions",
        mini: "I",
        component: TermsCMS,
        layout: "/admin",
      },
      {
        path: "/privacy-policy",
        visible: true,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Privacy Policy",
        mini: "I",
        component: PrivacyPolicyCMS,
        layout: "/admin",
      },
      {
        path: "/cash-for-cars",
        visible: false,
        authentication: true,
        role: 9,
        isStaff: false,
        name: "Test Drive Details",
        mini: "T",
        component: TestDriveDetail,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    visible: false,
    isStaff: false,
    authentication: true,
    role: 5,
    name: "Employees",
    icon: <LiaMosqueSolid size={25} />,
    state: "employeeCollapse",
    views: [
      {
        path: "/employees",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "List",
        mini: "I",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/employee-create/:id?",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Create/Update",
        mini: "I",
        component: CreateEmployee,
        layout: "/admin",
      },
      {
        path: "/employee-detail/:id",
        visible: false,
        authentication: true,
        role: 5,
        isStaff: false,
        name: "Profile",
        mini: "I",
        component: DetailEmployee,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    visible: false,
    isStaff: null,
    authentication: true,
    name: "Pages",
    role: 99,
    icon: <BsFillJournalBookmarkFill size={25} />,
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        visible: false,
        isStaff: null,
        authentication: true,
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "Forgot Password",
        mini: "L",
        component: ForgotPassword,
        layout: "/auth",
      },

      {
        path: "/reset-password",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "Reset Password",
        mini: "R",
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/register",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "Register",
        mini: "R",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/dealer-register",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "Register",
        mini: "R",
        component: RegisterDealer,
        layout: "/auth",
      },
      {
        path: "/lock-screen",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth",
      },
      {
        path: "/user-profile",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
      },
      {
        path: "/change-password",
        visible: false,
        role: 99,
        isStaff: null,
        authentication: true,
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/invoice",
    visible: true,
    authentication: true,
    isStaff: false,
    name: "Invoice",
    role: 99,
    icon: <FaFileInvoiceDollar size={25} />,
    component: Invoice,
    layout: "/admin",
  },

  {
    path: "/logout",
    visible: true,
    isDealer: true,
    authentication: true,
    isStaff: null,
    role: 99,
    name: "Logout",
    icon: <HiOutlineLogout size={25} />,
    component: Logout,
    layout: "/auth",
  },

  {
    path: "/acceptable-use-policy",
    visible: false,
    authentication: false,
    isStaff: null,
    role: 99,
    name: "Logout",
    icon: <MdPowerSettingsNew size={25} />,
    component: AcceptableUse,
    layout: "/auth",
  },
  {
    path: "/un-authorized",
    visible: false,
    isStaff: null,
    authentication: true,
    role: 99,
    name: "Un Authorized",
    component: UnAuthorized,
    layout: "/auth",
  },
  {
    path: "/not-found",
    visible: false,
    isStaff: null,
    authentication: true,
    role: 99,
    name: "Not Found",
    component: NotFound,
    layout: "/auth",
  },
  {
    path: "/try-again",
    visible: false,
    isStaff: null,
    authentication: true,
    role: 99,
    name: "Not Found",
    component: Error,
    layout: "/auth",
  },
  {
    path: "/success/",
    visible: false,
    isStaff: null,
    authentication: false,
    role: 99,
    name: "Payment Success",
    component: PaymentSuccess,
    layout: "/payment",
  },
  {
    path: "/failed/",
    visible: false,
    isStaff: null,
    authentication: false,
    role: 99,
    name: "Payment Failure",
    component: PaymentFailure,
    layout: "/payment",
  },
];

export default routes;

import React, { useState } from "react";
import _ from "lodash";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";


import Moment from "moment";
// import CreateForm from "./CreateForm"
import {
  FETCH_INVENTORY_ONE,
  SG_FETCH_VIN_UPLOAD,
  SG_EDIT_INVENTORY,
  CREATE_INVENTORY,
  DESTROY_INVENTORY,
  SG_FETCH_RELATED_DEALER,
  RESET_UPDATED_INVENTORY,
  SG_ADD_VIN_UPLOAD,
  SG_EDIT_VIN_UPLOAD,
} from "constants/actions";


const CreatEdit = (props) => {
  const dispatch = useDispatch();


  const search = window.location.search;
  const params = new URLSearchParams(search);

  let ReturnUrl = params.get("return-url");

  const [inventory, setInventory] = React.useState([]);
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
 //

  const [vinForm, setVinForm] = React.useState(false);

  const [pageErrors, setPageErrors] = React.useState(false);

  //
  const [decodedVin, setDecodedVin] = React.useState([]);


  const [blackBookList, setBlackBookList] = React.useState([]);
  const [blackBookData, setBlackBookData] = React.useState([]);

  const [decodedStyle, setDecodedStyle] = React.useState([]);
  const [decodedEngine, setDecodedEngine] = React.useState([]);
  const [decodedExtColor, setDecodedExtColor] = React.useState([]);
  const [decodedIntColor, setDecodedIntColor] = React.useState([]);
  //
  const [style, setStyle] = React.useState([]);
  const [styleId, setStyleId] = React.useState([]);
  const [color, setColor] = React.useState([]);
  const [intColor, setIntColor] = React.useState([]);

  const [engine, setEngine] = React.useState([]);

  const [mileage, setMileage] = React.useState(0);
  const [blackBookCategory, setBlackBookCategory] = React.useState(0);

  // const [selectedEngine, setSelectedEngine] = React.useState([]);

  const [vinDecodeError, setVinDecodeError] = React.useState(false);
  const [vinDecodeMessage, setVinDecodeMessage] = React.useState(false);
  const [blackBookMessage, setBlackBookMessage] = React.useState(false);

  const [standardEquipment, setStandardEquipment] = React.useState([]);
  const [genericEquipment, setGenericEquipment] = React.useState([]);
  const [technicalSpecification, setTechnicalSpecification] = React.useState([]);
  const [consumerInformation, setConsumerInformation] = React.useState([]);

  const [factoryImages, setFactoryImages] = React.useState([]);

  const [metaDescription, setMetaDescription] = React.useState([]);

  const [isStyleOpen, setIsStyleOpen] = useState(false);
  const [isExtColOpen, setIsExtColOpen] = useState(false);
  const [isEngineOpen, setIsEngineOpen] = useState(false);
  const [isIntColOpen, setIsIntColOpen] = useState(false);
  const [isBlackBookOpen, setIsBlackBookOpen] = useState(false)

  const [inventoryId, setInventoryId] = useState(props.id);
  const [vindecodeId, setVindecodeId] = useState(props.vinid);

  const [disableVinDecode, setDisableVinDecoder] = useState(true);

  const [inventoryCreated, setInventoryCreated] = React.useState(null);
  const [toHome, setToHome] = React.useState(false);
  const [toId, settoId] = React.useState(false);


  // const toggle = () => setIsOpen(!isOpen);


  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (props.id) {
      setInventoryId(props.id);
    }
    if (props.vinid) {
      setVindecodeId(props.vinid);
    }
  }, [props]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.

    // dispatch(getPaginatedInventories(activePage, pageparams))
    if (inventoryId) {
      dispatch({type: RESET_UPDATED_INVENTORY})
      dispatch({ type: FETCH_INVENTORY_ONE, payload: inventoryId });
      settoId(false)
    }
    if (vindecodeId) {
      dispatch({type: RESET_UPDATED_INVENTORY})
      dispatch({ type: SG_FETCH_VIN_UPLOAD, payload: vindecodeId });
      settoId(false)
    }
    dispatch({type: SG_FETCH_RELATED_DEALER})
  }, [dispatch, vindecodeId, inventoryId]);

  const { get_inventory, get_inventory_updated,
     decoded_vin, inventory_created, get_errors,
     related_dealer } = useSelector(
    (state) => ({
      get_inventory: state.inventories[parseInt(inventoryId)],
      get_inventory_updated: state.inventories.updated,
      decoded_vin: state.vinupload,
      get_errors: state.errors,
      related_dealer: state.dealers.related,
      inventory_created: state.inventories.created[0],
    }),
    shallowEqual
  );



  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (decoded_vin[vinForm]) {
      setVindecodeId(decoded_vin[vinForm].id);
    }
  }, [decoded_vin, vinForm]);


React.useEffect(() => {
  if (!_.isEmpty(get_errors.msg)) {
 setPageErrors(JSON.stringify(get_errors.msg.data))
  }
 
}, [get_errors])



  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    // console.log(vinForm.length, inventory)
    if (vinForm.length === 17 && inventory.length === 0 && !vindecodeId) {
      // console.log(vinForm.length, inventory.length === 0)
      setDisableVinDecoder(false);
    } else {
      // console.log(vinForm.length, inventory)
      setDisableVinDecoder(true);
    }
  }, [vinForm, inventory]);

  function EngineDetails(engine) {
    const cylinders = engine.cylinders;

    let displace = {};
    if (engine.displacement["value"].length > 0) {
      displace = {
        unit: engine.displacement["value"][0]["@unit"],
        value: engine.displacement["value"][0]["#text"],
      };
    } else {
      displace = {
        unit: engine.displacement["value"]["@unit"],
        value: engine.displacement["value"]["#text"],
      };
    }

    let fuelCapacity = {};
    if (engine.fuelCapacity) {
      fuelCapacity = {
        unit: engine.fuelCapacity["@unit"],
        value: engine.fuelCapacity["@high"],
      };
    }

    let fuelEconomy = {};
    if (engine.fuelEconomy) {
      fuelEconomy = {
        unit: engine.fuelEconomy["@unit"],
        city: engine.fuelEconomy.city["@high"],
        hwy: engine.fuelEconomy.hwy["@high"],
      };
    }

    let horsepower = {};
    if (engine.horsepower) {
      horsepower = {
        rpm: engine.horsepower["@rpm"],
        value: engine.horsepower["@value"],
      };
    }

    let torque = {};
    if (engine.netTorque) {
      torque = {
        rpm: engine.netTorque["@rpm"],
        value: engine.netTorque["@value"],
      };
    }
    // const Displacment =   Displace ? `${Displace.value} ${Displace.unit} ` : ""
    const label = `${displace ? `${displace.value} ${displace.unit} ` : ""} ${
      horsepower.value ? `${horsepower.value}HP` : ""
    } ${engine.engineType["#text"]}`;
    const EngineInfo = {
      label,
      cylinders,
      displace,
      fuelCapacity,
      fuelEconomy,
      horsepower,
      torque,
    };

    return EngineInfo;
  }
  React.useEffect(() => { 
    if (decodedStyle.division) {
      // console.log(mileage)
setMetaDescription(`Used ${decodedStyle['@modelYear']} ${decodedStyle.division['#text']} ${decodedStyle.model['#text']} ${decodedStyle['@name']} with ${mileage} kms many more `)
    }
    // console.log(mileage)

  }, [decodedExtColor, decodedIntColor, decodedStyle, mileage ])

  React.useEffect(() => {
    
    if ((decodedExtColor, decodedStyle)) {
      if (decodedExtColor["@colorCode"]) {
        const ColorCode = decodedExtColor["@colorCode"];
        
        
        const ColorShots = ["01", "02", "03"];
        const ShotImages = [];
        let id = 1;
        for (var value of ColorShots) {
          let xlarge,
            large,
            medium,
            small = "";

          decodedStyle.mediaGallery.colorized
            .filter(
              (fl) =>
                fl["@primaryColorOptionCode"] === ColorCode &&
                fl["@shotCode"] === value &&
                fl["@backgroundDescription"] === "Transparent"
            )
            .map((p) => {
              if (p["@width"] === "2100") {
                xlarge = p["@url"];
              }
              if (p["@width"] === "1280") {
                large = p["@url"];
              }
              if (p["@width"] === "640") {
                medium = p["@url"];
              }
              if (p["@width"] === "320") {
                small = p["@url"];
              }
            });
          ShotImages.push({ id, shot: value, xlarge, large, medium, small });
          id++;
        }

        const ViewColorShots = []
        decodedStyle.mediaGallery.view.map(p => ViewColorShots.push(p['@shotCode']))
        const UnqiueColorShots = [...new Set(ViewColorShots)]
        
        for (var value of UnqiueColorShots) {
          let xlarge,
            large,
            medium,
            small = "";

          decodedStyle.mediaGallery.view
            .filter(
              (fl) =>
                fl["@shotCode"] === value &&
                fl["@backgroundDescription"] === "Transparent"
            )
            .map((p) => {
              if (p["@width"] === "2100") {
                xlarge = p["@url"];
              }
              if (p["@width"] === "1280") {
                large = p["@url"];
              }
              if (p["@width"] === "640") {
                medium = p["@url"];
              }
              if (p["@width"] === "320") {
                small = p["@url"];
              }
            });
          ShotImages.push({ id, shot: value, xlarge, large, medium, small });
          id++;
        }

        setFactoryImages(ShotImages)
      }
    }
  }, [decodedStyle, decodedExtColor]);

  function tryParseJSON (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return jsonString;
};

  React.useEffect(() => {
    if (decoded_vin) {
      if (decoded_vin[vindecodeId]) {
         
        const BlackBook =  tryParseJSON(decoded_vin[vindecodeId].blackbook_resp);
       
        //  
      
       if (BlackBook) {
         if (BlackBook.message_list)
        {
          setBlackBookMessage(JSON.stringify(BlackBook.message_list))
          // console.log(BlackBook.message_list)
        }
       
        
        if (!BlackBook.used_vehicles) {
          
          return;
        }
        
        setBlackBookList(BlackBook.used_vehicles.used_vehicle_list)
        if (BlackBook.used_vehicles.used_vehicle_list.length > 1)
        {
          
          setIsBlackBookOpen(true)
        } else {
          // setIsBlackBookOpen(true)
          
          setBlackBookData(BlackBook.used_vehicles.used_vehicle_list[0])
        }
       }
        
      }
    }
  },[decoded_vin])


  React.useEffect(() => {
    if (decoded_vin) {
      if (decoded_vin[vindecodeId]) {
        const ChromeData = JSON.parse(decoded_vin[vindecodeId].chromedata_resp);
        // console.log(ChromeData);
        if (!ChromeData) {
          return;
        }
 
        if (ChromeData["S:Envelope"]) {
          setVinDecodeMessage(`You are attempting to update the Inventory from Vin Decode`)

          if (ChromeData["S:Envelope"]["S:Body"].VehicleDescription) {
            const VehicleDescription =
              ChromeData["S:Envelope"]["S:Body"].VehicleDescription;

            setDecodedVin(VehicleDescription.vinDescription);
            setVinForm(VehicleDescription.vinDescription["@vin"]);
            // console.log(decodedVin);
            // console.log(VehicleDescription.style);

            if (VehicleDescription.style.length > 1) {
              setStyle(VehicleDescription.style);

              setIsStyleOpen(true);
            } else {
              setStyleId(VehicleDescription.style["@id"]);
              setDecodedStyle(VehicleDescription.style);
            }
            const FactoryImages = [];

            // console.log(styleId)
            if (VehicleDescription.engine.length > 1) {
              const EngineList = [];

              VehicleDescription.engine.map((p, k) => {
                //
                EngineList.push({
                  value: k,
                  label: EngineDetails(p),
                });
              });

              setEngine(EngineList);
              setIsEngineOpen(true);
            } else {
              // setDecodedEngine(VehicleDescription.engine)
              setDecodedEngine(EngineDetails(VehicleDescription.engine));
            }

            // console.log(VehicleDescription.exteriorColor);
            if (VehicleDescription.exteriorColor.length > 0) {
              setColor(
                VehicleDescription.exteriorColor.filter((fl) =>
                  fl.styleId.includes(styleId)
                )
              );
              setIsExtColOpen(true);
            }

            // console.log(VehicleDescription.interiorColor);
            if (VehicleDescription.interiorColor.length > 0) {
              const UniqueInternalColor = [];

              const InterioColor = [
                ...new Set(
                  VehicleDescription.interiorColor
                    .filter((fl) => fl.styleId.includes(styleId))
                    .map((p) => p["@colorName"])
                ),
              ];

              InterioColor.map((p, k) => {
                UniqueInternalColor.push({ value: k + 1, label: p });
              });

              setIntColor(UniqueInternalColor);
              setIsIntColOpen(true);
            } else {
              // console.log(VehicleDescription.interiorColor)
              setDecodedIntColor({value: 1, label: VehicleDescription.interiorColor['@colorName']})
            }

            if (VehicleDescription.standard.length > 0) {
              const StandardList = VehicleDescription.standard;
              // const MechanicalList = []
              const StandardEqiupmentFull = [];
              const MechanicalList = [];
              const ExteriorList = [];
              const EntertainmentList = [];
              const InteriorList = [];
              const SafetyList = [];
              const GenericEquipment = [];
              const TechnicalSpecification = [];
              const ConsumerInformation = [];

              if (VehicleDescription.consumerInformation.length > 1)
              {
                // console.log(VehicleDescription.consumerInformation.filter((fl) => fl.styleId.includes(styleId)))
              VehicleDescription.consumerInformation
              .filter((fl) => fl.styleId.includes(styleId))
              .map((p, k) => {
                let type = p.type["#text"];
                const items = [];
                if (p.item.length > 0)
                { 
                  
                  p.item.map((it, ky) => {
                    items.push({
                      id: ky + 1,
                      name: it["@name"],
                      value: it["@value"],
                    });
                  });
                }
               
                ConsumerInformation.push({ id: k + 1, type, items });
              });

              setConsumerInformation(ConsumerInformation);
              }
              

              VehicleDescription.genericEquipment.map((p, k) => {
                if (p.styleId.includes(styleId)) {
                  let group = p.definition.group["#text"];
                  let category = p.definition.category["#text"];
                  let header = p.definition.header["#text"];

                  GenericEquipment.push({ id: k + 1, group, category, header });
                }
              });

              // console.log(GenericEquipment)

              setGenericEquipment(GenericEquipment);

              VehicleDescription.technicalSpecification.map((p, k) => {
                let name = p.definition.title["#text"];
                let value = "";

                if (p.value.length > 0) {
                  p.value.map((vl) => {
                    if (vl.styleId.includes(styleId)) {
                      value = vl["@value"];
                    }
                  });
                } else {
                  if (p.value.styleId.includes(styleId)) {
                    value = p.value["@value"];
                  }
                }

                TechnicalSpecification.push({
                  id: k + 1,
                  name: name,
                  value: value,
                });
              });

              setTechnicalSpecification(TechnicalSpecification);

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "MECHANICAL" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                MechanicalList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Mechanical",
                value: MechanicalList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "EXTERIOR" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                ExteriorList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Exterior",
                value: ExteriorList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "ENTERTAINMENT" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                EntertainmentList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Entertainment",
                value: EntertainmentList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "INTERIOR" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                InteriorList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Interior",
                value: InteriorList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "SAFETY" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                SafetyList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({ name: "Safety", value: SafetyList });
              // InventoryDescription
              // console.log(StandardEqiupmentFull)
              setStandardEquipment(StandardEqiupmentFull);
            }
          } else {
            // console.log(JSON.stringify(ChromeData['S:Envelope']['S:Body']['S:Fault']))
            setVinDecodeError(
              JSON.stringify(ChromeData["S:Envelope"]["S:Body"]["S:Fault"])
            );
          }
        }
      }
    }
  }, [decoded_vin, vindecodeId, styleId]);



React.useEffect(() =>{
  
  if (blackBookData.kilometer_adjustments) {
    if (mileage) {
      // remove cents 
      let bBookList = blackBookData.kilometer_adjustments
      delete bBookList.cents_per_kilometer

      const sorted_object = Object.fromEntries(
      Object.entries(bBookList)
        .sort(([,v1], [,v2]) => +v2 - +v1)
    );

    let selected = ''
        // console.log(sorted_object)
    for (const [key, value] of Object.entries(sorted_object)) {
     
      selected= key
      if (value <= mileage) {
        break
      }
    }
    setBlackBookCategory(selected)
    // console.log(selected)
    }

    // console.log(blackBookCategory)
  }
}, [blackBookData, mileage])


  React.useEffect(() => {
    // console.log(get_inventory);
    if (get_inventory) {
      setInventory(get_inventory);
      setVinForm(get_inventory.vin);
     
    }
  }, [get_inventory]);


  // _.get(decodedVin, 'decodedVin.specification')
  const VinDecode = () => {
    // console.log(vinForm, "here")
    dispatch({ type: SG_ADD_VIN_UPLOAD, payload: { vin: vinForm } });
    setDisableVinDecoder(true);
  
  };

  


  const changeStyle = (e) => {
    // console.log(e);
    setStyleId(e["@id"]);
    if (vindecodeId) {
      setDecodedStyle(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_trim: e["@name"],
          revised_trim_id: e["@id"],
        },
      });
    }
  };

  const changeExtColor = (e) => {
    // console.log(e);
    if (vindecodeId) {
      setDecodedExtColor(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_color_code: e["@rgbValue"],
          revised_ext_color: e["@colorName"],
        },
      });
    }
  };

  const changeIntColor = (e) => {
    if (vindecodeId) {
      // console.log(e);
      setDecodedIntColor(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: { id: vindecodeId, revised_interior_color: e.label },
      });
    }
  };

  const changeBlackBookTrim = (e) => {
    if (vindecodeId) {
     
      setBlackBookData(e);
      
    }
  };

  const checkCategory = (e) => {
   
      setMileage(e.target.value)

     
  }

  const changeEngine = (e) => {
    if (vindecodeId) {
      
      setDecodedEngine(e.label);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_engine: e.label.label,
          revised_engine_id: e.value,
        },
      });
    }
  };

  function isInt(value) {
    return (
      !isNaN(value) &&
      ((parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))) ||
        (parseFloat(Number(value)) == value && !isNaN(parseFloat(value, 10))))
    );
  }
// console.log(decoded_vin[vindecodeId])
// revised_ext_color
  return (
    <>
    {/* <CreateForm 
    inventoryCreated={inventoryCreated}
    resetColWidth={props.resetColWidth}
    pageErrors={pageErrors}
    vinDecodeError={vinDecodeError}
    blackBookMessage={blackBookMessage}
    blackBookData={blackBookData}
    vinDecodeMessage={vinDecodeMessage}
    inventoryId={inventoryId}
    vinForm={vinForm}
    disableVinDecode={disableVinDecode}
    isStyleOpen={isStyleOpen}
    style={style}
    isExtColOpen={isExtColOpen}
    color={color}
    decodedExtColor={decodedExtColor}
    isEngineOpen={isEngineOpen}
    engine={engine}
    decoded_vin={decoded_vin}
    vindecodeId={vindecodeId}
    isIntColOpen={isIntColOpen}
    intColor={intColor}
    isBlackBookOpen={isBlackBookOpen}
    blackBookList={blackBookList}
    decodedVin={decodedVin}
    inventory={inventory}
    ReturnUrl={ReturnUrl}
    settoId={settoId}
    //
    setInventoryCreated={setInventoryCreated}
    setToHome

     /> */}
    </>
  );
};

export default CreatEdit;

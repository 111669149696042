import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// plugin that creates slider
// import Slider from "nouislider";

// reactstrap components
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";

const ListInformation = ({ information }) => {
  const [descriptionList, setDescriptionList] = React.useState([]);

  React.useEffect(() => {
    setDescriptionList(information);
  }, [information]);
  // console.log(descriptionList)
  return (
    <>
    
    <Table bordered striped>
      <tbody>
      {descriptionList? descriptionList.map((p, k) => {
        
          return (
            <tr key={k}>
              <td className="table-head-content">{p.name}</td>
              <td>{p.value}</td>
            </tr>
          );
        }): null}
      </tbody>
    </Table>
    </>
  );
};



const ConsumerInformation = ({ information }) => {
  const [collapses, setCollapses] = React.useState([0]);
  let ConsumerInfo = []
  if (information[0]) {
      ConsumerInfo = information
  }


  const changeCollapse = (collapse) => {

    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // console.log(ConsumerInfo)
 
  // for (const property in ConsumerInfo) {
  //   console.log(`${property}: ${ConsumerInfo[property]}`);
  //   for (const xx in ConsumerInfo[property]) {
  //     console.log(`${xx} ${JSON.stringify(ConsumerInfo[property][xx])}`)
  //     console.log(`${xx} ${JSON.stringify(ConsumerInfo[property][xx])}`)
  //   }
  // }
  // console.log(typeof ConsumerInfo)
  return (
    <>
      <Row>
        <Col>
          <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
          >
            {ConsumerInfo? ConsumerInfo.map((p,k) => {
              return (
                <Card className="card-plain" key={k+1}>
                <CardHeader role="tab">
                  <a
                    aria-expanded={collapses.includes(k+1)}
                    href="#pablo"
                    data-parent="#accordion"
                    data-toggle="collapse"
                    onClick={(e) => {
                      e.preventDefault();
                      changeCollapse(k+1);
                    }}
                  >
                    {p.type} <i className="nc-icon nc-minimal-down" />
                  </a>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={collapses.includes(k+1)}>
                  <CardBody>
                    <ListInformation information={p.items} />
                  </CardBody>
                </Collapse>
              </Card>
                
              )

            }): null}
            
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ConsumerInformation;

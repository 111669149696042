import React from "react";
import Grid from "@mui/material/Grid";
import CoinStats from "./CoinStats";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Fonts } from "../constants/src/AppEnums";

const Coins = ({ coinsData }) => {
  console.log({ coinsData });
  return (
    <Box>
      <Grid container spacing={2}>
        {coinsData.map((item, index) => (
          <Grid item lg={3} md={4} xs={12} sm={6}>
            <CoinStats
              icon={item.icon}
              bgColor={item.bgColor}
              data={item}
              heading={item.title}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Coins;

Coins.defaultProps = {
  coinsData: [
    {
      value: "",
      footer: "",
      title: "",
      icon: "",
    },
  ],
};

Coins.propTypes = {
  coinsData: PropTypes.object,
};

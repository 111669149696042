import React, { useState } from "react";

import AppDialog from "../../crema/AppDialog";

import CreateTask from "TaskLists/MiniCreate";
import NewTask from "WebComments/NewTask";

const AddNewWebCommentUpload = ({
  isAddTaskOpen,
  id,
  vinid,
  resetColWidth,
  setAddTaskOpen,
  onCloseAddTask,
  selectedDate,
  title,
}) => {
  const [label, setLabel] = useState("");
  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => onCloseAddTask()}
      title={label}
    >
      <NewTask
        setAddTaskOpen={setAddTaskOpen}
      />
    </AppDialog>
  );
};

export default AddNewWebCommentUpload;

import { 
    GET_LOGIN_TRAIL, 
    FETCH_LOGIN_TRAIL, 
    CLEAR_ALL } from "constants/actions";

const initialState = {
    logintrail: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LOGIN_TRAIL:
            return {
                ...state,
                logintrail: action.payload
            };
        case FETCH_LOGIN_TRAIL:
            return { ...state, logintrailed: action.payload };
        case CLEAR_ALL:
            return {
                ...state,
                logintrail: []
            };
        default:
            return state;
    }
}


import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_CUSTOMER_INVENTORY } from "constants/actions";

// reactstrap components
import {
    Spinner,
    Fade,
    Table,
    Row,
    Col,
} from "reactstrap";

const InventoryInterested = ({ customer_id }) => {
    const dispatch = useDispatch();
    const [webLeads, setWebLeads] = React.useState([]);


    React.useEffect(() => {
        if (customer_id) {
            dispatch({
                type: SG_GET_CUSTOMER_INVENTORY,
                payload: { id: customer_id },
            });
        }

    }, [dispatch, customer_id]);

    const {
        get_web_leads,
    } = useSelector(
        (state) => ({
            get_web_leads: state.webleads.customer_inventory.results,
            get_pending: state.webleads.pending,
            get_auth: state.auth,
            get_errors: state.errors,
        }),
        shallowEqual
    );

    React.useEffect(() => {
        setWebLeads(get_web_leads);
    }, [get_web_leads]);

    return (
        <>
            <div>
                <Row>

                    <Col md="12">
                        <Table responsive>
                            <thead className="table-active">
                                <tr>
                                    <th>Inventory</th>
                                    <th>Source</th>
                                    <th>Stage</th>
                                    <th className="text-right">Created</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {webLeads ? (
                                    webLeads.map((p, k) => (
                                        <tr key={k}>

                                            {/* {showAll? <td>{p.assigned_to}</td>: null} */}
                                            <td>
                                                {p.inventory.map(l =>
                                                (
                                                    <div key={l.id}>
                                                        <p>
                                                            {`${l.year} ${l.make} ${l.model} ${l.color}`}
                                                        </p>
                                                        <p>
                                                            VIN: {l.vin}
                                                        </p>
                                                        <p>
                                                            Status: {l.status}
                                                        </p>
                                                        <Link to={`/admin/inventory-detail/${l.id}`}>View More</Link>
                                                    </div>

                                                )

                                                )}

                                            </td>
                                            <td>
                                                {p.web_lead_source ? p.web_lead_source_name : null}
                                            </td>
                                            <td>{p.web_lead_stage ? p.web_lead_stage_name : null}</td>
                                            <td className="text-right">
                                                {p.created_at
                                                    ? moment(p.created_at).format(
                                                        "YYYY-MM-DD hh:mm a"
                                                    )
                                                    : "No date given"}
                                            </td>
                                            <td>
                                                <Link to={`/admin/lead-detail/${p.id}`}>View More</Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No Leads Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>

        </>
    );
};

export default InventoryInterested;

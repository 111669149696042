import { put, all, call, takeLatest } from 'redux-saga/effects'
import { 
GET_TASK_LISTS_PENDING, GET_TASK_LISTS, LOAD_TASK_LISTS, 
FETCH_TASK_LISTS_PENDING,
FETCH_TASK_LISTS, 
SG_FETCH_TASK_LISTS, 
DELETE_TASK_LIST, 
ADD_TASK_LIST, CREATE_TASK_LIST, 
EDIT_TASK_LISTS, 
UPDATE_TASK_LISTS,
 CLEAR_TASK_LISTS, 
     ALL_ERRORS } from "constants/actions";

import * as api from '../constants/api';


function* loadTask(action) {
  // console.log(action)
    yield put({ type: GET_TASK_LISTS_PENDING });
    try {
      const json = yield call(api.GET_EMPLOYEE_TASKS, action)

    yield put({ type: GET_TASK_LISTS, payload: json.data });

    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
}

export function* taskLoad() {
   yield takeLatest(LOAD_TASK_LISTS, loadTask);
}


function* fetchTask(action) {
    yield put({ type: FETCH_TASK_LISTS_PENDING });
    try {
      const json = yield call(api.FETCH_EMPLOYEE_TASK, action.payload)

      yield put({ type: FETCH_TASK_LISTS, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* taskFetchOne() {
    yield takeLatest(SG_FETCH_TASK_LISTS, fetchTask);
  }


  function* editTask(action) {

    try {
      const json = yield call(api.EDIT_EMPLOYEE_TASK, action.payload.id, action.payload);
  
      yield put({ type: EDIT_TASK_LISTS, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* taskEdit() {
    yield takeLatest(UPDATE_TASK_LISTS, editTask);
  }
  
  function* addTask(action) {
    try {
      const json = yield call(api.ADD_EMPLOYEE_TASK, action.payload);
  
      yield put({ type: ADD_TASK_LIST, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* taskAdd() {
    yield takeLatest(CREATE_TASK_LIST, addTask);
  }
  
export default function* index() {
    yield all([
      taskLoad(), 
      taskFetchOne(),
      taskEdit(),
      taskAdd(),
    ]);
  }
import {
    GET_INVENTORY_NOTES,
    INVENTORY_LIST,
    GET_INVENTORY_NOTES_PENDING,
    FETCH_INVENTORY_NOTES,
    EDIT_INVENTORY_NOTES,
    ADD_INVENTORY_NOTES,
    CLEAR_ALL,
    DELETE_INVENTORY_NOTES,
} from "constants/actions";

const initialState = {
    inventorynotes: [],
    inventorylist: [],
    loadinginventorynotes: false,
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INVENTORY_NOTES_PENDING:
            return {
                ...state,
                pending: true,
                loadinginventorynotes: true,
            }
        case INVENTORY_LIST:
            return {
                ...state,
                inventorylist: action.payload,
                loadinginventorynotes: false,
                pending: false
            };
        case GET_INVENTORY_NOTES:
            return {
                ...state,
                [action.payload[0].inventory]: action.payload[0],
                loadinginventorynotes: false,
                pending: false
            };
        case FETCH_INVENTORY_NOTES:
            return {
                ...state,
                [action.payload.inventory]: action.payload,
                loadinginventorynotes: false,
                pending: false
            };

        case EDIT_INVENTORY_NOTES:
            return { ...state, [action.payload.inventory]: action.payload, loadinginventorynotes: false };

        case DELETE_INVENTORY_NOTES:
            return {
                ...state,
                inventorynotes: state.inventoryfinance.filter(Inventory => Inventory.id !== action.payload)
            };
        case ADD_INVENTORY_NOTES:
            return {
                ...state,
                inventorynotes: [...state.inventoryfinance, action.payload], loadinginventorynotes: false
            };
        case CLEAR_ALL:
            return {
                ...state,
                inventorynotes: [],
                inventorylist: [],
                loadinginventorynotes: false,
                pending: false
            };
        default:
            return state;
    }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_INVENTORIES:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, inventories: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }

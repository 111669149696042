import React, { useState } from "react";
import _ from "lodash";

import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import ReactDatetime from "react-datetime";

// react plugin used to create datetimepicker
// import Datetime from "react-datetime";
import { addDays, subDays } from "date-fns";
// import Moment from "moment";

import Select from "react-select";
import DecodeDetail from "./DecodeDetail";
import { toast } from "react-toastify";
import { Button as MUIButton } from "@mui/material";

import "./style.css";
import {
  FETCH_INVENTORY_ONE,
  SG_FETCH_VIN_UPLOAD,
  SG_EDIT_INVENTORY,
  CREATE_INVENTORY,
  DESTROY_INVENTORY,
  SG_FETCH_RELATED_DEALER,
  RESET_UPDATED_INVENTORY,
  SG_ADD_VIN_UPLOAD,
  SG_EDIT_VIN_UPLOAD,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  CustomInput,
  Collapse,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

import {
  VehicleOptions,
  FuelOptions,
  WheelBaseTypeOptions,
  CabTypeOptions,
  PickupBedtypeOptions,
  PickupBedlengthOptions,
  TransmissionOptions,
  ConditionOptions,
  StatusOptions,
} from "Inventory/inventorystatus";
const CreatEdit = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  let ReturnUrl = params.get("return-url");

  const ShowAllSpecs = true;

  const [inventory, setInventory] = React.useState([]);
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
  //
  const [selDelLocation, setSelDelLocation] = React.useState(false);
  const [selVehType, setSelVehType] = React.useState(false);
  const [selStatus, setSelStatus] = React.useState(false);
  const [selTranmission, setSelTranmission] = React.useState(false);
  const [selCondition, setSelCondition] = React.useState(false);
  const [errorResponseStatus, setErrorResponseStatus] = React.useState(false);
  const [selFuelType, setSelFuelType] = React.useState({
    value: "Gasoline",
    label: "Gasoline",
  });
  const [selWheelbaseType, setSelWheelbaseType] = React.useState(false);
  const [selCabType, setSelCabType] = React.useState(false);
  const [selPickupBedtype, setSelPickupBedtype] = React.useState(false);
  const [selPickupBedlength, setSelPickupBedlength] = React.useState(false);

  const [vinForm, setVinForm] = React.useState(false);

  const [pageErrors, setPageErrors] = React.useState(false);
  const [engineErrors, setEngineErrors] = React.useState(false);
  //
  const [decodedVin, setDecodedVin] = React.useState([]);

  const [blackBookList, setBlackBookList] = React.useState([]);
  const [blackBookData, setBlackBookData] = React.useState([]);
  const [blackbookChanged, setBlackbookChanged] = React.useState("");

  const [decodedStyle, setDecodedStyle] = React.useState([]);
  const [decodedEngine, setDecodedEngine] = React.useState([]);
  const [decodedExtColor, setDecodedExtColor] = React.useState([]);
  const [decodedIntColor, setDecodedIntColor] = React.useState([]);
  const [seatingCapacity, setSeatingCapacity] = React.useState(false);
  const [seatingRows, setSeatingRows] = React.useState(2);
  const [selectAnEngine, setSelectAnEngine] = React.useState(false);
  //
  const [style, setStyle] = React.useState([]);
  const [styleId, setStyleId] = React.useState([]);
  const [extColor, setExtColor] = React.useState([]);
  const [intColor, setIntColor] = React.useState([]);

  const [engineOptions, setEngineOptions] = React.useState([]);
  const [selectedengine, setSelectedEngine] = React.useState([]);

  const [mileage, setMileage] = React.useState(0);
  const [blackBookCategory, setBlackBookCategory] = React.useState(0);

  const [vinDecodeError, setVinDecodeError] = React.useState(false);

  const [vinDecodeErrorList, setVinDecodeErrorList] = React.useState(false);

  const [vinDecodeMessage, setVinDecodeMessage] = React.useState(false);
  const [blackBookMessage, setBlackBookMessage] = React.useState(false);

  const [standardEquipment, setStandardEquipment] = React.useState([]);
  const [genericEquipment, setGenericEquipment] = React.useState([]);
  const [technicalSpecification, setTechnicalSpecification] = React.useState(
    []
  );
  const [consumerInformation, setConsumerInformation] = React.useState([]);

  const [factoryImages, setFactoryImages] = React.useState([]);

  const [metaDescription, setMetaDescription] = React.useState([]);

  const [isStyleOpen, setIsStyleOpen] = useState(false);
  const [isExtColOpen, setIsExtColOpen] = useState(false);
  const [isEngineOpen, setIsEngineOpen] = useState(false);
  const [isIntColOpen, setIsIntColOpen] = useState(false);
  const [isBlackBookOpen, setIsBlackBookOpen] = useState(false);

  const [inventoryId, setInventoryId] = useState(props.id);
  const [vindecodeId, setVindecodeId] = useState(props.vinid);
  const [vindecodeFull, setVindecodeFull] = useState({});

  const [disableVinDecode, setDisableVinDecoder] = useState(true);

  const [exteriorColor, setExteriorColor] = useState(false);

  const [inventoryCreated, setInventoryCreated] = React.useState(null);
  const [toHome, setToHome] = React.useState(false);
  const [toId, settoId] = React.useState(false);

  const [dealerLocations, setDealerLocations] = React.useState([]);

  const StandardEqiupmentFull = [];
  // const toggle = () => setIsOpen(!isOpen);
  const [showSave, setShowSave] = React.useState(false);

  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (props.id) {
      setInventoryId(props.id);
    }
    if (props.vinid) {
      setVindecodeId(props.vinid);
    }
  }, [props]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.

    // dispatch(getPaginatedInventories(activePage, pageparams))
    if (inventoryId) {
      dispatch({ type: RESET_UPDATED_INVENTORY });
      dispatch({ type: FETCH_INVENTORY_ONE, payload: inventoryId });
      settoId(false);
    }
    if (vindecodeId) {
      dispatch({ type: RESET_UPDATED_INVENTORY });
      dispatch({ type: SG_FETCH_VIN_UPLOAD, payload: vindecodeId });
      settoId(false);
    }
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch, vindecodeId, inventoryId]);

  const {
    get_inventory,
    get_inventory_updated,
    decoded_vin,
    inventory_created,
    get_errors,
    related_dealer,
  } = useSelector(
    (state) => ({
      get_inventory: state.inventories[parseInt(inventoryId)],
      get_inventory_updated: state.inventories.updated,
      decoded_vin: state.vinupload,
      get_errors: state.errors,
      related_dealer: state.dealers.related,
      inventory_created: state.inventories.created[0],
    }),
    shallowEqual
  );

  // setSelectedEngine
  // console.log(get_inventory)
  // console.log(get_inventory?.inventory_notes?.descode_summary_html)
  // console.log(decoded_vin)
  // console.log('selectedengine', selectedengine)
  // console.log('engineOptions', engineOptions)

  React.useEffect(() => {
    if (related_dealer) {
      // console.log(related_dealer)
      setDealerLocations(related_dealer);
      // setLocationid(related_dealer[0].id)
    }
  }, [related_dealer]);

  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (decoded_vin[vinForm]) {
      setVindecodeFull(decoded_vin[vinForm]);
      setVindecodeId(decoded_vin[vinForm].id);
    }
  }, [decoded_vin, vinForm]);

  React.useEffect(() => {
    if (!_.isEmpty(get_errors.msg)) {
      setPageErrors(JSON.stringify(get_errors.msg.data));
    }
  }, [get_errors]);

  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (get_inventory_updated) {
      setShowSave(false);

      setTimeout(() => {
        if (ReturnUrl) {
          history.push(`/admin/${ReturnUrl}`);
        } else {
          settoId(props.fileid);
        }
      }, 1000);
    }
  }, [get_inventory_updated]);

  function Capitalize(str) {
    // console.log("changed str", str)
    let words = str.split(" ");
    setExteriorColor(
      words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase()
    );
  }

  React.useEffect(() => {
    // if (_.get(inventory_created, "id")) {
    if (inventory_created) {
      setInventoryCreated(inventory_created);
      setTimeout(() => {
        if (ReturnUrl) {
          history.push(`/admin/${ReturnUrl}`);
        } else {
          setToHome(true);
        }
      }, 1000);
    }
  }, [inventory_created]);

  // function IsJsonString(str) {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // }

  React.useEffect(() => {
    // console.log(vinForm.length, inventory)
    if (vinForm.length === 17 && inventory.length === 0 && !vindecodeId) {
      // console.log(vinForm.length, inventory.length === 0)
      setDisableVinDecoder(false);
    } else {
      // console.log(vinForm.length, inventory)
      setDisableVinDecoder(true);
    }
  }, [vinForm, inventory]);

  function EngineDetails(engineOptions) {
    if (!engineOptions) {
      setEngineErrors(
        "Engine details not found in the right format, please enter manually, or check for errors above"
      );
      return null;
    }
    const cylinders = engineOptions?.cylinders;

    let displace = {};
    if (engineOptions?.displacement) {
      if (engineOptions?.displacement?.["value"].length > 0) {
        displace = {
          unit: engineOptions.displacement["value"][0]["@unit"],
          value: engineOptions.displacement["value"][0]["#text"],
        };
      } else {
        displace = {
          unit: engineOptions.displacement["value"]["@unit"],
          value: engineOptions.displacement["value"]["#text"],
        };
      }
    }

    let fuelCapacity = {};
    if (engineOptions?.fuelCapacity) {
      fuelCapacity = {
        unit: engineOptions.fuelCapacity["@unit"],
        value: engineOptions.fuelCapacity["@high"],
      };
    }

    let fuelEconomy = {};
    if (engineOptions?.fuelEconomy) {
      fuelEconomy = {
        unit: engineOptions.fuelEconomy["@unit"],
        city: engineOptions.fuelEconomy.city["@high"],
        hwy: engineOptions.fuelEconomy.hwy["@high"],
      };
    }

    let horsepower = {};
    if (engineOptions?.horsepower) {
      horsepower = {
        rpm: engineOptions.horsepower["@rpm"],
        value: engineOptions.horsepower["@value"],
      };
    }

    let torque = {};
    if (engineOptions?.netTorque) {
      torque = {
        rpm: engineOptions.netTorque["@rpm"],
        value: engineOptions.netTorque["@value"],
      };
    }
    // const Displacment =   Displace ? `${Displace.value} ${Displace.unit} ` : ""
    const label = `${displace ? `${displace.value} ${displace.unit} ` : ""} ${
      horsepower.value ? `${horsepower.value}HP` : ""
    } ${engineOptions?.engineType["#text"]}`;
    //

    const EngineInfo = {
      label,
      cylinders,
      displace,
      fuelCapacity,
      fuelEconomy,
      horsepower,
      torque,
    };

    return EngineInfo;
  }
  // console.log(engineOptions)

  React.useEffect(() => {
    if (decodedStyle.division) {
      // console.log(mileage)
      setMetaDescription(
        `Used ${decodedStyle["@modelYear"]} ${decodedStyle.division["#text"]} ${decodedStyle.model["#text"]} ${decodedStyle["@name"]} with ${mileage} kms many more `
      );
    }
    // console.log(mileage)
  }, [decodedExtColor, decodedIntColor, decodedStyle, mileage]);

  React.useEffect(() => {
    if ((decodedExtColor, decodedStyle)) {
      if (decodedExtColor) {
        if (decodedStyle?.mediaGallery?.colorized) {
          const ColorCode = decodedExtColor["@colorCode"];
          const ColorShots = ["01", "02", "03"];
          const ShotImages = [];

          let id = 1;
          for (var value of ColorShots) {
            let xlarge,
              large,
              medium,
              small = "";

            decodedStyle.mediaGallery.colorized
              .filter(
                (fl) =>
                  fl["@primaryColorOptionCode"] === ColorCode &&
                  fl["@shotCode"] === value &&
                  fl["@backgroundDescription"] === "Transparent"
              )
              .map((p) => {
                if (p["@width"] === "2100") {
                  xlarge = p["@url"];
                }
                if (p["@width"] === "1280") {
                  large = p["@url"];
                }
                if (p["@width"] === "640") {
                  medium = p["@url"];
                }
                if (p["@width"] === "320") {
                  small = p["@url"];
                }
              });
            ShotImages.push({ id, shot: value, xlarge, large, medium, small });
            id++;
          }

          const ViewColorShots = [];
          if (decodedStyle?.mediaGallery?.view) {
            decodedStyle.mediaGallery.view
              .filter((fl) => fl["@shotCode"] > 3)
              .map((p) => ViewColorShots.push(p["@shotCode"]));
          }

          const UnqiueColorShots = [...new Set(ViewColorShots)];

          for (var value of UnqiueColorShots) {
            let xlarge,
              large,
              medium,
              small = "";

            decodedStyle.mediaGallery.view
              .filter(
                (fl) =>
                  fl["@shotCode"] === value &&
                  fl["@backgroundDescription"] === "Transparent"
              )
              .map((p) => {
                if (p["@width"] === "2100") {
                  xlarge = p["@url"];
                }
                if (p["@width"] === "1280") {
                  large = p["@url"];
                }
                if (p["@width"] === "640") {
                  medium = p["@url"];
                }
                if (p["@width"] === "320") {
                  small = p["@url"];
                }
              });
            ShotImages.push({ id, shot: value, xlarge, large, medium, small });
            id++;
          }

          setFactoryImages(ShotImages);
        }
      }
    }
  }, [decodedStyle, decodedExtColor]);

  function tryParseJSON(jsonString) {
    try {
      var o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === "object") {
        return o;
      }
    } catch (e) {}

    return jsonString;
  }

  // setBlackBookData(e);
  // dispatch({
  //   type: SG_EDIT_VIN_UPLOAD,
  //   payload: {
  //     id: vindecodeId,
  //     revised_bb_style_series: `${e.style} ${e.series}`,
  //     revised_bb_uvc: e.uvc,
  //   },
  // });

  React.useEffect(() => {
    if (decoded_vin) {
      if (decoded_vin[vindecodeId]) {
        const BlackBook = tryParseJSON(decoded_vin[vindecodeId].blackbook_resp);

        if (BlackBook) {
          if (BlackBook.message_list) {
            setBlackBookMessage(JSON.stringify(BlackBook.message_list));
            // console.log(BlackBook.message_list)
          }

          if (!BlackBook.used_vehicles) {
            return;
          }

          setBlackBookList(BlackBook.used_vehicles.used_vehicle_list);
          if (BlackBook.used_vehicles.used_vehicle_list.length > 1) {
            setIsBlackBookOpen(true);
          } else {
            // setIsBlackBookOpen(true)

            setBlackBookData(BlackBook.used_vehicles.used_vehicle_list[0]);
          }
        }
      }
    }
  }, [decoded_vin]);

  // console.log(blackBookList)
  // console.log(decoded_vin[vindecodeId])

  React.useEffect(() => {
    if (decoded_vin[vindecodeId]?.revised_bb_uvc) {
      setBlackBookData(
        blackBookList.filter(
          (fl) => fl.uvc === decoded_vin[vindecodeId].revised_bb_uvc
        )[0]
      );
    }
  }, [blackBookList, decoded_vin]);

  React.useEffect(() => {
    if (decoded_vin[vindecodeId]?.revised_color_code) {
      setDecodedExtColor(
        extColor.filter(
          (fl) =>
            fl["@rgbValue"] === decoded_vin[vindecodeId].revised_color_code
        )[0]
      );
    }
  }, [extColor, decoded_vin]);

  React.useEffect(() => {
    if (engineOptions) {
      setSelectedEngine(
        engineOptions?.[decoded_vin?.[vindecodeId]?.revised_engine_id]
      );
      // if (decoded_vin[vindecodeId]?.revised_engine_id) {
      setDecodedEngine(
        engineOptions[decoded_vin?.[vindecodeId]?.revised_engine_id]?.label
      );

      // }
      // console.log("Engine Selected")
    }
  }, [engineOptions, decoded_vin]);

  React.useEffect(() => {
    if (decoded_vin[vindecodeId]?.revised_interior_color) {
      setDecodedIntColor(
        intColor.filter(
          (fl) => fl.label === decoded_vin[vindecodeId].revised_interior_color
        )[0]
      );
    }
  }, [intColor, decoded_vin]);

  React.useEffect(() => {
    setSeatingCapacity(inventory?.pass_capacity);
    setSeatingRows(inventory?.seat_rows);
  }, [inventory]);

  // console.log(decodedVin.standard_seating)
  // console.log(inventory.pass_capacity)
  // console.log(seatingCapacity)

  React.useEffect(() => {
    if (decoded_vin) {
      if (decoded_vin[vindecodeId]) {
        const ChromeData = JSON.parse(
          decoded_vin[vindecodeId]?.chromedata_resp
        );

        if (!ChromeData) {
          setVinDecodeError("No information found");
          return;
        }

        if (ChromeData["S:Envelope"]) {
          setVinDecodeMessage(
            `You are attempting to update the Inventory from Vin Decode`
          );

          if (ChromeData["S:Envelope"]["S:Body"]?.VehicleDescription) {
            const VehicleDescription =
              ChromeData["S:Envelope"]["S:Body"].VehicleDescription;

            if (!VehicleDescription?.vinDescription) {
              setVinDecodeErrorList(VehicleDescription.responseStatus);
              return;
            }

            setDecodedVin(VehicleDescription?.vinDescription);
            setVinForm(VehicleDescription?.vinDescription["@vin"]);
            //
            setIsStyleOpen(true);

            // console.log(VehicleDescription?.vinDescription)
            VehicleDescription?.technicalSpecification &&
              VehicleDescription.technicalSpecification.map((ve) => {
                // console.log(ve?.definition?.title?.['#text'])
                if (ve?.definition?.title?.["#text"] === "Passenger Capacity") {
                  // console.log("passenger capacity", ve?.value?.['@value'])
                  setSeatingCapacity(ve?.value?.["@value"]);
                  if (ve?.value?.["@value"] > 6) {
                    setSeatingRows(3);
                  }
                }
                // console.log(ve?.value?.['@value'])
              });
            // console.log(VehicleDescription?.style)

            // console.log(VehicleDescription?.responseStatus)
            if (VehicleDescription?.responseStatus?.status) {
              setErrorResponseStatus(
                VehicleDescription?.responseStatus?.status
              );
              setVisible(true);
            } else {
              setErrorResponseStatus(false);
            }
            // console.log(VehicleDescription?.responseStatus?.status)

            if (VehicleDescription?.style) {
              if (VehicleDescription?.style?.length > 1) {
                setStyle(VehicleDescription.style);
                if (decoded_vin[vindecodeId].revised_trim) {
                  setDecodedStyle(
                    VehicleDescription.style.filter(
                      (fl) =>
                        fl["@id"] == decoded_vin[vindecodeId].revised_trim_id
                    )[0]
                  );
                  setStyleId(
                    VehicleDescription.style.filter(
                      (fl) =>
                        fl["@id"] == decoded_vin[vindecodeId].revised_trim_id
                    )[0]["@id"]
                  );
                }
              } else {
                setStyleId(VehicleDescription?.style["@id"]);
                setDecodedStyle(VehicleDescription?.style);
              }
            }
            // const FactoryImages = [];

            if (VehicleDescription?.engine?.length > 1) {
              const EngineList = [];

              VehicleDescription.engine.map((p, k) => {
                // console.log(p)
                EngineList.push({
                  value: k,
                  label: EngineDetails(p),
                });
              });
              setEngineOptions(EngineList);
              setIsEngineOpen(true);
            } else {
              // setDecodedEngine(VehicleDescription.engine)
              setDecodedEngine(EngineDetails(VehicleDescription?.engine));
            }

            // console.log(VehicleDescription.exteriorColor);
            if (VehicleDescription?.exteriorColor?.length > 0) {
              setExtColor(
                VehicleDescription.exteriorColor.filter((fl) =>
                  fl.styleId.includes(styleId)
                )
              );
              setIsExtColOpen(true);
            }

            if (VehicleDescription?.interiorColor) {
              if (VehicleDescription?.interiorColor?.length > 0) {
                const UniqueInternalColor = [];
                const InterioColor = [
                  ...new Set(
                    VehicleDescription.interiorColor
                      .filter((fl) => fl.styleId.includes(styleId))
                      .map((p) => p["@colorName"])
                  ),
                ];

                InterioColor.map((p, k) => {
                  UniqueInternalColor.push({ value: k + 1, label: p });
                });

                setIntColor(UniqueInternalColor);
                setIsIntColOpen(true);
              } else {
                // console.log(VehicleDescription.interiorColor)
                setDecodedIntColor({
                  value: 1,
                  label: VehicleDescription.interiorColor["@colorName"],
                });
              }
            }
            if (VehicleDescription?.standard) {
            }
            if (VehicleDescription?.standard?.length > 0) {
              const StandardList = VehicleDescription.standard;
              // const MechanicalList = []

              const MechanicalList = [];
              const ExteriorList = [];
              const EntertainmentList = [];
              const InteriorList = [];
              const SafetyList = [];
              const GenericEquipment = [];
              const TechnicalSpecification = [];
              const ConsumerInformation = [];

              if (VehicleDescription.consumerInformation.length > 1) {
                // console.log(VehicleDescription.consumerInformation.filter((fl) => fl.styleId.includes(styleId)))
                VehicleDescription.consumerInformation
                  .filter((fl) => fl.styleId.includes(styleId))
                  .map((p, k) => {
                    let type = p.type["#text"];
                    const items = [];
                    if (p.item.length > 0) {
                      p.item.map((it, ky) => {
                        items.push({
                          id: ky + 1,
                          name: it["@name"],
                          value: it["@value"],
                        });
                      });
                    }

                    ConsumerInformation.push({ id: k + 1, type, items });
                  });

                setConsumerInformation(ConsumerInformation);
              }

              VehicleDescription.genericEquipment.map((p, k) => {
                if (p.styleId.includes(styleId)) {
                  let group = p.definition.group["#text"];
                  let category = p.definition.category["#text"];
                  let header = p.definition.header["#text"];

                  GenericEquipment.push({ id: k + 1, group, category, header });
                }
              });

              // console.log(GenericEquipment)
              // console.log("Vehicle Engine Changed")
              setGenericEquipment(GenericEquipment);

              VehicleDescription.technicalSpecification.map((p, k) => {
                let name = p.definition.title["#text"];
                let value = "";

                if (p.value.length > 0) {
                  p.value.map((vl) => {
                    if (vl.styleId.includes(styleId)) {
                      value = vl["@value"];
                    }
                  });
                } else {
                  if (p.value.styleId.includes(styleId)) {
                    value = p.value["@value"];
                  }
                }

                TechnicalSpecification.push({
                  id: k + 1,
                  name: name,
                  value: value,
                });
              });

              setTechnicalSpecification(TechnicalSpecification);

              // console.log(StandardList)
              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "MECHANICAL" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                MechanicalList.push({ id: k + 1, name: p.description });
              });

              StandardEqiupmentFull.push({
                name: "Mechanical",
                value: MechanicalList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "EXTERIOR" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                ExteriorList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Exterior",
                value: ExteriorList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "ENTERTAINMENT" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                EntertainmentList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Entertainment",
                value: EntertainmentList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "INTERIOR" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                InteriorList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({
                name: "Interior",
                value: InteriorList,
              });

              StandardList.filter(
                (fl) =>
                  fl.header["#text"] === "SAFETY" &&
                  fl.styleId.includes(styleId)
              ).map((p, k) => {
                SafetyList.push({ id: k + 1, name: p.description });
              });
              StandardEqiupmentFull.push({ name: "Safety", value: SafetyList });
              // InventoryDescription
              // console.log(StandardEqiupmentFull)
              setStandardEquipment(StandardEqiupmentFull);
            }
          } else {
            // console.log(JSON.stringify(ChromeData['S:Envelope']['S:Body']['S:Fault']))
            setVinDecodeError(
              JSON.stringify(ChromeData["S:Envelope"]["S:Body"]["S:Fault"])
            );
          }
        }
      }
    }
  }, [decoded_vin, vindecodeId, styleId]);

  // console.log(technicalSpecification)
  const formatOptionLabel = (props) => {
    const RgbVale = `#${props?.["@rgbValue"]}`;
    const GenericCol = props?.genericColor["@name"];

    // console.log(GenericCol)
    return (
      <div style={{ display: "flex" }}>
        <div>Mfd: {props["@colorName"]}</div>

        <div
          style={{
            backgroundColor: `${RgbVale}`,
            marginLeft: "10px",
            width: "100px",
          }}
        >
          {RgbVale}
        </div>
        <div style={{ marginLeft: "5px" }}>Generic: {GenericCol}</div>
      </div>
    );
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  // Define Blackbook Category based on information we got from Blackbook API
  React.useEffect(() => {
    if (blackBookData?.kilometer_adjustments) {
      if (mileage) {
        // remove cents
        let bBookList = blackBookData.kilometer_adjustments;
        delete bBookList.cents_per_kilometer;

        const sorted_object = Object.fromEntries(
          Object.entries(bBookList).sort(([, v1], [, v2]) => +v2 - +v1)
        );

        let selected = "above_rough_threshold";

        const rev_sorted_object = Object.fromEntries(
          Object.entries(bBookList).sort(([, v1], [, v2]) => +v1 - +v2)
        );
        const BlackBookValues = [];
        for (const [key, value] of Object.entries(rev_sorted_object)) {
          // console.log(key, value)
          BlackBookValues.push({
            mileage: value,
            category: key,
          });
        }

        if (BlackBookValues.filter((p) => p.mileage >= mileage)[0]?.category) {
          selected = BlackBookValues.filter((p) => p.mileage >= mileage)[0]
            ?.category;
        }

        // console.log(BlackBookValues.filter(p => p.mileage >= mileage)[0]?.category)

        setBlackBookCategory(selected);
      }
    }
  }, [blackBookData, mileage]);
  // 71764
  // console.log(exteriorColor)
  React.useEffect(() => {
    // console.log(get_inventory);
    if (get_inventory) {
      setInventory(get_inventory);
      setVinForm(get_inventory?.vin);
      setMileage(get_inventory?.kilometers);
      // console.log(get_inventory.color)
      let words = get_inventory?.color?.split(" ");
      setExteriorColor(
        words?.[0].charAt(0).toUpperCase() + words?.[0].slice(1).toLowerCase()
      );
      // console.log("capitalize", Capitalize(get_inventory?.color))

      // setExteriorColor(Capitalize(get_inventory.color))

      // setStartDate(Date.parse(get_inventory.veh_in_date))
      setStartDate(moment(get_inventory.veh_in_date).format("YYYY-MM-DD"));

      setSelCondition(
        ConditionOptions.filter((fl) => fl.value === get_inventory.condition)[0]
      );
      setSelStatus(
        StatusOptions.filter((fl) => fl.value === get_inventory.status)[0]
      );
      setSelTranmission(
        TransmissionOptions.filter(
          (fl) => fl.value === get_inventory.transmission
        )[0]
      );
      setSelVehType(
        VehicleOptions.filter((fl) => fl.value === get_inventory.veh_type)[0]
      );
      setSelDelLocation(
        dealerLocations.filter((fl) => fl.id === get_inventory.location)[0]
      );
      if (get_inventory?.fuel_type) {
        setSelFuelType(
          FuelOptions.filter((fl) => fl.value === get_inventory.fuel_type)[0]
        );
      }
      if (get_inventory?.cab_type) {
        setSelCabType(
          CabTypeOptions.filter((fl) => fl.value === get_inventory.cab_type)[0]
        );
      }
      setValue("color", get_inventory.color, { shouldDirty: true });
    }
  }, [get_inventory]);

  React.useEffect(() => {
    if (selVehType) {
      register({ name: "veh_type" }, { required: false });
    } else {
      register(
        { name: "veh_type" },
        { required: "Please select a vehicle Type" }
      );
    }

    if (selFuelType) {
      register({ name: "fuel_type" }, { required: false });
    } else {
      register(
        { name: "fuel_type" },
        { required: "Please select a Fuel Type" }
      );
    }
    if (exteriorColor) {
      register({ name: "color" }, { required: false });
    } else {
      register({ name: "color" }, { required: "Please select a color" });
    }

    if (selCondition) {
      register({ name: "condition_select" }, { required: false });
    } else {
      register(
        { name: "condition_select" },
        { required: "Please select Condition" }
      );
    }
    if (selDelLocation) {
      register({ name: "location" }, { required: false });
    } else {
      register({ name: "location" }, { required: "Please select a Location" });
    }
    if (selStatus) {
      register({ name: "status_select" }, { required: false });
    } else {
      register({ name: "status_select" }, { required: "Please select Status" });
    }

    if (selTranmission) {
      register({ name: "transmission_select" }, { required: false });
    } else {
      register(
        { name: "transmission_select" },
        { required: "Please select Transmission" }
      );
    }
  }, [
    register,
    selVehType,
    selFuelType,
    selCabType,
    selCondition,
    selDelLocation,
    selStatus,
    selTranmission,
  ]);

  React.useEffect(() => {
    if (isEngineOpen) {
      if (selectedengine?.label) {
        // console.log("Engine Selected")
        setSelectAnEngine(false);
        setShowSave(false);
      } else {
        setSelectAnEngine("Please select and Engine");
        setShowSave(true);
      }
    }
  }, [isEngineOpen, selectedengine]);

  // console.log(inventory)
  // console.log(selectedengine)

  const changeFuelType = (e) => {
    setSelFuelType(e);
  };
  const changeWheelbasetype = (e) => {
    setSelWheelbaseType(e);
  };
  const changeCabType = (e) => {
    setSelCabType(e);
  };
  const changePickupBedtype = (e) => {
    setSelPickupBedtype(e);
  };
  const changePickupBedlength = (e) => {
    setSelPickupBedlength(e);
  };
  const changeVehType = (e) => {
    setSelVehType(e);
  };
  const changeCondition = (e) => {
    setSelCondition(e);
  };
  const changeLocation = (e) => {
    setSelDelLocation(e);
  };
  const changeStatus = (e) => {
    setSelStatus(e);
  };
  const changeTransmission = (e) => {
    setSelTranmission(e);
  };
  // _.get(decodedVin, 'decodedVin.specification')
  const VinDecode = () => {
    // console.log(vinForm, "here")
    dispatch({ type: SG_ADD_VIN_UPLOAD, payload: { vin: vinForm } });
    setDisableVinDecoder(true);
  };

  const onSubmit = (data) => {
    // vehicle_in_date
    console.log("Payload data", data);
    console.log("package_code : data.package_code", data.package_code);
    setShowSave(true);
    if (inventoryId) {
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id: inventoryId,
          ...data,
          color: exteriorColor,
          location: selDelLocation.id,
          transmission: selTranmission.value,
          status: selStatus.value,
          condition: selCondition.value,
          veh_type: selVehType.value,
          fuel_type: selFuelType?.value,
          package_code: data.package_code,
          wheelbase: data.wheelbase,
          wheelbase_type: selWheelbaseType.value,
          cab_type: selCabType.value,
          pickup_bedtype: selPickupBedtype.value,
          pickup_bedlength: selPickupBedlength.value,
          veh_in_date: moment(startDate).format("YYYY-MM-DDThh:mm"),
          blackbook_data: blackBookData,
          new_factory_images: factoryImages,
          new_inventory_description: [
            { name: "Standard Equipment", response: standardEquipment },
            {
              name: "Technical Specification",
              response: technicalSpecification,
            },
            { name: "Generic Equipment", response: genericEquipment },
            { name: "Consumer Information", response: consumerInformation },
          ],
        },
      });

      const notify2 = (props) =>
        toast.success(`Sucessfully Updated Inventory!`);
      notify2();
    } else {
      dispatch({
        type: CREATE_INVENTORY,
        payload: {
          ...data,
          color: exteriorColor,
          vin_decoder_id: vindecodeId,
          location: selDelLocation.id,
          transmission: selTranmission.value,
          status: selStatus.value,
          condition: selCondition.value,
          veh_type: selVehType.value,
          fuel_type: selFuelType.value,
          package_code: data.package_code,
          wheelbase: data.wheelbase,
          wheelbase_type: selWheelbaseType.value,
          cab_type: selCabType.value,
          pickup_bedtype: selPickupBedtype.value,
          pickup_bedlength: selPickupBedlength.value,
          veh_in_date: moment(startDate).format("YYYY-MM-DDThh:mm"),
          blackbook_data: blackBookData,
          new_factory_images: factoryImages,
          new_inventory_description: [
            { name: "Standard Equipment", response: standardEquipment },
            {
              name: "Technical Specification",
              response: technicalSpecification,
            },
            { name: "Generic Equipment", response: genericEquipment },
            { name: "Consumer Information", response: consumerInformation },
          ],
        },
      });

      const notify2 = (props) =>
        toast.success(`Sucessfully Created New Inventory!`);
      notify2();
    }

    if (props.resetColWidth) {
      props.resetColWidth(false);
    }
  };

  // const ChangedStatus = (value) => {
  //   setSelStatus(value);
  // };
  // console.log(decodedStyle)
  const changeStyle = (e) => {
    // console.log(e);
    setStyleId(e["@id"]);
    if (vindecodeId) {
      setDecodedStyle(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_trim: e["@name"],
          revised_trim_id: e["@id"],
        },
      });
    }
  };

  const changeExtColor = (e) => {
    // console.log(e);
    if (vindecodeId) {
      setDecodedExtColor(e);
      // const GenericCol = e?.genericColor['@name']
      setExteriorColor(e?.genericColor["@name"]);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_color_code: e["@rgbValue"],
          revised_ext_color: e["@colorName"],
        },
      });
    }
  };

  const changeIntColor = (e) => {
    if (vindecodeId) {
      // console.log(e);
      setDecodedIntColor(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: { id: vindecodeId, revised_interior_color: e.label },
      });
    }
  };

  const changeBlackBookTrim = (e) => {
    if (vindecodeId) {
      //  console.log(e)
      setBlackBookData(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_bb_style_series: `${e.style} ${e.series}`,
          revised_bb_uvc: e.uvc,
        },
      });
    }
  };

  const checkCategory = (e) => {
    setMileage(e.target.value);
    if (vindecodeId) {
      setBlackbookChanged("text-changed");
    }
  };

  const changingCategory = () => {
    if (vindecodeId) {
      setBlackbookChanged("");
    }
  };

  const changeEngine = (e) => {
    if (vindecodeId) {
      setDecodedEngine(e.label);
      setSelectedEngine(e);
      dispatch({
        type: SG_EDIT_VIN_UPLOAD,
        payload: {
          id: vindecodeId,
          revised_engine: e.label.label,
          revised_engine_id: e.value,
        },
      });
    }
  };

  function isInt(value) {
    return (
      !isNaN(value) &&
      ((parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))) ||
        (parseFloat(Number(value)) == value && !isNaN(parseFloat(value, 10))))
    );
  }

  // React.useEffect(() => {
  //   setShowSave(!_.isEmpty(errors))
  // }, [errors]);
  // console.log(decoded_vin[vindecodeId]?.revised_color_code)
  return (
    <>
      {toHome ? (
        <Redirect to={`/admin/inventory-detail/${inventoryCreated.id}`} />
      ) : null}

      {toId ? (
        <Redirect to={`/admin/full-vin-decode-file-detail/${toId}`} />
      ) : null}
      <div className="content">
        <ErrorBoundary>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <CardTitle tag="h4">
                  {inventoryId ? "Edit Inventory" : "Create Inventory"}
                </CardTitle>

                {props.resetColWidth ? (
                  <Button
                    color="link"
                    onClick={() => props.resetColWidth(false)}
                  >
                    <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {pageErrors ? (
                <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                  {pageErrors}
                </Alert>
              ) : null}
              {errorResponseStatus && (
                <Alert
                  color="danger"
                  isOpen={errorResponseStatus}
                  toggle={() => setErrorResponseStatus(false)}
                >
                  <p>Errors Found</p>
                  {JSON.stringify(errorResponseStatus)}
                </Alert>
              )}
              {vinDecodeErrorList && (
                <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                  <p className="text-danger">
                    {JSON.stringify(vinDecodeErrorList)}
                  </p>
                </Alert>
              )}
              {/*  Warnings are removed 
               {vinDecodeError ? (
                <p className="text-danger">{vinDecodeError}</p>
              ) : null}
              {blackBookMessage ? (
                <p className="text-info">{blackBookMessage}</p>
              ) : null}
              {vinDecodeMessage ? (
                <p className="text-info">{vinDecodeMessage}</p>
              ) : null} */}

              {/* <CustomColSelect extColor={extColor} 
              changeExtColor={changeExtColor}
              decodedExtColor={decodedExtColor}
              /> */}

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="block-div">
                  <p>
                    VIN{" "}
                    {inventoryId
                      ? "(Cannot Edit VIN, delete and reupload)"
                      : ""}{" "}
                  </p>
                  <FormGroup>
                    <Row>
                      <Col md="8">
                        <Input
                          type="text"
                          name="vin"
                          onChange={(e) => setVinForm(e.target.value)}
                          disabled={inventoryId ? true : false}
                          defaultValue={vinForm ? vinForm : inventory.vin}
                          innerRef={register({
                            required: "Please enter your VIN",
                            minLength: {
                              value: 17,
                              message: "VIN must have at least 17 characters",
                            },
                          })}
                        />
                        <p className="text-danger small">
                          {errors.vin && <span>{errors.vin.message}</span>}
                        </p>
                      </Col>
                      <Col md="3">
                        <MUIButton
                          className="btn-fill mt-n1"
                          variant="contained"
                          disabled={disableVinDecode}
                          onClick={() => VinDecode()}
                          color={"preAuto"}
                        >
                          Decode
                        </MUIButton>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
                <Collapse isOpen={isStyleOpen}>
                  <div className="block-div">
                    <Row>
                      <Col>
                        <p>Style / Trim</p>
                        <Select
                          name="multipleStyle"
                          getOptionLabel={(option) => `${option["@name"]}`}
                          getOptionValue={(option) => `${option["@id"]}`}
                          options={style}
                          className="text-dark"
                          value={decodedStyle}
                          onChange={(e) => changeStyle(e)}
                          // isClearable={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                <Collapse isOpen={isExtColOpen}>
                  <div className="block-div">
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <p>Exterior Color</p>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="2">
                            {decoded_vin[vindecodeId] ? (
                              <div
                                style={{
                                  backgroundColor: `#${decoded_vin[vindecodeId].revised_color_code}`,
                                  width: "60px",
                                  height: "30px",
                                }}
                              >
                                {"     "}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col>
                            <Select
                              name="multipleColor"
                              getOptionLabel={(option) =>
                                `${option["@colorName"]}`
                              }
                              getOptionValue={(option) =>
                                `${option["@rgbValue"]}`
                              }
                              options={extColor}
                              value={decodedExtColor}
                              formatOptionLabel={formatOptionLabel}
                              className="text-dark"
                              onChange={(e) => changeExtColor(e)}
                              // isClearable={true}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Collapse>

                <Collapse isOpen={isEngineOpen}>
                  <div className="block-div">
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <p>Engine Options</p>{" "}
                          </Col>
                        </Row>

                        <Select
                          name="multipleEngine"
                          getOptionLabel={(option) => `${option.label.label}`}
                          getOptionValue={(option) => `${option.value}`}
                          options={engineOptions}
                          className="text-dark"
                          onChange={(e) => changeEngine(e)}
                          value={selectedengine}
                          // isClearable={true}
                        />
                        <p className="text-danger small">
                          {selectAnEngine && <span>{selectAnEngine}</span>}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                <Collapse isOpen={isIntColOpen}>
                  <div className="block-div">
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <p>Internal Color</p>{" "}
                          </Col>
                        </Row>

                        <Row>
                          {/*<Col md="2">
                           <div
                    style={{
                      backgroundColor: `#${rgbValue}`,
                      width: "60px",
                      height: "30px",
                    }}
                  >
                    {"     "}
                  </div> 
                          </Col>*/}
                          <Col>
                            <Select
                              name="multipleIntColor"
                              options={intColor}
                              className="text-dark"
                              onChange={(e) => changeIntColor(e)}
                              value={decodedIntColor}
                              // isClearable={true}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                <Collapse isOpen={isBlackBookOpen}>
                  <div className="block-div">
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <p>Black Book Trim</p>{" "}
                          </Col>
                        </Row>

                        <Select
                          name="multipleBlackbook"
                          getOptionLabel={(option) =>
                            `${option.style} ${option.series}`
                          }
                          getOptionValue={(option) => `${option.uvc}`}
                          options={blackBookList}
                          className="text-dark"
                          onChange={(e) => changeBlackBookTrim(e)}
                          value={blackBookData}
                          // isClearable={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Year</label>
                      <Input
                        type="text"
                        name="year"
                        defaultValue={
                          decodedVin["@modelYear"]
                            ? decodedVin["@modelYear"]
                            : inventory.year
                        }
                        innerRef={register({
                          required: "Please enter model year",
                          minLength: {
                            value: 4,
                            message:
                              "Model Year must have at least 4 characters",
                          },
                        })}
                      />
                      <p className="text-danger small">
                        {errors.year && <span>{errors.year.message}</span>}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Make</label>
                      <Input
                        type="text"
                        name="make"
                        defaultValue={
                          decodedVin["@division"]
                            ? decodedVin["@division"]
                            : inventory.make
                        }
                        innerRef={register({
                          required: "Please enter the Make",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.make && <span>{errors.make.message}</span>}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Model</label>
                      <Input
                        type="text"
                        name="model"
                        defaultValue={
                          decodedVin["@modelName"]
                            ? decodedVin["@modelName"]
                            : inventory.model
                        }
                        innerRef={register({
                          required: "Please enter model",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.model && <span>{errors.model.message}</span>}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Trim</label>
                      <Input
                        type="text"
                        name="trim"
                        defaultValue={
                          decodedStyle["@name"]
                            ? decodedStyle["@name"]
                            : inventory.trim
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label>Kilometers (Mileage)</label>
                      <Input
                        type="text"
                        name="kilometers"
                        defaultValue={mileage ? mileage : inventory.kilometers}
                        onBlur={checkCategory}
                        onKeyDown={changingCategory}
                        innerRef={register({
                          required: "Please enter odometer reading, or hours",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.kilometers && (
                          <span>{errors.kilometers.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Color</label>
                      <Input
                        type="text"
                        name="color"
                        value={exteriorColor}
                        onChange={(e) => Capitalize(e.target.value)}
                        innerRef={register({
                          required: "Please enter Color",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.color && <span>{errors.color.message}</span>}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Manufacturer Color</label>

                      <Input
                        type="text"
                        name="mfd_color_code"
                        defaultValue={
                          decodedExtColor?.["@colorName"]
                            ? decodedExtColor["@colorName"]
                            : inventory.mfd_color_code
                        }
                        innerRef={register({
                          required: "Please enter Manfacturer Color ",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.mfd_color_code && (
                          <span>{errors.mfd_color_code.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Interior Color</label>
                      <Input
                        type="text"
                        name="interior_color"
                        defaultValue={
                          decodedIntColor?.label
                            ? decodedIntColor.label
                            : inventory.interior_color
                        }
                        innerRef={register({
                          required: "Please Select or Enter Interior Color",
                        })}
                        // innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Body Type</label>
                      <Input
                        type="text"
                        name="body_type"
                        defaultValue={
                          decodedVin["@bodyType"]
                            ? decodedVin["@bodyType"]
                            : inventory.body_type
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Driving Wheels</label>
                      <Input
                        type="text"
                        name="driving_wheels"
                        defaultValue={
                          decodedStyle["@drivetrain"]
                            ? decodedStyle["@drivetrain"]
                            : inventory.driving_wheels
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label>Market Class</label>
                  <Input
                    type="text"
                    name="market_class"
                    defaultValue={
                      decodedVin.marketClass
                        ? decodedVin.marketClass["#text"]
                        : inventory.market_class
                    }
                    innerRef={register}
                  />
                </FormGroup>
                {engineErrors && <p className="text-danger">{engineErrors}</p>}
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Engine </label>
                      <Input
                        type="text"
                        name="engine"
                        defaultValue={
                          decodedEngine?.label
                            ? decodedEngine.label
                            : inventory.engine
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col>
                    <label>steering type</label>
                    <Input
                      type="text"
                      name="steering_type"
                      defaultValue={
                        decodedVin.steering_type
                          ? decodedVin.steering_type
                          : inventory.steering
                      }
                      innerRef={register}
                    />
                  </Col> */}
                  {/* <Col>
                    <label>Transmission Details</label>
                    <Input
                      type="text"
                      name="transmission_details"
                      defaultValue={
                        decodedVin.transmission
                          ? decodedVin.transmission
                          : inventory.transmission_details
                      }
                      innerRef={register}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>List Price</label>
                      <Input
                        type="text"
                        name="list_price"
                        defaultValue={
                          inventory?.list_price || vindecodeFull?.list_price
                        }
                        innerRef={register({
                          required: "Please enter List Price (0 for blank)",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.list_price && (
                          <span>{errors.list_price.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Cost Price</label>
                      <Input
                        type="text"
                        name="cost_price"
                        defaultValue={
                          inventory?.cost_price || vindecodeFull?.cost_price
                        }
                      />
                      <p className="text-danger small">
                        {errors.cost_price && (
                          <span>{errors.cost_price.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label>Doors</label>
                      <Input
                        type="text"
                        name="pass_doors"
                        defaultValue={
                          decodedStyle["@passDoors"]
                            ? decodedStyle["@passDoors"]
                            : inventory.pass_doors
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Seats (Capacity)</label>
                      <Input
                        type="text"
                        name="pass_capacity"
                        defaultValue={
                          seatingCapacity
                            ? seatingCapacity
                            : inventory.pass_capacity
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <div className={blackbookChanged}>
                        <label>
                          Blackbook Category{" "}
                          {blackbookChanged ? (
                            <span className="show-updated-text">Updated</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <Input
                          type="text"
                          disabled
                          name="blackbook_category"
                          innerRef={register}
                          defaultValue={
                            blackBookCategory
                              ? blackBookCategory
                              : inventory.blackbook_category
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <label>Vehicle In-date </label>
                    <FormGroup>
                      <ReactDatetime
                        name="veh_in_date"
                        dateFormat="YYYY-MM-DD"
                        value={startDate}
                        // defaultValue={inventory.veh_in_date}
                        inputProps={{
                          className: "form-control",
                          placeholder: "Date Picker Here",
                        }}
                        onChange={setStartDate}
                        timeFormat={false}
                      />
                    </FormGroup>
                    {/* <FormGroup>
                             <Controller
                  as={ReactDatetime}
                  control={control}
                  name="veh_in_date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control border-input"
                   selected={startDate}
                   maxDate={addDays(new Date(), 30)}
                   minDate={subDays(new Date(), 30)}
                   type="text"
                   onChange={date => setStartDate(date)} 
                  //   onChange={handleDateTimeChange}
                /> 
                          </FormGroup> */}
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Seat Rows</label>
                      <Input
                        type="text"
                        name="seat_rows"
                        defaultValue={seatingRows}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Highway mileage</label>
                      <Input
                        type="text"
                        name="highway_mileage"
                        innerRef={register}
                        defaultValue={
                          decodedEngine?.fuelEconomy
                            ? decodedEngine.fuelEconomy.hwy
                            : inventory.highway_mileage
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>City Mileage</label>
                      <Input
                        type="text"
                        name="city_mileage"
                        innerRef={register}
                        defaultValue={
                          decodedEngine?.fuelEconomy
                            ? decodedEngine.fuelEconomy.city
                            : inventory.city_mileage
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Displacement</label>
                      <Input
                        type="text"
                        name="displacement_value"
                        defaultValue={
                          decodedEngine?.displace
                            ? `${decodedEngine.displace.value} ${decodedEngine.displace.unit}`
                            : inventory.displacement_value
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Horsepower</label>
                      <Input
                        type="text"
                        name="horsepower_value"
                        defaultValue={
                          decodedEngine?.horsepower
                            ? `${decodedEngine.horsepower.value}@${decodedEngine.horsepower.rpm}`
                            : inventory.horsepower_value
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label>Torque</label>
                      <Input
                        type="text"
                        name="torque_value"
                        defaultValue={
                          decodedEngine?.torque
                            ? `${decodedEngine.torque.value}@${decodedEngine.torque.rpm}`
                            : inventory.torque_value
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Cylinders</label>
                      <Input
                        type="text"
                        name="cylinders"
                        defaultValue={
                          decodedEngine?.cylinders
                            ? decodedEngine.cylinders
                            : inventory.cylinders
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Package Code</label>
                      <Input
                        type="text"
                        name="package_code"
                        defaultValue={
                          decodedEngine?.package_code
                            ? `${decodedEngine.package_code.value}@${decodedEngine.package_code}`
                            : inventory.package_code
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label>Wheelbase (mm)</label>
                      <Input
                        type="text"
                        name="wheelbase"
                        defaultValue={
                          decodedEngine?.wheelbase
                            ? `${decodedEngine.wheelbase.value}@${decodedEngine.wheelbase}`
                            : inventory.wheelbase
                        }
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Wheelbase Type</label>
                      <Select
                        options={WheelBaseTypeOptions}
                        name="wheelbase_type"
                        className="text-dark"
                        control={control}
                        value={selWheelbaseType}
                        // defaultValue={selWheelbaseType}
                        onChange={changeWheelbasetype}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Cab Type</label>
                      <Select
                        options={CabTypeOptions}
                        name="cab_type"
                        className="text-dark"
                        control={control}
                        value={selCabType}
                        onChange={changeCabType}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label>Pickup Bedtype</label>
                      <Select
                        options={PickupBedtypeOptions}
                        name="pickup_bedtype"
                        className="text-dark"
                        control={control}
                        value={selPickupBedtype}
                        onChange={changePickupBedtype}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Pickup Bedlength</label>
                      <Select
                        options={PickupBedlengthOptions}
                        name="pickup_bedlength"
                        className="text-dark"
                        control={control}
                        value={selPickupBedlength}
                        onChange={changePickupBedlength}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Row>
                        <Col>
                          <label>Select Location:</label>
                        </Col>
                      </Row>

                      <Select
                        name="location"
                        options={dealerLocations}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => `${option.id}`}
                        className="text-dark"
                        control={control}
                        value={selDelLocation}
                        onChange={changeLocation}
                        innerRef={register}

                        // rules={{ required: "You must select a Location" }}
                      />
                      {selDelLocation ? (
                        ""
                      ) : (
                        <p className="text-danger small">
                          {errors.location && (
                            <span>{errors.location.message}</span>
                          )}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Row>
                        <Col>
                          <label>Fuel Type</label>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Select
                        options={FuelOptions}
                        name="fuel_type"
                        className="text-dark"
                        control={control}
                        value={selFuelType}
                        onChange={changeFuelType}
                        innerRef={register}
                      />
                      {selFuelType ? (
                        ""
                      ) : (
                        <p className="text-danger small">
                          {errors.fuel_type && (
                            <span>{errors.fuel_type.message}</span>
                          )}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Row>
                        <Col>
                          <label>Vehicle Type</label>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Select
                        options={VehicleOptions}
                        name="veh_type"
                        className="text-dark"
                        value={selVehType}
                        onChange={changeVehType}
                        innerRef={register}
                      />
                      {selVehType ? (
                        ""
                      ) : (
                        <p className="text-danger small">
                          {errors.veh_type && (
                            <span>{errors.veh_type.message}</span>
                          )}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <label>Condition</label>
                      </Col>
                    </Row>
                    <Select
                      options={ConditionOptions}
                      name="condition_select"
                      className="text-dark"
                      control={control}
                      value={selCondition}
                      onChange={changeCondition}
                      innerRef={register}
                    />

                    {selCondition ? (
                      ""
                    ) : (
                      <p className="text-danger small">
                        {errors.condition_select && (
                          <span>{errors.condition_select.message}</span>
                        )}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <label>Status</label>
                      </Col>
                    </Row>
                    <Select
                      name="status_select"
                      options={StatusOptions}
                      className="text-dark"
                      control={control}
                      value={selStatus}
                      onChange={changeStatus}
                      innerRef={register}
                    />{" "}
                    {selStatus ? (
                      ""
                    ) : (
                      <p className="text-danger small">
                        {errors.status_select && (
                          <span>{errors.status_select.message}</span>
                        )}
                      </p>
                    )}
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <label>Transmission </label>
                      </Col>
                    </Row>

                    <Select
                      name="transmission_select"
                      options={TransmissionOptions}
                      value={selTranmission}
                      onChange={changeTransmission}
                      className="text-dark"
                      control={control}
                      innerRef={register}
                    />
                    {selTranmission ? (
                      ""
                    ) : (
                      <p className="text-danger small">
                        {errors.transmission_select && (
                          <span>{errors.transmission_select.message}</span>
                        )}
                      </p>
                    )}
                  </Col>
                </Row>
                {/* {selStatus?.id===6 &&
                         <Row>
                            <Col>
                            Select Customer
                            </Col>
                            <Col>
                            
                            </Col>
                          </Row>
                        }  */}
                <FormGroup>
                  <label>Meta Description</label>
                  <Input
                    type="textarea"
                    name="meta_description"
                    defaultValue={
                      metaDescription
                        ? metaDescription
                        : inventory.meta_description
                    }
                    id="meta_description"
                    innerRef={register}
                  />
                </FormGroup>

                {props.hideDecode == true ? (
                  ""
                ) : (
                  <DecodeDetail
                    id={inventoryId}
                    decodedStyle={decodedStyle}
                    ShowAllSpecs={ShowAllSpecs}
                    standardEquipment={standardEquipment}
                    technicalSpecification={technicalSpecification}
                    genericEquipment={genericEquipment}
                    consumerInformation={consumerInformation}
                    factoryImages={factoryImages}
                    descodeSummary={
                      get_inventory?.inventory_notes?.descode_summary_html
                    }
                  />
                )}
                {!_.isEmpty(errors) && (
                  <p className="text-danger p-2">
                    Please double check for Errors Above, once resolved click on
                    Save below to continue
                  </p>
                )}
                <MUIButton
                  className="mb-2"
                  variant="contained"
                  disabled={showSave}
                  color="preAuto"
                  type="submit"
                >
                  Save
                </MUIButton>

                <p className="text-white bg-dark p-2">
                  Warning: Unless you see the inventory summary page, or
                  notification it's unlikely everything would have saved
                </p>
              </Form>
            </CardBody>
          </Card>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreatEdit;

import React from "react";
import PropsTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import { alpha } from "@mui/material";

const VerticalCollapseItem = ({ children, sidebarTextColor, ...rest }) => {
  return (
    <ListItem
      className={"rounded-menu"}
      sx={{
        height: 40,
        my: 0.125,
        pl: "31px",
        pr: 1.875,
        whiteSpace: "nowrap",
        transition: "all 0.4s ease",
        "& .nav-item-text": {
          fontWeight: 500,
          color: alpha("rgba(0,0,0,0.7)", 0.7),
          fontSize: 14,
        },

        "& .nav-item-icon": {
          color: alpha("rgba(0,0,0,0.7)", 0.7),
          fontSize: 14,
          display: "block",
        },

        "& .nav-item-icon-arrow": {
          color: alpha("rgba(0,0,0,0.7)", 0.7),
        },

        "& .MuiIconButton-root": {
          mr: 3,
          padding: 0,
        },

        "& .MuiTouchRipple-root": {
          zIndex: 10,
        },

        "&.open, &:hover, &:focus": {
          "& .nav-item-text": {
            fontWeight: 500,
            color: sidebarTextColor,
          },

          "& .nav-item-icon": {
            color: sidebarTextColor,
          },

          "& .nav-item-icon-arrow": {
            color: sidebarTextColor,
          },
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      {...rest}
      style={{
        marginLeft: "8px",
        marginRight: "8px",
        borderRadius: "8px",
      }}
    >
      {children}
    </ListItem>
  );
};

export default VerticalCollapseItem;

VerticalCollapseItem.propTypes = {
  children: PropsTypes.node,
  sidebarTextColor: PropsTypes.string,
};

import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import Avatar from "react-avatar";
import { LOAD_ALL_EMPLOYEES } from "constants/actions";

import CreateEdit from "./CreateEdit";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";

import { TiTick } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AddNewEmployee from "./AddNewEmployee";

const List = (props) => {
  const dispatch = useDispatch();
  const [employees, setEmployees] = React.useState([]);
  const [updated, setUpdated] = React.useState(false);
  const [colWidth, setColWidth] = React.useState([12, 0]);
  const [showEmployee, setShowEmployee] = React.useState(0);
  React.useEffect(() => {
    if (props.dealerId) {
      dispatch({ type: LOAD_ALL_EMPLOYEES, payload: props.params });
    } else {
      dispatch({ type: LOAD_ALL_EMPLOYEES });
    }
  }, [dispatch, props, updated]);

  const { employee_list } = useSelector(
    (state) => ({
      employee_list: state.employees.employees,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setEmployees(employee_list);
  }, [employee_list]);
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };
  const SelectedEmployee = (id = 0) => {
    setShowEmployee(id);
    // setColWidth([6, 6]);
    onOpenAddTask();
  };
  const resetColWidth = (updated) => {
    setUpdated(updated);
    setColWidth([12, 0]);
    setShowEmployee(false);
  };
  // console.log('dealerId', props.dealerId)
  return (
    <>
      <Row>
        <Col md={colWidth[(0, 0)]}>
          <Card>
            <CardHeader>
              <Row>
                <Col md="10">
                  <CardTitle tag="h4">Team Members</CardTitle>
                </Col>

                <Col>
                  {props.showAdd ? (
                    <>
                      <MUIButton
                        color="preAuto"
                        variant="contained"
                        onClick={() => SelectedEmployee(0)}
                      >
                        Add New
                      </MUIButton>

                      {/* <Button
                      color="info"
                      // to={`/admin/employee-create`}
                      // tag={Link}
                      onClick={() => SelectedEmployee(0)}
                      outline
                      size="sm"
                      >
                      Add New
                    </Button> */}
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name / Role</StyledTableCell>
                        <StyledTableCell align="center">Email</StyledTableCell>
                        <StyledTableCell align="center">Leads</StyledTableCell>
                        <StyledTableCell align="center">More</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employees &&
                        employees.map((employee, k) => (
                          <StyledTableRow key={k}>
                            <StyledTableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    mr: 2,
                                  }}
                                >
                                  <Avatar
                                    size={40}
                                    round
                                    name={`${employee.first_name} ${employee.last_name}`}
                                  />
                                </Box>
                                <Box>
                                  {employee.first_name} {employee.last_name}
                                  <br />
                                  <small>
                                    {employee.status_text} {employee.role_text}
                                  </small>
                                </Box>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {employee.email}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {employee.get_leads ? (
                                <TiTick size={40} color={"#00ff00"} />
                              ) : (
                                <IoClose size={40} color={"#eb4034"} />
                              )}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <RemoveRedEyeOutlinedIcon
                                color={"preAuto"}
                                onClick={() => SelectedEmployee(employee?.id)}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
              </Paper>
              {/* <ul className="list-unstyled team-members">
                {employees.map((p, k) => (
                  <li key={k}>
                    <Row>
                      <Col md="1" xs="12">
                        <div className="avatar">
                          <Avatar name={`${p.first_name} ${p.last_name}`} />
                        </div>
                      </Col>
                      <Col md="3" xs="12">
                        {p.first_name} {p.last_name}
                        <br />
                        <span className="text-muted">
                          <small>
                            {p.status_text} {p.role_text}
                          </small>
                        </span>
                      </Col>
                      <Col md="3" xs="12">
                        {p.email}
                      </Col>
                      <Col md="3" xs="12">
                        <span
                          style={{
                            backgroundColor: p.get_leads
                              ? "#00ff00"
                              : "#eb4034",
                            padding: "10px",
                          }}
                        >
                          Leads # {p.get_leads ? "Yes" : "No"}
                        </span>
                      </Col>
                      <Col md="2" xs="12">
                        <Button
                          // to={`/admin/employee-detail/${p.id}`}
                          // tag={Link}
                          onClick={() => SelectedEmployee(p?.id)}
                          size="sm"
                        >
                          View
                        </Button>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul> */}
              {
                "[Active can login, Paused => Logged out of all systems, no leads, , InActive => Login Disabled and need to re-register]"
              }
            </CardBody>
          </Card>
        </Col>

        {/* <Col className={colWidth[1] > 0 ? "d-block" : "d-none"}>
          <CreateEdit
            dealerId={props.dealerId}
            resetColWidth={resetColWidth}
            employeeId={showEmployee}
          />
        </Col> */}
      </Row>
      <AddNewEmployee
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
        dealerId={props.dealerId}
        resetColWidth={resetColWidth}
        employeeId={showEmployee}
      />
    </>
  );
};

export default List;

import React from "react";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";

// import { forgotPassword } from "../actions/auth";
import SweetAlert from "react-bootstrap-sweetalert";

import { REQUEST_PASSWORD_RESET, LOGIN_REQUEST } from "constants/actions";

import keyConfig from "../_config";

// reactstrap components
// import {
//   Alert,
//   Card,
//   CardHeader,
//   CardTitle,
//   CardBody,
//   CardFooter,
//   Container,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Input,
//   Label,
//   Button,
// } from "reactstrap";

// core components
import bgImage from "assets/img/bg/fabio-mangione.jpg";
import { Box, Typography, Button } from "@mui/material";
import AuthWrapper from "./auth/AuthWrapper";
import { Fonts } from "../crema/constants/src";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AppTextField from "crema/AppFormComponents/AppTextField";
import IntlMessages from "crema/helpers/src/IntlMessages";

const validationSchema = yup.object({
  email: yup
    .string()
    .email(<IntlMessages id="The Email you entered is not a valid format!" />)
    .required(<IntlMessages id="Please enter Email Address!" />),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const { register, handleSubmit, errors, reset } = useForm();

  const [alert, setAlert] = React.useState(false);
  const [forgotError, setForgotError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [reMsg, setRegMsg] = React.useState(false);
  const [statusMsg, setStatusMsg] = React.useState(false);
  const [submitPage, setSubmitPage] = React.useState(false);

  const [ipData, setIpData] = React.useState(null);
  const [showReport, setShowReport] = React.useState(true);

  React.useEffect(() => {
    if (showReport) {
      fetch("https://extreme-ip-lookup.com/json") // Get the IP data
        .then((res) => res.json())
        .then((ip) => {
          setIpData(ip.query);
          setShowReport(false);
        });
      // .then(ip => Promise.all([ip, getFingerprint()]))    // Get the fingerprint
      // .then(([ip, fp]) => {
      //   setFingerprint(fp);                               // Update the state
      //   setIpData(ip);
      //   setShowReport(false);
      // });
    }
  }, [showReport]);

  const { resetError, registerError, get_messages } = useSelector(
    (state) => ({
      resetError: state.reset_password_error,
      registerError: state.errors.msg,
      get_messages: state.messages.status,
    }),
    shallowEqual
  );

  if (registerError) {
    if (registerError["email"] && forgotError === false) {
      // console.log(registerError['email'])
      setForgotError(registerError["email"]);
    }
  }

  const onSubmit = (data) => {
    // console.log(data.email, keyConfig.portalkey)
    dispatch({
      type: REQUEST_PASSWORD_RESET,
      payload: {
        ...data,
        dealer_token: keyConfig.passwordReset,
        token_type: "dealer",
        user_ip: ipData,
      },
    });
    // e.target.reset(); // reset after form submit
  };

  const { error, message } = useSelector(
    (state) => ({
      error: state.errors.errors,
      message: state.messages.status,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (error) {
      setErrorMsg(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (message) {
      setRegMsg(message);
    }
    if (message === "OK") {
      // console.log(message, reMsg)
      // redirect after 5 seconds
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Sucess"
          onConfirm={() => goLogin()}
          confirmBtnBsStyle="info"
        >
          If this email was found in our records, we will send you an email soon
          with next steps. Please check your email!
        </SweetAlert>
      );
      setSubmitPage(true);
      setTimeout(() => {
        history.push("/auth/login");
      }, 10000);
    }
  }, [message]);
  // console.log(message, reMsg)
  function goLogin() {
    history.push("/auth/login");
  }

  return (
    <>
      <AuthWrapper
        title="Login"
        rightSection={
          <>
            <Typography
              component="h2"
              sx={{
                fontWeight: Fonts.BOLD,
                // fontSize: 30,
                mb: 2,
              }}
            >
              Please enter the registered email id and we will sent a link to
              your email with the next steps
            </Typography>
          </>
        }
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ mb: { xs: 4, xl: 5 } }}>
            <Typography
              variant="h2"
              component="h2"
              sx={{
                mb: 0.75,
                color: (theme) => theme.palette.text.primary,
                fontWeight: Fonts.SEMI_BOLD,
                fontSize: { xs: 14, xl: 16 },
              }}
            >
              <IntlMessages id="Forget Your Password?" />
            </Typography>

            <Typography
              sx={{
                pt: 1.5,
                fontSize: 15,
                color: "grey.500",
              }}
            >
              <span style={{ marginRight: 4 }}>
                <IntlMessages id="Have password?" />
              </span>
              <Box
                component="span"
                sx={{
                  fontWeight: Fonts.MEDIUM,
                  "& a": {
                    color: "#1d4aa7",

                    textDecoration: "none",
                  },
                }}
              >
                <Link to="/auth/login">
                  <IntlMessages id="Sign In" />
                </Link>
              </Box>
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                // onSubmit={(data, { setSubmitting, resetForm }) => {
                //   setSubmitting(true);
                //   // console.log(data)
                //   onSubmit(data);
                //   // resetForm();
                //   setSubmitting(false);
                // }}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form style={{ textAlign: "left" }}>
                    <Box sx={{ mb: { xs: 5, lg: 8 } }}>
                      <AppTextField
                        placeholder="Email"
                        name="email"
                        label={<IntlMessages id="Email Address" />}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            fontSize: 14,
                          },
                        }}
                        variant="outlined"
                      />
                    </Box>

                    <div>
                      <Button
                        variant="contained"
                        color="preAuto"
                        disabled={isSubmitting}
                        sx={{
                          fontWeight: Fonts.REGULAR,
                          textTransform: "capitalize",
                          fontSize: 16,
                          minWidth: 160,
                        }}
                        type="submit"
                      >
                        <IntlMessages id="Get Started" />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        {alert}
      </AuthWrapper>
    </>
  );
};

export default ForgotPassword;

import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import { CMS_HOMEPAGE_TYPE, LOAD_HOMEPAGE_TYPE, UPDATE_CMS_HOME_PAGE_TYPE } from "constants/actions";
import { EditorInLineConvertToHTML } from "Inventory/InventoryDetail/EditorConvertToHTML";
export default function Cms() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [dealerId, setDealerId] = React.useState();
  const { id, slug } = useParams();
  const [editSubject, setEditSubject] = React.useState("");
  const [editHeadline, setEditHeadline] = React.useState("");
  const [editSubjectContentLeft, setEditSubjectContentLeft] = React.useState("");
  const [editSubjectContentRight, setEditSubjectContentRight] = React.useState("");
  const [editSubjectContent, setEditSubjectContent] = React.useState("");
  const [editMiddleContent, setEditMiddleContent] = React.useState("");
  const [editMiddleContentLeft, setEditMiddleContentLeft] = React.useState("");
  const [editMiddleContentRight, setEditMiddleContentRight] = React.useState("");
  const [editInventory, setEditInventory] = React.useState("");
  const [editBottomContent, setEditBottomContent] = React.useState("");
  const notify = () => toast.success(`Data Updated successfully`);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_HOMEPAGE_TYPE, payload: id });
  }, [dispatch, id]);

  const { get_auth, get_homepage } = useSelector(
    (state) => ({
      get_homepage: state.homepage.homepage,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_homepage) {
      console.log("in if");
      setEditHeadline(get_homepage["results"]?.[0]?.headlineContent);
      setEditSubjectContentLeft(get_homepage["results"]?.[0]?.subjectContentLeft);
      setEditSubjectContentRight(get_homepage["results"]?.[0]?.subjectContentRight);
      setEditSubjectContent(get_homepage["results"]?.[0]?.subjectContent);
      setEditMiddleContentLeft(get_homepage["results"]?.[0]?.middleContentleft);
      setEditMiddleContentRight(get_homepage["results"]?.[0]?.middleContentRight);
      setEditMiddleContent(get_homepage["results"]?.[0]?.middleContent);
      setEditInventory(get_homepage["results"]?.[0]?.inventoryContent);
      setEditBottomContent(get_homepage["results"]?.[0]?.bottomContent);
    } 
  }, [get_homepage]);

  const BtnCallBackHomePage = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        headlineContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackSubject = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        subjectContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackSubjectLeft = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        subjectContentLeft: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackSubjectRight = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        subjectContentRight: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackMiddle = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        middleContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackMiddleLeft = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        middleContentleft: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackMiddleRight = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        middleContentRight: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackBottom = (data) => {
    dispatch({
      type: UPDATE_CMS_HOME_PAGE_TYPE,
      payload: {
        id: get_homepage["results"]?.[0]?.id,
        bottomContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  //   const onSubmit = (data) => {
  //   dispatch({
  //     type: CMS_HOMEPAGE_TYPE,
  //     payload: {
  //       headlineContent: data.headlineContent,
  //       // inventoryContent: data.inventoryContent,
  //       subjectContent: data.subjectContent,
  //       subjectContentLeft: data.subjectContentLeft,
  //       subjectContentRight: data.subjectContentRight,
  //       middleContent: data.middleContent,
  //       middleContentRight: data.middleContentRight,
  //       middleContentleft: data.middleContentLeft,
  //       bottomContent: data.bottomContent,
  //       company: dealerId ? dealerId : get_auth.dealer_id,
  //     },
  //   });
  //   notify()
  // };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardHeader>
                    <CardTitle tag="h4">Home Page CMS</CardTitle>
                    {/* <h5 className="card-category">My Tasks</h5> */}
                  </CardHeader>
                </Col>
              </Row>

              {/* Create Api for future use */}
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Headline Content</label>
                  <Input
                    type="text"
                    name="headlineContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

              

                <FormGroup>
                  <label>Subject Content</label>
                  <Input
                    type="text"
                    name="subjectContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Subject Content Left</label>
                  <Input
                    type="text"
                    name="subjectContentLeft"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Subject Content Right</label>
                  <Input
                    type="text"
                    name="subjectContentRight"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Middle Content</label>
                  <Input
                    type="text"
                    name="middleContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Middle Content Right</label>
                  <Input
                    type="text"
                    name="middleContentRight"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Middle Content Left</label>
                  <Input
                    type="text"
                    name="middleContentLeft"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Bottom Content</label>
                  <Input
                    type="text"
                    name="bottomContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form> */}

              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Headline Content "
                    editContent={editHeadline}
                     BtnCallBack={BtnCallBackHomePage}
                  />
                </Col>
              </Row>
              <br/>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Subject Content"
                    editContent={editSubjectContent}
                     BtnCallBack={BtnCallBackSubject}
                  />
                </Col>
              </Row>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Subject Content Left"
                    editContent={editSubjectContentLeft}
                     BtnCallBack={BtnCallBackSubjectLeft}
                  />
                </Col>
              </Row>
              <br/>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Subject Content Right"
                    editContent={editSubjectContentRight}
                     BtnCallBack={BtnCallBackSubjectRight}
                  />
                </Col>
              </Row>
              <br/>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Middle Content"
                    editContent={editMiddleContent}
                     BtnCallBack={BtnCallBackMiddle}
                  />
                </Col>
              </Row>
              <br/>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Middle Content Right"
                    editContent={editMiddleContentRight}
                     BtnCallBack={BtnCallBackMiddleRight}
                  />
                </Col>
              </Row>
              <br/>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Middle Content Left"
                    editContent={editMiddleContentLeft}
                     BtnCallBack={BtnCallBackMiddleLeft}
                  />
                </Col>
              </Row>
              <br/>
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Bottom Content"
                    editContent={editBottomContent}
                     BtnCallBack={BtnCallBackBottom}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

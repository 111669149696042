import { GET_STRIPE_CUSTOMER } from "constants/actions";
import { GET_CASHFORCARS } from "constants/actions";
import {
  FETCH_CASH_FOR_CARS_CMS,
  EDIT_CASH_FOR_CARS,
  UPDATE_CMS_CONTACT_PAGE,
  UPDATE_CMS_PRIVACY_PAGE,
  UPDATE_CMS_HOME_PAGE,
  UPDATE_CMS_TERMS_PAGE,
  UPDATE_CMS_ABOUT_PAGE,
  CASH_FOR_CARS,
  CMS_CREATE_CONTACT_PAGE,
  CMS_PRIVACY_POLICY,
  CMS_HOMEPAGE,
  STRIPE_CREATE_INVOICE,
  STRIPE_CREATE_CUSTOMER,
  STRIPE_FETCH_INVOICE,
  CMS_TERMS,
  CMS_ABOUT,
  LOAD_CMS_PRIVACY,
  LOAD_HOMEPAGE,
  LOAD_CMS_TERMS,
  LOAD_CMS_ABOUT,
  LOAD_CMS_CONTACT_PAGE,
  GET_STRIPE_PRODUCTS,

} from "constants/actions";

const initialState = {
  cashforcars: [],
  contactpage: [],
  privacy: [],
  about: [],
  terms: [],
  homepage: [],
  created: [],
  selected: [],
  stripecustomer: [],
  stripeproducts: [],
  stripeinvoice: [],
  createstripecustomer: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CASHFORCARS:
      return {
        ...state,
        cashforcars: action.payload,
      };
      case GET_STRIPE_CUSTOMER:
        return {
          ...state,
          stripecustomer: action.payload,
        };

        case GET_STRIPE_PRODUCTS:
        return {
          ...state,
          stripeproducts: action.payload,
        };

      case LOAD_CMS_PRIVACY:
        return {
          ...state,
          privacy: action.payload,
        };

        case LOAD_HOMEPAGE:
          return {
            ...state,
            homepage: action.payload,
          };
        case LOAD_CMS_TERMS:
        return {
          ...state,
          terms: action.payload,
        };

        case LOAD_CMS_ABOUT:
        return {
          ...state,
          about: action.payload,
        };
      case LOAD_CMS_CONTACT_PAGE:
      return {
        ...state,
        contactpage: action.payload,
      };

    case FETCH_CASH_FOR_CARS_CMS:
      return { ...state, [action.payload.id]: action.payload };

    case EDIT_CASH_FOR_CARS:
      return { ...state, [action.payload.id]: action.payload };

      case UPDATE_CMS_CONTACT_PAGE:
      return { ...state, [action.payload.id]: action.payload };

      case UPDATE_CMS_PRIVACY_PAGE:
      return { ...state, [action.payload.id]: action.payload };

      case UPDATE_CMS_HOME_PAGE:
      return { ...state, [action.payload.id]: action.payload };

      case UPDATE_CMS_TERMS_PAGE:
        return { ...state, [action.payload.id]: action.payload };

      case UPDATE_CMS_ABOUT_PAGE:
      return { ...state, [action.payload.id]: action.payload };

    case CASH_FOR_CARS:
      return {
        ...state,
        created: action.payload,
      };
      case CMS_CREATE_CONTACT_PAGE:
      return {
        ...state,
        created: action.payload,
      };
      case CMS_PRIVACY_POLICY:
        return {
          ...state,
          created: action.payload,
        };
        case CMS_HOMEPAGE:
        return {
          ...state,
          created: action.payload,
        };
        case STRIPE_CREATE_INVOICE:
        return {
          ...state,
          created: action.payload,
        };
        case STRIPE_CREATE_CUSTOMER:
          return {
            ...state,
            created: action.payload,
          };
        case STRIPE_FETCH_INVOICE:
        return {
          ...state,
          stripeinvoice: action.payload,
        };


        case CMS_TERMS:
        return {
          ...state,
          created: action.payload,
        };
        case CMS_ABOUT:
        return {
          ...state,
          created: action.payload,
        };
    default:
      return state;
  }
}

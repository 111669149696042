import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import {
  CASH_FOR_CARS_CMS,
  LOAD_GET_CASHFORCARS,
  UPDATE_CASH_FOR_CARS,
} from "constants/actions";
import { EditorInLineConvertToHTML } from "Inventory/InventoryDetail/EditorConvertToHTML";
export default function CashForCars(props) {
  const [dealerId, setDealerId] = React.useState();
  const { id, slug } = useParams();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [cashforcars, setCashForCars] = React.useState([]);
  const [editSubject, setEditSubject] = React.useState("");
  const [editNewContent, setEditContent] = React.useState("");
  const notify = () => toast.success(`Data Updated successfully`);


  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_GET_CASHFORCARS, payload: id });
  }, [dispatch, id]);


  const { get_auth, get_cashforcars } = useSelector(
    (state) => ({
      get_cashforcars: state.cashforcars.cashforcars,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  const BtnCallBackCFC = (data) => {
    dispatch({
      type: UPDATE_CASH_FOR_CARS,
      payload: {
        id: get_cashforcars["results"]?.[0]?.id,
        subjectContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };

  const BtnCallBackCFC2 = (data) => {
    dispatch({
      type: UPDATE_CASH_FOR_CARS,
      payload: {
        id: get_cashforcars["results"]?.[0]?.id,
        bottomContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  React.useEffect(() => {
    if (get_cashforcars) {
      setEditSubject(get_cashforcars["results"]?.[0]?.subjectContent);
      setEditContent(get_cashforcars["results"]?.[0]?.bottomContent);
    }
  }, [get_cashforcars]);

  // const onSubmit = (data) => {
  //   dispatch({
  //     type: CASH_FOR_CARS_CMS,
  //     payload: {
  //       subjectContent: data.subjectContent,
  //       bottomContent: data.bottomContent,
  //       company: dealerId ? dealerId : get_auth.dealer_id,
  //     },
  //   });
  //   notify()
  // };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardHeader>
                    <CardTitle tag="h4">Cash For Cars CMS</CardTitle>
                    {/* <h5 className="card-category">My Tasks</h5> */}
                  </CardHeader>
                </Col>
              </Row>
              {/* Create Api for future use */}
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Subject And Content</label>
                  <Input
                    type="text"
                    name="subjectContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Botton Content </label>
                  <Input
                    type="text"
                    name="bottomContent"
                    innerRef={register({
                      required: "Please enter Last Name",
                    })}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form> */}

         
                <Row className="pt-1">
                  <Col md="12">
                    <EditorInLineConvertToHTML
                      heading="Subject & Content "
                      editContent={editSubject}
                      BtnCallBack={BtnCallBackCFC}
                    />
                  </Col>
                </Row>

                <br />
                <Row className="pt-1">
                  <Col md="12">
                    <EditorInLineConvertToHTML
                      heading="Bottom Content "
                      editContent={editNewContent}
                      BtnCallBack={BtnCallBackCFC2}
                    />
                  </Col>
                </Row>
       
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import NoImage from "assets/images/no-image-available.png";

import Card from "@mui/material/Card";
import ShareIcon from "@mui/icons-material/Share";
// import Tooltip from "@mui/material/Tooltip";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { grey, red } from "@mui/material/colors";
// import Tooltip from '@mui/material/Tooltip';
import { alpha } from "@mui/material";
// import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import { Button as MUIButton, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { BsLink45Deg } from "react-icons/bs";
import { BsSpeedometer2 } from "react-icons/bs";
import { SiMdbook } from "react-icons/si";
import { MdGroups } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";

// reactstrap components
import {
  // Button,
  Input,
  Form,
  // Input,
  Spinner,
  Fade,
  Table,
  Row,
  Col,
  Label,
  // Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Tooltip,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  LOAD_INVENTORY_LIST,
  SG_INVENTORY_LIST,
  LOAD_COMPARED_INVENTORY_LIST,
  SG_FETCH_RELATED_DEALER,
  SG_EDIT_INVENTORY,
} from "constants/actions";

import CarCard from "./CarCard";
import ToolTipComp from "./ToolTipComp";
import { StatusOptions } from "Inventory/inventorystatus";

import "./style.css";

import { CSVLink, CSVDownload } from "react-csv";

function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

const { Parser } = require("json2csv");

const CSV_HEADERS = [
  "Id",
  "VIN",
  "Stock",
  "Year",
  "Make",
  "Model",
  "Trim",
  "Color",
  "Manufacturer_Color",
  "Interior",
  "Manufacturer_interior",
  "Transmission",
  "Engine",
  "No_of_images",
  "Body_Type",
  "Vehicle_Type",
  "Driving_Wheels",
  "Engine_Type",
  "Fuel_Type",
  "Cylinders",
  "Displacement_unit",
  "Kilometers",
  "List_Price",
  "Cost_Price",
  "Status",
  "Vehicle_In",
  "Doors",
  "Seat_Rows",
  "Passenger_Capacity",
  "Syndicate_Ex",
  "Trim_Ex",
  "VDP_Syndication",
];

const SELECTED_CSV_HEADERS = [
  { label: "Id", value: "id" },
  { label: "VIN", value: "vin" },
  { label: "Stock", value: "stock" },
  { label: "Year", value: "year" },
  { label: "Make", value: "make" },
  { label: "Model", value: "model" },
  { label: "Trim", value: "trim" },
  { label: "Color", value: "color" },
  { label: "Manufacturer_Color", value: "mfd_color_code" },
  { label: "Interior", value: "interior_color" },
  { label: "Manufacturer_interior", value: "interior_mfd_color_code" },
  { label: "Transmission", value: "transmission" },
  { label: "Engine", value: "engine" },
  { label: "No_of_images", value: "total_images" },
  { label: "Body_Type", value: "body_type" },
  { label: "Vehicle_Type", value: "veh_type" },
  { label: "Driving_Wheels", value: "driving_wheels" },
  { label: "Engine_Type", value: "engine_type" },
  { label: "Fuel_Type", value: "fuel_type" },
  { label: "Cylinders", value: "cylinders" },
  { label: "Displacement_unit", value: "displacement_unit" },
  { label: "Kilometers", value: "kilometers" },
  { label: "List_Price", value: "list_price" },
  { label: "Cost_Price", value: "cost_price" },
  { label: "Status", value: "status_text" },
  { label: "Vehicle_In", value: "veh_in_date" },
  { label: "Doors", value: "pass_doors" },
  { label: "Seat_Rows", value: "seat_rows" },
  { label: "Passenger_Capacity", value: "pass_capacity" },
  { label: "Syndicate_Ex", value: "syndicate_extra" },
  { label: "Trim_Ex", value: "trim_extra" },
  { label: "VDP_Syndication", value: "vdp_header_syndicate" },
];

const ShowCompared = ({ vin, comparedList }) => {
  // if (comparedList) {
  //   console.log("console list gounf", comparedList);
  //   console.log(comparedList.filter((fl) => fl.vin === vin)[0]);
  // }.

  if (!vin) {
    return <>Not Info sfd</>;
  }
  if (!comparedList) {
    return <>Not Info</>;
  }
  if (comparedList.filter((fl) => fl.vin === vin)[0]) {
    return (
      <>
        Notes: {/* <Badge color="primary"> */}
        {comparedList.filter((fl) => fl.vin === vin)[0].notes}
        {/* </Badge> */}
      </>
    );
  } else {
    return <>{/* <p className="bg-danger">Not Found in Backend</p> */}</>;
  }
};

const InventoryList = () => {
  const dispatch = useDispatch();
  const json2csvParser = new Parser({
    fields: SELECTED_CSV_HEADERS,
    header: false,
  });
  const [pending, setPending] = useState();
  const [inventoryList, setInventoryList] = useState([]);
  const [comparedList, setComparedList] = useState([]);
  const [selFacebookOnly, setselFacebookOnly] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [selLocation, setSelLocation] = useState(false);
  const [ascending, setAscending] = useState(0);

  const [selectedFullRow, setSelectedFullRow] = useState([]);

  const [showSelectedRow, setShowSelectedRow] = useState(false);
  const [revisedStatus, setRevisedStatus] = useState(false);

  const [selXclean, setSelXclean] = useState(false);
  const [selClean, setSelClean] = useState(false);
  const [selAvg, setSelAvg] = useState(false);
  const [selRough, setSelRough] = useState(false);

  const [pageparams, setPageparams] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [inventoryCsv, setInventoryCsv] = useState([]);

  const [delistModal, setDelistModal] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
    formState,
  } = useForm();

  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_INVENTORY_LIST, payload: pageparams });
    dispatch({ type: LOAD_COMPARED_INVENTORY_LIST });
    dispatch({ type: SG_FETCH_RELATED_DEALER });
    dispatch({ type: SG_INVENTORY_LIST });
  }, [dispatch, pageparams]);

  // React.useEffect(() => {
  //   // Run! Like go get some data from an API.

  // }, [dispatch,]);

  const {
    get_inventories,
    get_pending,
    get_compared,
    get_inventory_notes,
    get_related,
  } = useSelector(
    (state) => ({
      get_inventories: state.inventories.inventoryList,
      get_pending: state.inventories.pending,
      get_compared: state.inventories.compared,
      get_inventory_notes: state.inventorynotes.inventorylist,
      get_related: state.dealers.related,
    }),
    shallowEqual
  );

  // console.log(get_inventories)
  // console.log(get_inventory_notes)
  // npx browserslist@latest --update-db
  useEffect(() => {
    if (get_inventories && get_inventories.length > 0) {
      const _Inven_List = [];
      get_inventories.map((item) => {
        const _invNotes = get_inventory_notes.filter(
          (inv) => inv.inventory_id === item.id
        )?.[0];

        return _Inven_List.push({
          id: item.id,
          vin: item.vin,
          stock: item.stock,
          year: item.year,
          make: item.make,
          model: item.model,
          trim: item.trim,
          color: item.color,
          mfd_color_code: item.mfd_color_code,
          interior_color: item.interior_color,
          interior_mfd_color_code: item.interior_mfd_color_code,
          transmission: item.transmission,
          engine: item.engine,
          total_images: item.total_images,
          body_type: item.body_type,
          veh_type: item.veh_type,
          driving_wheels: item.driving_wheels,
          engine_type: item.engine_type,
          fuel_type: item.fuel_type,
          cylinders: item.cylinders,
          displacement_unit: item.displacement_unit,
          kilometers: item.kilometers,
          list_price: item.list_price,
          cost_price: item.cost_price,
          status_text: item.status_text,
          veh_in_date: item.veh_in_date,
          pass_doors: item.pass_doors,
          seat_rows: item.seat_rows,
          pass_capacity: item.pass_capacity,
          syndicate_extra: _invNotes?.syndicate_extra,
          trim_extra: _invNotes?.trim_extra,
          vdp_header_syndicate: getText(_invNotes?.vdp_header_syndicate),
        });
      });
      // console.log(_Inven_List)
      setInventoryCsv(json2csvParser.parse(_Inven_List));
    }
  }, [get_inventories]);

  // console.log(inventoryCsv);

  function MoveToNewStatus() {
    selectedFullRow.map((p) =>
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id: p.id,
          status: revisedStatus.value,
        },
      })
    );
    // remove all selected row and close the modal
    setSelectedFullRow([]);
    setShowSelectedRow(false);
  }

  useEffect(() => {
    if (get_inventories) {
      setInventoryList(get_inventories);
    }
  }, [get_inventories]);

  useEffect(() => {
    if (get_compared) {
      setComparedList(get_compared.results);
    }
  }, [get_compared]);

  useEffect(() => {
    setPending(get_pending);
  }, [get_pending]);

  const sliderFBOnOpen = () => {
    setselFacebookOnly(!selFacebookOnly);
  };

  const OnLocationSelect = (e) => {
    if (e) {
      setSelLocation(e.id);
    } else {
      setSelLocation(null);
    }
  };

  const OnSelectStatus = (e) => {
    if (e) {
      setSelectedStatus(e.value);
    } else {
      setSelectedStatus(null);
    }
  };

  // queryset = self.queryset.filter(Q(
  //   company_id=dealer.id, status=4)).exclude(
  //       make__exact='').exclude(
  //       mfd_color_code__exact='').exclude(
  //       kilometers__lte=500).exclude(
  //       list_price__exact=0)

  useEffect(() => {
    if (get_inventories) {
      if (
        !selLocation &&
        !selFacebookOnly &&
        !selectedStatus &&
        !selXclean &&
        !selClean &&
        !selAvg &&
        !selRough
      ) {
        return setInventoryList(get_inventories);
      }
      let InventoryList = get_inventories;
      if (selFacebookOnly) {
        InventoryList = InventoryList.filter(
          (fl) => fl.status === 4 && fl.list_price > 0 && fl.kilometers > 499
        );
      }
      if (selectedStatus) {
        InventoryList = InventoryList.filter(
          (fl) => fl.status === parseInt(selectedStatus)
        );
      }
      if (selLocation) {
        InventoryList = InventoryList.filter(
          (fl) => fl.location === parseInt(selLocation)
        );
      }

      if (selXclean) {
        InventoryList = InventoryList.filter(
          (fl) => fl.blackbook_category !== "xclean_km_threshold"
        );
      }

      if (selClean) {
        InventoryList = InventoryList.filter(
          (fl) => fl.blackbook_category !== "clean_km_threshold"
        );
      }

      if (selAvg) {
        InventoryList = InventoryList.filter(
          (fl) => fl.blackbook_category !== "avg_km_threshold"
        );
      }

      if (selRough) {
        InventoryList = InventoryList.filter(
          (fl) => fl.blackbook_category !== "rough_km_threshold"
        );
      }
      return setInventoryList(InventoryList);
    }
  }, [
    selLocation,
    selFacebookOnly,
    selectedStatus,
    selXclean,
    selClean,
    selAvg,
    selRough,
    get_inventories,
  ]);

  const onSubmit = (data) => {
    let yearParams = [];
    if (data.search.value === "years") {
      let values = data.value.split(",");

      values.forEach((e) => {
        if (e) {
          yearParams.push(`&years=${e.trim()}`);
        }
      });

      setPageparams(yearParams.join(""));
    } else {
      setPageparams(`&${data.search.value}=${data.value}`);
    }
  };

  const SortImages = () => {
    let List = inventoryList;
    if (ascending === 2) {
      List.sort(
        ({ total_images: previousID }, { total_images: currentID }) =>
          currentID - previousID
      );
      setInventoryList(List);
      setAscending(1);
    } else {
      List.sort(
        ({ total_images: previousID }, { total_images: currentID }) =>
          previousID - currentID
      );
      setInventoryList(List);
      setAscending(2);
    }
  };

  const sliderXclean = () => {
    setSelXclean(!selXclean);
  };

  const sliderClean = () => {
    setSelClean(!selClean);
  };

  const sliderAvg = () => {
    setSelAvg(!selAvg);
  };

  const sliderRough = () => {
    setSelRough(!selRough);
  };
  const searchOptions = [
    { value: "vins", label: "vin" },
    { value: "colors", label: "color" },
    { value: "kilometers", label: "kilometers" },
    { value: "makes", label: "make" },
    { value: "body", label: "body" },
    { value: "models", label: "model" },
    { value: "years", label: "year" },
  ];

  const OnCheckClick = (e) => {
    if (selectedFullRow.includes(e)) {
      const SelectedFullList = selectedFullRow.filter((row) => row.id !== e.id);
      setSelectedFullRow(SelectedFullList);
    } else {
      const SelectedFullList = [...selectedFullRow, e];
      setSelectedFullRow(SelectedFullList);
    }
  };

  const OnStatusSelect = (e) => {
    if (e) {
      setRevisedStatus(e);
    } else {
      setRevisedStatus(null);
    }
  };
  const OnChkChange = () => {};
  const onUpdateInvList = () => {
    ListOfInventorySelected(selectedFullRow);
    setShowSelectedRow(true);
  };

  useEffect(() => {
    if (selectedFullRow.length === 0) {
      setShowSelectedRow(false);
    }
  }, [selectedFullRow]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {!showSelectedRow ? (
        <div className="content">
          <div className={pending ? "" : "d-none mt-3"}>
            <Fade in={pending} tag="h5">
              <div className="d-flex justify-content-center">
                <Spinner>Loading...</Spinner>
              </div>
            </Fade>
          </div>
          <div
            className="block"
            style={{
              borderRadius: 10,
              border: "2px solid #1d4aa7",
            }}
          >
            <Row>
              <Col>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Col md="3">
                      <label>Search For</label>
                      <Controller
                        as={<Select />}
                        name="search"
                        options={searchOptions}
                        // isClearable={() => Cleared()}
                        control={control}
                        className="text-dark"
                        rules={{ required: "Please enter search" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.search && <span>{errors.search.message}</span>}
                      </p>
                    </Col>
                    <Col md="7">
                      <label>Value</label>
                      <Input
                        type="text"
                        name="value"
                        innerRef={register({
                          required: "Please enter a search value",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.value && <span>{errors.value.message}</span>}
                      </p>
                    </Col>

                    <Col
                      md="2"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 18,
                      }}
                    >
                      <MUIButton
                        color="primary"
                        type="submit"
                        variant="outlined"
                      >
                        Search
                      </MUIButton>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
          <div
            className="block"
            style={{
              borderRadius: 10,
              border: "2px solid #1d4aa7",
            }}
          >
            <div className="d-flex align-items-end flex-column">
              <span
                style={{
                  backgroundColor: "#1d4aa7",
                  padding: 5,
                  borderRadius: 5,
                  color: "white",
                }}
              >
                <strong>Inventory Nos : {inventoryList.length}</strong>
              </span>
            </div>
            <Row>
              <Col md="3">
                <span className="" href="#" id="ToolTipSwitch">
                  <Label>FB Live List</Label>
                  <Input
                    type="switch"
                    id="showOpen"
                    onClick={sliderFBOnOpen}
                    checked={selFacebookOnly}
                    onChange={sliderFBOnOpen}
                    name="showOpen"
                    label="FB Live List "
                  />
                </span>
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="ToolTipSwitch"
                  toggle={() => {
                    setTooltipOpen(!tooltipOpen);
                  }}
                >
                  FB List filters list price = 0, mileage &#x3e; 500
                </Tooltip>
              </Col>
              <Col md="4">
                <Label>Status</Label>
                <Select
                  name="status_select"
                  options={StatusOptions}
                  isClearable
                  placeholder="All Sale Status"
                  // value={revisedStatus}
                  onChange={(e) => OnSelectStatus(e)}
                />
              </Col>

              <Col md="4">
                <Label>Location</Label>
                <Select
                  name="location_select"
                  options={get_related}
                  isClearable
                  getOptionLabel={(option) => ` ${option.name}`}
                  getOptionValue={(option) => `${option.id}`}
                  placeholder="All Locations"
                  onChange={(e) => OnLocationSelect(e)}
                />
              </Col>
            </Row>
          </div>
          <div
            className="block"
            style={{
              borderRadius: 10,
              border: "2px solid #1d4aa7",
            }}
          >
            <Row className="d-flex justify-content-between mx-2">
              <p className="sub-head mb-2">Blackbook Category</p>
              <Button onClick={SortImages}>
                {ascending === 0 ? (
                  <i className="fa fa-sort" aria-hidden="true"></i>
                ) : (
                  ""
                )}
                {ascending === 1 ? (
                  <i className="fa fa-sort-desc" aria-hidden="true"></i>
                ) : (
                  ""
                )}
                {ascending === 2 ? (
                  <i className="fa fa-sort-asc" aria-hidden="true"></i>
                ) : (
                  ""
                )}
                Images
              </Button>
            </Row>
            <Row>
              <Col>
                <Input
                  type="switch"
                  id="showXclean"
                  onClick={sliderXclean}
                  checked={selXclean}
                  onChange={sliderXclean}
                  name="showXclean"
                  label="Hide Xclean"
                />
              </Col>
              <Col>
                <Input
                  type="switch"
                  id="showClean"
                  onClick={sliderClean}
                  checked={selClean}
                  onChange={sliderClean}
                  name="showClean"
                  label="Hide Clean"
                />
              </Col>
              <Col>
                <Input
                  type="switch"
                  id="showAvg"
                  onClick={sliderAvg}
                  checked={selAvg}
                  onChange={sliderAvg}
                  name="showAvg"
                  label="Hide Avg"
                />
              </Col>
              <Col>
                <Input
                  type="switch"
                  id="showRough"
                  onClick={sliderRough}
                  checked={selRough}
                  onChange={sliderRough}
                  name="showRough"
                  label="Hide Rough"
                />
              </Col>
            </Row>
          </div>

          {selectedFullRow.length > 0 ? (
            <div
              className="block"
              style={{
                borderRadius: 10,
                border: "2px solid #1d4aa7",
              }}
            >
              <Row>
                <Col md="9" className="mt-2">
                  <Select
                    name="set_status"
                    options={StatusOptions}
                    isClearable
                    value={revisedStatus}
                    onChange={(e) => OnStatusSelect(e)}
                  />
                </Col>
                <Col md="3">
                  <MUIButton
                    color="preAuto"
                    variant="contained"
                    disabled={!revisedStatus}
                    onClick={onUpdateInvList}
                    sx={{
                      mt: 1,
                    }}
                  >
                    Update Selected
                  </MUIButton>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}

          {/* <div className={pending ? "d-none" : ""}>
            <Row>
              <Col md="12">
                <Table responsive>
                  <thead className="table-active">
                    <tr>
                      <th>Select</th>
                      <th colSpan="3">
                        Vehicle Information {" "}
                        <Button onClick={SortImages}>
                          {ascending === 0 ? (
                            <i className="fa fa-sort" aria-hidden="true"></i>
                          ) : (
                            ""
                          )}
                          {ascending === 1 ? (
                            <i
                              className="fa fa-sort-desc"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            ""
                          )}
                          {ascending === 2 ? (
                            <i
                              className="fa fa-sort-asc"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            ""
                          )}
                          Images
                        </Button>
                      </th>

                      <th className="text-right">In Date</th>
                      <th className="text-right">Cost</th>
                      <th>More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryList
                      ? inventoryList.map((p, k) => (
                        <tr
                          key={k}
                          className={
                            selectedFullRow.includes(p) ? "selected-row" : ""
                          }
                        >
                          <td>

                            <div className="d-flex justify-content-end">
                              <div className="d-flex flex-column">
                                <p onClick={() => OnCheckClick(p)}>
                                  {k + 1} </p>
                                <Input
                                  type="checkbox"
                                  id={`check_${p.id}`}
                                  checked={selectedFullRow.includes(p)}
                                  onClick={() => OnCheckClick(p)}
                                  onChange={OnChkChange}
                                />
                              </div>

                            </div>


                          </td>

                          <td colSpan="3">
                            <CarCard product={p}
                              comparedList={comparedList}
                              get_related={get_related}
                            />

                          </td>



                          <td
                            className={
                              p.status === 4
                                ? "text-right bg-success"
                                : "text-right bg-danger"
                            }
                          >
                            {p.veh_in_date} <hr />
                            {p.status_text}
                          </td>
                          <td className="text-right">{p.list_price}</td>
                          <td>
                            <Link to={`/admin/inventory-detail/${p.id}`}>
                              More
                            </Link>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {selectedFullRow.length > 0 ? (
              <div className="block">
                <Row>
                  <Col md="9" className="mt-2">
                    <Select
                      name="set_status"
                      options={StatusOptions}
                      value={revisedStatus}
                      isClearable
                      onChange={(e) => OnStatusSelect(e)}
                    />
                  </Col>
                  <Col md="3">
                    <Button
                      color="primary"
                      disabled={!revisedStatus}
                      onClick={onUpdateInvList}
                    >
                      Update Selected
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <CSVLink
              headers={CSV_HEADERS}
              data={inventoryCsv}
              filename={"inventory-file.csv"}
              className="btn btn-primary"
              target="_blank"
            >
              Download CSV
            </CSVLink>
          </div> */}

          <div className={pending ? "d-none" : ""}>
            {inventoryList
              ? inventoryList.map((p, k) => (
                  <Box sx={{ mt: 1.5 }} className="item-hover">
                    <Card
                      key={k}
                      sx={{
                        cursor: "pointer",
                        p: 2.5,
                        borderRadius: 4,

                        border: selectedFullRow.includes(p)
                          ? "2px solid #1d4aa7"
                          : null,
                        // mb: 5,
                      }}
                      onClick={() => OnCheckClick(p)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "column" },
                            color: "text.secondary",
                          }}
                        >
                          <Box
                            sx={{
                              mr: { sm: 2.5 },
                              mb: 0.3,
                              textAlign: "center",
                              ".crUserImage": {
                                objectFit: "cover",

                                // borderRadius: (theme) =>
                                //   theme.components.MuiCard.styleOverrides.root.borderRadius,
                                width: { sx: "100%", sm: 130 },
                                height: { sx: 180, sm: 130 },
                                borderRadius: 1,
                              },
                            }}
                          >
                            {p.get_thumb_image_url ? (
                              <img
                                src={`https://api.preautoco.com/${p.get_thumb_image_url}`}
                                alt="user"
                                className="crUserImage"
                              />
                            ) : (
                              <img src={NoImage} alt="No Image" />
                            )}
                          </Box>
                          <Box
                            component="h3"
                            sx={{
                              mr: 2.5,
                              mt: 1,
                              mb: 0,
                              fontSize: 16,
                              textAlign: "center",
                              // fontWeight: Fonts.BOLD,
                            }}
                          >
                            {/* {user.name} */}
                            {p.total_images === 0 ? (
                              <span style={{ color: "red" }}>
                                <i className="nc-icon nc-image" /> No Images
                                Found
                              </span>
                            ) : (
                              <span style={{ color: "green" }}>
                                <i className="nc-icon nc-image" />{" "}
                                {p.total_images} Images
                              </span>
                            )}
                          </Box>
                          <Box
                            sx={{
                              mt: 0.5,
                              // backgroundColor: "#75b9ff",
                              color: "#484848",
                              mr: { sm: 2.5 },
                              fontSize: 20,
                              textAlign: "center",
                              // fontWeight: Fonts.MEDIUM,
                            }}
                          >{`$${p.list_price}`}</Box>
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              // mb: 1.8,
                              display: "flex",
                              flexDirection: { xs: "column", sm: "row" },
                              color: "text.secondary",
                            }}
                          >
                            <Box
                              component="h3"
                              sx={{
                                mb: 0,
                                fontSize: 16,
                                color: "#292b2f",
                                // fontWeight: Fonts.BOLD,
                              }}
                            >
                              {p.year} {p.make} {p.model} {p.color}
                              {/* <span className="badge badge-primary"> */}
                              {/* <Tooltip title="View on website" arrow> */}
                              <a
                                href={`${get_related?.[0]?.website}vehicle/${p.id}/${p.slug}`}
                                target="_blank"
                                id={`tool-tip-for-${p.id}`}
                              >
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-hand-index-fill"
                                color="#ff0000" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                                <path d="M8.5 4.466V1.75a1.75 1.75 0 0 0-3.5 0v5.34l-1.199.24a1.5 1.5 0 0 0-1.197 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.272-2.715a2 2 0 0 0-1.99-2.199h-.582a5.184 5.184 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.634 2.634 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002z" />
                              </svg> */}
                                {/* <LinkIcon /> */}
                                <BsLink45Deg size={20} color={"#1d4aa7"} />
                              </a>
                              <ToolTipComp product={p} />
                            </Box>

                            <Box
                              sx={{
                                ml: { xs: -4, sm: "auto" },
                                mr: { sm: -4 },
                                display: "flex",
                                alignItems: "center",
                                justifyContent: {
                                  xs: "space-between",
                                  sm: "flex-end",
                                },
                                color: "text.secondary",
                              }}
                            >
                              {/* <Box sx={{ mx: 4 }} component="span">
                              <Tooltip
                                sx={{
                                  cursor: 'pointer',
                                }}
                                title={"share"}
                              >
                                <ShareIcon />
                              </Tooltip>
                            </Box>

                            <Box sx={{ mx: 4 }} component="span">
                              <Tooltip
                                sc={{
                                  cursor: 'pointer',
                                }}
                                title={"Bookmark"}
                              >
                                <BookmarkBorderIcon />
                              </Tooltip>
                            </Box> */}

                              <Box sx={{ mx: 4 }} component="span">
                                {p.veh_in_date}
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                              alignItems: { md: "center" },
                              gap: 1.2,
                              mt: 1.9,
                              rowGap: 2.5,
                            }}
                          >
                            <Box
                              sx={{
                                pr: 2,
                                // mt: 1.3,
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  px: 3,
                                  py: 1,
                                  color: "#000000",
                                  borderRadius: "30px",
                                  fontSize: 15,
                                  // fontWeight: Fonts.SEMI_BOLD,
                                  bgcolor: "#7df1c27a",
                                }}
                              >
                                {p.vin}
                              </Box>
                            </Box>
                            {comparedList &&
                            comparedList.length &&
                            !comparedList.find(
                              (f1) => f1.vin === p.vin
                            ) ? null : (
                              <Box
                                sx={{
                                  pr: 2,
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    px: 3,
                                    py: 1,
                                    color: "#000000",
                                    borderRadius: "30px",
                                    fontSize: 15,
                                    // fontWeight: Fonts.SEMI_BOLD,
                                    bgcolor: "#51cbce",
                                  }}
                                >
                                  {/* {!p.vin ? "Not Info" : ""}
                                  {!comparedList ? "Not Info" : ""} */}

                                  <ShowCompared
                                    vin={p.vin}
                                    comparedList={comparedList}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box sx={{ mt: 1.5, color: "#6b7290" }}>
                            {p.engine ? p.engine : " "}{" "}
                            {p.engine && p.mfd_color_code ? "|" : ""}{" "}
                            {p.mfd_color_code ? p.mfd_color_code : " "}
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                              alignItems: "end",
                            }}
                          >
                            <Box sx={{ mx: { xs: -1, xl: -2 } }}>
                              <Chip
                                label={`Seat: ${
                                  p.pass_capacity ? p.pass_capacity : ""
                                }`}
                                icon={<MdGroups size={20} />}
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.type === "dark"
                                      ? theme.palette.grey[700]
                                      : theme.palette.grey[200],
                                  padding: "4px 12px",
                                  marginTop: 1.5,
                                  marginRight: { xs: 1, xl: 2 },
                                  marginLeft: { xs: 1, xl: 2 },
                                  border: "1px solid",
                                  borderColor: grey[500],
                                  borderRadius: 2,
                                }}
                              />
                              <Chip
                                label={`Kms: ${
                                  p.kilometers ? p.kilometers : ""
                                }`}
                                icon={<BsSpeedometer2 size={20} />}
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.type === "dark"
                                      ? theme.palette.grey[700]
                                      : theme.palette.grey[200],
                                  padding: "4px 12px",
                                  marginTop: 1.5,
                                  marginRight: { xs: 1, xl: 2 },
                                  marginLeft: { xs: 1, xl: 2 },
                                  border: "1px solid",
                                  borderColor: grey[500],
                                  borderRadius: 2,
                                }}
                              />
                              {/* {p.blackbook_category ? ( */}
                              <Chip
                                label={`Threshold: ${
                                  p.blackbook_category
                                    ? p.blackbook_category
                                    : ""
                                }`}
                                icon={<SiMdbook size={20} color={"#ffffff"} />}
                                sx={{
                                  backgroundColor: (theme) =>
                                    p.blackbook_category ===
                                    "above_rough_threshold"
                                      ? "#000000"
                                      : p.blackbook_category ===
                                        "rough_km_threshold"
                                      ? "#FF5733"
                                      : p.blackbook_category ===
                                        "avg_km_threshold"
                                      ? "#FCA400"
                                      : p.blackbook_category ===
                                        "clean_km_threshold"
                                      ? "#008FFC"
                                      : p.blackbook_category ===
                                        "xclean_km_threshold"
                                      ? "#0DA200"
                                      : grey[500],
                                  padding: "4px 12px",
                                  marginTop: 1.5,
                                  marginRight: { xs: 1, xl: 2 },
                                  marginLeft: { xs: 1, xl: 2 },
                                  border: "1px solid",
                                  borderColor: grey[500],
                                  borderRadius: 2,
                                  color: "#FFFF",
                                }}
                              />
                              {/* ) : null} */}
                              {/* {p.location != undefined ? ( */}
                              <Chip
                                label={`Location: ${
                                  get_related && get_related.length
                                    ? get_related.find(
                                        (lo) => lo.id === p.location
                                      )
                                      ? get_related.find(
                                          (lo) => lo.id === p.location
                                        ).name
                                      : ""
                                    : ""
                                }`}
                                icon={<MdLocationPin size={20} />}
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.type === "dark"
                                      ? theme.palette.grey[700]
                                      : theme.palette.grey[200],
                                  padding: "4px 12px",
                                  marginTop: 1.5,
                                  marginRight: { xs: 1, xl: 2 },
                                  marginLeft: { xs: 1, xl: 2 },
                                  border: "1px solid",
                                  borderColor: grey[500],
                                  borderRadius: 2,
                                }}
                              />
                              {/* ) : null} */}
                              {/* {user.skills.map((skill, index) => {
                            return (
                              <Chip
                                key={index}
                                label={skill}
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.type === 'dark'
                                      ? theme.palette.grey[700]
                                      : theme.palette.grey[200],
                                  padding: '4px 12px',
                                  marginTop: 2,
                                  marginRight: { xs: 1, xl: 2 },
                                  marginLeft: { xs: 1, xl: 2 },
                                  border: '1px solid',
                                  borderColor: grey[500],
                                  borderRadius: 2,
                                }}
                              />
                            );
                          })} */}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 1.4,
                                ml: { md: "auto" },
                                textAlign: "end",
                              }}
                            >
                              <Box
                                // variant="contained"
                                // color="primary"
                                sx={{
                                  marginTop: "16px",
                                  padding: "9px 12px",
                                  lineHeight: "1",
                                  width: "150px",
                                  color: "#fff",
                                  borderRadius: "4px",
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  // marginRight: p.status_text == "Under Process" ? 2 : 0,
                                  backgroundColor:
                                    p.status_text === "Under Process"
                                      ? "#ef8157"
                                      : "#6bd098",
                                  marginTop: 2,
                                  padding: "9px 12px",
                                  lineHeight: 1,
                                  width:
                                    p.status_text === "Under Process"
                                      ? 150
                                      : 96,
                                  // fontWeight: Fonts.MEDIUM,
                                  "&:hover": {
                                    backgroundColor:
                                      p.status_text === "Under Process"
                                        ? "#ef8157"
                                        : "#6bd098",
                                  },
                                }}
                              >
                                {p.status_text}
                              </Box>
                              {/* <Button
                              variant="contained"
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.primary.contrastText,
                                color: grey[500],
                                marginTop: 2,
                                border: "1px solid",
                                borderColor: grey[500],
                                width: 96,
                                // fontWeight: Fonts.MEDIUM,
                                padding: "9px 12px",
                                lineHeight: 1,
                              }}
                            >
                              <Link to={`/admin/inventory-detail/${p.id}`}>
                                More
                                </Link>
                              </Button> */}
                              <Link
                                to={`/admin/inventory-detail/${p.id}`}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <MUIButton
                                  color="preAuto"
                                  type="submit"
                                  variant="outlined"
                                  sx={{
                                    marginTop: 2,
                                    height: 32,
                                  }}
                                >
                                  More
                                </MUIButton>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                ))
              : null}
          </div>
        </div>
      ) : (
        <ListOfInventorySelected
          selectedFullRow={selectedFullRow}
          setShowSelectedRow={setShowSelectedRow}
          revisedStatus={revisedStatus}
          setSelectedFullRow={setSelectedFullRow}
          MoveToNewStatus={MoveToNewStatus}
          delistModal={delistModal}
          setDelistModal={setDelistModal}
        />
      )}
    </>
  );
};

export default InventoryList;

function ListOfInventorySelected({
  selectedFullRow,
  setShowSelectedRow,
  setSelectedFullRow,
  revisedStatus,
  MoveToNewStatus,
  delistModal,
  setDelistModal,
}) {
  const RemoveFromList = (e) => {
    setSelectedFullRow(selectedFullRow.filter((row) => row !== e));
  };

  // const toggleDelist = () => setDelistModal(!delistModal);
  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardBody>
            <CardTitle className="text-center" tag="h3"></CardTitle>
            <div className="d-flex justify-content-between">
              <CardTitle tag="h4">
                Confirm Updation to{" "}
                <span
                  className={
                    revisedStatus?.warn === true
                      ? "bg-danger show-updated-text text-white"
                      : "bg-dark text-white"
                  }
                >
                  {revisedStatus ? revisedStatus.label : ""}
                </span>
              </CardTitle>

              <Button color="link" onClick={() => setShowSelectedRow(false)}>
                <i className="fa fa-times fa-2x" aria-hidden="true"></i>
              </Button>
            </div>
            <Row>
              <Col md="12">
                <Table responsive>
                  <thead className="table-active">
                    <tr>
                      <th>No</th>
                      <th>Year / Make / Model</th>
                      <th>VIN</th>
                      <th>Color</th>
                      <th className="text-right">Kilometers</th>
                      <th className="text-right">Status</th>
                      <th className="text-right">Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedFullRow
                      ? selectedFullRow.map((p, k) => (
                          <tr key={k}>
                            <td>{k + 1}</td>
                            <td>
                              {p.year} {p.make} {p.model}
                            </td>
                            <td>{p.vin}</td>
                            <td>{p.color}</td>

                            <td className="text-right">{p.kilometers}</td>
                            <td>{p.status_text}</td>
                            <td className="text-right">{p.list_price}</td>
                            <td>
                              <Button
                                color="link"
                                onClick={() => RemoveFromList(p)}
                              >
                                <i
                                  className="fa fa-times fa-1x"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <CardFooter>
              <div className="d-flex justify-content-end">
                <div className="p-2"></div>
                <div className="p-2">
                  {revisedStatus?.warn === true ? (
                    <Button
                      id="BaseDelist"
                      className="btn"
                      color="warning"
                      size="sm"
                      onClick={() => setDelistModal(!delistModal)}
                    >
                      Remove from Website
                    </Button>
                  ) : (
                    <Button
                      className="btn"
                      color="danger"
                      onClick={MoveToNewStatus}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </div>
            </CardFooter>
          </CardBody>
        </Card>

        <Modal isOpen={delistModal} toggle={() => setDelistModal(!delistModal)}>
          <ModalHeader toggle={() => setDelistModal(!delistModal)}>
            Confirm Delist
          </ModalHeader>
          <ModalBody>
            This action is not reversible! removes all associated images and
            retains some basic information in the backend. Use it when you have
            to remove the inventory from website.
          </ModalBody>
          <ModalFooter className="pr-2">
            <Button color="warning" onClick={MoveToNewStatus}>
              De List
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

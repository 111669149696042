import React from "react";
import { Row, Col, Tooltip } from "reactstrap";

import Coins from "../../crema/Coins";
import { Grid } from "@mui/material";
import AppGridContainer from "../../crema/AppGridContainer";
import StatsItemCard from "../../modules/StatsItemCard";

const CardStats = ({ totals, days, get_lead_rotation }) => {
  const [lefttooltipOpen, setLeftTooltipOpen] = React.useState(false);
  const [righttooltipOpen, setRightTooltipOpen] = React.useState(false);
  const toggleLeft = () => setLeftTooltipOpen(!lefttooltipOpen);
  const toggleRight = () => setRightTooltipOpen(!righttooltipOpen);

  // console.log(totals)
  return (
    <>
      {/* <Row m={50}> */}
      <AppGridContainer>
      {/* <Grid item lg={12} xs={12} md={12} mb={3}> */}
      <Grid item lg={3} md={4} xs={12} sm={6} key={1} mb={3}>
        <StatsItemCard
          stats={{
            id: 1,
            title: `Total Webvisits in Last ${days} days`,
            count: totals ? totals.total_website_visits : "",
            // new: "New 10",
            badgeColor: "#0A8FDC",
            // bgcolor: "#E7F4FB",
            icon: <i className="mdi mdi-web float-right"></i>,
          }}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12} sm={6} key={2} mb={3}>
        <StatsItemCard
          stats={{
            id: 2,
            title: `Total Sales in Last ${days} days`,
            count:
              totals && totals.total_sales_value
                ? `$${totals.total_sales_value}`
                : "$0",
            new: "New 10",
            badgeColor: "#9E49E6",
            bgcolor: "#F5EDFC",
            icon: (
              <i className="mdi mdi-wallet-travel float-right"></i>
            ),
          }}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12} sm={6} key={3} mb={3}>
        <StatsItemCard
          stats={{
            id: 3,
            title: `Total Customers in Last ${days} days`,
            count: totals ? totals.no_of_customers : "",
            new: "New 10",
            badgeColor: "#F49820",
            bgcolor: "#FFECDC",
            icon: (
              <i className="mdi mdi-account-multiple float-right"></i>
            ),
          }}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12} sm={6} key={4} mb={3}>
        <StatsItemCard
          stats={{
            id: 4,
            title: `Total Leads in Last ${days} days`,
            count: totals ? totals.no_of_leads : "",
            new: "New 10",
            badgeColor: "#F44D50",
            bgcolor: "#F04F47",
            icon: <i className="mdi mdi-chart-bar float-right"></i>,
          }}
        />
      </Grid>

      {/* <Coins
            coinsData={[
              {
                value: totals ? totals.total_website_visits : "",
                footer: `Total Sales in Last ${days} days`,
                title: "Webvisits",
                icon: <i className="mdi mdi-web mdi-24px float-right"></i>,
                bgColor: "#9E49E6",
              },
              {
                value:
                  totals && totals.total_sales_value
                    ? `$${totals.total_sales_value}`
                    : "$0",
                footer: `Total Sales in Last ${days} days`,
                title: "Total Sales",
                icon: (
                  <i className="mdi mdi-wallet-travel mdi-24px float-right"></i>
                ),
                bgColor: "#0A8FDC",
              },
              {
                value: totals ? totals.no_of_customers : "",
                footer: `Total Customers in Last ${days} days`,
                title: "Customers",
                icon: (
                  <i className="mdi mdi-account-multiple mdi-24px float-right"></i>
                ),
                bgColor: "#FFA940",
              },
              {
                value: totals ? totals.no_of_leads : "",
                footer: (
                  <>
                    <Row>
                      <Col id="lastlead">
                        <i className="fa fa-angle-left" />
                        {get_lead_rotation?.previous_lead}
                      </Col>
                      <Tooltip
                        placement="left"
                        isOpen={lefttooltipOpen}
                        target="lastlead"
                        toggle={toggleLeft}
                      >
                        Previous Lead
                      </Tooltip>

                      <Col id="nextlead">
                        <i className="fa fa-angle-right" />
                        {get_lead_rotation?.next_lead}
                      </Col>
                      <Tooltip
                        placement="bottom"
                        isOpen={righttooltipOpen}
                        target="nextlead"
                        toggle={toggleRight}
                      >
                        Next Lead
                      </Tooltip>
                    </Row>
                  </>
                ),
                title: "Leads",
                icon: (
                  <i className="mdi mdi-chart-bar mdi-24px float-right"></i>
                ),
                bgColor: "#F44D50",
              },
            ]}
          /> */}
      </AppGridContainer>
      {/* <Col lg="3" md="6" sm="6">
              <Card className="card-stats bg-gradient-success">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Sales</p>
                        <CardTitle tag="p">$ 
                        {totals? totals.total_sales_value: ''}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                     <i className="fa fa-refresh" />
                    {`Total Sales in Last ${days} days`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats bg-gradient-danger">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-vector text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Customers</p>
                        <CardTitle tag="p">{totals? totals.no_of_customers: ''}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-clock-o" />
                    {`Last ${days} days`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-clock-o text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Leads</p>
                        <CardTitle tag="p">{totals? totals.no_of_leads: ''}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <Row>
                      <Col id="lastlead">
                      <i className="fa fa-angle-left" />
                       {get_lead_rotation?.previous_lead}
                       </Col>
                       <Tooltip 
                       placement="left" 
                       isOpen={lefttooltipOpen} 
                       target="lastlead" 
                       toggle={toggleLeft}>
                        Previous Lead  
                      </Tooltip>
                      
                      <Col id="nextlead">
                      <i className="fa fa-angle-right" />
                      {get_lead_rotation?.next_lead}
                      </Col>
                      <Tooltip 
                       placement="bottom" 
                       isOpen={righttooltipOpen} 
                       target="nextlead" 
                       toggle={toggleRight}>
                    Next Lead
                  </Tooltip>
                    </Row>
                  </div>
                 
                </CardFooter>
              </Card>
            </Col> */}
      {/* </Row> */}
    </>
  );
};

export default CardStats;

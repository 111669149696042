import {
    GET_COMPANIES_PENDING, LOAD_COMPANY,
    GET_COMPANIES, DELETE_COMPANY, REMOVE_COMPANY,
    ADD_COMPANY, CLEAR_COMPANY, FETCH_COMPANIES_PENDING,
    FETCH_COMPANY, SG_FETCH_COMPANY, EDIT_COMPANY,
    ADD_COMPANY_LOGO, UPLOAD_COMPANY_LOGO, CLEAR_ALL,
} from "constants/actions";

const initialState = {
    companies: [],
    pending: false,
    pending_company: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMPANIES_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_COMPANIES:
            return {
                ...state,
                pending: false,
                companies: action.payload
            };
        case FETCH_COMPANIES_PENDING:
            return {
                ...state,
                pending_company: true
            };



        case FETCH_COMPANY:
            return { ...state, pending_company: false, [action.payload.id]: action.payload };

        case EDIT_COMPANY:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_COMPANY:
            return {
                ...state,
                companies: state.companies.filter(cmp => cmp.id !== action.payload)
            };
        // case ADD_COMPANY:
        //     return {
        //         ...state,
        //         companies: [...state.companies, action.payload]
        //     };
        case ADD_COMPANY:
            return {
                ...state,
                created: action.payload
            };
        case CLEAR_COMPANY:
            return {
                ...state,
                companies: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                companies: []
            };
        default:
            return state;
    }
}
    
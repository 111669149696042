import {call, put, all, takeLatest } from 'redux-saga/effects'
import { LOAD_CUSTOMER_APPOINTMENTS,
    GET_CUSTOMER_APPOINTMENTS_PENDING,
    GET_CUSTOMER_APPOINTMENTS,
    DELETE_CUSTOMER_APPOINTMENT,
    SG_DELETE_CUSTOMER_APPOINTMENT,
    ADD_CUSTOMER_APPOINTMENT,
    SG_ADD_CUSTOMER_APPOINTMENT,
    FETCH_CUSTOMER_APPOINTMENT,
    SG_FETCH_CUSTOMER_APPOINTMENT,
    UPDATE_CUSTOMER_APPOINTMENT,
    EDIT_CUSTOMER_APPOINTMENT,
    CLEAR_CUSTOMER_APPOINTMENT,
   ALL_ERRORS} from "constants/actions";

import * as api from '../constants/api';


function* loadAppointment(action) {
    yield put({ type: GET_CUSTOMER_APPOINTMENTS_PENDING });

    try {
      const json = yield call(api.GET_CUSTOMER_APPOINTMENTS, action.payload)
      
    yield put({ type: GET_CUSTOMER_APPOINTMENTS, payload: json.data });

    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
}

export function* appointmentLoad() {
   yield takeLatest(LOAD_CUSTOMER_APPOINTMENTS, 
    loadAppointment);
}


function* fetchApplication(action) {
    try {
      const json = yield call(api.FETCH_CUSTOMER_APPOINTMENT, action.payload)
   
      yield put({ type: FETCH_CUSTOMER_APPOINTMENT, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* appointmentFetchOne() {
    yield takeLatest(SG_FETCH_CUSTOMER_APPOINTMENT, 
        fetchApplication);
  }

  
export default function* index() {
    yield all([appointmentLoad(), 
        appointmentFetchOne()]);
  }
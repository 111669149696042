import {call, put, all, takeLatest } from 'redux-saga/effects'
import { LOAD_CREDITAPPLICATIONS, GET_CREDITAPPLICATIONS_PENDING,
GET_CREDITAPPLICATIONS,
DELETE_CREDITAPPLICATION,
ADD_CREDITAPPLICATION,
FETCH_CREDITAPPLICATION_PENDING,
FETCH_CREDITAPPLICATION,
SG_FETCH_CREDITAPPLICATION,
UPDATE_CREDITAPPLICATION,
EDIT_CREDITAPPLICATION,
CLEAR_CREDITAPPLICATION, ALL_ERRORS} from "constants/actions";

import * as api from '../constants/api';


function* loadApplication(action) {
    yield put({ type: GET_CREDITAPPLICATIONS_PENDING });

    try {
      const json = yield call(api.GET_CREDIT_APPLICATIONS, action.payload)
      
    yield put({ type: GET_CREDITAPPLICATIONS, payload: json.data });

    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
}

export function* applicationLoad() {
   yield takeLatest(LOAD_CREDITAPPLICATIONS, loadApplication);
}


function* fetchApplication(action) {
    yield put({ type: FETCH_CREDITAPPLICATION_PENDING });
    try {
      const json = yield call(api.FETCH_CREDIT_APPLICATION, action.payload)
   
      yield put({ type: FETCH_CREDITAPPLICATION, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* applicationFetchOne() {
    yield takeLatest(SG_FETCH_CREDITAPPLICATION, fetchApplication);
  }

  
export default function* index() {
    yield all([applicationLoad(), 
      applicationFetchOne()]);
  }
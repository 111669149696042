import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./store";

// import * as Sentry from '@sentry/browser';
import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Sentry.init({dsn: "https://d9a4e10f84b543d4ad7495a64c57df0e@o402315.ingest.sentry.io/5263294"});
// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<Provider store={store}>
//   <App/>
// </Provider>);
ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
    </LocalizationProvider>
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.register()
serviceWorker.register();

import {
  GET_TASK_LISTS_PENDING,
  GET_TASK_LISTS,
  LOAD_TASK_LISTS,
  FETCH_TASK_LISTS_PENDING,
  FETCH_TASK_LISTS,
  SG_FETCH_TASK_LISTS,
  DELETE_TASK_LIST,
  ADD_TASK_LIST,
  EDIT_TASK_LISTS,
  UPDATE_TASK_LISTS,
  CLEAR_TASK_LISTS,
  CLEAR_ALL,
  ALL_ERRORS
} from "constants/actions";

const initialState = {
  tasklists: [],
  created: [],
  loading: false,
  pending: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TASK_LISTS_PENDING:
      return {
        ...state,
        pending: true,
        loading: true
      };

    case GET_TASK_LISTS:
      return {
        ...state,
        tasklists: action.payload,
        loading: false,
        pending: false
      };

    case FETCH_TASK_LISTS:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loading: false
      };

    case EDIT_TASK_LISTS:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loading: false
      };

    case DELETE_TASK_LIST:
      return {
        ...state,
        tasklists: state.tasklists.filter(Trade => Trade.id !== action.payload)
      };

    case ADD_TASK_LIST:
      return {
        ...state,
        created: action.payload,
        loading: false
      };

    case CLEAR_ALL:
      return {
        ...state,
        tasklists: []
      };

    default:
      return state;
  }
}

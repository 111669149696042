const config = {
    googleKey: 'AIzaSyBeFwFPIB8G91Ye3f-p3t_zmz8MjvidgcI',
    loginproduction: 'https://api.datastruk.com/api/customer',
    loginlocal: 'http://127.0.0.1:8000/api/customer',
    loginapi: 'https://api.datastruk.com/api/customer',
    server_url:'https://api.datastruk.com/',

    production:'https://api.datastruk.com/api/web',
    local: 'http://127.0.0.1:8000/api/web',
    serverapi: `https://api.datastruk.com/api/web`,



    // bryden auto credentials
    apikey: null,
    portalkey: null,
    company: null,
    website: null,
    short_co: null,
    address: null,
    city: null,
    province: null,
    pincode: null,
    phone: null,
    fax: null,
    tollfree: null,
    emailid: null,
    terms_updated: null,
    privacy_updated: null,
    map_zoom: 16,
    lng: -63.670457,
    lat: 44.760241,
    web_lead_stage:6,
    web_lead_source:2,
    web_lead_type_credit_app:4,
    web_lead_source_interested:2,
    web_lead_type_trade:2,
    logo_url: null,
    // change this logo image
    logo: 'assets/images/logo/company-logo.png'

    // Macphee Ford Credentials
    // apikey: '043236ab-e637-4344-9526-c0ef55fd6053',
    // portalkey: 'bd910be0-feb5-4a53-b3cc-a458ed2fec97',
};
export default config
import React from "react";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// reactstrap components
import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Form,
  Spinner,
  Fade,
  // Table,
  Row,
  Col,
} from "reactstrap";

import { LOAD_VIN_UPLOAD } from "constants/actions";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
  Box,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";

const BlackBookResp = ({ blackbook_resp }) => {
  return (
    <>
      {blackbook_resp ? (
        <>
          <p>
            Results{" "}
            {blackbook_resp.used_vehicles?.used_vehicle_list
              ? blackbook_resp.used_vehicles.used_vehicle_list.length
              : "None"}
            {/* Blackbook found {blackbook_resp.used_vehicles.used_vehicle_list.length}{" "} */}
          </p>
          <p>
            <small>Selected trim is shown below, to change click resolve</small>
          </p>
        </>
      ) : (
        <p>
          no Blackbook info <br /> click resolve
        </p>
      )}
    </>
  );
};

const VinDecodeFull = (props) => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState();
  const [vinupload, setVinupload] = React.useState([]);
  const [vinuploadList, setVinuploadList] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({
      type: LOAD_VIN_UPLOAD,
      payload: { params: `inventory_id=${props.inventoryId}` },
    });
  }, [dispatch]);

  const { get_vinupload } = useSelector(
    (state) => ({
      get_vinupload: state.vinupload.vinuploads.results,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_vinupload) {
      setVinuploadList(get_vinupload);
    }
  }, [get_vinupload]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>No</StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        VIN
                        <br />
                        Year Make Model <br />
                      </StyledTableCell>

                      <StyledTableCell align="center">Trim</StyledTableCell>
                      <StyledTableCell align="center">Color</StyledTableCell>
                      <StyledTableCell align="center">More</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vinuploadList.map((p, k) => (
                      <StyledTableRow key={k}>
                        <StyledTableCell align="left">{k + 1}</StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {p.year} {p.make} {p.model} <hr />
                          {p.vin} <hr />
                          {p.notes}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {p.revised_trim ? p.revised_trim : "Trim not revised"}{" "}
                          <hr />
                          {p.revised_engine
                            ? p.revised_engine
                            : "Engine not revised"}{" "}
                          <hr />
                          <BlackBookResp blackbook_resp={p.blackbook_resp} />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {p.color}
                          <hr />
                          Ext:{" "}
                          {p.revised_ext_color
                            ? p.revised_ext_color
                            : " Not revised"}
                          <hr />
                          Int:{" "}
                          {p.revised_interior_color
                            ? p.revised_interior_color
                            : "Not revised"}
                        </StyledTableCell>

                        <StyledTableCell StyledTableCell align="center">
                          <Link
                            to={`/admin/full-vin-decode-edit/${p.id}/${props.inventoryId}?return-url=inventory-detail/${props.inventoryId}`}
                          >
                            <MUIButton
                              variant="contained"
                              style={{
                                backgroundColor: "blueviolet",
                                color: "#ffffff",
                                padding: "5px 5px",
                              }}
                            >
                              Resolve
                            </MUIButton>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
            </Paper>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VinDecodeFull;

import { put, call, all, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_LEADTYPES_PENDING,
  GET_LEADTYPES,
  LOAD_LEADTYPES,
  GET_DEFAULT_LEADTYPES,
  LOAD_DEFAULT_LEADTYPES,
  FETCH_LEADTYPES,
  FETCH_LEADTYPES_PENDING,
  SG_FETCH_LEADTYPES,
  DELETE_LEADTYPE,
  ADD_LEADTYPE,
  EDIT_LEADTYPES,
  UPDATE_LEADTYPES,
  SG_ADD_LEADTYPE,
  CLEAR_LEADTYPES,
  CLEAR_ALL,
  ALL_ERRORS,
  VALIDATION_ERRORS,
} from "constants/actions";

import * as api from "../constants/api";

function* loadLeadType(action) {
  yield put({ type: GET_LEADTYPES_PENDING });
  try {
    const json = yield call(api.GET_LEAD_TYPES, action.payload);

    yield put({ type: GET_LEADTYPES, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadTypeLoad() {
  yield takeLatest(LOAD_LEADTYPES, loadLeadType);
}

function* loadDefaultLeadType() {
  try {
    const json = yield call(api.GET_LEAD_TYPES, "company=1");

    yield put({ type: GET_DEFAULT_LEADTYPES, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadTypeLoadDefault() {
  yield takeLatest(LOAD_DEFAULT_LEADTYPES, loadDefaultLeadType);
}

function* fetchLeadType(action) {

  yield put({ type: FETCH_LEADTYPES_PENDING });
  try {
    const json = yield call(api.FETCH_LEAD_TYPE, action.payload);
    console.log("Featch type", json);
    yield put({ type: FETCH_LEADTYPES, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadTypeFetchOne() {
  yield takeLatest(SG_FETCH_LEADTYPES, fetchLeadType);
}

function* editLeadType(action) {
  yield put({ type: FETCH_LEADTYPES_PENDING });
  try {
    const json = yield call(
      api.EDIT_LEAD_TYPE,
      action.payload.id,
      action.payload
    );
    yield put({ type: EDIT_LEADTYPES, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadTypeEdit() {
  yield takeLatest(UPDATE_LEADTYPES, editLeadType);
}
// Add New
function* addLeadType(action) {
  try {
    const json = yield call(api.ADD_LEAD_TYPE, action.payload);
    yield put({ type: ADD_LEADTYPE, payload: json.data });
  } catch (e) {
    yield put({ type: VALIDATION_ERRORS, error: e });
  }
}

export function* leadTypeAdd() {
  yield takeEvery(SG_ADD_LEADTYPE, addLeadType);
}

export default function* index() {
  yield all([
    leadTypeLoad(),
    leadTypeLoadDefault(),
    leadTypeFetchOne(),
    leadTypeEdit(),
    leadTypeAdd(),
  ]);
}

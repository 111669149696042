import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

import Image from "./Image";

import {
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE,
  SG_SELECT_EMPLOYEE,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";
import { CREATE_LEAD } from "constants/actions";

const ActiveOptions = [
  { value: 1, label: "Active" },
  { value: 2, label: "Paused" },
];

const RoleOptions = [
  { value: 99, label: "Employee" },
  { value: 8, label: "Sales" },
  { value: 6, label: "Supervisor" },
  { value: 3, label: "Manager" },
  { value: 1, label: "Admin" },
];

const EmployeeDetail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [employee, setEmployee] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_SELECT_EMPLOYEE, payload: id });
    }
  }, [dispatch]);

  const { get_employee_selected, get_auth, get_errors } = useSelector(
    (state) => ({
      get_employee_selected: state.employees.selected,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if ([parseInt(id)] > 0) {
      setEmployee(get_employee_selected[parseInt(id)]);
    }
  }, [get_employee_selected, id]);

  return (
    <>
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h4">Employee Details</CardTitle>
                    </Col>

                    <Col>
                      <Button
                        color="info"
                        to={`/admin/employee-create/${id}`}
                        tag={Link}
                        outline
                        size="sm"
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <img src={employee ? employee.picture : ""} alt="..." />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <label>First Name </label>
                          <p>{employee ? employee.first_name : ""}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Last Name </label>
                          <p>{employee ? employee.last_name : ""}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Phone</label>
                          <p>{employee ? employee.phone : ""}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Email</label>
                          <p>{employee ? employee.email : ""}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Role</label>
                          <p>{employee ? employee.role_text : ""}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Active Status</label>
                          <p>{employee ? employee.status_text : ""}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label>About me / Notes</label>
                      <p>{employee ? employee.description : ""}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Image />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default EmployeeDetail;

import React from "react";
import PropsTypes from "prop-types";
import Box from "@mui/material/Box";

const SidebarBgWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundColor: "rgba(0,0,0,0.7)",
        // backgroundImage: "url(/assets/images/sidebar/sidebar-bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        // backgroundSize: "cover",
        color: "rgba(0,0,0,0.7)",
        // boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.04)',
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        },
        "& > *": {
          position: "relative",
          zIndex: 3,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SidebarBgWrapper;

SidebarBgWrapper.propTypes = {
  children: PropsTypes.node,
  allowSidebarBgImage: PropsTypes.bool,
  themeMode: PropsTypes.string,
  sidebarBgColor: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
  sidebarBgImageId: PropsTypes.oneOfType([
    PropsTypes.string,
    PropsTypes.number,
  ]),
};

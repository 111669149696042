import React from 'react';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    UncontrolledAlert,
  } from "reactstrap";

import PendingTasks from 'TaskLists/List';
import PendingLeads from 'Leads/List';
import SalesLeads from './SalesLeads';
import SalesFollowUps from './SalesFollowUps';
import SalesApplications from './SalesApplications';
import SalesWon from './SalesWon';
import TotalSalesVolume from "./Reports/TotalSalesVolume"


// https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f
const SalesDashboard = (props) => {
  // console.log(props.user.id)

    return (
        <>
              


            {/* <Row>
                <Col md="3">
                    <SalesLeads />
                </Col>
                <Col md="3">
                    <SalesFollowUps />
                </Col>
                <Col md="3">
                    <SalesApplications />
                </Col>
                <Col md="3">
                     <SalesWon /> 
                </Col>
            </Row> */}

            <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Pending Tasks</CardTitle>
              <h5 className="card-category">Tasks which i have to complete</h5>
            </CardHeader>
            <CardBody>
            {/* due-today=True */}
            <PendingTasks params={"open-past-due-date=True"} />

            {/* <PendingTasks params={"due-today=True"} /> */}
            </CardBody>
          </Card>
          </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">New Leads</CardTitle>
              <h5 className="card-category">My New Leads</h5>
            </CardHeader>
            <CardBody>
            
            <PendingLeads params={"&new-leads=true"} />
       
            </CardBody>
          </Card>
          </Col>
      </Row>
      {/* <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Based on Purchase Date</CardTitle>
              <h5 className="card-category">Customers who are expected to make a purchase in next 7 days</h5>
            </CardHeader>
            <CardBody>
            
                  <PendingLeads params={`&purchase-date=2020-06-01&employee=14`} /> 
                  
            </CardBody>
          </Card>
          </Col>
      </Row> */}

           
        </>
    );
};

export default SalesDashboard;
import React from "react";

// import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_ADMIN_TRADE_INS, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { Button as MUIButton, TextField } from "@mui/material";
import TablePaginationActions from "../mui/tables/TablePaginationActions";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";
// reactstrap components
import { Button, Form, Input, Row, Col } from "reactstrap";

const List = (props) => {
  const dispatch = useDispatch();

  const [tradeins, setTradeins] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);

  // pagination end
  const {
    register,
    handleSubmit,
    // watch,
    errors,
    setValue,
    control,
  } = useForm();

  React.useEffect(() => {
    dispatch({
      type: LOAD_ADMIN_TRADE_INS,
      payload: { page: pageactivePage, params: pageParams },
    });
  }, [dispatch, pageParams, pageactivePage, props]);

  const { get_trade_ins, get_auth, get_errors } = useSelector(
    (state) => ({
      get_trade_ins: state.tradein.admintradeins,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]

  React.useEffect(() => {
    setTradeins(get_trade_ins.results);
  }, [get_trade_ins]);

  // console.log("Browser", isBrowser, deviceType)

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_trade_ins) {
      setTotalResults(get_trade_ins.count);
      if (get_trade_ins.page_size) {
        setPerPage(get_trade_ins.page_size);
      }
      setTotalPages(Math.ceil(get_trade_ins.count / perPage));
    }
  }, [get_trade_ins]);

  const [page, setPage] = React.useState(0);

  // console.log(user)
  const handleClick = (event, newPage) => {
    dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: newPage + 1 });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };
  const ShowDetails = (id) => {
    props.setColWidth([0, 12]);
    props.setShowTradeIn(id);
  };
  const onSubmit = (data) => {
    // let yearParams = [];
    // console.log(data)
    setPageParams(`search=${data.value}`);
    setPage(0);
  };
  // console.log(tradeins)
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="7" className="mb-1">
            <Input
              type="text"
              name="value"
              innerRef={register({
                required: "Please enter a search value",
              })}
            />
            <p className="text-danger small">
              {errors.value && <span>{errors.value.message}</span>}
            </p>
          </Col>

          <Col md="2">
            <MUIButton
              color="preAuto"
              type="submit"
              variant="outlined"
              style={{
                marginBottom: 10,
              }}
            >
              Search
            </MUIButton>
          </Col>
        </Row>
      </Form>
      {/* <Row> */}
      {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
      {/* <Col md="12">
          <div className="mobile-content">
            {tradeins ? (
              tradeins.map((p, k) => (
                <div
                  key={k}
                  className={k % 2 ? "mb-3 shaded-background" : "mb-3"}
                >
                  <div className="d-flex flex-row">
                    <div className="p-2">Vin / UVC</div>
                    <div className="p-2 flex-grow-1">
                      {p.vin} {p.black_book_uvc}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="p-2">
                      {p.year} {p.make} {p.model}
                    </div>
                    <div className="p-2 float-right">{p.kilometers} kms</div>
                  </div>
                  <div className="d-flex">
                    <div className="p-2">{p.trim}</div>
                  </div>

                  <div className="d-flex justify-content-between p-2">

                    <small>
                      {" "}
                      Updated{" "}
                      {p.updated_at
                        ? moment(p.updated_at).format("YYYY-MM-DD")
                        : ""}
                    </small>


                    <Button size="sm" onClick={() => ShowDetails(p.id)}>
                      View More
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex">
                <div className="p-2">No Trade Ins</div>
              </div>
            )}
          </div>
          <Table responsive className="desktop-content">
            <thead className="table-active">
              <tr>
                <th>Vin / UVC</th>
                <th>Year</th>
                <th>Make</th>
                <th>Model</th>
                <th>Trim</th>
                <th>Date</th>
                <th className="text-right">Kms</th>
              </tr>
            </thead>
            <tbody>
              {tradeins ? (
                tradeins.map((p, k) => (
                  <tr key={k}>
                    <td>
                      {p.vin}
                      {p.black_book_uvc}
                    </td>
                    <td>{p.year}</td>
                    <td>{p.make}</td>
                    <td>{p.model}</td>
                    <td>{p.trim}</td>
                    <td>
                      {p.updated_at
                        ? moment(p.updated_at).format("YYYY-MM-DD")
                        : ""}
                    </td>
                    <td className="text-right">{p.kilometers}</td>
                    <td>
                      <Button size="sm" onClick={() => ShowDetails(p.id)}>
                        View More
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Trade Ins</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col> */}
      {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
      {/* </Row> */}

      <>
        <div className={pending ? "d-none" : ""}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Vin / UVC</StyledTableCell>
                    <StyledTableCell align="center">YEAR</StyledTableCell>
                    <StyledTableCell align="center">MAKE</StyledTableCell>
                    <StyledTableCell align="center">MODEL</StyledTableCell>
                    <StyledTableCell align="center">TRIM</StyledTableCell>
                    <StyledTableCell align="center">DATE</StyledTableCell>
                    <StyledTableCell align="center">KMS</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tradeins ? (
                    tradeins.map((p) => (
                      <StyledTableRow key={p}>
                        <StyledTableCell align="center">
                          {" "}
                          {p.vin}
                          {p.black_book_uvc}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {p.year}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {p.make}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {p.model}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {p.trim}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {p.updated_at
                            ? moment(p.updated_at).format("YYYY-MM-DD")
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {p.kilometers}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          onClick={() => ShowDetails(p.id)}
                        >
                          <p
                            style={{
                              color: "#1d4aa7",
                              margin: 0,
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </p>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableCell align="center">
                      No Trade Ins
                    </StyledTableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults || 0}
              rowsPerPage={12}
              page={page}
              onPageChange={handleClick}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            />
          </Paper>
        </div>
      </>
    </>
  );
};

export default List;

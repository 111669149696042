import React from "react";
// import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// import { addDays, subDays } from "date-fns";

import Select from "react-select";

// import { RHFInput } from 'react-hook-form-input';
import {
  UPDATE_LEAD_STAGE,
  SG_ADD_LEAD_STAGE,
  SG_FETCH_LEAD_STAGE,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  // CardHeader,
  CardTitle,
  // CardFooter,
  Button,
  // Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";
import { Button as MUIButton } from "@mui/material";

const StatusOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const OriginOptions = [
  { value: 1, label: "Online" },
  { value: 2, label: "Offline" },
];

const CreateLeadStage = ({ props, setAddTaskOpen, setLabel }) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  let CompanyId = params.get("company-id");

  const [leadSource, setLeadSource] = React.useState([]);
  // const [startDate, setStartDate] = React.useState(new Date());
  // const [endDate, setEndDate] = React.useState((addDays, new Date()), 30);
  const [selStatus, setSelStatus] = React.useState([]);
  const [selOrigin, setSelOrigin] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log(id);
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_LEAD_STAGE, payload: id });
    }
  }, [dispatch]);

  const { get_lead_stage, get_auth, get_errors } = useSelector(
    (state) => ({
      get_lead_stage: state.leadstage,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  // [parseInt(id)]

  React.useEffect(() => {
    // console.log(_.isEmpty(loginError.errors))
    if (!_.isEmpty(get_errors.errors)) {
      // console.log(loginError)

      setVisible(true);
      setPageErrors(get_errors);
    }
  }, [get_errors]);
  React.useEffect(() => {
    if (get_lead_stage[parseInt(id)]) {
      setLeadSource(get_lead_stage[parseInt(id)]);

      const SelectedStatus = StatusOptions.filter(
        (fl) => fl.value === get_lead_stage[parseInt(id)].active
      )[0];
      setSelStatus(SelectedStatus);

      // console.log(get_lead_source[parseInt(id)]);
      // console.log(SelectedOption);

      // console.log(SelectedStatus);
    }
  }, [get_lead_stage, id]);

  // console.log(selStatus);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    if (id) {
      if (selStatus) {
        // console.log(selStatus);
        setValue("active", selStatus);
      }
    }
  }, [get_lead_stage, id, selStatus]);

  const onSubmit = (data) => {
    if ([parseInt(id)] > 0) {
      dispatch({
        type: UPDATE_LEAD_STAGE,
        payload: {
          id,
          name: data.name,
          sort_order: data.sort_order,
          active: data.active.value,
          description: data.description,
        },
      });

      setTimeout(() => {
        setToDetail(true);
      }, 2000);
    } else {
      dispatch({
        type: SG_ADD_LEAD_STAGE,
        payload: {
          company: CompanyId,
          name: data.name,
          sort_order: data.sort_order,
          active: data.active.value,
          description: data.description,
        },
      });
      setTimeout(() => {
        setToHome(true);
      }, 2000);
    }

    // <Redirect />
  };

  setLabel && setLabel(`${id ? "Edit " : "Create "}` + "Lead Stage");
  return (
    <>
      <div className="content">
        <Alert
          color="danger"
          isOpen={visible}
          toggle={setVisible}
          style={{
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          {pageerrors.errors
            ? pageerrors.errors["detail"]
            : JSON.stringify(pageerrors.errors)}

          {/* {JSON.stringify(pageerrors.errors)} */}
        </Alert>
        {toDetail ? <Redirect to={`/admin/lead-stage-detail/${id}`} /> : null}
        {toHome ? <Redirect to={`/admin/lead-stages`} /> : null}
        <ErrorBoundary>
          <Row
            style={{
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <Col className="ml-auto mr-auto" md="10">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Name </label>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={leadSource ? leadSource.name : ""}
                    innerRef={register({
                      required: "Please enter Lead Source Name",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.name && <span>{errors.name.message}</span>}
                  </p>
                </FormGroup>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Sort Order</label>
                      <Input
                        type="text"
                        name="sort_order"
                        defaultValue={leadSource ? leadSource.sort_order : ""}
                        innerRef={register}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Active</label>
                      <Controller
                        as={<Select options={StatusOptions} />}
                        name="active"
                        control={control}
                      />{" "}
                      <p className="text-danger small">
                        {errors.active && <span>{errors.active.message}</span>}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <label>Description</label>
                  <Input
                    type="textarea"
                    defaultValue={leadSource ? leadSource.description : ""}
                    name="description"
                    id="description"
                    innerRef={register}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <MUIButton color="preAuto" variant="contained" type="submit">
                    {id ? "Update " : "Save "}
                  </MUIButton>
                </div>
              </Form>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateLeadStage;

import { GET_INVENTORY_EXTRA, GET_INVENTORY_EXTRA_PENDING, FETCH_INVENTORY_EXTRA, EDIT_INVENTORY_EXTRA,
    ADD_INVENTORY_EXTRA, CLEAR_ALL, DELETE_INVENTORY_EXTRA, } from "constants/actions";

const initialState = {
    inventoryextra: [],
    loadinginventoryextra: false,
    pending: false
};

export default function (state = initialState, action) { 
    switch (action.type) {
        case GET_INVENTORY_EXTRA_PENDING:
            return {
                ...state,
                pending: true,
                loadinginventoryextra: true,
            }
        case GET_INVENTORY_EXTRA:
            return {
                ...state,
                inventoryextra: action.payload, 
                loadinginventoryextra: false,
                pending: false
            };
        case FETCH_INVENTORY_EXTRA:
            console.log(action.payload)
            return { ...state, [action.payload.inventory_id]: action.payload, loadinginventoryextra: false };

        case EDIT_INVENTORY_EXTRA:
            return { ...state, [action.payload.inventory_id]: action.payload, loadinginventoryextra: false };

        case DELETE_INVENTORY_EXTRA:
            return {
                ...state,
                inventoryextra: state.inventoryextra.filter(Inventory => Inventory.id !== action.payload)
            };
        case ADD_INVENTORY_EXTRA:
            return {
                ...state,
                inventoryextra: [...state.inventoryextra, action.payload], loadinginventoryextra: false
            };
        case CLEAR_ALL:
            return {
                ...state,
                inventoryextra: []
            };
        default:
            return state;
    }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_INVENTORIES:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, inventories: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }

import React from 'react'
import { Bar } from 'react-chartjs-2'

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
const CarLeads = ({ carViews }) => {
  const [carLeadLabel, setCarLeadLabel] = React.useState([])
  const [carLeads, setCarLeads] = React.useState([])

  const [showHighest, setShowHighest] = React.useState(true)

  React.useEffect(() => {
    if (carViews) {

      if (showHighest) {

        let car_lead_label = [];
        let car_leads = [];
        carViews.filter(fl => fl.inv_status === 4)
          .sort((a, b) => parseFloat(b.leads) - parseFloat(a.leads))
          .map((p) => {
            car_lead_label.push(`${p.vehicle} (${p.status})`)
            car_leads.push(p.leads)
          })

        setCarLeadLabel(car_lead_label.slice(0, 20));
        setCarLeads(car_leads.slice(0, 20));

      } else {

        let car_lead_label = [];
        let car_leads = [];

        carViews.filter(fl => fl.inv_status === 4 &&
          fl.leads > 0)
          .sort((a, b) => parseFloat(a.leads) - parseFloat(b.leads))
          .map((p) => {
            car_lead_label.push(`${p.vehicle} (${p.status})`)
            car_leads.push(p.leads)
          })

        setCarLeadLabel(car_lead_label.slice(0, 20));
        setCarLeads(car_leads.slice(0, 20));
      }

    }

  }, [carViews, showHighest])


  const data = {
    labels: carLeadLabel,
    datasets: [
      {
        label: '# Leads',
        data: carLeads,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 52, 0.2)',
          'rgba(255, 123, 64, 0.2)',
          'rgba(85, 25, 50, 0.2)',
          'rgba(200, 95, 36, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 52, 0.2)',
          'rgba(255, 123, 64, 0.2)',
          'rgba(85, 25, 50, 0.2)',
          'rgba(200, 95, 36, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 52, 1)',
          'rgba(255, 123, 64, 1)',
          'rgba(85, 25, 50, 1)',
          'rgba(200, 95, 36, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 52, 1)',
          'rgba(255, 123, 64, 1)',
          'rgba(85, 25, 50, 1)',
          'rgba(200, 95, 36, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="7">
              <div className="numbers pull-left">Car Leads</div>
            </Col>
            <Col sm="5">
              <div className="pull-right">
                <Badge style={{ cursor: "pointer" }} color={showHighest ? "success" : "danger"}
                  pill
                  onClick={() => setShowHighest(!showHighest)}>
                  {showHighest ? "Maximum" : "Minimum"}
                </Badge>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h6 className="big-title">
            {/* Total Sales (Value) */}
          </h6>
          <Bar data={data} options={options} />
        </CardBody>

      </Card>

    </>
  )
}

export default CarLeads
import React, { useState } from "react";

import { Row, Col } from "reactstrap";

import CreateEdit from "./CreateEdit"

const InventoryForm = () => {  
  return (
    <>
    <div className="content">
        {/* <NewEdit id={id} /> */}
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <CreateEdit />
            </Col>
          </Row>
        </div>
      </div>

      
    </>
  );
};

export default InventoryForm;

import React from 'react'
import { Bar } from 'react-chartjs-2'

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
const CarLeads = ({ carViews }) => {
  const [carSoldLabel, setCarSoldLabel] = React.useState([])
  const [carSoldNos, setCarSoldNos] = React.useState([])

  const [showHighest, setShowHighest] = React.useState(true)

  React.useEffect(() => {
    if (carViews) {

      if (showHighest) {

        let car_sold_label = [];
        let car_solds = [];
        carViews.filter(fl => fl.inv_status > 4 && fl.days_to_sell > 0)
          .sort((a, b) => parseFloat(b.days_to_sell) - parseFloat(a.days_to_sell))
          .map((p) => {
            car_sold_label.push(`${p.vehicle} (${p.status})`)
            car_solds.push(p.days_to_sell)
          })
          setCarSoldLabel(car_sold_label.slice(0, 30));
          setCarSoldNos(car_solds.slice(0, 30));
      } else {

        let car_sold_label = [];
        let car_solds = [];

        carViews.filter(fl => fl.inv_status > 4 &&
          fl.days_to_sell > 0)
          .sort((a, b) => parseFloat(a.days_to_sell) - parseFloat(b.days_to_sell))
          .map((p) => {
            car_sold_label.push(`${p.vehicle} (${p.status})`)
            car_solds.push(p.days_to_sell)
          })

          setCarSoldLabel(car_sold_label.slice(0, 30));
          setCarSoldNos(car_solds.slice(0, 30));
      }

    }

  }, [carViews, showHighest])

  // React.useEffect(() => {
  //   let car_sold_label = [];
  //   let car_solds = [];

  //   if (carViews) {
  //     carViews.map((p) => {
  //       car_sold_label.push(`${p.vehicle} (${p.status})`)
  //       car_solds.push(p.days_to_sell)
  //     })

  //     setCarSoldLabel(car_sold_label.slice(0, 10));
  //     setCarSoldNos(car_solds.slice(0, 10));
  //   }

  // }, [carViews])


  const data = {
    labels: carSoldLabel,
    datasets: [
      {
        label: '# Days to Sell',
        data: carSoldNos,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 52, 0.2)',
          'rgba(255, 123, 64, 0.2)',
          'rgba(85, 25, 50, 0.2)',
          'rgba(200, 95, 36, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 52, 0.2)',
          'rgba(255, 123, 64, 0.2)',
          'rgba(85, 25, 50, 0.2)',
          'rgba(200, 95, 36, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 52, 0.2)',
          'rgba(255, 123, 64, 0.2)',
          'rgba(85, 25, 50, 0.2)',
          'rgba(200, 95, 36, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 52, 0.2)',
          'rgba(255, 123, 64, 0.2)',
          'rgba(85, 25, 50, 0.2)',
          'rgba(200, 95, 36, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 52, 1)',
          'rgba(255, 123, 64, 1)',
          'rgba(85, 25, 50, 1)',
          'rgba(200, 95, 36, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 52, 1)',
          'rgba(255, 123, 64, 1)',
          'rgba(85, 25, 50, 1)',
          'rgba(200, 95, 36, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 52, 1)',
          'rgba(255, 123, 64, 1)',
          'rgba(85, 25, 50, 1)',
          'rgba(200, 95, 36, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 52, 1)',
          'rgba(255, 123, 64, 1)',
          'rgba(85, 25, 50, 1)',
          'rgba(200, 95, 36, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="7">
              <div className="numbers pull-left">Days to Sell</div>
            </Col>
            <Col sm="5">
              <div className="pull-right">
                <Badge style={{ cursor: "pointer" }} color={showHighest ? "success" : "danger"}
                  pill
                  onClick={() => setShowHighest(!showHighest)}>
                  {showHighest ? "Highest" : "Lowest"}
                </Badge>
              </div>

            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h6 className="big-title">
            {/* Total Sales (Value) */}
          </h6>
          <Bar data={data} options={options} />
        </CardBody>

      </Card>

    </>
  )
}

export default CarLeads
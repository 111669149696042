import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IntlMessages from "crema/helpers/src/IntlMessages";

const StatusButton = styled(Button)(({ theme }) => ({
  fontSize: 12,
  [theme.breakpoints.up("lg")]: {
    fontSize: 14,
  },
}));

const StyledDoneIcon = styled(DoneIcon)(({ theme }) => ({
  marginRight: 4,
  fontSize: 18,
  verticalAlign: "middle",
  [theme.breakpoints.up("sm")]: {
    marginRight: 8,
  },
}));

const StatusToggleButton = ({ selectedTask, closeTask }) => {
  return (
    <>
      {selectedTask.status_text === "Closed" ? (
        <StatusButton
          variant="contained"
          color="primary"
          startIcon={<StyledDoneIcon />}
          onClick={() => closeTask(1)}
        >
          <IntlMessages id="COMPLETED" />
        </StatusButton>
      ) : (
        <StatusButton
          variant="outlined"
          color="primary"
          startIcon={<StyledDoneIcon />}
          onClick={() => closeTask(2)}
        >
          <IntlMessages id="MARK AS COMPLETED" />
        </StatusButton>
      )}
    </>
  );
};

export default StatusToggleButton;

StatusToggleButton.propTypes = {
  selectedTask: PropTypes.object.isRequired,
  closeTask: PropTypes.func,
};

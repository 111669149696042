import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "../constants/api";

import {
  LOAD_PRODUCTS,
  GET_PRODUCTS,
  LOAD_ACTIVE_PRODUCTS,
  REMOVE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_PRODUCT,
  ADD_PRODUCT,
  ONE_PRODUCT,
  FETCH_PRODUCT,
  UPDATE_PRODUCT,
  EDIT_PRODUCT,
  CLEAR_PRODUCT,
  ALL_ERRORS,
} from "constants/actions";

function* loadProduct(action) {
  try {
    const json = yield call(api.GET_PRODUCTS, action.payload);
    yield put({ type: GET_PRODUCTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productLoad() {
  yield takeLatest(LOAD_PRODUCTS, loadProduct);
}

function* loadActiveProduct(action) {
  try {
    const json = yield call(api.GET_ACTIVE_PRODUCTS, action.payload);
    yield put({ type: GET_PRODUCTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productActiveLoad() {
  yield takeLatest(LOAD_ACTIVE_PRODUCTS, loadActiveProduct);
}

function* fetchProduct(action) {
  try {
    const json = yield call(api.FETCH_PRODUCT, action.payload);

    yield put({ type: FETCH_PRODUCT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productFetch() {
  yield takeLatest(ONE_PRODUCT, fetchProduct);
}

function* editProduct(action) {
  try {
    const json = yield call(api.EDIT_PRODUCT, action.payload);
    yield put({ type: EDIT_PRODUCT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productEdit() {
  yield takeLatest(UPDATE_PRODUCT, editProduct);
}

function* createProduct(action) {
  try {
    const json = yield call(api.ADD_PRODUCT, action.payload);
    yield put({ type: ADD_PRODUCT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productCreate() {
  yield takeLatest(CREATE_PRODUCT, createProduct);
}

function* deleteProduct(action) {
  try {
    const json = yield call(api.DELETE_PRODUCT, action.payload);
    yield put({ type: DELETE_PRODUCT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productDelete() {
  yield takeLatest(REMOVE_PRODUCT, deleteProduct);
}

export default function* index() {
  yield all([
    productLoad(),
    productActiveLoad(),
    productFetch(),
    productEdit(),
    productCreate(),
    productDelete(),
  ]);
}

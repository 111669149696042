import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Icon, IconButton, ListItemText } from "@mui/material";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import VerticalItem from "../VerticalItem";
import Box from "@mui/material/Box";
import VerticalCollapseItem from "./VerticalCollapseItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const needsToBeOpened = (pathname, item) => {
  return pathname && isUrlInChildren(item, pathname);
};

const isUrlInChildren = (parent, url) => {
  if (!parent.views) {
    return false;
  }

  for (let i = 0; i < parent.views.length; i++) {
    if (parent.views[i].views) {
      if (isUrlInChildren(parent.views[i], url)) {
        return true;
      }
    }

    if (parent.views[i].path === url || url.includes(parent.views[i].path)) {
      return true;
    }
  }

  return false;
};

const VerticalCollapse = ({ item, level }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(() => needsToBeOpened(pathname, item));

  useEffect(() => {
    if (needsToBeOpened(pathname, item)) {
      setOpen(true);
    }
  }, [pathname, item]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <VerticalCollapseItem
        level={level}
        sidebarTextColor={"rgba(0, 0, 0, 0.87)"}
        button
        component="div"
        className={clsx("menu-vertical-collapse", open && "open")}
        onClick={handleClick}
      >
        {item.icon && (
          <Box
            component="span"
            sx={{
              display: "block",
              mr: 4,
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            {/* <Icon
           
            > */}
            {item.icon}
            {/* <i className={`${item.icon}`}></i> */}
            {/* </Icon> */}
          </Box>
        )}
        <ListItemText
          className="nav-item-content"
          primary={item.name}
          classes={{ primary: "nav-item-text" }}
        />

        <IconButton
          className="nav-item-icon-arrow-btn"
          // sx={{ p: 0, mr: 0.32 }}
          disableRipple
          size="small"
        >
          {open ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
          {/* <Icon className="nav-item-icon-arrow" color="inherit"></Icon> */}
        </IconButton>
      </VerticalCollapseItem>

      {item.views && (
        <Collapse in={open} className="collapse-views">
          {item.views.map((item, index) => (
            <React.Fragment key={index}>
              {item.collapse && (
                <VerticalCollapse item={item} level={level + 1} />
              )}

              {item.visible && <VerticalItem item={item} level={level + 1} />}
            </React.Fragment>
          ))}
        </Collapse>
      )}
    </>
  );
};

VerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    permittedRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    views: PropTypes.array,
    messageId: PropTypes.string,
    type: PropTypes.string,
  }),
  level: PropTypes.number,
};
VerticalCollapse.defaultProps = {};

export default React.memo(VerticalCollapse);

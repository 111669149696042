import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_UPLOAD_FILES, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";

import { currentApi } from "_config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Spinner,
  Fade,
  // Table,
  Row,
  Col,
} from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TablePaginationActions from "mui/tables/TablePaginationActions";
import { BsDownload } from "react-icons/bs";

const List = (props) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  // pagination end

  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams)
    dispatch({
      type: LOAD_UPLOAD_FILES,
      payload: { page: pageactivePage, params: pageParams },
    });
  }, [dispatch, pageParams, pageactivePage]);

  const {
    uploaded_files,
    get_pending,
    get_auth,
    get_errors,
    get_inventory_select,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      uploaded_files: state.fileupload.fileuploads,

      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setUploadedFiles(uploaded_files.results);
  }, [uploaded_files]);

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (uploaded_files) {
      setTotalResults(uploaded_files.count);
      if (uploaded_files.page_size) {
        setPerPage(uploaded_files.page_size);
      }
      setTotalPages(Math.ceil(uploaded_files.count / perPage));
    }
  }, [uploaded_files]);

  const [page, setPage] = React.useState(0);

  // console.log(user)
  const handleClick = (event, newPage) => {
    // console.log(page)
    dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: newPage + 1 });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle tag="h4">Uploaded VIN List</CardTitle>
          <Row>
            {/* <Col md="12">
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakClassName={"page-item"}
                  pageCount={totalPages}
                  marginPagesDisplayed={isMobile ? 0 : 2}
                  pageRangeDisplayed={isMobile ? 0 : 5}
                  onPageChange={handleClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"page-item pagination"}
                  activeClassName={"page-item active"}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  forcePage={activePage}
                />
              </div>
            </Col> */}
            <div className={pending ? "" : "d-none mt-3"}>
              <div className="d-flex justify-content-center">
                <Fade in={pending} tag="h5">
                  <Spinner>Loading...</Spinner>
                </Fade>
              </div>
            </div>
          </Row>
          <div className={pending ? "d-none" : ""}>
            <Row>
              <Col md="12">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Date</StyledTableCell>
                          <StyledTableCell align="center">Name</StyledTableCell>
                          <StyledTableCell align="center">File</StyledTableCell>
                          <StyledTableCell align="center">
                            Status
                          </StyledTableCell>
                          <StyledTableCell align="center">More</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {uploadedFiles
                          ? uploadedFiles.map((p, k) => (
                              <StyledTableRow key={k}>
                                <StyledTableCell>
                                  {p.created_at
                                    ? moment(p.created_at).format("YYYY-MM-DD")
                                    : null}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {p.name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <a
                                    href={currentApi + p.data_file}
                                    target="_blank"
                                    style={{
                                      color: "#1d4aa7",
                                    }}
                                  >
                                    <BsDownload size={20} color={"preAuto"} />
                                  </a>
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {p.status}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <Link
                                    to={`/admin/full-vin-decode-file-detail/${p.id}`}
                                  >
                                    <RemoveRedEyeOutlinedIcon
                                      size={40}
                                      color={"preAuto"}
                                    />
                                  </Link>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[12]}
                      component="div"
                      count={totalResults || 0}
                      rowsPerPage={
                        totalPages > 12
                          ? uploadedFiles.length || 0
                          : totalPages || 0
                      }
                      page={page}
                      onPageChange={handleClick}
                      ActionsComponent={TablePaginationActions}
                      sx={{
                        ".MuiTablePagination-selectLabel": {
                          marginTop: "auto",
                        },
                        ".MuiTablePagination-displayedRows": {
                          marginTop: "auto",
                        },
                      }}
                    />
                  </TableContainer>
                </Paper>
              </Col>
            </Row>
          </div>
          {/* <Row>
            <Col md="12">
              <div className="d-flex justify-content-end">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  // breakLabel={isMobile? '': <a className="page-link">...</a>}
                  breakClassName={"page-item"}
                  pageCount={totalPages}
                  marginPagesDisplayed={isMobile ? 0 : 2}
                  pageRangeDisplayed={isMobile ? 0 : 5}
                  onPageChange={handleClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"page-item pagination"}
                  activeClassName={"page-item active"}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  forcePage={activePage}
                />
              </div>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </>
  );
};

export default List;

  import { SW_INIT, SW_UPDATE } from 'constants/actions';
  
  const initialState = {
    serviceWorkerInitialized: false,
    newVersionAvailable: false,
    waitingWorker: null,
  }

  export default function (state = initialState, action)  {
   
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        newVersionAvailable: action.payload.newVersionAvailable,
        waitingWorker: action.payload.waitingWorker,
      };
    default:
      return state;
  }
}


import { put, call, takeLatest, all } from "redux-saga/effects";
import {
  GET_INVENTORY_ENGINE,
  GET_INVENTORY_ENGINE_PENDING,
  LOAD_INVENTORY_ENGINE,
  FETCH_INVENTORY_ENGINE,
  EDIT_INVENTORY_ENGINE,
  SG_UPDATE_INVENTORY_ENGINE,
  ADD_INVENTORY_ENGINE,
  DELETE_INVENTORY_ENGINE,
  SG_DELETE_INVENTORY_ENGINE,
  ALL_ERRORS,
} from "constants/actions";

import * as api from "constants/api";

function* getAllInventoryEngines(action) {
  yield put({ type: GET_INVENTORY_ENGINE_PENDING });
  try {
    const json = yield call(api.GET_ENGINES, action.payload);
    yield put({ type: GET_INVENTORY_ENGINE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryEngineLoad() {
  yield takeLatest(LOAD_INVENTORY_ENGINE, getAllInventoryEngines);
}

function* deleteInventoryEngine(action) {
  yield put({ type: GET_INVENTORY_ENGINE_PENDING });
  try {
    const json = yield call(api.DELETE_ENGINE, action.payload);
    yield put({ type: DELETE_INVENTORY_ENGINE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryengineDelete() {
  yield takeLatest(SG_DELETE_INVENTORY_ENGINE, deleteInventoryEngine);
}

// function* uploadImage(action) {
//   yield put({ type: INVENTORY_IMAGE_PENDING });
//   console.log(action.payload);
//   try {
//     const json = yield call(api.ADD_INVENTORY_IMAGE, action.payload);
//     console.log(json.data);
//     yield put({ type: ADD_INVENTORYIMAGE, payload: json.data });
//   } catch (e) {
//     yield put({ type: UPLOAD_FAILURE, payload: e });
//     yield put({ type: ALL_ERRORS, message: e });
//   }
// }

// export function* imageUploadRequest() {
//   yield takeLatest(SG_ADD_INVENTORYIMAGE, uploadImage);
// }

// function* loadImages(action) {
//   yield put({ type: GET_CARIMAGES_PENDING });
//   try {
//     const json = yield call(api.GET_INVENTORY_IMAGES, action.payload);

//     yield put({ type: GET_CARIMAGES, payload: json });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, error: e.message });
//   }
// }

// export function* imageLoad() {
//   yield takeLatest(LOAD_CARIMAGES, loadImages);
// }

// function* deleteImage(action) {
//   yield put({ type: DELETE_CARIMAGE_PENDING });
//   try {
//     // api call to delete the car image
//     const json = yield call(api.getPagedInventories, action.payload);
//     yield put({ type: DELETE_CARIMAGE, payload: parseInt(action.payload) });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, error: e.message });
//   }
// }

// export function* imageDelete() {
//   yield takeEvery(CARIMAGE_DELETE, deleteImage);
// }
// // function* fetchImage(action) {
// //     yield put({type: FETCH_INVENTORY_PENDING})
// //     try {
// //     // const json = yield call(api.getPagedInventories, action.payload)
// //     let car = car_images.filter(function(item) { return item.car_id === parseInt(action.payload); })[0]
// //     yield put({ type: FETCH_INVENTORY, payload: car, });
// //     }
// //     catch (e) {
// //     yield put({type: ALL_ERRORS, error: e.message});
// //     }
// // }

// // export function* inventoryFetchOne() {
// //     yield takeLatest(FETCH_INVENTORY_ONE, fetchImage);
// //  }

export default function* index() {
  yield all([
    inventoryEngineLoad(),
    inventoryengineDelete(),
    // imageuploadDelete(),
    // imageLoad(),
    // imageDelete(),
  ]);
}

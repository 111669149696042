import {
  GET_LEADTYPES_PENDING,
  GET_LEADTYPES,
  GET_DEFAULT_LEADTYPES,
  LOAD_LEADTYPES,
  FETCH_LEADTYPES,
  DELETE_LEADTYPE,
  ADD_LEADTYPE,
  UPDATE_LEADTYPES,
  CLEAR_LEADTYPES,
  EDIT_LEADTYPES,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  leadtypes: [],
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEADTYPES_PENDING:
      return {
        ...state,
        pending: true,
    
      };

    case GET_LEADTYPES:
      return {
        ...state,
        leadtypes: action.payload,
    
        pending: false,
      };

      case GET_DEFAULT_LEADTYPES:
      return {
        ...state,
        default: action.payload,
    
        pending: false,
      };

    case FETCH_LEADTYPES:
       console.log(action.payload)
      return {
        ...state,
        [action.payload.id]: action.payload,
    
      };

    case EDIT_LEADTYPES:
      // console.log(action.payload)
      return {
        ...state,
        [action.payload.id]: action.payload,
    
      };

    case DELETE_LEADTYPE:
      return {
        ...state,
        leadtypes: state.leadtypes.filter(
          (Lead) => Lead.id !== action.payload
        ),
      };

    case ADD_LEADTYPE:
      return {
        ...state,
        leadtypes: [...state.leadtypes, action.payload],
    
      };

    case CLEAR_ALL:
      return {
        ...state,
        leadtypes: [],
      };

    default:
      return state;
  }
}

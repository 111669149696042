import React, { useState } from "react";

import PropTypes from "prop-types";
import AppDialog from "../../crema/AppDialog";
import CreateTask from "../Create";

const AddNewTask = ({
  isAddTaskOpen,
  setAddTaskOpen,
  onCloseAddTask,
  selectedDate,
}) => {
  const [taskLabels, setTaskLabels] = useState([]);

  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => onCloseAddTask()}
      title={"Create Task"}
    >
      <CreateTask setAddTaskOpen={setAddTaskOpen} />
    </AppDialog>
  );
};

export default AddNewTask;

AddNewTask.propTypes = {
  isAddTaskOpen: PropTypes.bool.isRequired,
  onCloseAddTask: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
};

import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import { Row, Col, Spinner, Fade, UncontrolledAlert } from "reactstrap";
import Select from "react-select";

import InventoryCardStats from "Dashboard/Reports/InventoryCardStats";
import CarViews from "Dashboard/Reports/CarViews";

import CarDaysToSell from "Dashboard/Reports/CarDaysToSell";
import CarLeads from "Dashboard/Reports/CarLeads";

import { LOAD_LEAD_REPORTS } from "constants/actions";
// import { date } from "faker";

const searchOptions = [
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
    { value: 90, label: "Last 90 days" },
];

const WebLabelColors = [
    { value: 'Canada Drives', color: '#C71585' },
    { value: 'Footpath Marketing', color: '#8B008B' },
    { value: 'Kijiji Leads', color: '#4B0082' },
    { value: 'Loans Canada', color: '#B22222' },
    { value: 'Website lead', color: '#008000' },
    { value: 'Auto Trader Leads', color: '#FFD700' },
    { value: 'autoTRADER', color: '#32CD32' },
    { value: 'kijiji', color: '#008000' },
    { value: 'kijijiautos', color: '#8B008B' },
    { value: 'Stingray', color: '#008080' },
    { value: 'Stingray_Programmatic', color: '#7FFFD4' },
    { value: 'null', color: '#5F9EA0' },
    { value: 'In Progress', color: '#6495ED' },
    { value: 'New', color: '#00008B' },
    { value: 'Need Co-signer', color: '#BC8F8F' },
    { value: 'Processing Application', color: '#D2691E' },
    { value: 'On Hold', color: '#8B4513' },
    { value: 'Interested', color: '#800000' },
    { value: 'Not interested at this time', color: '#696969' },
    { value: 'No Contact', color: '#C71585' },
    { value: 'Junk - Un contactable', color: '#8B008B' },
    { value: 'Contact Us', color: '#4B0082' },
    { value: 'Credit Application', color: '#32CD32' },
    { value: 'Interested in Car', color: '#FF4500' },
    { value: 'facebook', color: '#FF4500' },
    { value: null, color: '#DCDCDC' },
]

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}
const InventoryReports = () => {
    const dispatch = useDispatch();
    const [pageparams, setPageparams] = React.useState(null);
    const [pending, setPending] = React.useState();
    const [totals, setTotals] = React.useState([]);
    const [carViews, setCarViews] = React.useState([]);
    const [carStatus, setCarStatus] = React.useState('live');

    const [daysSelected, setDaysSelected] = React.useState({
        value: 7,
        label: "Last 7 days",
    });

    const [carLabel, setCarLabel] = React.useState([]);
    const [carNos, setCarNos] = React.useState([]);

    React.useEffect(() => {
        // Run! Like go get some data from an API.
        dispatch({ type: LOAD_LEAD_REPORTS, payload: { params: pageparams } });
    }, [dispatch, pageparams]);

    const { get_reports, get_pending, get_car_views,
        get_lead_rotation } = useSelector(
            (state) => ({
                get_reports: state.reports.lead_reports,
                get_pending: state.reports.pending,
                get_car_views: state.reports.car_views,
                get_lead_rotation: state.reports.lead_reports.lead_rotation,
            }),
            shallowEqual
        );

    // console.log(get_reports)
    // console.log(get_reports.car_views);
    // console.log(totals)
    const ChangeDaySelected = (e) => {
        setDaysSelected(e);
        setPageparams(`days=${e.value}`);
    };

    React.useEffect(() => {
        setPending(get_pending);
    }, [get_pending]);

    // console.log(get_reports?.totals?.websource_name)
    React.useEffect(() => {
        if (get_reports?.results) {
            setTotals(get_reports.totals);

            let car_label = [];
            let car_nos = [];
            if (carStatus === 'live') {
                get_reports.car_views.filter(k => k.inv_status === 4).map((p) => {
                    car_label.push(`${p.vehicle} (${p.status})`)
                    car_nos.push(p.page_views)
                })
            } else {
                get_reports.car_views.map((p) => {
                    car_label.push(`${p.vehicle} (${p.status})`)
                    car_nos.push(p.page_views)
                })
            }

            setCarLabel(car_label.slice(0, 20));
            setCarNos(car_nos.slice(0, 20));
            setCarViews(get_reports.car_views)
        }

    }, [get_reports, carStatus]);

    return (
        <>
            <div className={pending ? "" : "d-none mt-3"}>
                <Fade in={pending} tag="h5">
                    <div className="d-flex justify-content-center">
                    <Spinner>
                Loading...
              </Spinner>
                    </div>
                </Fade>
            </div>
            <div className={pending ? "d-none mt-3" : ""}>
                {/* <BarChart /> */}

                <InventoryCardStats totals={totals}
                    days={daysSelected.value}
                    get_lead_rotation={get_lead_rotation} />


                {/* <Row>
                    <Col md="4" sm="12">
                        <TotalCustomers
                            dateLabel={dateLabel}
                            customerLabel={customerLabel}
                        />
                    </Col>
                    <Col md="4" sm="12">
                        <TotalLeads dateLabel={dateLabel} 
                        leadLabel={leadLabel} />
                    </Col>

                    <Col md="4" sm="12">
                        <TotalVisits dateLabel={dateLabel} 
                        leadLabel={webVisits} />
                    </Col>
                </Row> */}

                <CarViews
                    carLabel={carLabel}
                    carNos={carNos}
                    carStatus={carStatus}
                    setCarStatus={setCarStatus}
                />

                <CarDaysToSell carViews={carViews} />
                <CarLeads carViews={carViews} />


            </div>
        </>
    );
};

export default InventoryReports;
